/*
    Defines the API route we are using.
*/

var api_url = '';
var web_url = '';


switch( process.env.NODE_ENV ){
  case 'development':
    api_url = 'http://192.168.1.67:8000/api';
    web_url = 'http://192.168.1.67:8000';
  break;
 
  case 'production':
    api_url = 'https://api.florexpol.eu/api';
    web_url = 'https://api.florexpol.eu';
  break;
}



export const FLOREX_CONFIG = {
  API_URL: api_url,
  WEB_URL: web_url,
  VUE_APP_PLATFORM:process.env.VUE_APP_PLATFORM
}

import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import colors from 'vuetify/lib/util/colors'
import pl from 'vuetify/es5/locale/pl';

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.blue.darken4,
        secondary: colors.green.darken4,
        error: colors.red.darken3,
      },
    },
  },
  lang: {
    locales: {pl},
    current: 'pl',
  },
});

<template>
 <v-row no-gutters>
      <v-col>
          <v-sheet flat>
          <v-card flat>
              <v-data-table
                  :key="componentKey"
                  hide-default-footer
                  :headers="headers"
                  :items="tableItems"
                  class="elevation-1"
                  :options.sync="tableOptions"
                  :server-items-length="options.lineCount"
                  :loading="integrationDataTypesLoadStatus===1"
                  must-sort
                  dense
              >
                <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-toolbar-title>Typy danych</v-toolbar-title>
                    <v-divider
                      class="mx-4"
                      inset
                      vertical
                    ></v-divider>
                    

                    <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-on="on"
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Wyszukaj"
                          single-line
                          hide-details
                          style="max-width: 250px;"
                          @keydown.enter="searchIntegrationDataTypes"
                      ></v-text-field>
                      </template>
                        <span>Wpisz min 3 znaki i naciśnij Enter</span>
                    </v-tooltip>

                    <v-spacer></v-spacer>

                    
                    <v-dialog v-model="dialog" max-width="700px">
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" small class="mb-2 mt-2" fab dark color="primary">
                        <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <v-card :loading="integrationDataTypeLoadStatus===1 || integrationDataTypeEditStatus===1 || integrationDataTypeEditLoadStatus===1">
                       <v-card-title>
                        <span class="headline">{{itemEditing?'Edycja typu':'Nowy typ'}}</span>
                        </v-card-title>

                        <v-card-text>
                        <v-container>
                            <v-row>     
                              <v-col cols="12" sm="8" md="8">
                                  <v-text-field v-model="editedItem.name" :error-messages="nameErrors"  @input="$v.editedItem.name.$touch()"  @blur="$v.editedItem.name.$touch()"  label="Nazwa"></v-text-field>                            
                              </v-col>
                              <v-col cols="12" sm="4" md="4">
                                  <v-select v-model="editedItem.type" :items="lookups.dataTypes" :error-messages="typeErrors"  @input="$v.editedItem.type.$touch()"  @blur="$v.editedItem.type.$touch()"  label="Typ"></v-select>                            
                              </v-col>
                              <v-col cols="12" sm="6" md="6">
                                  <v-text-field v-model="editedItem.label" :error-messages="labelErrors"  @input="$v.editedItem.label.$touch()"  @blur="$v.editedItem.label.$touch()"  label="Etykieta"></v-text-field>                            
                              </v-col>
                              <v-col cols="12" sm="6" md="6">
                                  <v-text-field v-model="editedItem.description" label="Opis"></v-text-field>                            
                              </v-col>
                              <v-col cols="12" sm="12" md="12">
                                  <p>Wartość</p>
                                  <vue-json-editor v-model="editedItem.value" :show-btns="false" mode="code" :expandedOnStart="true"></vue-json-editor>
                              </v-col>
                            </v-row>
                            <v-row v-if="integrationDataTypeEditStatus===3">
                                    <v-col class="d-flex flex-column align-center">
                                        <v-alert v-for="err in errorMessageArray(integrationDataTypeEditText)" :key="err.index" dense outlined type="error">
                                          {{err}}
                                        </v-alert>    
                                    </v-col>
                            </v-row>   
                            <v-row v-if="integrationDataTypeAddStatus===3">
                                    <v-col class="d-flex flex-column align-center">
                                        <v-alert v-for="err in errorMessageArray(integrationDataTypeAddText)" :key="err.index" dense outlined type="error">
                                          {{err}}
                                        </v-alert>    
                                    </v-col>
                            </v-row>          
                               
                        </v-container>
                        </v-card-text>

                           
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="grey" text @click="close">Zamknij</v-btn>
                          <v-btn color="primary darken-1" text @click="handleSubmit">Zapisz</v-btn>
                        </v-card-actions>
                    </v-card>
                    </v-dialog>
                </v-toolbar>
                </template>
                <template v-slot:item.value="{ item }">
                  {{ item.value?_.truncate(JSON.stringify(item.value),{'length':50}):'' }}
                </template>
                 <template v-slot:item.actions="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon small @click="deleteItem(item)">
                    mdi-delete
                  </v-icon>
                </template>
            </v-data-table> 
            <div class="text-center pt-2">
            <v-btn @click="loadMore" v-if="!options.itemAdded && options.lineCount-tableItems.length>0 && integrationDataTypesLoadStatus===2" small color="primary"><v-icon left>mdi-download</v-icon>Załaduj więcej ({{ options.lineCount-tableItems.length }})</v-btn>
            <v-btn @click="loadIntegrationDataTypes" v-else-if="options.itemAdded && options.lineCount-tableItems.length>0" small color="primary"><v-icon left>mdi-reload</v-icon>Odśwież tabele aby móc załadować więcej ({{ options.lineCount-tableItems.length }})</v-btn>
            <v-btn @click="loadIntegrationDataTypes" v-else-if="options.itemSearched" small color="primary"><v-icon left>mdi-reload</v-icon>Anuluj wyszukiwanie</v-btn>
          
          </div>
            <confirm ref="confirm"></confirm>
          </v-card>
          </v-sheet>
          </v-col>
      <v-col md="auto">
        <menuIntegracjeAdmin />
      </v-col>
    </v-row>
</template>

<script>
  import confirm from '../components/Confirm'
  import menuIntegracjeAdmin from '../components/MenuIntegracjeAdmin'
  import required from 'vuelidate/lib/validators/required'
  import vueJsonEditor from 'vue-json-editor'

  export default {
    components:{
      confirm,
      menuIntegracjeAdmin,
      vueJsonEditor
    },
    data: () => ({
      componentKey:0,
      tableOptions: {},
      search:'',
      dialog: false,
      itemEditing:false,
      headers: [
        { text: 'Lp',align: 'start',sortable: false,value: 'lp',filterable:false},
        { text: 'Nazwa', value: 'name',sortable: true,filterable:true,filterColumn:"name" },
        { text: 'Typ', value: 'type',sortable: true,filterable:true,filterColumn:"type" },
        { text: 'Wartość', value: 'value',sortable:true,filterable:true,filterColumn:"value" },
        { text: 'Etykieta', value: 'label',sortable:true,filterable:true,filterColumn:"label"  },
        { text: 'Opis', value: 'description',sortable:true,filterable:true,filterColumn:"description"  },
        { text: '', value: 'actions', sortable: false,filterable:false},
      ],
    }),
    validations:{
      editedItem:{
          name:{
            required
          },
          label:{
            required
          },
          type:{
            required
          },
      }
    },
    watch: {
      tableOptions: {
        handler () {
          var currentOptions = {...this.options};
          const { sortBy, sortDesc } = this.tableOptions
        
          //zmiana sortowania
          if (sortBy.length === 1 && sortDesc.length === 1) {
            currentOptions.orderBy = sortBy[0];
            currentOptions.orderDirection = sortDesc[0]?'desc':'asc';
            this.options = currentOptions;
          } 
        },
        deep: true,
      },
      dialog(val) {
        this.$v.$reset()
        if(val){
        }else{
          this.close()
        }
      },
      integrationDataTypeEditStatus(val){
        if(val===2){
          this.componentKey++;
          this.close();
        }
      },
      integrationDataTypeAddStatus(val){
        if(val===2){
          this.componentKey++;
          this.close();
        }
      }
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      lookups(){
        return this.$store.getters.getLookups;
      },
      editedItem(){
        return this.$store.getters.getIntegrationDataTypeEdit;
      },
      options:{
        get () {  
          return this.$store.getters.getIntegrationDataTypeOptions;
        },
        set (value) {
          this.search='';
          this.$store.dispatch( 'setIntegrationDataTypeOptions', {options:value} )
        }
      },
    
      tableItems(){
        return this.$store.getters.getIntegrationDataTypes;
      },
      integrationDataTypeLoadStatus(){
        return this.$store.getters.getIntegrationDataTypeLoadStatus;
      },
      integrationDataTypesLoadStatus(){
        return this.$store.getters.getIntegrationDataTypesLoadStatus;
      },
      integrationDataTypeEditLoadStatus(){
        return this.$store.getters.getIntegrationDataTypeEditLoadStatus
      },
      integrationDataTypeEditStatus(){
        return this.$store.getters.getIntegrationDataTypeEditStatus();
      },
      integrationDataTypeAddStatus(){
        return this.$store.getters.getIntegrationDataTypeAddStatus();
      },
      integrationDataTypeEditText(){
        return this.$store.getters.getIntegrationDataTypeEditText
      },
      integrationDataTypeAddText(){
        return this.$store.getters.getIntegrationDataTypeAddText
      },
      nameErrors () {
        const errors = []
        if (!this.$v.editedItem.name.$dirty) return errors
        !this.$v.editedItem.name.required && errors.push('Nazwa jest wymagana')
        return errors
      },
      labelErrors () {
        const errors = []
        if (!this.$v.editedItem.label.$dirty) return errors
        !this.$v.editedItem.label.required && errors.push('Etykieta jest wymagana')
        return errors
      },
      typeErrors () {
        const errors = []
        if (!this.$v.editedItem.type.$dirty) return errors
        !this.$v.editedItem.type.required && errors.push('Typ jest wymagany')
        return errors
      },

    },
    mounted(){
      if(this.lookups.integrations.length>0){
        this.loadIntegrationDataTypes();
      }else{
        this.$store.dispatch( 'loadLookups' );
        setTimeout(() => {
          this.loadIntegrationDataTypes();
        }, 1000)
      }
      
    },
    methods: {
      loadIntegrationDataTypes(){
        this.search='';
        this.$store.dispatch( 'loadIntegrationDataTypes' );
      },
      loadMore(){
        this.$store.dispatch( 'loadMoreIntegrationDataTypes' );
      },
      editItem(item) {
        this.itemEditing = true
        this.$store.dispatch( 'loadIntegrationDataTypeEdit', {slug:  item.id}); 
        this.dialog = true
      },
      searchIntegrationDataTypes(){
        if(this.search.length>2){
          const filterableHeaders = _.join(_.map(_.filter(this.headers,{'filterable':true}),'filterColumn'),';');
          this.$store.dispatch( 'searchIntegrationDataTypes',{filterableHeaders:filterableHeaders,q:this.search});
        }
      },
      errorMessageArray(val) {
        const errors = val;
        var err = [];
        if(!errors)return [];

        if(errors.name){
          errors.name.forEach(e => {
            err.push(e);
          });
        }
      
        return err;
      },     
      deleteItem (item) {
        this.$refs.confirm.open('Usuń', 'Czy na pewno chcesz usunać typ?', { color: 'error' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'forceDeleteIntegrationDataType', {slug:  item.id});
            }
        })
      },
      close () {
        this.dialog = false
        this.$v.$reset()
        setTimeout(() => {
          this.$store.dispatch( 'loadIntegrationDataTypeEditDefault'); 
          this.$store.dispatch( 'loadIntegrationDataTypeAddDefault');
          this.itemEditing = false
        }, 300)
      },
      handleSubmit () {
        this.$v.$touch()
        if (this.$v.$invalid) {

        } else {
           if (this.itemEditing) {
              this.$store.dispatch( 'editIntegrationDataType', {
                slug:  this.editedItem.id,
                name:  this.editedItem.name,
                type:  this.editedItem.type,
                value:  this.editedItem.value,
                label:  this.editedItem.label,
                description:  this.editedItem.description
              });
           }else{
              this.$store.dispatch( 'newIntegrationDataType', {
                name:  this.editedItem.name,
                type:  this.editedItem.type,
                value:  this.editedItem.value,
                label:  this.editedItem.label,
                description:  this.editedItem.description
              });
           }
        }
       
      },
    },
  }
</script>
<style>
.search{
      max-width: 250px;
}
.jsoneditor-vue{
      height: 300px;
}
</style>
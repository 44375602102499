var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Historia zadania")]),_c('v-spacer'),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"150px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"choose_date mt-6 mr-5",attrs:{"label":"Od","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.logsDateFrom),callback:function ($$v) {_vm.logsDateFrom=$$v},expression:"logsDateFrom"}},on))]}}]),model:{value:(_vm.dateLogsFromMenu),callback:function ($$v) {_vm.dateLogsFromMenu=$$v},expression:"dateLogsFromMenu"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","no-title":""},on:{"input":function($event){_vm.dateLogsFromMenu = false}},model:{value:(_vm.logsDateFrom),callback:function ($$v) {_vm.logsDateFrom=$$v},expression:"logsDateFrom"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"150px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"choose_date mt-6 mr-5",attrs:{"label":"Do","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.logsDateTo),callback:function ($$v) {_vm.logsDateTo=$$v},expression:"logsDateTo"}},on))]}}]),model:{value:(_vm.dateLogsToMenu),callback:function ($$v) {_vm.dateLogsToMenu=$$v},expression:"dateLogsToMenu"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","no-title":""},on:{"input":function($event){_vm.dateLogsToMenu = false}},model:{value:(_vm.logsDateTo),callback:function ($$v) {_vm.logsDateTo=$$v},expression:"logsDateTo"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"5","md":"5"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tableItems,"loading":_vm.integrationTaskLogsLoadStatus===1,"must-sort":"","dense":"","items-per-page":_vm.itemsPerPage},scopedSlots:_vm._u([{key:"top",fn:function(){return undefined},proxy:true},{key:"item.on_demand",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.on_demand),callback:function ($$v) {_vm.$set(item, "on_demand", $$v)},expression:"item.on_demand"}})]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.durationToHuman(item.duration))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ml-2",attrs:{"color":_vm.selectedTaskLogId===item.id?'tertiary':'primary',"dark":"","x-small":""},on:{"click":function($event){return _vm.openLogActions(item)}}},[_vm._v("Pokaż"),_c('v-icon',{attrs:{"right":"","dark":"","size":"15"}},[_vm._v("mdi-arrow-right-thick")])],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"7","md":"7"}},[_c('v-dialog',{attrs:{"max-width":"700"},model:{value:(_vm.dialogData),callback:function ($$v) {_vm.dialogData=$$v},expression:"dialogData"}},[_c('v-card',[_c('v-card-text',{staticClass:"pt-5"},[_c('vue-json-pretty',{attrs:{"data":_vm.dialogDataValue,"showDoubleQuotes":false,"showLine":false}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":function($event){_vm.dialogData=false}}},[_vm._v("Zamknij")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersActions,"items":_vm.tableItemsActions,"loading":_vm.integrationTaskActionsLogsLoadStatus===1,"dense":"","items-per-page":_vm.itemsPerPage},scopedSlots:_vm._u([{key:"top",fn:function(){return undefined},proxy:true},{key:"item.label",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.label)+" "),(item.parent_task_id != null)?_c('v-icon',{staticStyle:{"margin-top":"-2px"},attrs:{"size":"20","color":"primary"},on:{"click":function($event){return _vm.openLogActionsParent(item)}}},[_vm._v("mdi-arrow-right-thick")]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status)+" ")]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.durationToHuman(item.duration))+" ")]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [(item.error)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"label":"","small":""}},on),[_vm._v(_vm._s(item.code))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.error))])]):_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(_vm._s(item.code))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.conditions)?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openData(item.conditions)}}},on),[_vm._v("mdi-arrow-decision-outline")]):_c('v-icon',_vm._g({staticClass:"mr-2"},on),[_vm._v("mdi-arrow-decision-outline")])]}}],null,true)},[_c('span',[_vm._v("Warunki")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.filters)?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openData(item.filters)}}},on),[_vm._v("mdi-filter-outline")]):_c('v-icon',_vm._g({staticClass:"mr-2"},on),[_vm._v("mdi-filter-outline")])]}}],null,true)},[_c('span',[_vm._v("Filtry")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.input)?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openData(item.input)}}},on),[_vm._v("mdi-import")]):_c('v-icon',_vm._g({staticClass:"mr-2"},on),[_vm._v("mdi-import")])]}}],null,true)},[_c('span',[_vm._v("Dane wejściowe")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.output)?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openData(item.output)}}},on),[_vm._v("mdi-export")]):_c('v-icon',_vm._g({staticClass:"mr-2"},on),[_vm._v("mdi-export")])]}}],null,true)},[_c('span',[_vm._v("Dane wyjściowe")])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { FLOREX_CONFIG } from '../config'
import axios from 'axios'

export default {

    /*
        GET     /api/groups
    */
    getGroups: function(options){
        return axios.get( FLOREX_CONFIG.API_URL + '/groups',{
            params:{
                offset:options.offset,
                itemsPerPage:options.itemsPerPage,
                orderBy:options.orderBy,
                orderDirection:options.orderDirection,
                showDeleted:options.showDeleted,
        }});
    },

    /*
        GET     /api/groups/search
    */
   searchGroups: function(options){
    return axios.get( FLOREX_CONFIG.API_URL + '/groups/search',{
        params:{
            showDeleted:options.showDeleted,
            filterableHeaders:options.filterableHeaders,
            q:options.q
    }});
},

    /*
		POST 	/api/groups
	*/
    postAddNewGroup: function( name,order,shipment,document, desktop, dictionary, setting ){
        return axios.post( FLOREX_CONFIG.API_URL + '/groups',
            {
                name: name,
                order:order,
                shipment:shipment,
                document:document,
                desktop: desktop,
                dictionary: dictionary,
                setting: setting,
            }
        );
    },

    /*
		GET 	/api/groups/{slug}/edit
	*/
    getGroupEdit: function( slug ){
		return axios.get( FLOREX_CONFIG.API_URL + '/groups/' + slug + '/edit' );
	},

    /*
		PUT 	/api/groups/{slug}
	*/
    putEditGroup: function(slug, name,order,shipment,document, desktop, dictionary, setting){
        return axios.put( FLOREX_CONFIG.API_URL + '/groups/'+ slug,
            {
                name: name,
                order:order,
                shipment:shipment,
                document:document,
                desktop: desktop,
                dictionary: dictionary,
                setting: setting,
            }
        );
    },

     /*
		GET 	/api/groups/reactivate/{slug}
	*/
    putReactivateGroup: function(slug){
        return axios.get( FLOREX_CONFIG.API_URL + '/groups/reactivate/' + slug);
    },

    /*
		DELETE /api/groups/{slug}
	*/
    deleteGroup: function( slug ){
		return axios.delete( FLOREX_CONFIG.API_URL + '/groups/' + slug );
    },
    
    /*
		DELETE /api/groups/{slug}/force
	*/
    forceDeleteGroup: function( slug ){
		return axios.delete( FLOREX_CONFIG.API_URL + '/groups/' + slug + '/force' );
	}
}
<template>
    <v-container>
      <v-row>
      <v-toolbar flat color="white">
                    <v-toolbar-title>Historia zadania</v-toolbar-title>
                    
                    <!-- <v-divider
                    class="mx-4"
                    inset
                    vertical
                    ></v-divider> -->
                    

                    <v-spacer></v-spacer>
                    <v-menu
                                      v-model="dateLogsFromMenu"
                                      :close-on-content-click="false"
                                      :nudge-right="40"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="150px"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                          v-model="logsDateFrom"
                                          label="Od"
                                          prepend-icon="mdi-calendar"
                                          readonly
                                          v-on="on"
                                          class="choose_date mt-6 mr-5"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker  first-day-of-week="1" no-title v-model="logsDateFrom" @input="dateLogsFromMenu = false"></v-date-picker>
                      </v-menu>
                      <v-menu
                                      v-model="dateLogsToMenu"
                                      :close-on-content-click="false"
                                      :nudge-right="40"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="150px"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                          v-model="logsDateTo"
                                          label="Do"
                                          prepend-icon="mdi-calendar"
                                          readonly
                                          v-on="on"
                                          class="choose_date mt-6 mr-5"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker first-day-of-week="1" no-title v-model="logsDateTo" @input="dateLogsToMenu = false"></v-date-picker>
                      </v-menu>   
      </v-toolbar>
      </v-row>
      <v-row>
      <v-col cols="12" sm="5" md="5">
            <v-data-table
                :headers="headers"
                :items="tableItems"
                class="elevation-1"
                :loading="integrationTaskLogsLoadStatus===1"
                must-sort
                dense
                :items-per-page="itemsPerPage"
            >
                <template v-slot:top>
                
                </template>
                <template v-slot:item.on_demand="{ item }">
                  <v-simple-checkbox v-model="item.on_demand" disabled></v-simple-checkbox>
                </template>
                <template v-slot:item.duration="{ item }">
                  {{durationToHuman(item.duration)}}
                </template>
                <template v-slot:item.status="{ item }">
                  {{item.status}}
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn @click="openLogActions(item)" :color="selectedTaskLogId===item.id?'tertiary':'primary'" dark x-small class="ml-2">Pokaż<v-icon right dark size="15">mdi-arrow-right-thick</v-icon></v-btn>
                </template>
            </v-data-table> 
        </v-col>

      <v-col cols="12" sm="7" md="7">
        <v-dialog v-model="dialogData" max-width="700">
                    <v-card>
                        <v-card-text class="pt-5">
                            <vue-json-pretty
                              :data="dialogDataValue"
                              :showDoubleQuotes="false"
                              :showLine="false"
                             >
                            </vue-json-pretty>
                      
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="grey" text @click="dialogData=false">Zamknij</v-btn>
                        </v-card-actions>
                    </v-card>
        </v-dialog>

        <v-data-table
                :headers="headersActions"
                :items="tableItemsActions"
                class="elevation-1"
                :loading="integrationTaskActionsLogsLoadStatus===1"
                dense
                :items-per-page="itemsPerPage"
            >
                <template v-slot:top>
                
                </template>
                <!-- <template v-slot:item.on_demand="{ item }">
                  <v-simple-checkbox v-model="item.on_demand" disabled></v-simple-checkbox>
                </template> -->
                <template v-slot:item.label="{ item }">
                  {{item.label}} 
                  <v-icon @click="openLogActionsParent(item)" v-if="item.parent_task_id != null" style="margin-top:-2px;" size="20" color="primary">mdi-arrow-right-thick</v-icon>
                </template>
                <template v-slot:item.status="{ item }">
                  {{item.status}}
                </template>
                <template v-slot:item.duration="{ item }">
                  {{durationToHuman(item.duration)}}
                </template>
                <template v-slot:item.code="{ item }">
                  <v-tooltip left v-if="item.error">
                      <template v-slot:activator="{ on }">
                          <v-chip v-on="on" label small>{{item.code}}</v-chip>
                      </template>
                           <span>{{item.error}}</span>
                  </v-tooltip>
                  <v-chip v-else label small>{{item.code}}</v-chip>
                </template>
                <template v-slot:item.actions="{ item }">
                  <!-- <v-btn @click="openLogActions(item)" color="primary" x-small class="ml-2">Pokaż<v-icon right dark size="15">mdi-arrow-right-thick</v-icon></v-btn> -->
                  <v-tooltip right >
                                      <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" v-if="item.conditions" @click="openData(item.conditions)" color="primary" class="mr-2">mdi-arrow-decision-outline</v-icon>
                                        <v-icon v-on="on" v-else class="mr-2">mdi-arrow-decision-outline</v-icon>
                                      </template>
                                      <span>Warunki</span>
                  </v-tooltip>
                  <v-tooltip right >
                                      <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" v-if="item.filters" @click="openData(item.filters)" color="primary" class="mr-2">mdi-filter-outline</v-icon>
                                        <v-icon v-on="on" v-else class="mr-2">mdi-filter-outline</v-icon>
                                      </template>
                                      <span>Filtry</span>
                  </v-tooltip>
                  <v-tooltip right >
                                      <template v-slot:activator="{ on }">
                                        <v-icon v-on="on"  v-if="item.input" @click="openData(item.input)" color="primary" class="mr-2">mdi-import</v-icon>
                                        <v-icon v-on="on"  v-else class="mr-2">mdi-import</v-icon>
                                      </template>
                                      <span>Dane wejściowe</span>
                  </v-tooltip>
                  <v-tooltip right >
                                      <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" v-if="item.output" @click="openData(item.output)" color="primary" class="mr-2">mdi-export</v-icon>
                                        <v-icon v-on="on" v-else class="mr-2">mdi-export</v-icon>
                                      </template>
                                      <span>Dane wyjściowe</span>
                  </v-tooltip>

                </template>
            </v-data-table> 
      </v-col>
    </v-row>
    </v-container>
          
</template>

<script>
  import confirm from '../components/Confirm'
  import VueJsonPretty from 'vue-json-pretty'
  import 'vue-json-pretty/lib/styles.css';

  export default {
    components:{
      confirm,
      VueJsonPretty
    },
    props:['tId'],
    data: () => ({
      itemsPerPage:20,
      tableOptions: {},
      search:'',
      dateLogsFromMenu:false,
      dateLogsToMenu:false,
      selectedTaskLogId:undefined,
      dialogData:false,
      dialogDataValue:undefined,
      headers: [
        { text: 'Data uruchomienia', value: 'created_at',filterable:true },
        { text: 'Na żądanie', value: 'on_demand',filterable:true },
        { text: 'Status', value: 'status',filterable:false },
        { text: 'Czas', value: 'duration',filterable:false },
        { text: '', value: 'actions', sortable: false },
      ],
      headersActions: [
        { text: 'Akcja', value: 'label',filterable:false },
        { text: '', value: 'actions', sortable: false },
        { text: 'Czas', value: 'duration',filterable:false },
        { text: 'Status', value: 'status',filterable:false },
        { text: 'Kod', value: 'code',filterable:false },
      ],
    }),
    watch: {
      tableOptions: {
        handler () {
          var currentOptions = {...this.options};
          const { sortBy, sortDesc } = this.tableOptions
        
          //zmiana sortowania
          if (sortBy.length === 1 && sortDesc.length === 1) {
            currentOptions.orderBy = sortBy[0];
            currentOptions.orderDirection = sortDesc[0]?'desc':'asc';
            this.options = currentOptions;
          } 
        },
        deep: true,
      },
      tId(value){
        if(value){
          this.taskId = value;
          this.selectedTaskLogId = undefined
        }
        

        //this.$store.dispatch( 'loadIntegrationTaskActionsLogs', {slug:0} )
      }
    },
    computed: {
      
      options:{
        get () {  
          return this.$store.getters.getIntegrationTaskLogsOptions;
        },
        set (value) {
          this.search='';
          this.selectedTaskLogId = undefined;
          this.$store.dispatch( 'setIntegrationTaskLogsOptions', {options:value} )
        }
      },
      logsDateFrom: {
        get () {
          return this.$store.getters.getIntegrationTaskLogsOptions.fromDate;
        },
        set (value) {
          var currentOptions = {...this.$store.getters.getIntegrationTaskLogsOptions};
          currentOptions.fromDate = value;
          this.options = currentOptions;
        }
      },
      taskId: {
        get () {
          return this.$store.getters.getIntegrationTaskLogsOptions.taskId;
        },
        set (value) {
          var currentOptions = {...this.$store.getters.getIntegrationTaskLogsOptions};
          currentOptions.taskId = value;
          this.options = currentOptions;
        }
      },
      logsDateTo: {
        get () {
          return this.$store.getters.getIntegrationTaskLogsOptions.toDate;
        },
        set (value) {
          var currentOptions = {...this.$store.getters.getIntegrationTaskLogsOptions};
          currentOptions.toDate = value;
          this.options = currentOptions;
        }
      },
      tableItems(){
        return this.$store.getters.getIntegrationTaskLogs;
      },
      integrationTaskLogsLoadStatus(){
        return this.$store.getters.getIntegrationTaskLogsLoadStatus();
      },
      tableItemsActions(){
        return this.$store.getters.getIntegrationTaskActionsLogs;
      },
      integrationTaskActionsLogsLoadStatus(){
        return this.$store.getters.getIntegrationTaskActionsLogsLoadStatus();
      },

    },
    mounted(){
      this.loadIntegrationTaskLogs();
    },
    methods: {
      durationToHuman(val){
        if(!val)return '';

        if(val < 1000){
          return val + 'ms';
        }else if(val < 60*1000){
          return Math.floor(val/1000) + 's';
        }else{
          return Math.floor(val/(60*1000)) + 'm';
        }
      },
      loadIntegrationTaskLogs(){
        var currentOptions = {...this.$store.getters.getIntegrationTaskLogsOptions};
        var dateFrom = new Date(); 
        var dateTo = new Date(); 
        var dateFrom = new Date(dateFrom.setDate(dateTo.getDate()-1));
        currentOptions.taskId = this.tId;
        currentOptions.fromDate = dateFrom.toISOString().substr(0,10);
        currentOptions.toDate = dateTo.toISOString().substr(0,10);
        this.options = currentOptions;
      },
      openLogActions(item){
        this.selectedTaskLogId = item.id;
        this.$store.dispatch( 'loadIntegrationTaskActionsLogs', {slug:item.id} )
      },
       openLogActionsParent(item){
        this.$store.dispatch( 'loadIntegrationTaskActionsLogs', {slug:item.parent_task_id} )
      },
      openData(value){
        this.dialogDataValue = value;
        this.dialogData = true;
      }
    },
  }
</script>


<style scoped>
.search{
      max-width: 200px;
}
.choose_date{
      max-width: 150px;
}
</style>

<template>
          <v-sheet flat>
        
          <v-card flat>
              <v-data-table
                  :key="componentKey"
                  hide-default-footer
                  disable-pagination
                  :headers="computedHeaders"
                  :items="tableItems"
                  class="elevation-1"
                  :options.sync="tableOptions"
                  :server-items-length="1"
                  :loading="integrationsLoadStatus===1 || integrationEditStatus===1 || integrationEditLoadStatus===1"
                  must-sort
              >
                <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-toolbar-title>Ustawienia integracji</v-toolbar-title>
                    
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialogNew" max-width="700px" v-if="user.permissions['setting'] >= 10">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" small class="mb-2 mt-2" fab dark color="primary">
                        <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                          <span class="headline">Podłącz nowe konto</span>
                        </v-card-title>

                        <v-card-text>
                        <v-container>
                            <v-row>     
                                <!-- <v-col cols="12" sm="6" md="6" class="d-flex flex-column align-center">
                                  <v-btn @click="newItem('Allegro')" height="100px" outlined width="174"><img src="/integration/allegro.png" height="90px" /></v-btn>
                                </v-col>
                                      
                                <v-col cols="12" sm="6" md="6" class="d-flex flex-column align-center">
                                  <v-btn @click="newItem('Geis')" height="100px" outlined width="174"><img src="/integration/geis.png" height="90px" /></v-btn>
                                </v-col> -->

                                <v-col cols="12" sm="6" md="6" class="d-flex flex-column align-center">
                                  <v-btn @click="newItem('Florexpol')" height="100px" outlined width="174"><img src="/integration/florexpol.png" width="150px" /></v-btn>
                                </v-col>

                                <v-col cols="12" sm="6" md="6" class="d-flex flex-column align-center">
                                  <v-btn @click="newItem('PrestaShop')" height="100px" outlined width="174"><img src="/integration/presta.png" height="90px" /></v-btn>
                                </v-col>

                                <v-col cols="12" sm="6" md="6" class="d-flex flex-column align-center">
                                  <v-btn @click="newItem('Disp')" height="100px" outlined width="174"><img src="/integration/disp.png" height="90px" /></v-btn>
                                </v-col>

                                <v-col cols="12" sm="6" md="6" class="d-flex flex-column align-center">
                                  <v-btn @click="newItem('Order')" height="100px" outlined width="174"><img src="/integration/order.png" height="90px" /></v-btn>
                                </v-col>

                                <v-col cols="12" sm="6" md="6" class="d-flex flex-column align-center">
                                  <v-btn @click="newItem('Docs')" height="100px" outlined width="174"><img src="/integration/docs.png" height="90px" /></v-btn>
                                </v-col>

                                <v-col cols="12" sm="6" md="6" class="d-flex flex-column align-center">
                                  <v-btn @click="newItem('Email')" height="100px" outlined width="174"><img src="/integration/email.png" height="90px" /></v-btn>
                                </v-col>

                                 <v-col cols="12" sm="6" md="6" class="d-flex flex-column align-center">
                                  <v-btn @click="newItem('Codido')" height="100px" outlined width="174"><img src="/integration/codido.png" width="150px" /></v-btn>
                                </v-col>

                                <v-col cols="12" sm="6" md="6" class="d-flex flex-column align-center">
                                  <v-btn @click="newItem('Fakturownia')" height="100px" outlined width="174"><img src="/integration/fakturownia.png" width="150px" /></v-btn>
                                </v-col>

                                 <v-col cols="12" sm="6" md="6" class="d-flex flex-column align-center">
                                  <v-btn @click="newItem('PP')" height="100px" outlined width="174"><img src="/integration/pp.png" width="150px" /></v-btn>
                                </v-col>

                                <v-col cols="12" sm="6" md="6" class="d-flex flex-column align-center">
                                  <v-btn @click="newItem('Task')" height="100px" outlined width="174"><img src="/integration/task.png" width="90px" /></v-btn>
                                </v-col>
                                
                            </v-row> 
                        </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="grey" text @click="closeNew">Zamknij</v-btn>
                        </v-card-actions>
                    </v-card>

                    </v-dialog>

                    <v-dialog v-model="dialog" max-width="700px">
                    
                    <geisSettings v-if="editedItem.type.name==='Geis'" :form-title="formTitle" :close-function="close" :submit-function="handleIntegrationSubmit" />
                    <allegroSettings v-else-if="editedItem.type.name==='Allegro'" :query="$route.query" :form-title="formTitle" :close-function="close" :submit-function="handleIntegrationSubmit" />
                    <dispSettings v-else-if="editedItem.type.name==='Wysyłki'" :query="$route.query" :form-title="formTitle" :close-function="close" :submit-function="handleIntegrationSubmit" />
                    <orderSettings v-else-if="editedItem.type.name==='Zamówienia'" :query="$route.query" :form-title="formTitle" :close-function="close" :submit-function="handleIntegrationSubmit" />
                    <emailSettings v-else-if="editedItem.type.name==='E-Mail'" :query="$route.query" :form-title="formTitle" :close-function="close" :submit-function="handleIntegrationSubmit" />
                    <prestaSettings v-else-if="editedItem.type.name==='PrestaShop'" :query="$route.query" :form-title="formTitle" :close-function="close" :submit-function="handleIntegrationSubmit" />
                    <codidoSettings v-else-if="editedItem.type.name==='Codido'" :query="$route.query" :form-title="formTitle" :close-function="close" :submit-function="handleIntegrationSubmit" />
                    <fakturowniaSettings v-else-if="editedItem.type.name==='Fakturownia'" :query="$route.query" :form-title="formTitle" :close-function="close" :submit-function="handleIntegrationSubmit" />
                    <florexpolSettings v-else-if="editedItem.type.name==='Florexpol'" :query="$route.query" :form-title="formTitle" :close-function="close" :submit-function="handleIntegrationSubmit" />
                    <ppSettings v-else-if="editedItem.type.name==='PP'" :query="$route.query" :form-title="formTitle" :close-function="close" :submit-function="handleIntegrationSubmit" />
                    <docsSettings v-else-if="editedItem.type.name==='Dokumenty'" :query="$route.query" :form-title="formTitle" :close-function="close" :submit-function="handleIntegrationSubmit" />
<taskSettings v-else-if="editedItem.type.name==='Zadania'" :query="$route.query" :form-title="formTitle" :close-function="close" :submit-function="handleIntegrationSubmit" />

                    </v-dialog>
                </v-toolbar>
                </template>
                <template v-slot:item.type="{ item }">
                  <v-chip
                      color="tertiary"
                      label
                      small
                      dark
                    >
                      <v-icon v-if="item.type.category===1" small left>
                        mdi-shopping-outline
                      </v-icon>
                      <v-icon v-else-if="item.type.category===2" small left>
                        mdi-truck-outline
                      </v-icon>

                      <v-icon v-else-if="item.type.category===5" small left>
                        mdi-file-document-outline
                      </v-icon>
                      
                      <img height="16" class="mr-1" style="margin-left:-6px;" v-else-if="item.type.category===3" src="/spedix_white.ico" />
                      <img height="16" class="mr-1" style="margin-left:-6px;" v-else-if="item.type.category===4" src="/logo_listek_white.png" />
                      {{item.type.name}}
                    </v-chip>      
                </template>
                <template v-if="user.permissions['setting'] >= 10" v-slot:item.details="{ item }">       
                  <v-btn @click="editItem(item)" color="primary" x-small class="ml-2">Edytuj</v-btn>
                  <v-btn v-if="user.permissions['setting'] >= 10" color="primary" x-small @click="deleteItem(item)" class="ml-2">Usuń</v-btn>
                </template> 
            </v-data-table> 
         
            <confirm ref="confirm"></confirm>
          </v-card>
          </v-sheet>
</template>

<script>
  import confirm from '../components/Confirm'
  import geisSettings from '../components/integration/Geis'
  import allegroSettings from '../components/integration/Allegro'
  import dispSettings from '../components/integration/Disp'
  import orderSettings from '../components/integration/Order'
  import emailSettings from '../components/integration/Email'
  import prestaSettings from '../components/integration/PrestaShop'
  import codidoSettings from '../components/integration/Codido'
  import fakturowniaSettings from '../components/integration/Fakturownia'
  import florexpolSettings from '../components/integration/Florexpol'
  import ppSettings from '../components/integration/PP'
  import docsSettings from '../components/integration/Docs'
  import taskSettings from '../components/integration/Task'

  export default {
    components:{
      confirm,
      geisSettings,
      allegroSettings,
      dispSettings,
      prestaSettings,
      orderSettings,
      emailSettings,
      codidoSettings,
      fakturowniaSettings,
      florexpolSettings,
      ppSettings,
      docsSettings,
      taskSettings
    },
    props:['aId'],
    data: () => ({
      componentKey:0,
      tableOptions: {},
      dialog: false,
      dialogNew: false,
      itemEditing:false,
      headers: [
        { text: 'Lp',align: 'start',sortable: false,value: 'lp',filterable:false},
        { text: 'Typ',align: 'start', value: 'type',sortable: true },
        { text: 'Nazwa', value: 'name',sortable: true },
        { text: 'Data utworzenia', value: 'created_at',sortable: true },
        { text: '', value: 'details', sortable: false,filterable:false},
      ],
    }),
    validations:{

    },
    watch: {
      tableOptions: {
        handler () {
          var currentOptions = {...this.options};
          const { sortBy, sortDesc } = this.tableOptions
        
          //zmiana sortowania
          if (sortBy.length === 1 && sortDesc.length === 1) {
            currentOptions.orderBy = sortBy[0];
            currentOptions.orderDirection = sortDesc[0]?'desc':'asc';
            this.options = currentOptions;
          } 
        },
        deep: true,
      },
      dialog(val) {
        if(val){
        }else{
          this.close()
        }
      },
      dialogNew(val) {
        if(val){
        }else{
          this.closeNew()
        }
      },
      integrationEditStatus(val){
        if(val===2){
          this.componentKey++;
          this.close();
        }
      },
      integrationEditLoadStatus(val){
        if(val===2){
          this.dialog = true
        }
      },
      integrationsLoadStatus(val){
        if(val===2 && this.$route.query.state){
          this.itemEditing = true
          this.$store.dispatch( 'loadIntegrationEdit', {slug:  this.$route.query.state}); 
        }
      },
      integrationAddStatus(val){
        if(val===2){
          this.dialogNew = false
          this.dialog = true
        }
      }
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      lookups(){
        return this.$store.getters.getLookups;
      },
      editedItem(){
        return this.$store.getters.getIntegrationEdit;
      },
      options:{
        get () {  
          return this.$store.getters.getIntegrationOptions;
        },
        set (value) {
          this.search='';
          this.itemEditing = false;
          this.$store.dispatch( 'setIntegrationOptions', {options:value} )
        }
      },
      accountId: {
        get () {
          return this.$store.getters.getIntegrationOptions.accountId;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.accountId = value;
          this.options = currentOptions;
        }
      },
      computedHeaders(){
        if(this.user.group_id>1)return _.filter(this.headers,function(o) { return o.value!='account.name'; })
        else return this.headers;
      },
      
      tableItems(){
        return this.$store.getters.getIntegrations;
      },
      integration(){
        return this.$store.getters.getIntegration;
      },
     
      integrationLoadStatus(){
        return this.$store.getters.getIntegrationLoadStatus;
      },
      integrationsLoadStatus(){
        return this.$store.getters.getIntegrationsLoadStatus();
      },
      integrationEditLoadStatus(){
        return this.$store.getters.getIntegrationEditLoadStatus();
      },
      integrationEditStatus(){
        return this.$store.getters.getIntegrationEditStatus();
      },
      integrationEditText(){
        return this.$store.getters.getIntegrationEditText
      },
      integrationAddStatus(){
        return this.$store.getters.getIntegrationAddStatus();
      },
      integrationAddText(){
        return this.$store.getters.getIntegrationAddText;
      },
      specialIntegrationAddStatus(){
        return this.$store.getters.getSpecialIntegrationAddStatus();
      },
      formTitle () {
        return !this.itemEditing ? 'Nowe konto' : 'Edycja konta'
      },
    },
    mounted(){
      if(this.lookups.integrations.length>0){
        this.loadIntegrations();
      }else{
        this.$store.dispatch( 'loadLookups' );
        setTimeout(() => {
          this.loadIntegrations();
        }, 1000)
      }
    },
    methods: {
      loadIntegrations(){
        this.search='';
        this.accountId = this.aId;//pobiera z propsów
      },
     
      editItem(item) {
        this.itemEditing = true
        this.$store.dispatch( 'loadIntegrationEdit', {slug:  item.id});  
      },

      newItem(type) {
        this.$store.dispatch( 'addIntegration', {
                type:  type
        });
      },

      showDetailes(item){
        this.dialogIntegrationId = item.id;
        this.dialogIntegration = true
      },
      cancelItem(item){
        this.$refs.confirm.open('Anuluj', 'Czy na pewno chcesz anulować zamówienie? Operacja jest nieodwracalna.', { color: 'error' }).then((confirm) => {
            if(confirm){
                this.$store.dispatch( 'editIntegrationStatus', {slug:  item.id, status:5}); 
            }
        })
       
      },
      deleteItem (item) {
        this.$refs.confirm.open('Usuń', 'Czy na pewno chcesz usunać konto?', { color: 'error' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'forceDeleteIntegration', {slug:  item.id});
            }
        })
      },
      close () {
        this.dialog = false
        //this.$v.$reset()
        setTimeout(() => {
          this.$store.dispatch( 'loadIntegrationEditDefault'); 
          this.$store.dispatch( 'loadIntegrationAddDefault');
          this.itemEditing = false

          if(this.$route.query.code || this.$route.query.error){
            this.$router.push({ name: 'integracje.ustawienia' });
          }

        }, 300)
      },
      closeNew () {
        this.dialogNew = false
      },
     
      handleIntegrationSubmit () {
              this.$store.dispatch( 'editIntegration', {
                slug:  this.editedItem.id,
                name:  this.editedItem.name,
                integrable_type:  this.editedItem.integrable_type,
                integrable:  this.editedItem.integrable,
              });
       
      },
    },
  }
</script>
<template>

  <v-text-field
    v-model="itemLocal"
    outlined
    single-line
    dense
    class="inputs-container"
    style="font-size:0.875rem;"
    :error="$v.itemLocal.$error"
    hide-details
    @input="changeInput()"
    @blur="changeInput()"
    :ref="itemName"
  >
    <template v-slot:label>
      <div style="font-size:0.875rem;">
        {{ errors.includes(itemName)?label:'' }}
      </div>
    </template>
    <template v-slot:append>
      <v-tooltip v-if="$v.itemLocal.$error" bottom>
        <template v-slot:activator="{ on }">
          <v-icon color="error" v-on="on" class="ml-0"
            >mdi-information-outline</v-icon
          >
        </template>
        <span v-html="errorMessageFormat"></span>
      </v-tooltip>
    </template>
  </v-text-field>

</template>
<script>
import required from "vuelidate/lib/validators/required";
//import minLength from 'vuelidate/lib/validators/minLength'
//import maxLength from 'vuelidate/lib/validators/maxLength'
import numeric from "vuelidate/lib/validators/numeric";
import decimal from "vuelidate/lib/validators/decimal";
import alpha from "vuelidate/lib/validators/alpha";

import { helpers } from "vuelidate/lib/validators";
export const alphaDiacritic = helpers.regex('alphaDiacritic', /^[a-zA-ZÀ-ž]*$/);
export const alphaWhiteDiacritic = helpers.regex('alphaWhiteDiacritic', /^[a-zA-ZÀ-ž\s]*$/);

export default {
  props: {
    itemName:String,
    item: [String, Number],
    errors:String,
    label: String,
    required:{
        type:Boolean,
        default:false
    },
    decimal:{
        type:Boolean,
        default:false
    },
    alpha:{
        type:Boolean,
        default:false
    },
  },
  data: () => ({
    itemLocal: "",
  }),
  mounted: function () {
    //this.itemLocal = this.item
    //this.requiredOverride = 
  },
  watch:{
      item: {
        immediate:true,
        handler(val, oldVal){
            this.itemLocal = this.item       
        }
    },
  },
  validations() {
    return {
      itemLocal:this.rules
    };
  },
  computed: {
    rules() {
      var ob = {};
      if (this.required && this.decimal)ob={required,decimal};
      else if (this.required && this.alpha)ob={required,alphaWhiteDiacritic};
      else if (!this.required && this.decimal)ob={decimal};
      else if (!this.required && this.alpha)ob={alphaWhiteDiacritic};
      else if (this.required)ob={required};
      return ob;
    },
    errorMessage() {
      //this.$emit('update:title', newTitle)
      const errors = [];
      if (!this.$v.itemLocal.$dirty) return errors;
      this.required && !this.$v.itemLocal.required && errors.push("Pole jest wymagane");
      this.decimal && !this.$v.itemLocal.decimal && errors.push("Może zawierać tylko cyfry");
      this.alpha && !this.$v.itemLocal.alpha && errors.push("Może zawierać tylko litery");
      return errors;
    },
    errorMessageFormat() {
      const errors = this.errorMessage;
      var err = "";
      errors.forEach(element => {
        err += element + "<br />";
      });
      return err;
    }
  },
  methods: {
    changeInput() {
        this.$v.itemLocal.$touch()
        if(!this.$v.itemLocal.$error){
            this.$emit('update:item', this.itemLocal)
            this.$emit('update:errors', this.errors.replace(this.itemName,'').trim())
        }else{
            this.$emit('update:errors', this.errors.replace(this.itemName,'').trim().concat(this.itemName+' '))
        }
    },
    resetAndSetFocus(){
       this.reset()
       setTimeout(() => {
            this.$refs[this.itemName].focus()
      })
    },
    reset(){
       this.itemLocal = ''
       this.$v.$reset()
    }
  }
};
</script>
<style scoped>
.v-input input{
    padding:0px !important;
    max-height: 27px !important;
}
.v-input__slot{
    min-height: 27px !important;
}
.v-input__append-inner{
  margin-top:1px !important;
}
.v-text-field{
      width: 100px;
}
</style>
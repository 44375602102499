
import { FLOREX_CONFIG } from '../config'
import axios from 'axios'


export default {

    
    /*
        GET     /api/integrationOrders
    */
    getIntegrationOrders: function(options){
        return axios.get( FLOREX_CONFIG.API_URL + '/integrationOrders',{
            params:{
                offset:options.offset,
                itemsPerPage:options.itemsPerPage,
                orderBy:options.orderBy,
                orderDirection:options.orderDirection,
                accountId:options.accountId,
                statusId:options.statusId,
                showActive:options.showActive,
                fromDate:options.fromDate,
                toDate:options.toDate,
                sourceId:options.sourceId,
                paymentType:options.paymentType,
                withAmountError:options.withAmountError,
                return:options.return,
                returned:options.returned
        }});
    },

    /*
        GET     /api/integrationOrders/search
    */
   searchIntegrationOrders: function(options){
        return axios.get( FLOREX_CONFIG.API_URL + '/integrationOrders/search',{
            params:{
                integrationId:options.integrationId,
                filterableHeaders:options.filterableHeaders,
                q:options.q
        }});
    },


    /*
		GET 	/api/integrationOrders/{slug}/edit
	*/
    getIntegrationOrderEdit: function( slug ){
		return axios.get( FLOREX_CONFIG.API_URL + '/integrationOrders/' + slug + '/edit' );
    },

    

    /*
		PUT 	/api/integrationOrdersOrderStatus/{slug}
	*/
    putEditIntegrationOrderOrderStatus: function(slug, order_id){
        return axios.put( FLOREX_CONFIG.API_URL + '/integrationOrders/'+ slug + '/orderStatus',
            {
                order_id: order_id
            }
        );
    },

    /*
		PUT 	/api/integrationOrders/{slug}/move
	*/
    putEditIntegrationOrderMove: function(slug, selected){
        return axios.put( FLOREX_CONFIG.API_URL + '/integrationOrders/'+ slug + '/move',
            {
                selected: selected
            }
        );
    },



    /*
		PUT 	/api/integrationOrders/{slug}
	*/
    putEditIntegrationOrder: function(slug, order, buyer,amount,amount_net,amount_currency){
        return axios.put( FLOREX_CONFIG.API_URL + '/integrationOrders/'+ slug,
            {
                order: order,
                buyer: buyer,
                amount:amount,
                amount_net: amount_net,
                amount_currency:amount_currency,
            }
        );
    },

    /*
		POST 	/api/integrationOrders/{slug}
	*/
    postNewIntegrationOrder: function(order, buyer,amount,amount_net,amount_currency){
        return axios.post( FLOREX_CONFIG.API_URL + '/integrationOrders',
            {
                order: order,
                buyer: buyer,
                amount:amount,
                amount_net: amount_net,
                amount_currency:amount_currency,
            }
        );
    },


    /*
		PUT /api/integrationOrders/force
	*/
    forceDeleteIntegrationOrder: function( slug ){
        return axios.put( FLOREX_CONFIG.API_URL + '/integrationOrders/force',
        {
            slug: slug,
        } );
    },
    
}
import AccountAPI from "../api/account";

export const accounts = {
    state: {
        accountEdit: {
            id: 0,
            lp: 0,
            name: undefined,
            nip:undefined,
            regon:undefined,
            krs:undefined,
            logo:undefined
        },
        accountEditDefault: {
            id: 0,
            lp: 0,
            name: undefined,
            nip:undefined,
            regon:undefined,
            krs:undefined,
            logo:undefined
        },
        accountEditLoadStatus: 0,
        accountEditStatus: 0,
        accountEditText: "",
    },

    actions: {
        loadAccountEdit({ commit }, data) {
            commit("setAccountEditLoadStatus", 1);
            commit("setAccountEditStatus", 0);
            commit("setAccountEditText", "");

            AccountAPI.getAccountEdit(data.slug)
                .then(function(response) {
                    commit("setAccountEdit", response.data);
                    commit("setAccountEditLoadStatus", 2);
                })
                .catch(function() {
                    commit("setAccountEdit", {});
                    commit("setAccountEditLoadStatus", 3);
                });
        },
        loadAccountEditDefault({ commit }) {
            commit("setAccountEditDefault");
        },
        loadAccountAddDefault({ commit }) {
            commit("setAccountAddStatus", 0);
            commit("setAccountAddText", "");
            commit("setAccountEditStatus", 0);
            commit("setAccountEditText", "");
        },
        editAccount({ commit, state, dispatch }, data) {
            commit("setAccountEditStatus", 1);

            AccountAPI.putEditAccount(
                data.slug,
                data.name,
                data.nip,
                data.regon,
                data.krs,
                data.logo
            )
                .then(function(response) {
                    commit("setAccountEditStatus", 2);
                })
                .catch(function(error) {
                    commit("setAccountEditStatus", 3);
                    commit("setAccountEditText", error.response.data.errors);
                });
        },
    },
    mutations: {
        setAccountLoadStatus(state, status) {
            state.accountLoadStatus = status;
        },
        setAccountsLoadStatus(state, status) {
            state.accountsLoadStatus = status;
        },
        setAccounts(state, accounts) {
            state.accounts = accounts;
        },
        setAccountEdit(state, account) {
            state.accountEdit = account;
        },
      
        setAccountEditDefault(state) {
            state.accountEdit = { ...state.accountEditDefault };
        },
        setAccountEditStatus(state, status) {
            state.accountEditStatus = status;
        },
        setAccountEditText(state, text) {
            state.accountEditText = text;
        },
        setAccountEditLoadStatus(state, status) {
            state.accountEditLoadStatus = status;
        },
    },

    getters: {
        getAccountLoadStatus(state) {
            return state.accountLoadStatus;
        },
        getAccountEdit(state) {
            return state.accountEdit;
        },
        getAccountEditStatus(state) {
          return function(){
            return state.accountEditStatus;
          }
        },
        getAccountEditing(state) {
            return state.accountEdit.id > 0;
        },
        getAccountEditText(state) {
            return state.accountEditText;
        },
        getAccountEditLoadStatus(state) {
            return state.accountEditLoadStatus;
            
        },
    }
};

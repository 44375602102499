<template>
          <v-sheet flat>

          <v-snackbar
            v-model="integrationShipmentSnackbar"
            timeout="5000"
            top
          >
            {{integrationShipmentEditText}}
          </v-snackbar>

          <v-card flat :loading="integrationShipmentEditStatus===1 || integrationShipmentLabelLoadStatus===1">
            <v-toolbar flat color="white">
                    <v-toolbar-title>{{independentItem?'Nowa niezależna wysyłka':''}}</v-toolbar-title>
                    <!-- <v-toolbar-title>{{editedItem.order_date}}</v-toolbar-title>

                    <v-divider
                    v-if="editedItem.order_date"
                    class="mx-4"
                    inset
                    vertical
                    ></v-divider> -->
                    <v-toolbar-title>{{editedItem.buyer.contact_name}}</v-toolbar-title>

                    <v-divider
                    v-if="editedItem.amount"
                    class="mx-4"
                    inset
                    vertical
                    ></v-divider>
                    <v-toolbar-title v-if="editedItem.amount">{{$n(editedItem.amount + (editedItem.carrier.delivery_cost_amount?editedItem.carrier.delivery_cost_amount:0),'number','pl-PL')}} {{editedItem.amount_currency}}</v-toolbar-title>

                    <v-spacer></v-spacer>
                    <v-btn small color="primary" @click="showOrder = !showOrder">{{showOrder?'Ukryj zamówienie':'Pokaż zamówienie'}}</v-btn>
                        <v-divider
                        v-if="editedItem.amount"
                        class="mx-4"
                        inset
                        vertical
                        ></v-divider>
                      <v-toolbar-title class="mr-3" v-if="editedItem.shipment_id">
                        <!-- <a :href="'https://www.geis.pl/pl/sledzenie-przesylki?p='+editedItem.shipment_id" target="_blank" class="offerLink">{{editedItem.shipment_id}}</a> -->
                        {{editedItem.shipment_id}}
                      </v-toolbar-title>
                      <!-- <v-menu
                        v-model="labelPrintMenu"
                        v-if="editedItem.shipment_id"
                        :close-on-content-click="true"
                      >
                        <template v-slot:activator="{ on }">
                          <v-icon
                              v-on="on"
                              size="35"
                              color="primary"
                            >
                              mdi-printer
                            </v-icon>
                        </template>

                        <v-card width="250">
                          <v-container>
                            <v-row>
                              <v-col class="col-4"><h3>PDF</h3></v-col>
                              <v-col class="col-2 pr-0"><v-icon @click="downloadLabel('pdf',1)" size="24" color="tetriary">mdi-watermark mdi-rotate-180</v-icon></v-col>
                              <v-col class="col-2 pr-0"><v-icon @click="downloadLabel('pdf',2)" size="24" color="tetriary">mdi-watermark mdi-flip-v</v-icon></v-col>
                              <v-col class="col-2 pr-0"><v-icon @click="downloadLabel('pdf',3)" size="24" color="tetriary">mdi-watermark mdi-flip-h</v-icon></v-col>
                              <v-col class="col-2 pr-1"><v-icon @click="downloadLabel('pdf',4)" size="24" color="tetriary">mdi-watermark</v-icon></v-col>
                            </v-row>
                            <v-row>
                              <v-col class="col-4"></v-col>
                              <v-col class="col-2 pr-0 pt-0"><v-icon @click="downloadLabel('pdf2',1)" size="27" color="tetriary">mdi-page-layout-body mdi-rotate-90</v-icon></v-col>
                            </v-row>
                             <v-row v-if="selectedPrinter">
                              <v-col class="col-4"><h3>EPL</h3></v-col>
                              <v-col class="col-2 pr-0"><v-icon @click="downloadLabel('epl',200)" size="24" color="tetriary">mdi-view-module</v-icon></v-col>
                              <v-col class="col-2 pr-0"><v-icon @click="downloadLabel('epl',300)" size="24" color="tetriary">mdi-view-comfy</v-icon></v-col>
                            </v-row>
                             <v-row v-if="selectedPrinter">
                              <v-col class="col-4"><h3>ZPL</h3></v-col>
                              <v-col class="col-2 pr-0"><v-icon @click="downloadLabel('zpl',200)" size="24" color="tetriary">mdi-view-module</v-icon></v-col>
                              <v-col class="col-2 pr-0"><v-icon @click="downloadLabel('zpl',300)" size="24" color="tetriary">mdi-view-comfy</v-icon></v-col>
                            </v-row>
                             <v-row>
                              <v-col class="col-4"><h3>BMP</h3></v-col>
                              <v-col class="col-2 pr-0"><v-icon @click="downloadLabel('bmp',1)" size="24" color="tetriary">mdi-image-size-select-actual</v-icon></v-col>
                            </v-row>
                          </v-container>
                        </v-card>
                      </v-menu> -->

                     <v-divider
                     v-if="editedItem.shipment_id"
                    class="mx-4"
                    inset
                    vertical
                    ></v-divider>

                    <v-toolbar-title>{{editedItem.pickup_date}}</v-toolbar-title>
                    <v-divider
                    v-if="editedItem.pickup_date"
                    class="mx-4"
                    inset
                    vertical
                    ></v-divider>
                    <v-tooltip v-if="editedItem.status>-1" left>
                      <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            size="42"
                            :color="_.find(lookups.integrationShipmentStatus,{'value':editedItem.status}).color"
                          >
                            {{_.find(lookups.integrationShipmentStatus,{'value':editedItem.status}).icon}}
                        </v-icon>
                      </template>
                      <span>{{_.find(lookups.integrationShipmentStatus,{'value':editedItem.status}).text}}</span>
                    </v-tooltip>
                    
                    
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col class="col-9" v-if="!independentItem">
                    <!-- <v-card class="mb-5" v-if="editedItem.order.length===0">
                        <v-toolbar flat dense color="white">
                            <v-toolbar-title>Zamówienie</v-toolbar-title>
                        </v-toolbar>
                          <v-container class="pt-0">
                           <v-row>
                            <v-col class="col-2"><v-text-field :readonly="editedItem.Closed" filled dense single-line hide-details="auto" v-model="editedItem.amount" label="Kwota zamówienia"></v-text-field></v-col>
                            <v-col class="col-1"><v-text-field :readonly="editedItem.Closed" filled dense single-line hide-details="auto" v-model="editedItem.amount_currency" label="Waluta"></v-text-field></v-col>
                            <v-col class="col-6"></v-col>
                            <v-col class="col-3"><v-text-field :readonly="editedItem.Closed" filled dense single-line hide-details="auto" v-model="editedItem.buyer.buyer_login" label="Kupujący"></v-text-field></v-col>
                          </v-row>
                          <v-row>
                            <v-col class="col-12" >
                                <v-textarea rows="2" :readonly="editedItem.Closed" filled hide-details v-model="editedItem.custom_order" label="Treść"></v-textarea>
                            </v-col>
                          </v-row>
                          </v-container>
                    </v-card> -->

                    <v-data-table
                        v-if="showOrder"
                        v-for="order in editedItem.order"
                        :key="order.order_id"
                        :hide-default-footer="order.item_list.length<11"
                        :headers="computedOrderHeaders"
                        :items="order.item_list"
                        class="elevation-1 mb-5"
                        dense
                    >
                      <template v-slot:top>
                      <v-toolbar flat dense color="white">
                          <!-- <v-toolbar-title>{{_.find(lookups.integrations,{'value':order.integration_id}).text}}</v-toolbar-title> -->
                          <v-tooltip v-if="editedItem.order_status" top>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                  v-on="on"
                                  size="25"
                                  class="mr-2"
                                  :color="_.find(lookups.integrationOrderStatus,{'value':editedItem.order_status}).color"
                                >
                                  {{_.find(lookups.integrationOrderStatus,{'value':editedItem.order_status}).icon}}
                              </v-icon>
                            </template>
                            <span>{{_.find(lookups.integrationOrderStatus,{'value':editedItem.order_status}).text}}</span>
                          </v-tooltip>
                          <v-toolbar-title>Zamówienie | {{order.order_id}}</v-toolbar-title>
                          <!-- <v-chip
                              color="tertiary"
                              text-color="white"
                              class="ml-3"
                            >
                              {{order.order_status.text}}
                              <v-icon size="20" @click="refreshOrderStatus(order)" right>
                                mdi-reload
                              </v-icon>
                          </v-chip> -->

                          <v-icon size="28" class="ml-5" color="orange darken-4" v-if="order.invoice_required">
                                mdi-file-document
                          </v-icon>

                          <v-tooltip v-if="order.order_message" right>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                  v-on="on"
                                  size="28"
                                  class="ml-5"
                                  color="blue lighten-3"
                                >
                                  mdi-message-text
                              </v-icon>
                            </template>
                            <span>{{order.order_message}}</span>
                          </v-tooltip>
                        
                          <v-spacer></v-spacer>
                          <v-toolbar-title>{{order.payment_type.text}} {{order.payment_provider?'('+order.payment_provider+')':''}}</v-toolbar-title>
                          <v-divider
                            class="mx-4"
                            inset
                            vertical
                          ></v-divider>
                          <v-toolbar-title>{{order.delivery_method_name}} ({{$n(order.delivery_cost_amount,'number','pl-PL')}} {{order.delivery_cost_currency}})</v-toolbar-title>
                      </v-toolbar>
                      </template>
                      <template v-slot:item.selection="{ item }">       
                        <v-simple-checkbox class="mt-0 mb-0 mr-0 ml-0" hide-details="auto" single-line v-model="item.selection" label=""></v-simple-checkbox>
                      </template>
                      <template v-slot:item.item_name="{ item }">       
                       <a :href="'https://allegro.pl/oferta/'+item.item_reference" target="_blank" class="offerLink">{{item.item_name}}</a>
                      </template>
                      <template v-slot:item.item_price="{ item }">       
                        {{$n(item.item_price,'number','pl-PL')}} {{item.item_price_currency}}
                      </template>
                      <template v-slot:item.item_price_net="{ item }">       
                        {{$n(item.item_price_net,'number','pl-PL')}} {{item.item_price_currency}}
                      </template>
                      <template v-slot:item.item_price_sum="{ item }">       
                        {{$n(item.item_price*item.item_quantity,'number','pl-PL')}} {{item.item_price_currency}}
                      </template>

                      <template v-slot:item.item_tax_rate="{ item }">       
                        {{item.item_tax_rate}}%
                      </template>
                      <template v-slot:item.actions="{ item }">       
                        <!-- <v-btn @click="editItem(item)" color="primary" x-small class="ml-2">Edytuj</v-btn>
                        <v-btn v-if="user.group_id > 1 && user.group_id <= 4" color="primary" x-small @click="deleteItem(item)" class="ml-2">Usuń</v-btn> -->
                      </template> 
                  </v-data-table>
                  <div class="text-center mb-5" v-if="!_.isEmpty(selectedItems)">
                    <v-btn v-if="!_.isEmpty(selectedItems)"  @click="moveItems()" color="primary" small>Wydziel do nowej wysyłki</v-btn>
                  </div>

                  <v-data-table
                        :headers="pickupHeaders"
                        :hide-default-footer="editedItem.pickup_list.length<11"
                        :items="editedItem.pickup_list"
                        class="elevation-1 mb-5"
                        dense
                    >
                      <template v-slot:top>
                      <v-toolbar flat dense color="white">
                          <!-- <v-toolbar-title>{{_.find(lookups.integrations,{'value':order.integration_id}).text}}</v-toolbar-title> -->
                          
                          <v-toolbar-title>Zawartość wysyłki</v-toolbar-title>
                          <!-- <v-chip
                              color="tertiary"
                              text-color="white"
                              class="ml-3"
                            >
                              {{order.order_status.text}}
                              <v-icon size="20" @click="refreshOrderStatus(order)" right>
                                mdi-reload
                              </v-icon>
                          </v-chip> -->

                          <!-- <v-icon size="28" class="ml-5" color="orange darken-4" v-if="order.invoice_required">
                                mdi-file-document
                          </v-icon> -->

                          <!-- <v-tooltip v-if="order.order_message" right>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                  v-on="on"
                                  size="28"
                                  class="ml-5"
                                  color="blue lighten-3"
                                >
                                  mdi-message-text
                              </v-icon>
                            </template>
                            <span>{{order.order_message}}</span>
                          </v-tooltip> -->
                        
                          <v-spacer></v-spacer>
                          <v-toolbar-title v-if="editedItem.carrier.shipment_weight">{{$n(editedItem.carrier.shipment_weight/1000,'number','pl-PL')}}kg</v-toolbar-title>
                          
                          <!-- <v-toolbar-title>{{editedItem.order.payment_type.text}} {{editedItem.order.payment_provider?'('+editedItem.order.payment_provider+')':''}}</v-toolbar-title> -->
                          <v-divider
                            class="mx-4"
                            inset
                            vertical
                          ></v-divider>
                          <v-toolbar-title> {{$n(editedItem.carrier.delivery_cost_amount,'number','pl-PL')}} PLN</v-toolbar-title>
                      </v-toolbar>
                      </template>
                      <template v-slot:item.selection="{ item }">       
                        <v-simple-checkbox class="mt-0 mb-0 mr-0 ml-0" hide-details="auto" single-line v-model="item.selection" label=""></v-simple-checkbox>
                      </template>
                      <template v-slot:item.item_name="{ item }">       
                       {{item.item_name}}
                      </template>
                      <template v-slot:item.item_price="{ item }">       
                        {{$n(item.item_price,'number','pl-PL')}} {{item.item_price_currency}}
                      </template>
                   
                      <template v-slot:item.item_price_net="{ item }">       
                        {{$n(item.item_price_net,'number','pl-PL')}} {{item.item_price_currency}}
                      </template>
                      <template v-slot:item.item_price_sum="{ item }">       
                        {{$n(item.item_price*item.item_quantity,'number','pl-PL')}} {{item.item_price_currency}}
                      </template>
                      <template v-slot:item.item_tax_rate="{ item }">       
                        {{item.item_tax_rate}}%
                      </template>
                      <template v-slot:item.actions="{ item }">       
                        <!-- <v-btn @click="editItem(item)" color="primary" x-small class="ml-2">Edytuj</v-btn>
                        <v-btn v-if="user.group_id > 1 && user.group_id <= 4" color="primary" x-small @click="deleteItem(item)" class="ml-2">Usuń</v-btn> -->
                      </template> 
                  </v-data-table>
                 <!-- <v-data-table
                        hide-default-footer
                        disable-sort
                        :headers="pickupHeaders"
                        :items=" editedItem.pickup_list"
                        class="elevation-1 mb-5"
                        dense
                    >
                      <template v-slot:top>
                      <v-toolbar flat dense color="white">
                          <v-toolbar-title>Nośniki</v-toolbar-title>
                          <v-spacer></v-spacer>
                          <v-btn v-if="!editedItem.Closed && editedItem.integration_id" @click="addPickupItem" small class="mb-2 mt-2 ml-5" fab dark color="primary">
                            <v-icon dark>mdi-plus</v-icon>
                          </v-btn>
                      </v-toolbar>
                      </template>
                      <template v-slot:item.type="{ item }">    
                        <v-select :readonly="editedItem.Closed" @change="changeMUC(item)" :background-color="getColorIfErrorPickupList(editedItem.pickup_list.indexOf(item),'type')" @blur="$v.editedItem.pickup_list.$touch()" filled dense single-line hide-details="auto" v-model="item.type" :items="editedItem.carrier.muc_list" class="mt-1 mb-1" >
                          <template v-slot:selection="{ item }">
                            <b>{{item.value}}</b>
                          </template>  
                        </v-select>   
                      </template>
                      <template v-slot:item.quantity="{ item }">       
                        <v-text-field :readonly="editedItem.Closed" :background-color="getColorIfErrorPickupList(editedItem.pickup_list.indexOf(item),'quantity')" @blur="$v.editedItem.pickup_list.$touch()" filled dense single-line hide-details="auto" v-model="item.quantity"></v-text-field>
                      </template>
                      <template v-slot:item.height="{ item }">       
                        <v-text-field :readonly="editedItem.Closed" @change="calculateVolume(item)" :background-color="getColorIfErrorPickupList(editedItem.pickup_list.indexOf(item),'height')" @blur="$v.editedItem.pickup_list.$touch()" filled dense single-line hide-details="auto" v-model="item.height"></v-text-field>
                      </template>
                      <template v-slot:item.length="{ item }">       
                        <v-text-field :readonly="editedItem.Closed" @change="calculateVolume(item)"  :background-color="getColorIfErrorPickupList(editedItem.pickup_list.indexOf(item),'length')" @blur="$v.editedItem.pickup_list.$touch()" filled dense single-line hide-details="auto" v-model="item.length"></v-text-field>
                      </template>
                      <template v-slot:item.width="{ item }">       
                        <v-text-field :readonly="editedItem.Closed" @change="calculateVolume(item)"  :background-color="getColorIfErrorPickupList(editedItem.pickup_list.indexOf(item),'width')" @blur="$v.editedItem.pickup_list.$touch()" filled dense single-line hide-details="auto" v-model="item.width"></v-text-field>
                      </template>
                      
                      <template v-slot:item.weight="{ item }">       
                        <v-text-field :readonly="editedItem.Closed" @change="calculateVolume(item)" :background-color="getColorIfErrorPickupList(editedItem.pickup_list.indexOf(item),'weight')" @blur="$v.editedItem.pickup_list.$touch()" filled dense single-line hide-details="auto" v-model="item.weight"></v-text-field>
                      </template>
                      <template v-slot:item.volume="{ item }">       
                        <span style="font-size:16px;">{{item.volume}}</span>
                      </template>

                      <template v-slot:item.actions="{ item }">       
                        <v-btn v-if="!editedItem.Closed" color="primary" x-small @click="deletePickupItem(item)" class="ml-2">Usuń</v-btn>
                      </template>
                  </v-data-table> -->
                  
                  <!-- <v-card class="mb-5" v-if="editedItem.integration_id && !editedItem.shipment_id && editedItem.carrier">
                        <v-toolbar flat dense color="white">
                            <v-toolbar-title>Ustawienia nadania</v-toolbar-title>
                        </v-toolbar>
                          <v-container class="pt-0">
                            <v-row>
                              <v-col class="col-3">
                                <v-menu
                                        v-model="datePickupMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="150px"
                                      >
                                        <template v-slot:activator="{ on }">
                                          <v-text-field
                                            hide-details="auto"
                                            clearable
                                            v-model="editedItem.carrier.settings.pickup_date"
                                            label="Data odbioru"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-on="on"
                                            class="choose_date pt-0"
                                            single-line
                                            :background-color="getColorIfErrorPickupDate()"
                                          ></v-text-field>
                                        </template>
                                        <v-date-picker  first-day-of-week="1" no-title v-model="editedItem.carrier.settings.pickup_date" @input="datePickupMenu = false"></v-date-picker>
                                </v-menu>
                              </v-col>

                              <v-col class="col-3">
                                  <v-checkbox v-if="editedItem.order.length>0" class="mt-2" hide-details="auto" single-line v-model="editedItem.carrier.settings.put_shipment_id" label="Przypisz numery nadania"></v-checkbox>
                              </v-col>

                              <v-col class="col-4">
                                  <v-checkbox v-if="editedItem.order.length>0" class="mt-2" hide-details="auto" single-line v-model="editedItem.carrier.settings.put_send_status" label="Zmień statusy zamówień na Wysłane"></v-checkbox>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col class="col-5 pt-0 pb-0">
                                <v-text-field hide-details="auto" dense filled v-model="editedItem.carrier.settings.note" label="Notatka dla odbiorcy" ></v-text-field>
                              </v-col>
                              <v-col class="col-5 pt-0 pb-0">
                                <v-text-field hide-details="auto" dense filled v-model="editedItem.carrier.settings.note_driver" label="Notatka dla kierowcy" ></v-text-field>
                              </v-col>
                              <v-col class="col-2 pt-0 pb-0 d-flex flex-row-reverse mt-2">
                                  <v-btn :disabled="cantBeSend()" @click="putShipment" color="primary" large>Nadaj</v-btn>
                              </v-col>
                            </v-row>
                          </v-container>
                       
                  </v-card> -->
                <v-row class="mt-0" >
                  <v-col class="col-7" v-if="editedItem.logs.length>1">
                      <v-data-table
                        
                        hide-default-footer
                        :headers="logHeaders"
                        :items="editedItem.logs"
                        class="elevation-1 mb-5"
                        dense
                      >
                      <template v-slot:top>
                        <v-toolbar flat dense color="white">
                            <v-toolbar-title>Historia statusów</v-toolbar-title>
                        </v-toolbar>
                      </template>
                      <template v-slot:item.statusIcon="{ item }">
                                <v-icon
                                  :color="_.find(lookups.integrationShipmentStatus,{'value':item.status}).color"
                                >
                                  {{_.find(lookups.integrationShipmentStatus,{'value':item.status}).icon}}
                                </v-icon>
                      </template>
                      <template v-slot:item.status="{ item }">
                          {{_.find(lookups.integrationShipmentStatus,{'value':item.status}).text}}
                      </template>
                      </v-data-table>
                  </v-col>
                  
                </v-row>
                </v-col>
                <!-- <v-col class="col-6" v-if="independentItem">
                  <v-card>
                        <v-toolbar flat dense color="white">
                            <v-toolbar-title>Zamówienie</v-toolbar-title>
                        </v-toolbar>
                        <v-container class="pt-0">

                          <v-row>
                            <v-col class="col-8"><v-text-field filled dense single-line hide-details="auto" v-model="editedItem.amount" label="Kwota zamówienia"></v-text-field></v-col>
                            <v-col class="col-4"><v-text-field filled dense single-line hide-details="auto" v-model="editedItem.amount_currency" label="Waluta"></v-text-field></v-col>
                          </v-row>
                          <v-row>
                            <v-col class="col-12">
                                <v-textarea filled hide-details v-model="editedItem.custom_order" label="Treść"></v-textarea>
                            </v-col>
                          </v-row>

                        </v-container>
                  </v-card>
                </v-col> -->
                <v-col :class="independentItem?'col-6':'col-3'">
                  <!-- <v-card class="mb-5" v-if="!independentItem">
                        <v-toolbar flat dense color="white">
                            <v-toolbar-title>Nadawca</v-toolbar-title>
                        </v-toolbar>
                        <v-container class="pt-0">
                          <v-select 
                              :readonly="editedItem.Closed" 
                              @change="integrationIdChange" 
                              :outlined="!editedItem.integration_id" 
                              :filled="editedItem.integration_id>0" 
                              hide-details single-line 
                              :clearable="!editedItem.Closed"
                              v-model="editedItem.integration_id" 
                              :items="carrierIntegrations" 
                              label="Wybierz">
                            <template v-slot:selection="{ item }">
                              <b>{{item.text}}</b>
                            </template>
                          </v-select>
                        </v-container>
                        <v-container class="pt-0" v-if="editedItem.carrier && editedItem.carrier.service_list">
                          <v-container  class="pt-0 pl-0 pb-1" v-for="s_item in editedItem.carrier.service_list" :key="s_item.value">
                            <v-checkbox :readonly="editedItem.Closed" class="mt-0 pb-0" hide-details v-model="s_item.selected" :label="s_item.text"></v-checkbox>
                            <v-container v-if="s_item.parameters && s_item.selected" class="pl-0 pr-0 pt-0 pb-0">
                              <v-text-field 
                                v-for="p_item in s_item.parameters" 
                                :key="p_item.name" 
                                filled dense single-line hide-details="auto"
                                v-model="p_item.value"
                                :background-color="getColorIfErrorCommon(editedItem.carrier.service_list.indexOf(s_item),s_item.parameters.indexOf(p_item))"
                                :label="p_item.text" 
                                class="mb-2"
                               :readonly="editedItem.Closed"
                              >
                              </v-text-field>
                            </v-container>
                          </v-container>
                        </v-container>
                      </v-card> -->
                    <v-card class="mb-5">
                        <v-toolbar flat dense color="white">
                            <v-toolbar-title>Odbiorca</v-toolbar-title>
                            <v-tooltip v-if="editedItem.buyer.contact_email" right>
                              <template v-slot:activator="{ on }">
                                  <v-icon
                                    v-on="on"
                                    class="pl-2"
                                    @click="doCopy"
                                  >
                                    mdi-email-plus-outline
                                </v-icon>
                              </template>
                              <span>Skopiuj adres e-mail</span>
                            </v-tooltip>
                            <v-spacer></v-spacer>
                            <v-switch v-if="!editedItem.Closed && !this.independentItem" v-model="buyerEdit" class="mt-4"></v-switch>
                        </v-toolbar>
                        <v-container v-if="buyerEdit && !editedItem.Closed"  class="pt-0">
                          <v-row>
                            <v-col class="col-12 pl-3 pr-3 pt-0 pb-2">
                              <v-text-field filled dense single-line hide-details  v-model="editedItem.buyer.delivery_company_name" label="Firma"></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="col-5 pl-3 pr-2 pt-0 pb-2">
                              <v-text-field filled dense single-line hide-details v-model="editedItem.buyer.delivery_first_name" :background-color="getColorIfError('delivery_first_name')"
                              @blur="$v.editedItem.buyer.delivery_first_name.$touch()"  label="Imię"></v-text-field>
                            </v-col>
                            <v-col class="col-7 pl-0 pr-3 pt-0 pb-2">
                              <v-text-field filled dense single-line hide-details v-model="editedItem.buyer.delivery_last_name" :background-color="getColorIfError('delivery_last_name')"
                              @blur="$v.editedItem.buyer.delivery_last_name.$touch()" label="Nazwisko"></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="col-12 pl-3 pr-3 pt-0 pb-2">
                              <v-text-field filled dense single-line hide-details v-model="editedItem.buyer.delivery_street" :background-color="getColorIfError('delivery_street')" 
                                  @blur="$v.editedItem.buyer.delivery_street.$touch()"  label="Ulica"></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="col-3 pl-3 pr-0 pt-0 pb-2">
                              <v-text-field filled dense single-line hide-details v-model="editedItem.buyer.delivery_post_code" :background-color="getColorIfError('delivery_post_code')"  
                                  @blur="$v.editedItem.buyer.delivery_post_code.$touch()" label="Kod pocztowy"></v-text-field>
                            </v-col>
                            <v-col class="col-7 pl-2 pr-2 pt-0 pb-2">
                              <v-text-field filled dense single-line hide-details v-model="editedItem.buyer.delivery_city" :background-color="getColorIfError('delivery_city')"   
                                  @blur="$v.editedItem.buyer.delivery_city.$touch()" label="Miasto"></v-text-field>
                            </v-col>
                            <v-col class="col-2 pl-0 pr-3 pt-0 pb-2">
                              <v-text-field filled dense single-line hide-details v-model="editedItem.buyer.delivery_country_code" :background-color="getColorIfError('delivery_country_code')"   
                                  @blur="$v.editedItem.buyer.delivery_country_code.$touch()" label="Kod kraju"></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row v-if="independentItem || editedItem.order.length===0">
                            <v-col class="col-12 pl-3 pr-3 pt-0 pb-2">
                              <v-text-field filled dense single-line hide-details v-model="editedItem.buyer.contact_email" :background-color="getColorIfError('contact_email')" 
                                  @blur="$v.editedItem.buyer.contact_email.$touch()"  label="Adres e-mail"></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="col-12 pl-3 pr-3 pt-0 pb-2">
                              <v-text-field filled dense single-line hide-details v-model="editedItem.buyer.contact_phone" :background-color="getColorIfError('contact_phone')" 
                                  @blur="$v.editedItem.buyer.contact_phone.$touch()"  label="Numer telefonu"></v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                        <v-container v-else  class="pt-0">
                          <v-row class="pl-5 pr-5 d-flex flex-column">
                            <h2>{{editedItem.buyer.delivery_company_name}}</h2>
                            <h2>{{editedItem.buyer.delivery_first_name}} {{editedItem.buyer.delivery_last_name}}</h2>
                            <h2>{{editedItem.buyer.delivery_street}}</h2>
                            <h2>{{editedItem.buyer.delivery_post_code}} {{editedItem.buyer.delivery_city}} {{editedItem.buyer.delivery_country_code}}</h2>
                            <h2>{{editedItem.buyer.contact_phone}}</h2>
                          </v-row>
                        </v-container>
                      </v-card>

                      <v-card v-for="invoice in computedInvoices" :key="invoice.invoice_post_code">
                        <v-toolbar flat dense color="white">
                            <v-toolbar-title>Faktura</v-toolbar-title>
                        </v-toolbar>
                        <v-container class="pt-0">
                          <v-row class="pl-5 pr-5 d-flex flex-column">
                            <h2>{{invoice.invoice_company_name}}</h2>
                            <h2>{{invoice.invoice_first_name}} {{invoice.invoice_last_name}}</h2>
                            <h2>{{invoice.invoice_street}}</h2>
                            <h2>{{invoice.invoice_post_code}} {{invoice.invoice_city}} {{invoice.invoice_country_code}}</h2>
                            <h2 v-if="invoice.invoice_company_vat">NIP: {{invoice.invoice_company_vat}}</h2>
                          </v-row>
                        </v-container>
                      </v-card>
                </v-col>
              </v-row>
                


            </v-card-text>
            <v-card-actions>
                          <v-btn @click="cancelShipment" v-if="editedItem.status===5" large color="primary" class="ml-3 mb-3">Anuluj nadanie</v-btn>
                          <p class="text--secondary caption ml-5">Ostatnia aktualizacja: {{editedItem.updated_at}}</p>
                          <v-spacer></v-spacer>
                          <v-btn color="grey" text @click="close()">Zamknij</v-btn>

                          <v-btn v-if="independentItem" color="primary darken-1" text @click="handleSubmit">Dodaj</v-btn>
                          <v-btn v-else-if="!editedItem.Closed" color="primary darken-1" text @click="handleSubmit">Zapisz</v-btn>
             </v-card-actions>
            <confirm ref="confirm"></confirm>
          </v-card>
          </v-sheet>
</template>

<script>
  import confirm from '../components/Confirm'
  import required from 'vuelidate/lib/validators/required'
  import email from 'vuelidate/lib/validators/email'
  import integer from 'vuelidate/lib/validators/integer'
  import requiredIf from 'vuelidate/lib/validators/requiredIf'
  import { helpers } from 'vuelidate/lib/validators'
  const decimalPL = helpers.regex('decimalPL', /^[-]?\d*(\,\d+)?$/)

  import i18n from "../main";

  export default {
    components:{
      confirm,
    },
    data: () => ({
      integrationShipmentSnackbar:false,
      componentKey:0,
      datePickupMenu:false,
      itemEditing:false,
      bEdit:false,
      labelPrintMenu:false,
      showOrder:false,
      orderHeaders: [
        { text: '', value: 'selection',sortable: false },
       { text: 'Kod',align: 'start', value: 'item_external_id',sortable: true },
        { text: 'Towar',align: 'start', value: 'item_name',sortable: true },
        { text: 'Ilość', value: 'item_quantity',sortable: true },
        { text: 'Cena jedn.', value: 'item_price',sortable: true },
        { text: 'Cena jedn. netto', value: 'item_price_net',sortable: true },
        { text: 'Suma', value: 'item_price_sum',sortable: true },
        { text: 'Stawka VAT', value: 'item_tax_rate',sortable: true },
        { text: 'Wysłanych', value: 'item_quantity_send',sortable: true },
        { text: '', value: 'actions', sortable: false,filterable:false},
      ],
      pickupHeaders: [
        { text: 'Kod',align: 'start', value: 'item_external_id',sortable: true },
        { text: 'Towar',align: 'start', value: 'item_name',sortable: true },
        { text: 'Ilość', value: 'item_quantity',sortable: true },
        { text: 'Cena jedn.', value: 'item_price',sortable: true },
        { text: 'Cena jedn. netto', value: 'item_price_net',sortable: true },
        { text: 'Suma', value: 'item_price_sum',sortable: true },
        { text: 'Stawka VAT', value: 'item_tax_rate',sortable: true },
        { text: '', value: 'actions', sortable: false,filterable:false},
      ],
      logHeaders: [
        { text: '',align: 'start', value: 'statusIcon',sortable: false },
        { text: 'Data',align: 'start', value: 'created_at',sortable: false },
        { text: 'Status', value: 'status',sortable: false },
        { text: '', value: 'status_message',sortable:false},
      ],
    }),
    validations(){
      return this.inputValidation;
    },
    watch: {
      integrationShipmentEditStatus(val){
        if(val===2){
          this.$emit('updated');
        }else if(val===3){
          this.integrationShipmentSnackbar = true;
          //this.dialog = false;
        }
      },
      // integrationShipmentLabelLoadStatus(val){
      //   if(val===2){
      //     this.dialog = true
      //   }
      // },
      // integrationShipmentEditLoadStatus(val){
      //   if(val===2){
      //     this.dialog = true
      //   }
      // },
      // integrationShipmentsLoadStatus(val){
      //   if(val===2 && this.$route.query.state){
      //     this.itemEditing = true
      //     this.$store.dispatch( 'loadIntegrationShipmentEdit', {slug:  this.$route.query.state}); 
      //   }
      // },
      // integrationShipmentAddStatus(val){
      //   if(val===2){
      //     this.dialogNew = false
      //     this.dialog = true
      //   }
      // }
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      lookups(){
        return this.$store.getters.getLookups;
      },
      editedItem(){
        return this.$store.getters.getIntegrationShipmentEdit;
      },
      carrierIntegrations(){
        return _.filter(this.lookups.integrations,{'type':2})
      },
      buyerEdit:{
        get () {  
          return this.bEdit || this.independentItem;
        },
        set (value) {
          this.bEdit = value;
        }
      },
      inputValidation(){
        let validationObject = {
          editedItem:{
          buyer:{
            delivery_first_name:{
              required: requiredIf(function (nestedModel) {
                return !this.editedItem.buyer.delivery_company_name;
              })
            },
             delivery_last_name:{
              required: requiredIf(function (nestedModel) {
                return !this.editedItem.buyer.delivery_company_name;
              })
            },
            delivery_city:{required},
            delivery_post_code:{required},
            delivery_street:{required},
            delivery_country_code:{required},
            contact_email:{required},
            contact_phone:{required}
          },
          // carrier:{
          //   service_list:{

          //   },
          //   settings:{
          //     pickup_date:{required}
          //   }
          // },
          // pickup_list:{
          //  $each:{
          //      type:{required},
          //     length:{required,integer},
          //     width:{required,integer},
          //     height:{required,integer},
          //     quantity:{required,integer},
          //     weight:{required,decimalPL}
          //   }
          // }
        }}

        // if(this.independentItem)validationObject.editedItem.carrier = {}
  
        // var i = 0;
        // if(this.independentItem || !this.editedItem.carrier) return validationObject;

        // this.editedItem.carrier.service_list.forEach((service_value,service_index) => {
        //   //console.log(service_value.parameters.length);
        //    let serviceIndex = service_index;
        //    let serviceValue = service_value;

        //    let ob = {parameters:{}};
        //     _.forIn(serviceValue.parameters, function(value, key, object) {
        //       if(value.required && value.type=='float'){
        //          ob.parameters[key] = {value:{required, decimalPL}}
        //       }else if(value.required && value.type=='number'){
        //          ob.parameters[key] = {value:{required, integer}}
        //       }else if(value.required){
        //          ob.parameters[key] = {value:{required}}
        //       }

        //     });
        //     if(serviceValue.selected)validationObject.editedItem.carrier.service_list[serviceIndex] = ob;
        // });

        return validationObject;
      },
      computedInvoices(){
        var invoices = [];
        this.editedItem.order.forEach(o => {
          if(o.invoice_required)invoices.push({
             "invoice_company_name":o.invoice_company_name,
             "invoice_first_name":o.invoice_first_name,
             "invoice_last_name":o.invoice_last_name,
             "invoice_street":o.invoice_street,
             "invoice_post_code":o.invoice_post_code,
             "invoice_city":o.invoice_city,
             "invoice_country_code":o.invoice_country_code,
             "invoice_company_vat":o.invoice_company_vat
          });
        });
        return _.uniqWith(invoices, _.isEqual);
      },
      computedOrderHeaders(){
        if(this.editedItem.integration_id || this.editedItem.total_item_line_count<2)return _.filter(this.orderHeaders,function(o) { return o.value!='selection'; })
        else return this.orderHeaders;
      },
      selectedItems(){
        var items = {}
        this.editedItem.order.forEach(o => {
          let or =  [];
          o.item_list.forEach(i => {
            if(i.selection)or.push(i.item_reference);
          });
          if(or.length>0){
            items[o.order_id] = or;
          }
        });
        return items;
      },
      tableItems(){
        return this.$store.getters.getIntegrationShipments;
      },
      integrationShipment(){
        return this.$store.getters.getIntegrationShipment;
      },
      integrationShipmentEditStatus(){
        return this.$store.getters.getIntegrationShipmentEditStatus();
      },
      integrationShipmentLabelLoadStatus(){
        return this.$store.getters.getIntegrationShipmentLabelLoadStatus();
      },
      integrationShipmentEditText(){
        return this.$store.getters.getIntegrationShipmentEditText
      },
      selectedPrinter(){
        return window.selected_device;
      },
      independentItem(){
        return this.editedItem.hasOwnProperty('independentItem');
      }
    },
    mounted(){
      //this.buyerEdit = this.independentItem;
      // if(this.lookups.integrationShipments.length>0){
      //   this.loadIntegrationShipments();
      // }else{
      //   this.$store.dispatch( 'loadLookups' );
      //   setTimeout(() => {
      //     this.loadIntegrationShipments();
      //   }, 1000)
      // }
    },
    methods: {
      refreshOrderStatus(order){
        this.$store.dispatch( 'editIntegrationShipmentOrderStatus', {slug:this.editedItem.id,order_id:  order.order_id});
      },
      integrationIdChange(){
        this.$store.dispatch( 'editIntegrationShipmentIntegrationId', {slug:this.editedItem.id, integration_id:  this.editedItem.integration_id});
      },
      // requiredCommorErrors(service_index,parameter_index) {
      //   const errors = []
      //   if (!this.$v.editedItem.carrier || 
      //   !this.$v.editedItem.carrier.service_list[service_index] || 
      //   !this.$v.editedItem.carrier.service_list[service_index]['parameters'][parameter_index] || 
      //   !this.$v.editedItem.carrier.service_list[service_index]['parameters'][parameter_index].value.$dirty) return errors
      //   !this.$v.editedItem.carrier.service_list[service_index]['parameters'][parameter_index].value.required && errors.push('Parametr jest wymagany')
      //   return errors
      // },
      // getColorIfErrorCommon(service_index,parameter_index) {
      //   if (!this.$v.editedItem.carrier || 
      //   !this.$v.editedItem.carrier.service_list[service_index] || 
      //   !this.$v.editedItem.carrier.service_list[service_index]['parameters'][parameter_index] || 
      //   !this.$v.editedItem.carrier.service_list[service_index]['parameters'][parameter_index].value.$dirty) return '';
      //   if(this.$v.editedItem.carrier.service_list[service_index]['parameters'][parameter_index].value.$error) return 'red accent-1';
      // },
      // getColorIfErrorPickupDate() {
      //   if(!this.$v.editedItem.carrier || !this.$v.editedItem.carrier.settings.$dirty) return ''
      //   if(this.$v.editedItem.carrier.settings.pickup_date.$error) return 'red accent-1'
      // },
      getColorIfError(parameter) {
        if(!this.$v.editedItem.buyer[parameter] || !this.$v.editedItem.buyer[parameter].$dirty) return ''
        if(!this.$v.editedItem.buyer[parameter].required) return 'red accent-1'
      },
      // getColorIfErrorPickupList(list_index,parametr) {
      //   if(!this.$v.editedItem.pickup_list.$each[list_index][parametr] || !this.$v.editedItem.pickup_list.$each[list_index][parametr].$dirty) return ''
      //   if(this.$v.editedItem.pickup_list.$each[list_index][parametr].$error) return 'red accent-1'
      // },
      doCopy: function () {
        const container = document.querySelector('.v-dialog');
        this.$copyText(this.editedItem.buyer.contact_email, container);
      },
      changeMUC(item){
        if(this.editedItem.carrier && this.editedItem.carrier.muc_list){
          var defaults = _.find(this.editedItem.carrier.muc_list,{'value':item.type}).defaults;
          item.width = defaults?defaults.width:null;
          item.length = defaults?defaults.length:null;
          this.calculateVolume(item);
        }
      },
      calculateVolume(item){
        this.$v.editedItem.pickup_list.$each[this.editedItem.pickup_list.indexOf(item)].$touch();

        if(!this.$v.editedItem.pickup_list.$each[this.editedItem.pickup_list.indexOf(item)].$error){
          item.volume = i18n.n(_.round(item.width/100 * item.length/100 * item.height/100,3), 'number', 'pl-PL');
        }else item.volume = null;
      },
      deletePickupItem(item){
        const index = this.editedItem.pickup_list.indexOf(item);
        this.editedItem.pickup_list.splice(index, 1);
      },
      addPickupItem(){
        this.editedItem.pickup_list.push({
          type:undefined,
          quantity:1,
          length:null,
          width:null,
          height:null,
          weight:null,
          volume:null
        });
      },
      putShipment(){
        this.$v.$touch()
         if (this.$v.$invalid) {
           
        } else {
            //console.log('makeSHipment');

            this.$store.dispatch( 'editIntegrationShipmentSend', {
                slug:  this.editedItem.id,
                order:  this.editedItem.order,
                carrier:  this.prepareCarrier(),
                pickup_list:  this.preparePickupList(),
                buyer:  this.editedItem.buyer
           });
        }
      },
      cantBeSend(){
        //if(!this.editedItem.pickup_list || this.editedItem.pickup_list.length==0)return true;
        return false;
      },
      downloadLabel(type,position){
        this.$store.dispatch( 'getIntegrationShipmentLabel', {slug:this.editedItem.id,position:position, type:type});
      },
      moveItems(){
        this.$store.dispatch( 'editIntegrationShipmentMove', {
                slug:  this.editedItem.id,
                selected:  this.selectedItems
        });
      },
      cancelShipment(){
        this.$refs.confirm.open('Anuluj', 'Czy na pewno chcesz anulować nadanie wysyłki?', { color: 'error' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'editIntegrationShipmentCancel', {slug:  this.editedItem.id});
            }
        })
      },
      // loadIntegrationShipments(){
      //   this.search='';
      //   this.accountId = this.aId;//pobiera z propsów
      // },
     
      // editItem(item) {
      //   this.itemEditing = true
      //   this.$store.dispatch( 'loadIntegrationShipmentEdit', {slug:  item.id});  
      // },

      // newItem(type) {
      //   this.$store.dispatch( 'addIntegrationShipment', {
      //           type:  type
      //   });
      // },

      // showDetailes(item){
      //   this.dialogIntegrationShipmentId = item.id;
      //   this.dialogIntegrationShipment = true
      // },
      // cancelItem(item){
      //   this.$refs.confirm.open('Anuluj', 'Czy na pewno chcesz anulować zamówienie? Operacja jest nieodwracalna.', { color: 'error' }).then((confirm) => {
      //       if(confirm){
      //           this.$store.dispatch( 'editIntegrationShipmentStatus', {slug:  item.id, status:5}); 
      //       }
      //   })
       
      // },
      // deleteItem (item) {
      //   this.$refs.confirm.open('Usuń', 'Czy na pewno chcesz usunać konto?', { color: 'error' }).then((confirm) => {
      //       if(confirm){
      //          this.$store.dispatch( 'forceDeleteIntegrationShipment', {slug:  item.id});
      //       }
      //   })
      // },
      // close () {
      //   this.dialog = false
      //   //this.$v.$reset()
      //   setTimeout(() => {
      //     this.$store.dispatch( 'loadIntegrationShipmentEditDefault'); 
      //     this.$store.dispatch( 'loadIntegrationShipmentAddDefault');
      //     this.itemEditing = false

      //     if(this.$route.query.code || this.$route.query.error){
      //       this.$router.push({ name: 'integracje.ustawienia' });
      //     }

      //   }, 300)
      // },
      // closeNew () {
      //   this.dialogNew = false
      // },
     
      // handleIntegrationShipmentSubmit () {
      //         this.$store.dispatch( 'editIntegrationShipment', {
      //           slug:  this.editedItem.id,
      //           name:  this.editedItem.name,
      //           integrable_type:  this.editedItem.integrable_type,
      //           integrable:  this.editedItem.integrable,
      //         });
       
      // },
      close(){
        this.$v.$reset();
        this.$emit('close');
        this.showOrder=false;
      },
      prepareCarrier(){
        let carrier = JSON.parse(JSON.stringify(this.editedItem.carrier));
           if(carrier && carrier.service_list){
             carrier.service_list.forEach(s_item => {
               if(s_item.parameters){
                 s_item.parameters.forEach(p_item => {
                   if(p_item.type=='float' && p_item.value)p_item.value = parseFloat(p_item.value.toString().replace(/\./g, "").replace(/,/, "."));
                 });
               }
             });
           }
        return carrier;
      },
      preparePickupList(){
        let pickup_list = JSON.parse(JSON.stringify(this.editedItem.pickup_list));
           if(pickup_list){
             pickup_list.forEach(p_item => {
               p_item.weight = parseFloat(p_item.weight.toString().replace(/\./g, "").replace(/,/, "."));
               p_item.volume = parseFloat(p_item.volume.toString().replace(/\./g, "").replace(/,/, "."));
             });
           }
        return pickup_list;
      },
      handleSubmit () {
        this.$v.$touch()
         if (this.$v.$invalid) {
           
        } else {
            if(this.independentItem){//new item
               this.$store.dispatch( 'addIntegrationShipment', {
                buyer:  this.editedItem.buyer,
                amount: this.editedItem.amount,
                amount_currency: this.editedItem.amount_currency,
                custom_order: this.editedItem.custom_order
              });
            }else{
              this.$store.dispatch( 'editIntegrationShipment', {
                slug:  this.editedItem.id,
                order:  this.editedItem.order,
                carrier:  this.prepareCarrier(),
                pickup_list:  this.preparePickupList(),
                buyer:  this.editedItem.buyer,
                amount: this.editedItem.amount,
                amount_currency: this.editedItem.amount_currency,
                custom_order: this.editedItem.custom_order
              });
            }
        }
      }
    },
  }
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color:red;
  border-width: 2px;
}
a.offerLink{
  text-decoration: none;
  color: inherit;
}
a.offerLink:hover{
  text-decoration: underline;
}
</style>

<template>
                  <v-card  :loading="bankAccountAddStatus===1 || bankAccountEditLoadStatus===1 || bankAccountEditStatus===1">
                    <v-card-title>
                      <img class="mr-3" src="/logo_listek.png" />
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        
                          <v-row>                   
                            <v-col cols="12" sm="12" md="12">
                              <v-text-field v-model="editedItem.bank" :error-messages="bankErrors" required @input="$v.editedItem.bank.$touch()"  @blur="$v.editedItem.bank.$touch()" label="Nazwa banku"></v-text-field>                            
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                              <v-text-field v-model="editedItem.iban" :error-messages="ibanErrors" required @input="$v.editedItem.iban.$touch()"  @blur="$v.editedItem.iban.$touch()" label="IBAN"></v-text-field>
                            </v-col>
                             <v-col cols="12" sm="3" md="3">
                              <v-select v-model="editedItem.currency" :error-messages="currencyErrors" :items="lookups.bankAccountCurrency" label="Waluta rachunku"></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="editedItem.swift" :error-messages="swiftErrors" required @input="$v.editedItem.swift.$touch()"  @blur="$v.editedItem.swift.$touch()" label="SWIFT"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="2" md="2">
                              <v-checkbox
                                :disabled="itemEditing && itemWasCod"
                                v-model="editedItem.cod"
                                label="COD"
                              ></v-checkbox>
                              
                            </v-col>
                            <v-col cols="12" sm="2" md="2">
                              <v-checkbox
                                :disabled="itemEditing && itemWasDocument"
                                v-model="editedItem.document"
                                label="Dokument"
                              ></v-checkbox>
                              
                            </v-col>
                            
                           
                          </v-row>
                        
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="gray" text @click="close()">Anuluj</v-btn>
                      <v-btn color="primary darken-1" text @click="handleSubmit" >Zapisz</v-btn>
                    </v-card-actions>
                  </v-card>
</template>

<script>
  import required from 'vuelidate/lib/validators/required'

  export default {
    data: () => ({
    
    }),
    props:['itemEditing','itemWasCod', 'itemWasDocument'],
    validations: {
      editedItem:{
        bank: {
          required,
        },
        iban:{
          required
        },
        currency:{
          required
        },
        swift:{
          required
        },
      }
    },
    watch: {
    
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      lookups(){
        return this.$store.getters.getLookups;
      },
      editedItem(){
        return this.$store.getters.getBankAccountEdit;
      },
      bankAccountEditLoadStatus(){
        return this.$store.getters.getBankAccountEditLoadStatus
      },
      bankAccountEditStatus(){
        return this.$store.getters.getBankAccountEditStatus();
      },
      bankAccountEditText(){
        return this.$store.getters.getBankAccountEditText
      },
      bankAccountAddStatus(){
        return this.$store.getters.getBankAccountAddStatus();
      },
      bankAccountAddText(){
        return this.$store.getters.getBankAccountAddText;
      },
    
      formTitle () {
        return !this.itemEditing ? 'Nowy rachunek' : 'Edycja rachunku'
      },
      bankErrors () {
        const errors = []
        if (!this.$v.editedItem.bank.$dirty) return errors
        !this.$v.editedItem.bank.required && errors.push('Nazwa banku jest wymagana')
        return errors
      },
      ibanErrors () {
        const errors = []
        if (!this.$v.editedItem.iban.$dirty) return errors
        !this.$v.editedItem.iban.required && errors.push('Nr IBAN jest wymagany')
        return errors
      },
      currencyErrors () {
        const errors = []
        if (!this.$v.editedItem.currency.$dirty) return errors
        !this.$v.editedItem.currency.required && errors.push('Waluta jest wymagana')
        return errors
      },
      swiftErrors () {
        const errors = []
        if (!this.$v.editedItem.swift.$dirty) return errors
        !this.$v.editedItem.swift.required && errors.push('Nr SWIFT jest wymagany')
        return errors
      },
    },
 
    methods: {
      close(){
        this.$v.$reset();
        this.$emit('close');
      },
       handleSubmit () {
        this.$v.$touch()
        if (this.$v.$invalid) {

        } else {
         
            if (this.itemEditing) {
              //edycja
              this.$store.dispatch( 'editBankAccount', {
                slug: this.editedItem.id,
                bank:  this.editedItem.bank,
                iban:  this.editedItem.iban,
                currency:  this.editedItem.currency,
                swift: this.editedItem.swift,
                cod: this.editedItem.cod,
                document:  this.editedItem.document,
              });
            } else {//new item
              this.$store.dispatch( 'addBankAccount', {
                  bank:  this.editedItem.bank,
                  iban:  this.editedItem.iban,
                  currency:  this.editedItem.currency,
                  swift: this.editedItem.swift,
                  cod: this.editedItem.cod,
                  document:  this.editedItem.document,
              });

            }
        }
      },
    },
  }
</script>


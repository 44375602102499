import { FLOREX_CONFIG } from '../config'
import axios from 'axios'

export default {

    getCookie: function(){
        console.log(FLOREX_CONFIG.API_URL);
        return axios.get( FLOREX_CONFIG.WEB_URL + '/sanctum/csrf-cookie');
    },

    loginUser: function( login, password, code){
        //console.log(FLOREX_CONFIG.API_URL);
        return axios.post( FLOREX_CONFIG.WEB_URL + '/login',
            {
                login: login,
                password: password,
                code: code
            }
        );
    },


    logoutUser: function(){
        return axios.get( FLOREX_CONFIG.WEB_URL + '/logout');
    },


    /*
        GET     /api/user
    */
    getUser: function(){
        return axios.get( FLOREX_CONFIG.API_URL + '/user' );
    },

    
    /*
        GET     /api/users
    */
   getUsers: function(options){
        return axios.get( FLOREX_CONFIG.API_URL + '/users',{
            params:{
                offset:options.offset,
                itemsPerPage:options.itemsPerPage,
                orderBy:options.orderBy,
                orderDirection:options.orderDirection,
                showDeleted:options.showDeleted,
        }});
    },

    /*
        GET     /api/users/search
    */
    searchUsers: function(options){
        return axios.get( FLOREX_CONFIG.API_URL + '/users/search',{
            params:{
                showDeleted:options.showDeleted,
                filterableHeaders:options.filterableHeaders,
                q:options.q
        }});
    },

    /*
        POST 	/api/users
    */
    postAddNewUser: function( name, surname, mobile, email, login, password,oneTimePassword,group_id,departments ){
        return axios.post( FLOREX_CONFIG.API_URL + '/users',
            {
                name: name,
                surname: surname,
                mobile: mobile,
                email:email,
                login: login,
                password: password,
                oneTimePassword:oneTimePassword,
                group_id:group_id,
                departments:departments
            }
        );
    },

    /*
        GET 	/api/users/{slug}/edit
    */
    getUserEdit: function( slug ){
        return axios.get( FLOREX_CONFIG.API_URL + '/users/' + slug + '/edit' );
    },

    /*
        PUT 	/api/users/{slug}
    */
    putEditUser: function(slug, name, surname, mobile,email,  login,group_id, departments){
        return axios.put( FLOREX_CONFIG.API_URL + '/users/'+ slug,
            {
                name: name,
                surname: surname,
                mobile: mobile,
                email:email,
                login: login,
                group_id:group_id,
                departments:departments
            }
        );
    },

    /*
        GET 	/api/users/reactivate/{slug}
    */
    putReactivateUser: function(slug){
        return axios.get( FLOREX_CONFIG.API_URL + '/users/reactivate/' + slug);
    },

    /*
        DELETE /api/users/{slug}
    */
    deleteUser: function( slug ){
        return axios.delete( FLOREX_CONFIG.API_URL + '/users/' + slug );
    },

    /*
        DELETE /api/users/{slug}/force
    */
    forceDeleteUser: function( slug ){
        return axios.delete( FLOREX_CONFIG.API_URL + '/users/' + slug + '/force' );
    },


     /*
		PUT 	/api/user/{slug}/reset
	*/
    putEditUserPassword: function(slug, password, oneTimePassword){
        return axios.put( FLOREX_CONFIG.API_URL + '/user/'+ slug + '/reset',
            {
                password: password,
                oneTimePassword: oneTimePassword
            }
        );
    },

    /*
		PUT 	/api/user/{slug}/resetOneTime
	*/
    putEditUserOneTimePassword: function(slug, password, oldPassword){
        return axios.put( FLOREX_CONFIG.API_URL + '/user/'+ slug + '/resetOneTime',
            {
                password: password,
                oldPassword: oldPassword
            }
        );
    },
    

}
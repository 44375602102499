<template>
<v-card :loading="integrationAddStatus===1 || integrationTestStatus===1">
                       <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                        <v-spacer /><img src="/integration/allegro.png" height="50px" class="mr-3"/>
                        </v-card-title>
                        <v-card-text>
                              <v-container>
                                <v-row>     
                                              <v-col cols="12" sm="6" md="6">
                                                <v-text-field v-model="editedItemIntegration.name" :error-messages="nameErrors" @input="$v.editedItemIntegration.name.$touch()"  label="Nazwa konta"></v-text-field>                            
                                              </v-col>
                                </v-row> 
                                          <v-row v-if="editedItem.token">     
                                             <v-col cols="12" sm="6" md="6">
                                                <v-text-field readonly v-model="editedItem.token.jti" label="Identyfikator tokena JWT"></v-text-field>                            
                                              </v-col>
                                              <v-col cols="12" sm="6" md="6">
                                                <v-text-field readonly v-model="editedItem.token_expiry_at" label="Ważność aktualnego tokena"></v-text-field>                            
                                              </v-col>
                                              <!-- <v-col cols="12" sm="6" md="6">
                                                <v-text-field readonly v-model="editedItem.last_event_id" label="Numer ostatniego zarejestrowanego zdarzenia"></v-text-field>                            
                                              </v-col>         -->
                                          </v-row> 
                                          <v-row v-if="!editedItem.token && editedItem.link">
                                            <v-col cols="12" sm="6" md="6">
                                              <v-btn @click="allegroLogin" dark color="primary">Zaloguj się do Allegro</v-btn>
                                            </v-col>
                                          </v-row>
                                          <v-row v-if="query.error">
                                                  <v-col class="d-flex flex-column align-center">
                                                      <v-alert dense outlined type="error">
                                                        Błąd podczas łączenia konta
                                                      </v-alert>    
                                                  </v-col>
                                          </v-row>  
                                          <v-row v-if="integrationTestStatus===2">
                                                  <v-col class="d-flex flex-column align-center">
                                                      <v-alert dense outlined type="success">
                                                        Test połączenia zakończony sukcesem
                                                      </v-alert>    
                                                  </v-col>
                                          </v-row> 
                                          <v-row v-if="integrationTestStatus===3">
                                                  <v-col class="d-flex flex-column align-center">
                                                      <v-alert dense outlined type="error">
                                                        Nastąpił problem z połączeniem
                                                      </v-alert>   
                                                      <v-btn @click="allegroLogin" dark color="primary">Zaloguj się ponownie</v-btn>
                                                  </v-col>
                                          </v-row>   
                                          
                                                         
        </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn v-if="editedItem.token" color="grey" text @click="testConnection">Testuj połączenie</v-btn>
                          <v-btn color="grey" text @click="closeFunction">Zamknij</v-btn>
                          <v-btn color="primary darken-1" text @click="submit" >Zapisz</v-btn>
                        </v-card-actions>
                    </v-card>
         
</template>

<script>
  import required from 'vuelidate/lib/validators/required'

  export default {
    data: () => ({

    }),
    props:['formTitle','closeFunction','submitFunction','query'],
    validations:{
      editedItem:{
          // client_id:{
          //   required,
          // },
          // client_secret:{
          //   required,
          // }  
      },
      editedItemIntegration:{
          name:{
            required,
          },
      }
    },
    watch: {
     
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      editedItem(){
        return this.$store.getters.getIntegrationEdit.integrable;
      },
      editedItemIntegration(){
        return this.$store.getters.getIntegrationEdit;
      },
      integrationAddStatus(){
        return this.$store.getters.getIntegrationAddStatus();
      },
      integrationTestStatus(){
        return this.$store.getters.getIntegrationTestStatus();
      },
      nameErrors () {
        const errors = []
        if (!this.$v.editedItemIntegration.name.$dirty) return errors
        !this.$v.editedItemIntegration.name.required && errors.push('Nazwa konta jest wymagana')
        return errors
      },
      // client_idErrors () {
      //   const errors = []
      //   if (!this.$v.editedItem.client_id.$dirty) return errors
      //   !this.$v.editedItem.client_id.required && errors.push('Nr Klienta jest wymagany')
      //   return errors
      // },
      // client_secretErrors () {
      //   const errors = []
      //   if (!this.$v.editedItem.client_secret.$dirty) return errors
      //   !this.$v.editedItem.client_secret.required && errors.push('Hasło klienta jest wymagane')
      //   return errors
      // },
    },
    mounted(){
      if(this.query.code){
        //this.editedItem.code = this.query.code;
        //this.submit();
        this.$store.dispatch( 'putAllegroCode', {
                id:  this.editedItemIntegration.id,
                code:  this.query.code,
        });
      }
    },
    methods: {
      testConnection(){
         this.$store.dispatch( 'testIntegration', {
              slug:  this.editedItemIntegration.id
        });
      },
      allegroLogin(){
        window.location.href = this.editedItem.link;
      },
      submit(){
        this.$v.$touch()
        if (this.$v.$invalid) {

        } else {
          this.editedItem.link = undefined;
          //this.editedItem.integration = undefined;
          this.submitFunction();
        }
      }
    },
  }
</script>
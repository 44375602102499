<template>
<v-row no-gutters>
      <v-col>
          <v-card flat>
            <v-data-table
                dense
                :key="componentKey"
                hide-default-footer
                :headers="computedHeaders"
                :items="tableItems"
                class="elevation-1"
                :items-per-page="-1"
                :options.sync="tableOptions"
                v-model="tableItemsSelected"
                :loading="StornChecksLoadStatus===1 || integrationTaskRunningStatus===1"
            >
                <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-toolbar-title>Kontrola Storn Wysyłek</v-toolbar-title>
                    
                    <v-dialog v-model="dialog" :max-width="dialogWidth">
                      <orderEditor v-on:close="dialog=false" />
                    </v-dialog>

                    <v-spacer></v-spacer>
                    
                    
                </v-toolbar>
                </template>
                 <template v-slot:item.Wartosc_Wysylki="{ item }">
                    {{item.Wartosc_Wysylki?$n(item.Wartosc_Wysylki,'number','pl-PL'):''}} PLN
                 </template>
                 <template v-slot:item.Kwota_Wysylki="{ item }">
                    {{item.Kwota_Wysylki?$n(item.Kwota_Wysylki,'number','pl-PL'):''}} PLN
                 </template>
                 <template v-slot:item.Status="{ item }">
                   <v-icon color="green" v-if="item.Status===1">mdi-check-circle-outline</v-icon>
                   <v-icon color="error" v-else>mdi-circle-outline</v-icon>
                   <span class="font-weight-medium ml-2">{{_.find(lookups.stornCheckStaus,{'value':item.Status}).text}}</span>
                   <v-btn v-if="item.Status===12" color="error" class="ml-2" x-small @click="reloadOrderAction(item['Codido Order ID'])">Pobierz</v-btn>
                   <v-btn v-if="item.Status===11" color="error" class="ml-2" x-small @click="reloadShipmentAction(item['Codido Shipment ID'])">Pobierz</v-btn>
                   <v-btn v-if="item.Status===8" color="error" class="ml-2" x-small @click="refreshShipmentAction(item['Codido Shipment ID'])">Pobierz</v-btn>
                   <v-btn v-if="item.Status===10" color="error" class="ml-2" x-small @click="reloadStornAction(item['Spedix Shipment ID'])">Utwórz</v-btn>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn v-if="item['Spedix Order ID']" color="primary" x-small @click="openOrder(item['Spedix Order ID'],item['Wartosc_Wysylki'])">Zamówienie</v-btn>
                </template>
                
            </v-data-table> 
           
            <confirm ref="confirm"></confirm>
          </v-card>
      </v-col>
      <v-col md="auto"
      >
        <v-container class="d-flex flex-column pa-0 ml-3">
          <v-btn @click="handleSubmit" :disabled="StornChecksLoadStatus===1 || $store.getters.getStornChecks.length!=0 || !fromDate || !toDate" large color="primary"  width="250" active-class="aa" exact>Analizuj</v-btn>
          <v-btn @click="clearData" :disabled="$store.getters.getStornChecks.length===0" large color="primary"  width="250" active-class="aa" exact class="mt-5">
                Wyszyść dane
          </v-btn>
          <v-card width="250" class="mt-5">
              <v-card-text>
                <div>
                  <v-container class="pa-0 widget-header"><strong>Zakres analizy</strong></v-container>
                  <v-row no-gutters dense>
                      <v-menu
                                      v-model="dateFromMenu"
                                      :close-on-content-click="false"
                                      :nudge-right="40"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="150px"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                        hide-details
                                         clearable
                                          v-model="fromDate"
                                          label="Od"
                                          prepend-icon="mdi-calendar"
                                          readonly
                                          v-on="on"
                                          class="choose_date mt-6 mr-5"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker  first-day-of-week="1" no-title v-model="fromDate" @input="dateFromMenu = false"></v-date-picker>
                      </v-menu>
                  </v-row>
                  <v-row no-gutters dense>
                      <v-menu
                                      v-model="dateToMenu"
                                      :close-on-content-click="false"
                                      :nudge-right="40"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="150px"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                        hide-details
                                          clearable
                                          v-model="toDate"
                                          label="Do"
                                          prepend-icon="mdi-calendar"
                                          readonly
                                          v-on="on"
                                          class="choose_date mt-6 mr-5"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker  first-day-of-week="1" no-title v-model="toDate" @input="dateToMenu = false"></v-date-picker>
                      </v-menu>
                  </v-row>
                </div>
              </v-card-text>
        </v-card>
        <v-card v-if="$store.getters.getStornChecks.length>0" width="250" class="mt-5">
              <v-card-text>
                <div>
                  <v-container class="pa-0 widget-header mb-2"><strong>Podsumowanie (ilość/wartość)</strong></v-container>
                  <v-row no-gutters dense>
                     <v-col cols="auto"><v-icon color="white" class="mr-1" size="20">mdi-checkbox-blank-circle</v-icon></v-col>
                     <v-col>Codido</v-col>
                     <v-col>Spedix</v-col>
                  </v-row>
                  <v-row no-gutters dense>
                     <v-col cols="auto"><v-icon  class="mr-1" :color="options.codido_qty==options.spedix_qty?'green':'red'" size="20">mdi-checkbox-blank-circle</v-icon></v-col>
                     <v-col>{{options.codido_qty}}</v-col>
                     <v-col>{{options.spedix_qty}}</v-col>
                  </v-row>
                  <v-row no-gutters dense>
                     <v-col cols="auto"><v-icon  class="mr-1" :color="options.codido_sum==-options.spedix_sum?'green':'red'" size="20">mdi-checkbox-blank-circle</v-icon></v-col>
                     <v-col>{{$n(options.codido_sum,'number','pl-PL')}}</v-col>
                     <v-col>{{$n(options.spedix_sum,'number','pl-PL')}}</v-col>
                  </v-row>
                </div>
              </v-card-text>
        </v-card>
          <v-card width="250" class="mt-5">
              <v-card-text>
                <div>
                  <v-container class="pa-0 widget-header"><strong>Status</strong></v-container>
                  <v-row no-gutters dense><v-checkbox :disabled="statusId>0" hide-details v-model="showActive" label="Tylko błędne"></v-checkbox></v-row>
                  <v-row no-gutters dense><v-select hide-details clearable v-model="statusId" :items="lookups.stornCheckStaus" label="Wybrany status"></v-select></v-row>
                </div>
              </v-card-text>
        </v-card>
        </v-container>
        <!-- <menuIntegracjeDokumenty>
          <template v-slot:actions>
            <v-btn @click="multiDelete" v-if="multiEdit" :disabled="tableItemsSelected.length<=0" large color="primary"  width="250" active-class="aa" exact>
                Usuń wybrane
            </v-btn>
            <v-btn @click="advanceCSV" v-if="multiEdit" :disabled="tableItemsSelected.length<=0 || _.difference(_.map(tableItemsSelected,'type'),[_.find(lookups.StornCheckTypes,{'value':1}).text]).length>0" large color="primary"  width="250" active-class="aa" exact>
                Zaliczki CSV
            </v-btn>
            <v-btn @click="stornCSV" v-if="multiEdit" :disabled="tableItemsSelected.length<=0 || _.difference(_.map(tableItemsSelected,'type'),[_.find(lookups.StornCheckTypes,{'value':10}).text]).length>0" large color="primary"  width="250" active-class="aa" exact>
                Korekty CSV
            </v-btn>
           
          </template>
        </menuIntegracjeDokumenty> -->
      </v-col>
    </v-row>
</template>

<script>
  import confirm from '../components/Confirm'
  import orderEditor from '../components/IntegrationOrderEditor'

  export default {
    components:{
      confirm,
      orderEditor
    },
    props:['aId'],
    data: () => ({
      componentKey:0,
      tableOptions: {},
      tableItemsSelected:[],
      search:'',
      dialog: false,
      dialogWidth:1500,
      dateFromMenu:false,
      dateToMenu:false,
      headers: [
        { text: 'Lp',align: 'start',sortable: false,value: 'lp',filterable:false,visible:true},
        { text: 'Data', value: 'Data',sortable: true,filterable:true,filterColumn:"Data",visible:true },
        { text: 'Nr Wysyłki CODIDO', value: 'Codido Shipment ID',sortable:true,filterable:true,filterColumn:"Codido Shipment ID",visible:true  },
        { text: 'Nr Zamówienia CODIDO', value: 'Codido Order ID',sortable: true,filterable:true,filterColumn:"Codido Order ID",visible:true },
        { text: 'Nr Zamówienia PRESTA', value: 'Presta Order ID',sortable: true,filterable:true,filterColumn:"Presta Order ID",visible:true },
        { text: 'Suma wysyłki', value: 'Wartosc_Wysylki',sortable: true,filterable:true,filterColumn:"Wartość Wysyłki",visible:true },
        { text: 'Koszt wysyłki', value: 'Kwota_Wysylki',sortable: true,filterable:true,filterColumn:"Kwota Wysyłki",visible:true },
        { text: 'Status', value: 'Status',sortable:true,filterable:true,filterColumn:"Status",visible:true},
        { text: '', value: 'actions', sortable: false,filterable:false,visible:true},
      ],
    }),
    validations() {
      
    },
    beforeRouteLeave (to, from, next) {
      next();
    },
    watch: {
      tableOptions: {
        handler () {
          var currentOptions = {...this.options};
          const { sortBy, sortDesc } = this.tableOptions
          //zmiana sortowania
          if (sortBy.length === 1 && sortDesc.length === 1) {
            currentOptions.orderBy = sortBy[0];
            currentOptions.orderDirection = sortDesc[0]?'desc':'asc';
            this.options = currentOptions;
          } 
        },
        deep: true,
      },
      dialog (val) {
        //this.$v.$reset()
        if(val){

        }else{
          //this.close()
        }
      },
      integrationOrderEditLoadStatus(val){
        if(val===2){
          this.dialog = true;
        }
      },
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      lookups(){
        return this.$store.getters.getLookups;
      },
      StornChecksLoadStatus(){
        return this.$store.getters.getStornChecksLoadStatus();
      },
      options:{
        get () {  
          return this.$store.getters.getStornCheckOptions;
        },
        set (value) {
          this.search='';
          this.$store.dispatch( 'setStornCheckOptions', {options:value} )
        }
      },
      accountId: {
        get () {
          return this.$store.getters.getStornCheckOptions.accountId;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.accountId = value;
          this.options = currentOptions;
        }
      },
      statusId: {
        get () {
          return this.$store.getters.getStornCheckOptions.statusId;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.statusId = value;
          this.options = currentOptions;
        }
      },
      showActive: {
        get () {
          return this.$store.getters.getStornCheckOptions.showActive;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.showActive = value;
          this.options = currentOptions;
        }
      },
      fromDate: {
        get () {
          return this.$store.getters.getStornCheckOptions.fromDate;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.fromDate = value;
          this.options = currentOptions;
        }
      },
      toDate: {
        get () {
          return this.$store.getters.getStornCheckOptions.toDate;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.toDate = value;
          this.options = currentOptions;
        }
      },
      tableItems(){
        if(this.statusId){
          return _.filter(this.$store.getters.getStornChecks,{'Status':this.statusId});
        }else if(this.showActive)return _.filter(this.$store.getters.getStornChecks,(o)=>{return o.Status != 1})
        
        return this.$store.getters.getStornChecks;
      },
      StornChecksLoadStatus(){
        return this.$store.getters.getStornChecksLoadStatus();
      },
      
      computedHeaders(){
        return _.filter(this.headers,function(o) { return o.visible; })
      },
      
      integrationOrderEditLoadStatus(){
        return this.$store.getters.getIntegrationOrderEditLoadStatus()
      },
      integrationTaskRunningStatus(){
        return this.$store.getters.getIntegrationTaskRunningStatus();
      },
    },
    methods: {
      clearData(){
        this.$store.commit( 'setStornChecksLoadStatus',0);
        this.$store.commit( 'setStornChecks',[]);
      },
      openOrder(id,amount){
        var order = _.find(this.tableItems,{'Spedix Order ID':id,'Wartosc_Wysylki':amount})
        this.$store.commit( 'setIntegrationOrderPayUNote',{
          date:order['Data'],
          amount:order['Wartosc_Wysylki'],
          currency:'PLN',
          status:order['Status'],
          desc:''
        });

        this.$store.dispatch( 'loadIntegrationOrderEdit', {slug:  id}); 
      },
      reloadOrderAction(codido_order_id){
        this.$store.dispatch( 'runCustomIntegrationTask', {
                        slug: 37,//Zadnie pobieranie wybranego zamówienia
                        initialValues:{'codido_order_id':codido_order_id}
        });
      },
      reloadShipmentAction(codido_shipment_id){
        this.$store.dispatch( 'runCustomIntegrationTask', {
                        slug: 48,//Zadnie aktualizacja wysyłki
                        initialValues:{'codido_shipment_id':codido_shipment_id}
        });
      },
      refreshShipmentAction(codido_shipment_id){
        this.$store.dispatch( 'runCustomIntegrationTask', {
                        slug: 10016,//Zadnie odświeżanie wysyłki
                        initialValues:{'codido_shipment_id':codido_shipment_id}
        });
      },
      reloadStornAction(shipment_id){
        this.$store.dispatch( 'runCustomIntegrationTask', {
                slug: 10006,//Korekta częściowa
                initialValues:{'spedix_shipment_id':shipment_id}
        });
      },
      
      handleSubmit() {
        this.$store.dispatch( 'loadStornChecks');
      },
    },
  }
</script>


<style scoped>
.search{
      max-width: 250px;
}
</style>

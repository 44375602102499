import DepartmentAPI from "../api/department";

export const departments = {
    state: {
        departments: [],
        departmentOptions:{
            lineCount:0,
            showDeleted:false,
            orderBy:'name',
            orderDirection:'asc',
            itemsPerPage:10,
            itemAdded:false,
            itemSearched:false
        },
        departmentsLoadStatus: 0,
        departmentAddStatus: 0,
        departmentAddText: "",

        department: {
            id: 0,
            lp: 0,
            name: '',
            city:'',
            street:'',
            post:undefined,
            email:undefined,
            phone:'',
            main:false,
            account:{
                nip:undefined,
                regon:undefined,
                krs:undefined
            }
        },
        departmentEdit: {
            id: 0,
            lp: 0,
            name: '',
            city:'',
            street:'',
            post:undefined,
            email:undefined,
            phone:'',
            main:false,
            account:{
                nip:undefined,
                regon:undefined,
                krs:undefined
            }
        },
        departmentEditDefault: {
            id: 0,
            lp: 0,
            name: '',
            city:'',
            street:'',
            post:undefined,
            email:undefined,
            phone:'',
            main:false,
            account:{
                nip:undefined,
                regon:undefined,
                krs:undefined
            }
        },
        departmentEditLoadStatus: 0,
        departmentEditStatus: 0,
        departmentEditText: "",

        departmentDeleteStatus: 0,
        departmentDeleteText: "",

    },

    actions: {
        loadDepartments({ state, commit, dispatch }) {
            commit("setDepartmentsLoadStatus", 1);
                DepartmentAPI.getDepartments({
                    offset:0,
                    itemsPerPage:state.departmentOptions.itemsPerPage, 
                    orderBy:state.departmentOptions.orderBy,
                    orderDirection:state.departmentOptions.orderDirection,
                    showDeleted:state.departmentOptions.showDeleted,
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setDepartmentLineCount", response.data.lineCount);
                    commit("setDepartments", response.data.departments);
                    dispatch("applyIndexOnDepartments");
                    commit("setDepartmentsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setDepartments", []);
                    commit("setDepartmentsLoadStatus", 3);
                });
         
        },
        loadMoreDepartments({ state, commit, dispatch }) {
            commit("setDepartmentsLoadStatus", 1);
                DepartmentAPI.getDepartments({
                    offset:state.departments.length,
                    itemsPerPage:state.departmentOptions.itemsPerPage, 
                    orderBy:state.departmentOptions.orderBy,
                    orderDirection:state.departmentOptions.orderDirection,
                    showDeleted:state.departmentOptions.showDeleted,
                })
                .then(function(response) {
                    commit("setDepartmentLineCount", response.data.lineCount);
                    commit("setDepartments", _.concat(state.departments, response.data.departments));
                    dispatch("applyIndexOnDepartments");
                    commit("setDepartmentsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setDepartments", []);
                    commit("setDepartmentsLoadStatus", 3);
                });
         
        },
        searchDepartments({ state, commit, dispatch },data) {
            commit("setDepartmentsLoadStatus", 1);
                DepartmentAPI.searchDepartments({
                    showDeleted:state.departmentOptions.showDeleted,
                    filterableHeaders:data.filterableHeaders,
                    q:data.q
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setDepartmentSearchedTrue");
                    commit("setDepartmentLineCount", response.data.lineCount);
                    commit("setDepartments", response.data.departments);
                    dispatch("applyIndexOnDepartments");
                    commit("setDepartmentsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setDepartments", []);
                    commit("setDepartmentsLoadStatus", 3);
                });
         
        },
        setDepartmentOptions({ state, commit, dispatch },data){
            commit("setDepartmentOptions",data.options);
            dispatch("loadDepartments");
        },
        applyIndexOnDepartments({ commit, state, dispatch }) {
            let localDepartments = state.departments;

            var i = 1;
            localDepartments.forEach(department => {
                department.lp = i;
                i++;
            });

            commit("setDepartments",localDepartments);
        },
        addDepartment({ commit, state, dispatch }, data) {
            commit("setDepartmentAddStatus", 1);
            commit("setDepartmentAddText", "");

            DepartmentAPI.postAddNewDepartment(
                data.name,
                data.city,
                data.street,
                data.post,
                data.email,
                data.phone,
                data.main,
            )
                .then(function(response) {
                    commit("setDepartmentAddStatus", 2);
                    commit("setAddedDepartment",response.data);
                    commit("setDepartmentLineCount", state.departmentOptions.lineCount + 1);
                    dispatch("applyIndexOnDepartments");
                    
                    dispatch("loadDepartmentLookup");
                })
                .catch(function(error) {
                    commit("setDepartmentAddStatus", 3);
                    commit("setDepartmentAddText", error.response.data.errors);
                });
        },

        loadDepartmentEdit({ commit }, data) {
            commit("setDepartmentEditLoadStatus", 1);
            commit("setDepartmentEditStatus", 0);
            commit("setDepartmentEditText", "");

            DepartmentAPI.getDepartmentEdit(data.slug)
                .then(function(response) {
                    commit("setDepartmentEdit", response.data);
                    commit("setDepartmentEditLoadStatus", 2);
                })
                .catch(function() {
                    commit("setDepartmentEdit", {});
                    commit("setDepartmentEditLoadStatus", 3);
                });
        },
        loadMainDepartmentEdit({ commit }, data) {
            commit("setDepartmentEditLoadStatus", 1);
            commit("setDepartmentEditStatus", 0);
            commit("setDepartmentEditText", "");

            DepartmentAPI.getMainDepartmentEdit()
                .then(function(response) {
                    commit("setDepartment", response.data);
                    commit("setDepartmentEditLoadStatus", 2);
                })
                .catch(function() {
                    commit("setDepartmentEdit", {});
                    commit("setDepartmentEditLoadStatus", 3);
                });
        },
        loadDepartmentEditDefault({ commit }) {
            commit("setDepartmentEditDefault");
        },
        loadDepartmentAddDefault({ commit }) {
            commit("setDepartmentAddStatus", 0);
            commit("setDepartmentAddText", "");
        },
        editDepartment({ commit, state, dispatch }, data) {
            commit("setDepartmentEditStatus", 1);

            DepartmentAPI.putEditDepartment(
                data.slug,
                data.name,
                data.city,
                data.street,
                data.post,
                data.email,
                data.phone,
                data.main,
            )
                .then(function(response) {
           
                    commit("setDepartmentEditStatus", 2);
                    commit("setEditedDepartment",response.data)
                    dispatch("applyIndexOnDepartments");
                    dispatch("loadDepartmentLookup");
                })
                .catch(function(error) {
                    commit("setDepartmentEditStatus", 3);
                    commit("setDepartmentEditText", error.response.data.errors);
                });
        },
        editDepartmentReactivate({ commit, state, dispatch }, data) {
            commit("setDepartmentEditStatus", 1);
  
            DepartmentAPI.putReactivateDepartment(data.slug)
                .then(function(response) {
                    commit("setDepartmentEditStatus", 2);
                    commit("setEditedDepartment",response.data)
                    dispatch("applyIndexOnDepartments");
                    
                    dispatch("loadDepartmentLookup");
                })
                .catch(function(error) {
                    commit("setDepartmentEditStatus", 3);
                });
        },
        deleteDepartment({ commit, state, dispatch }, data) {
            commit("setDepartmentDeleteStatus", 1);

            DepartmentAPI.deleteDepartment(data.slug)
                .then(function(response) {
                    commit("setDepartmentDeleteStatus", 2);
                    if(state.departmentOptions.showDeleted){
                        commit("setEditedDepartment",response.data)
                    }else{
                        commit("setDepartmentLineCount", state.departmentOptions.lineCount - 1);
                        commit("setDeletedDepartment",response.data)
                    }
                    
                    dispatch("loadDepartmentLookup");
                    dispatch("applyIndexOnDepartments");
                })
                .catch(function() {
                    commit("setDepartmentDeleteStatus", 3);
                });
        },
        forceDeleteDepartment({ commit, state, dispatch }, data) {
            commit("setDepartmentDeleteStatus", 1);

            DepartmentAPI.forceDeleteDepartment(data.slug)
                .then(function(response) {
                    commit("setDepartmentDeleteStatus", 2);
                    commit("setDepartmentLineCount", state.departmentOptions.lineCount - 1);
                    commit("setDeletedDepartment",response.data)
                    dispatch("applyIndexOnDepartments");
                })
                .catch(function() {
                    commit("setDepartmentDeleteStatus", 3);
                });
        },
    },
    mutations: {
        setDepartmentOptions(state,options){
            state.departmentOptions = options;
        },
        setDepartmentsLoadStatus(state, status) {
            state.departmentsLoadStatus = status;
        },
        setDepartments(state, departments) {
            state.departments = departments;
        },
        setDepartmentLineCount(state, lineCount) {
            state.departmentOptions.lineCount = lineCount;
        },
        setDepartmentAddStatus(state, status) {
            state.departmentAddStatus = status;
        },
        setDepartmentAddText(state, text) {
            state.departmentAddText = text;
        },
        setDepartmentEdit(state, department) {
            state.departmentEdit = department;
        },
        setDepartment(state, department) {
            state.department = department;
        },
        setEditedDepartment(state, department) {
            var lp = _.find(state.departments,{'id':department.id}).lp;
            var index = _.findIndex(state.departments,{'id':department.id});
            if(department.main){//main change
                state.departments.forEach(element => {
                    element.can_delete = true;
                    element.main = false;
                });
            }
            state.departments[index] = department;
            state.departments[index].lp = lp;
        },
        setAddedDepartment(state, department) {
            if(department.main){//main change
                state.departments.forEach(element => {
                    element.can_delete = true;
                    element.main = false;
                });
            }
            state.departmentOptions.itemAdded = true;
            state.departments.push(department);
        },
        setDepartmentSearchedTrue(state) {
            state.departmentOptions.itemSearched = true;
        },
        setOptionsItemAddedAndSearchedFalse(state) {
            state.departmentOptions.itemAdded = false;
            state.departmentOptions.itemSearched = false;
        },
        setDeletedDepartment(state, department) {
            var index = _.findIndex(state.departments,{'id':department.id});
            state.departments.splice(index,1);
        },
        setDepartmentEditDefault(state) {
            state.departmentEdit = { ...state.departmentEditDefault };
        },
        setDepartmentEditStatus(state, status) {
            state.departmentEditStatus = status;
        },
        setDepartmentEditText(state, text) {
            state.departmentEditText = text;
        },
        setDepartmentEditLoadStatus(state, status) {
            state.departmentEditLoadStatus = status;
        },
        setDepartmentDeleteStatus(state, status) {
            state.departmentDeleteStatus = status;
        },
        setDepartmentDeletedText(state, text) {
            state.departmentDeleteText = text;
        }
    },

    getters: {
        getDepartmentOptions(state){
            return state.departmentOptions;
        },
        getDepartmentsLoadStatus(state) {
            return state.departmentsLoadStatus;
        },
        getDepartments(state) {
            return state.departments;
        },
        getDepartment(state) {
            return state.department;
        },
        getDepartmentAddStatus(state) {
            return function(){
                return state.departmentAddStatus;
            } 
        },
        getDepartmentAddText(state) {
            return state.departmentAddText;
        },
        getDepartmentEdit(state) {
            return state.departmentEdit;
        },
        getDepartmentEditStatus(state) {
          return function(){
            return state.departmentEditStatus;
          }
        },
        getDepartmentEditing(state) {
            return state.departmentEdit.id > 0;
        },
        getDepartmentEditText(state) {
            return state.departmentEditText;
        },
        getDepartmentEditLoadStatus(state) {
            return state.departmentEditLoadStatus;
            
        },
        getDepartmentDeleteStatus(state) {
            return function(){
                return state.departmentDeleteStatus;
            }
        },
        getDepartmentDeleteText(state) {
            return state.departmentDeleteText;
        }
    }
};

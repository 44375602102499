import { FLOREX_CONFIG } from '../config'
import axios from 'axios'

export default {

    /*
        GET     /api/departments
    */
    getDepartments: function(options){
        return axios.get( FLOREX_CONFIG.API_URL + '/departments',{
            params:{
                offset:options.offset,
                itemsPerPage:options.itemsPerPage,
                orderBy:options.orderBy,
                orderDirection:options.orderDirection,
                showDeleted:options.showDeleted,
        }});
    },

    /*
        GET     /api/departments/search
    */
   searchDepartments: function(options){
    return axios.get( FLOREX_CONFIG.API_URL + '/departments/search',{
        params:{
            showDeleted:options.showDeleted,
            filterableHeaders:options.filterableHeaders,
            q:options.q
    }});
},

    /*
		POST 	/api/departments
	*/
    postAddNewDepartment: function( name, city, street, post, email, phone, main ){
        return axios.post( FLOREX_CONFIG.API_URL + '/departments',
            {
                name: name,
                city: city,
                street: street,
                post: post,
                email: email,
                phone: phone,
                main: main,
            }
        );
    },

    /*
		GET 	/api/departments/{slug}/edit
	*/
    getDepartmentEdit: function( slug ){
		return axios.get( FLOREX_CONFIG.API_URL + '/departments/' + slug + '/edit' );
    },
    
    
    /*
		GET 	/api/departments/main
	*/
    getMainDepartmentEdit: function(){
		return axios.get( FLOREX_CONFIG.API_URL + '/departments/main' );
	},

    /*
		PUT 	/api/departments/{slug}
	*/
    putEditDepartment: function(slug, name, city, street, post, email, phone, main){
        return axios.put( FLOREX_CONFIG.API_URL + '/departments/'+ slug,
            {
                name: name,
                city: city,
                street: street,
                post: post,
                email: email,
                phone: phone,
                main: main,
            }
        );
    },

     /*
		GET 	/api/departments/reactivate/{slug}
	*/
    putReactivateDepartment: function(slug){
        return axios.get( FLOREX_CONFIG.API_URL + '/departments/reactivate/' + slug);
    },

    /*
		DELETE /api/departments/{slug}
	*/
    deleteDepartment: function( slug ){
		return axios.delete( FLOREX_CONFIG.API_URL + '/departments/' + slug );
    },
    
    /*
		DELETE /api/departments/{slug}/force
	*/
    forceDeleteDepartment: function( slug ){
		return axios.delete( FLOREX_CONFIG.API_URL + '/departments/' + slug + '/force' );
	}
}
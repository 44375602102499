import { FLOREX_CONFIG } from '../config'
import axios from 'axios'


export default {

    
    /*
        GET     /api/integrationDataTypes
    */
    getIntegrationDataTypes: function(options){
        return axios.get( FLOREX_CONFIG.API_URL + '/integrationDataTypes',{
            params:{
                offset:options.offset,
                itemsPerPage:options.itemsPerPage,
                orderBy:options.orderBy,
                orderDirection:options.orderDirection,
        }});
    },

    /*
        GET     /api/integrationDataTypes/search
    */
   searchIntegrationDataTypes: function(options){
        return axios.get( FLOREX_CONFIG.API_URL + '/integrationDataTypes/search',{
            params:{
                filterableHeaders:options.filterableHeaders,
                q:options.q
        }});
    },


    /*
		GET 	/api/integrationDataTypes/{slug}/edit
	*/
    getIntegrationDataTypeEdit: function( slug ){
		return axios.get( FLOREX_CONFIG.API_URL + '/integrationDataTypes/' + slug + '/edit' );
	},

    /*
		PUT 	/api/integrationDataTypes/{slug}
	*/
    putEditIntegrationDataType: function(slug, name, type, value, label, description){
        return axios.put( FLOREX_CONFIG.API_URL + '/integrationDataTypes/'+ slug,
            {
                name: name,
                type: type,
                value: value,
                label: label,
                description: description,
            }
        );
    },

    /*
		POST 	/api/integrationDataTypes/
	*/
    postNewIntegrationDataType: function(name, type, value, label, description){
        return axios.post( FLOREX_CONFIG.API_URL + '/integrationDataTypes',
            {
                name: name,
                type: type,
                value: value,
                label: label,
                description: description,
            }
        );
    },

    /*
		DELETE /api/integrationDataTypes/{slug}/force
	*/
    forceDeleteIntegrationDataType: function( slug ){
		return axios.delete( FLOREX_CONFIG.API_URL + '/integrationDataTypes/' + slug + '/force' );
    },
    
}
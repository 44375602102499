import { FLOREX_CONFIG } from '../config'
import axios from 'axios'


export default {

    
    /*
        GET     /api/integrationActions
    */
    getIntegrationActions: function(options){
        return axios.get( FLOREX_CONFIG.API_URL + '/integrationActions',{
            params:{
                offset:options.offset,
                itemsPerPage:options.itemsPerPage,
                orderBy:options.orderBy,
                orderDirection:options.orderDirection,
        }});
    },

    /*
        GET     /api/integrationActions/search
    */
   searchIntegrationActions: function(options){
        return axios.get( FLOREX_CONFIG.API_URL + '/integrationActions/search',{
            params:{
                filterableHeaders:options.filterableHeaders,
                q:options.q
        }});
    },


    /*
		GET 	/api/integrationActions/{slug}/edit
	*/
    getIntegrationActionEdit: function( slug ){
		return axios.get( FLOREX_CONFIG.API_URL + '/integrationActions/' + slug + '/edit' );
	},

    /*
		PUT 	/api/integrationActions/{slug}
	*/
    putEditIntegrationAction: function(slug, integration_type_id, name, version, label, description, input, output, filters){
        return axios.put( FLOREX_CONFIG.API_URL + '/integrationActions/'+ slug,
            {
                integration_type_id: integration_type_id,
                name: name,
                version: version,
                label: label,
                description: description,
                input:input,
                output:output,
                filters:filters,
            }
        );
    },

    /*
		POST 	/api/integrationActions/
	*/
    postNewIntegrationAction: function(integration_type_id, name, version, label, description, input, output, filters){
        return axios.post( FLOREX_CONFIG.API_URL + '/integrationActions',
            {
                integration_type_id: integration_type_id,
                name: name,
                version: version,
                label: label,
                description: description,
                input:input,
                output:output,
                filters:filters,
            }
        );
    },

    /*
		DELETE /api/integrationActions/{slug}/force
	*/
    forceDeleteIntegrationAction: function( slug ){
		return axios.delete( FLOREX_CONFIG.API_URL + '/integrationActions/' + slug + '/force' );
    },
    
}
<template>
  <div id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant="miniVariant"
      app
      :style="`margin-top: ${$vuetify.application.top}px`"
      width="200"
    >
    <!-- <v-row>
      <v-col>
        <v-img :src="myLogo"></v-img>
      </v-col>
    </v-row> -->
    <!-- <v-img class="mt-2 ml-2 mr-2 mb-2" src="/logo_duze.png"></v-img> -->

      <v-list dense class="pt-0">
        <template v-for="item in items">         
          <v-list-item
            :key="item.path"
            :to="{ name: item.path }"
            link
            :class="item.path.split('.')[0] === $route.name.split('.')[0] ? 'v-list-item--active' : ''"
            v-if="user.permissions[item.module] >= 5"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title >
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <h5 style="color:grey; position: absolute;bottom:65px;left:5px">{{appVersion}}</h5>
    </v-navigation-drawer>

    <v-app-bar
      app
      color="primary"
      dark
      clipped-left
    >
      <v-app-bar-nav-icon @click.stop="miniVariant = !miniVariant" />
      <v-toolbar-title
        style="width: 150px"
        class="ml-2"
      >
       <v-img class="" src="/logo_duze_white.png"></v-img> 
      </v-toolbar-title>
      
      <v-spacer />
     
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn
            color="white"
            outlined
            v-on="on"
            class="text-none"
          >
           <v-icon left>mdi-account</v-icon> {{user.login}}
          </v-btn>
        </template>
        <v-list>

          <v-list-item
            key="logout"
            @click="logout"
          >
            <v-list-item-title >Wyloguj</v-list-item-title>
          </v-list-item>

          <v-list-item
            key="passchange"
            :to="{ name: 'haslo' }"
          >
            <v-list-item-title>Zmień hasło</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <confirm ref="confirm"></confirm>
    </v-app-bar>
  </div>

</template>

<script>
  import { FLOREX_CONFIG } from '../../config'
  //import myLogoPng from "../../img/logo_white.png"
  import {Permissions} from "../../mixins/permissions"
  import confirm from '../../components/Confirm'

  export default {
    components: { confirm },
    mixins:[
      Permissions
    ],
    props: {
      source: String,
    },
    data: () => ({
      //myLogo:myLogoPng,
      dialog: false,
      drawer: null,
      miniVariant: false,
      items: [
        // { icon: 'mdi-monitor-dashboard', text: 'Pulpit', path: 'pulpit',module:'desktop' },
        // { icon: 'mdi-clipboard-account-outline', text: 'Dziennik', path: 'dziennik',module:'journal'},
        // { icon: 'mdi-calculator', text: 'Rozliczenia', path: 'rozliczenia',module:'settlement'},
        // { icon: 'mdi-cash-register', text: 'Kasa', path:'kasa.dokumenty', module:'payment'},
        // { icon: 'mdi-chart-bar', text: 'Raporty', path: 'raporty',module:'report'},
        // { icon: 'mdi-account-box-multiple-outline', text: 'Klienci', path: 'klienci', module:'dictionary' },
        { icon: 'mdi-basket-outline', text: 'Zamówienia', path: 'zamowienia', permission:'admin', module:'order' },
        { icon: 'mdi-package-variant-closed', text: 'Wysyłki', path: 'wysylki', permission:'admin', module:'shipment' },
        { icon: 'mdi-file-document-outline', text: 'Dokumenty', path: 'dokumenty', permission:'admin', module:'document' },
        { icon: 'mdi-format-list-checks', text: 'Kontrola PayU', path: 'payu', permission:'admin', module:'setting' },
        { icon: 'mdi-format-list-text', text: 'Kontrola Tpay', path: 'tpay', permission:'admin', module:'setting' },
        { icon: 'mdi-format-list-group', text: 'Kontrola Storn', path: 'storn', permission:'admin', module:'setting' },
        { icon: 'mdi-file-table-box-multiple-outline', text: 'Słowniki', path: 'slowniki.dokumenty',module:'dictionary'},
        { icon: 'mdi-cog-outline', text: 'Ustawienia', path: 'ustawienia.firma',module:'setting'},
        
      ],
    }),
    watch:{
      userLoadStatus(val){
        if(val==2 && process.env.VERSION !== this.user.version){//czy nie ma nowej wersji aplikacji
          this.$refs.confirm.open('Odśwież', 'Używasz starej wersji aplikacji. Czy chcesz odświeżyć stronę?', { color: 'error' }).then((confirm) => {
            if(confirm){
               location.reload();
            }
        })
        }
      }
    },
    computed:{
      user(){
        return this.$store.getters.getUser;
      },
      appVersion(){
        return process.env.VERSION;
      },
      userLoadStatus(){
        return this.$store.getters.getUserLoadStatus();
      },
    },
    methods:{
      logout(){
        this.$store.dispatch( 'loadEmptyUser' );
      },
      
    }
  }
</script>

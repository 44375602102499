import { FLOREX_CONFIG } from '../config'
import axios from 'axios'

export default {



    /*
		GET 	/api/accounts/{slug}/edit
	*/
    getAccountEdit: function( slug ){
		return axios.get( FLOREX_CONFIG.API_URL + '/accounts/' + slug + '/edit' );
	},

    /*
		PUT 	/api/accounts/{slug}
	*/
    putEditAccount: function(slug, name,nip,regon,krs,logo){
        return axios.put( FLOREX_CONFIG.API_URL + '/accounts/'+ slug,
            {
                name: name,
                nip:nip,
                regon:regon,
                krs:krs,
                logo:logo
            }
        );
    },

}

import { FLOREX_CONFIG } from '../config'
import axios from 'axios'


export default {

    
    /*
        GET     /api/integrationShipments
    */
    getIntegrationShipments: function(options){
        return axios.get( FLOREX_CONFIG.API_URL + '/integrationShipments',{
            params:{
                offset:options.offset,
                itemsPerPage:options.itemsPerPage,
                orderBy:options.orderBy,
                orderDirection:options.orderDirection,
                integrationId:options.integrationId,
                accountId:options.accountId,
                statusId:options.statusId,
                showActive:options.showActive,
                fromDate:options.fromDate,
                toDate:options.toDate,
                pickupDate:options.pickupDate,
        }});
    },

    /*
        GET     /api/integrationShipments/search
    */
   searchIntegrationShipments: function(options){
        return axios.get( FLOREX_CONFIG.API_URL + '/integrationShipments/search',{
            params:{
                integrationId:options.integrationId,
                filterableHeaders:options.filterableHeaders,
                q:options.q
        }});
    },

    // /*
	// 	GET 	/api/integrationShipments/update
	// */
    // getUpdateIntegrationShipments: function( slug, options, count ){
    //     return axios.get( FLOREX_CONFIG.API_URL + '/integrationShipments/' + slug + '/update',{
    //         params:{
    //             options:options,
    //             count:count
    //     }});
    // },

    //  /*
	// 	GET 	/api/integrationShipments/{slug}/{catId}/categories
	// */
    // getCategoriesOfIntegrationShipments: function( slug, catId){
    //     return axios.get( FLOREX_CONFIG.API_URL + '/integrationShipments/' + slug + '/' + catId + '/categories');
    // },

    /*
		GET 	/api/integrationShipments/{slug}/edit
	*/
    getIntegrationShipmentEdit: function( slug ){
		return axios.get( FLOREX_CONFIG.API_URL + '/integrationShipments/' + slug + '/edit' );
    },

    /*
		GET 	/api/integrationShipments/{slug}/pdf
	*/
    getIntegrationShipmentLabel: function( slug , position, type){
		return axios.get( FLOREX_CONFIG.API_URL + '/integrationShipments/' + slug + '/' + position + '/' + type + '/label' );
    },
    
    // /*
	// 	GET 	/api/integrationShipments/muclist
	// */
    // getIntegrationShipmentEditMucList: function( ){
	// 	return axios.get( FLOREX_CONFIG.API_URL + '/integrationShipments/muclist' );
	// },

    /*
		PUT 	/api/integrationShipmentsOrderStatus/{slug}
	*/
    putEditIntegrationShipmentOrderStatus: function(slug, order_id){
        return axios.put( FLOREX_CONFIG.API_URL + '/integrationShipments/'+ slug + '/orderStatus',
            {
                order_id: order_id
            }
        );
    },

    /*
		PUT 	/api/integrationShipments/{slug}/move
	*/
    putEditIntegrationShipmentMove: function(slug, selected){
        return axios.put( FLOREX_CONFIG.API_URL + '/integrationShipments/'+ slug + '/move',
            {
                selected: selected
            }
        );
    },

    /*
		PUT 	/api/integrationShipmentsIntegrationId/{slug}
	*/
    putEditIntegrationShipmentIntegrationId: function(slug, integration_id){
        return axios.put( FLOREX_CONFIG.API_URL + '/integrationShipments/'+ slug + '/integrationId',
            {
                integration_id: integration_id
            }
        );
    },

    /*
		PUT 	/api/integrationShipments/merge
	*/
    putEditIntegrationShipmentMerge: function(slug){
        return axios.put( FLOREX_CONFIG.API_URL + '/integrationShipments/merge',
            {
                slug: slug
            }
        );
    },

    /*
		PUT 	/api/integrationShipments/{slug}/cancel
	*/
    getEditIntegrationShipmentCancel: function(slug){
        return axios.get( FLOREX_CONFIG.API_URL + '/integrationShipments/' + slug + '/cancel');
    },

    /*
		PUT 	/api/integrationShipments/{slug}
	*/
    putEditIntegrationShipment: function(slug, order,carrier, pickup_list, buyer,amount,amount_currency,custom_order){
        return axios.put( FLOREX_CONFIG.API_URL + '/integrationShipments/'+ slug,
            {
                order: order,
                carrier: carrier,
                pickup_list: pickup_list,
                buyer: buyer,
                amount:amount,
                amount_currency:amount_currency,
                custom_order:custom_order
            }
        );
    },

    /*
		POST 	/api/integrationShipments/{slug}
	*/
    postNewIntegrationShipment: function(buyer,amount,amount_currency,custom_order){
        return axios.post( FLOREX_CONFIG.API_URL + '/integrationShipments',
            {
                buyer: buyer,
                amount:amount,
                amount_currency:amount_currency,
                custom_order:custom_order
            }
        );
    },

    /*
		PUT 	/api/integrationShipments/{slug}
	*/
    putEditIntegrationShipmentSend: function(slug, order,carrier, pickup_list, buyer){
        return axios.put( FLOREX_CONFIG.API_URL + '/integrationShipments/'+ slug + '/send',
            {
                order: order,
                carrier: carrier,
                pickup_list: pickup_list,
                buyer: buyer
            }
        );
    },

    /*
		PUT /api/integrationShipments/force
	*/
    forceDeleteIntegrationShipment: function( slug ){
        return axios.put( FLOREX_CONFIG.API_URL + '/integrationShipments/force',
        {
            slug: slug,
        } );
    },
    
}
import IntegrationDataTypeAPI from "../api/integrationDataType";
import i18n from "../main";

export const integrationDataTypes = {
    state: {
        integrationDataTypes: [],
        integrationDataTypeOptions:{
            lineCount:0,
            orderBy:'name',
            orderDirection:'asc',
            itemsPerPage:20,
            itemAdded:false,
        },

        integrationDataTypesLoadStatus: 0,
        integrationDataTypeAddStatus: 0,
        integrationDataTypeAddText: "",

        integrationDataTypeEdit: {
            id:0,
            lp: 0,
            name:undefined,
            type:undefined,
            value:undefined,
            label:undefined,
            description:undefined,
        },
        integrationDataTypeEditDefault: {
            id:0,
            lp: 0,
            name:undefined,
            type:undefined,
            value:undefined,
            label:undefined,
            description:undefined,
        },
        integrationDataTypeAddLoadStatus: 0,
        integrationDataTypeAddStatus: 0,
        integrationDataTypeAddText: "",
        
        integrationDataTypeEditLoadStatus: 0,
        integrationDataTypeEditStatus: 0,
        integrationDataTypeEditText: "",

        integrationDataTypeDeleteStatus: 0,
        integrationDataTypeDeleteText: "",

    },

    actions: {
        loadIntegrationDataTypes({ state, commit, dispatch }) {
            commit("setIntegrationDataTypesLoadStatus", 1);
                IntegrationDataTypeAPI.getIntegrationDataTypes({
                    offset:0,
                    itemsPerPage:state.integrationDataTypeOptions.itemsPerPage, 
                    orderBy:state.integrationDataTypeOptions.orderBy,
                    orderDirection:state.integrationDataTypeOptions.orderDirection,
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setIntegrationDataTypeLineCount", response.data.lineCount);
                    commit("setIntegrationDataTypes", response.data.integrationDataTypes);
                    dispatch("applyIndexOnIntegrationDataTypes");
                    commit("setIntegrationDataTypesLoadStatus", 2);
                })
                .catch(function() {
                    commit("setIntegrationDataTypes", []);
                    commit("setIntegrationDataTypesLoadStatus", 3);
                });
         
        },
        loadMoreIntegrationDataTypes({ state, commit, dispatch }) {
            commit("setIntegrationDataTypesLoadStatus", 1);
                IntegrationDataTypeAPI.getIntegrationDataTypes({
                    offset:state.integrationDataTypes.length,
                    itemsPerPage:state.integrationDataTypeOptions.itemsPerPage, 
                    orderBy:state.integrationDataTypeOptions.orderBy,
                    orderDirection:state.integrationDataTypeOptions.orderDirection,
                })
                .then(function(response) {
                    commit("setIntegrationDataTypeLineCount", response.data.lineCount);
                    commit("setIntegrationDataTypes", _.concat(state.integrationDataTypes, response.data.integrationDataTypes));
                    dispatch("applyIndexOnIntegrationDataTypes");
                    commit("setIntegrationDataTypesLoadStatus", 2);
                })
                .catch(function() {
                    commit("setIntegrationDataTypes", []);
                    commit("setIntegrationDataTypesLoadStatus", 3);
                });
         
        },
        searchIntegrationDataTypes({ state, commit, dispatch },data) {
            commit("setIntegrationDataTypesLoadStatus", 1);
                IntegrationDataTypeAPI.searchIntegrationDataTypes({
                    integrationId:state.integrationDataTypeOptions.integrationId,
                    filterableHeaders:data.filterableHeaders,
                    q:data.q
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setIntegrationDataTypeSearchedTrue");
                    commit("setIntegrationDataTypeLineCount", response.data.lineCount);
                    commit("setIntegrationDataTypes", response.data.integrationDataTypes);
                    dispatch("applyIndexOnIntegrationDataTypes");
                    commit("setIntegrationDataTypesLoadStatus", 2);
                })
                .catch(function() {
                    commit("setIntegrationDataTypes", []);
                    commit("setIntegrationDataTypesLoadStatus", 3);
                });
         
        },
        setIntegrationDataTypeOptions({ state, commit, dispatch },data){
            commit("setIntegrationDataTypeOptions",data.options);
            dispatch("loadIntegrationDataTypes");
        },
        applyIndexOnIntegrationDataTypes({ commit, state, dispatch }) {
            let localIntegrationDataTypes = state.integrationDataTypes;

            var i = 1;
            localIntegrationDataTypes.forEach(integrationDataType => {
                // if(_.isNumber(integrationDataType.status)){
                //     integrationDataType.status = _.find(state.integrationDataTypeStatus, { 'value': integrationDataType.status }).text;
                // }
                integrationDataType.lp = i;
                i++;
            });

            commit("setIntegrationDataTypes",localIntegrationDataTypes);
        },
      
        loadIntegrationDataTypeEdit({ commit }, data) {
            commit("setIntegrationDataTypeEditLoadStatus", 1);
            commit("setIntegrationDataTypeEditStatus", 0);
            commit("setIntegrationDataTypeEditText", "");

            IntegrationDataTypeAPI.getIntegrationDataTypeEdit(data.slug)
                .then(function(response) {
                    commit("setIntegrationDataTypeEdit", response.data);
                    commit("setIntegrationDataTypeEditLoadStatus", 2);
                })
                .catch(function() {
                    commit("setIntegrationDataTypeEdit", {});
                    commit("setIntegrationDataTypeEditLoadStatus", 3);
                });
        },
        loadIntegrationDataTypeEditDefault({ commit }) {
            commit("setIntegrationDataTypeEditDefault");
        },
        loadIntegrationDataTypeAddDefault({ commit }) {
            commit("setIntegrationDataTypeAddStatus", 0);
            commit("setIntegrationDataTypeAddText", "");
            commit("setIntegrationDataTypeEditStatus", 0);
            commit("setIntegrationDataTypeEditText", "");
        },
        editIntegrationDataType({ commit, state, dispatch }, data) {
            commit("setIntegrationDataTypeEditStatus", 1);

            IntegrationDataTypeAPI.putEditIntegrationDataType(
                data.slug,
                data.name,
                data.type,
                data.value,
                data.label,
                data.description,
            )
                .then(function(response) {
           
                    commit("setIntegrationDataTypeEditStatus", 2);
                    commit("setEditedIntegrationDataType",response.data)
                    dispatch("applyIndexOnIntegrationDataTypes");
                })
                .catch(function(error) {
                    commit("setIntegrationDataTypeEditStatus", 3);
                    commit("setIntegrationDataTypeEditText", error.response.data.errors);
                });
        },

        newIntegrationDataType({ commit, state, dispatch }, data) {
            commit("setIntegrationDataTypeAddStatus", 1);

            IntegrationDataTypeAPI.postNewIntegrationDataType(
                data.name,
                data.type,
                data.value,
                data.label,
                data.description,
            )
                .then(function(response) {
           
                    commit("setIntegrationDataTypeAddStatus", 2);
                    commit("setAddedIntegrationDataType",response.data)
                    dispatch("applyIndexOnIntegrationDataTypes");
                })
                .catch(function(error) {
                    commit("setIntegrationDataTypeAddStatus", 3);
                    commit("setIntegrationDataTypeAddText", error.response.data.errors);
                });
        },
        
        forceDeleteIntegrationDataType({ commit, state, dispatch }, data) {
            commit("setIntegrationDataTypeDeleteStatus", 1);

            IntegrationDataTypeAPI.forceDeleteIntegrationDataType(data.slug)
                .then(function(response) {
                    commit("setIntegrationDataTypeDeleteStatus", 2);
                    commit("setIntegrationDataTypeLineCount", state.integrationDataTypeOptions.lineCount - 1);
                    commit("setDeletedIntegrationDataType",response.data)
                    dispatch("applyIndexOnIntegrationDataTypes");
                })
                .catch(function() {
                    commit("setIntegrationDataTypeDeleteStatus", 3);
                });
        },
    },
    mutations: {
        setIntegrationDataTypeLoadStatus(state, status) {
            state.integrationDataTypeLoadStatus = status;
        },
        setIntegrationDataType(state, integrationDataType) {
            state.integrationDataType = integrationDataType;
        },
        //#############
        setIntegrationDataTypeOptions(state,options){
            state.integrationDataTypeOptions = options;
        },
        setIntegrationDataTypesLoadStatus(state, status) {
            state.integrationDataTypesLoadStatus = status;
        },
        setIntegrationDataTypes(state, integrationDataTypes) {
            state.integrationDataTypes = integrationDataTypes;
        },
        setIntegrationDataTypeLineCount(state, lineCount) {
            state.integrationDataTypeOptions.lineCount = lineCount;
        },
        setIntegrationDataTypeAddStatus(state, status) {
            state.integrationDataTypeAddStatus = status;
        },
        setIntegrationDataTypeAddText(state, text) {
            state.integrationDataTypeAddText = text;
        },
        setIntegrationDataTypeEdit(state, integrationDataType) {
            state.integrationDataTypeEdit = integrationDataType;
        },
        setEditedIntegrationDataType(state, integrationDataType) {
            var lp = _.find(state.integrationDataTypes,{'id':integrationDataType.id}).lp;
            var index = _.findIndex(state.integrationDataTypes,{'id':integrationDataType.id});
            
            state.integrationDataTypes[index] = integrationDataType;
            state.integrationDataTypes[index].lp = lp;
        },
        setAddedIntegrationDataType(state, integrationDataType) {
            state.integrationDataTypeOptions.itemAdded = true;
            state.integrationDataTypes.push(integrationDataType);
        },
        setIntegrationDataTypeSearchedTrue(state) {
            state.integrationDataTypeOptions.itemSearched = true;
        },
        setOptionsItemAddedAndSearchedFalse(state) {
            state.integrationDataTypeOptions.itemAdded = false;
            state.integrationDataTypeOptions.itemSearched = false;
        },
        setDeletedIntegrationDataType(state, integrationDataType) {
            var index = _.findIndex(state.integrationDataTypes,{'id':integrationDataType.id});
            state.integrationDataTypes.splice(index,1);
        },
        setIntegrationDataTypeEditDefault(state) {
            state.integrationDataTypeEdit =  JSON.parse(JSON.stringify(state.integrationDataTypeEditDefault)); //{ ...state.integrationDataTypeEditDefault };
        },
        setIntegrationDataTypeEditStatus(state, status) {
            state.integrationDataTypeEditStatus = status;
        },
        setIntegrationDataTypeEditText(state, text) {
            state.integrationDataTypeEditText = text;
        },
        setIntegrationDataTypeEditLoadStatus(state, status) {
            state.integrationDataTypeEditLoadStatus = status;
        },
        setIntegrationDataTypeDeleteStatus(state, status) {
            state.integrationDataTypeDeleteStatus = status;
        },
        setIntegrationDataTypeDeletedText(state, text) {
            state.integrationDataTypeDeleteText = text;
        }
    },

    getters: {
        getIntegrationDataTypeLoadStatus(state) {
            return state.integrationDataTypeLoadStatus;
        },
        getIntegrationDataType(state) {
            return state.integrationDataType;
        },
        getIntegrationDataTypeOptions(state){
            return state.integrationDataTypeOptions;
        },
        getIntegrationDataTypesLoadStatus(state) {
            return state.integrationDataTypesLoadStatus;
        },
        getIntegrationDataTypes(state) {
            return state.integrationDataTypes;
        },
        getIntegrationDataTypeAddStatus(state) {
            return function(){
                return state.integrationDataTypeAddStatus;
            } 
        },
        getIntegrationDataTypeAddText(state) {
            return state.integrationDataTypeAddText;
        },
        getIntegrationDataTypeEdit(state) {
            return state.integrationDataTypeEdit;
        },
        getIntegrationDataTypeEditStatus(state) {
          return function(){
            return state.integrationDataTypeEditStatus;
          }
        },
        getIntegrationDataTypeEditText(state) {
            return state.integrationDataTypeEditText;
        },
        getIntegrationDataTypeEditLoadStatus(state) {
            return state.integrationDataTypeEditLoadStatus;
            
        },
        getIntegrationDataTypeDeleteStatus(state) {
            return function(){
                return state.integrationDataTypeDeleteStatus;
            }
        },
        getIntegrationDataTypeDeleteText(state) {
            return state.integrationDataTypeDeleteText;
        }
    }
};

import store from './store';
import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from './pages/Layout'
import Logowanie from './pages/Logowanie'
import ZmianaHasla from './pages/ZmianaHasla'
import PierwszaZmianaHasla from './pages/PierwszaZmianaHasla'


import Uzytkownicy from './pages/Uzytkownicy'
import Firma from './pages/Firma.vue'
import Centrala from './pages/Centrala.vue'
import Stanowiska from './pages/Stanowiska'

import Dokumenty from './pages/Dokumenty'
import KontrolaPayU from './pages/KontrolaPayU'
import KontrolaTPay from './pages/KontrolaTPay'
import KontrolaStorn from './pages/KontrolaStorn'

import UstawieniaIntegracji from './pages/UstawieniaIntegracji'
import ZadaniaIntegracji from './pages/ZadaniaIntegracji'
import ProduktyIntegracji from './pages/ProduktyIntegracji'
import TypyDanychIntegracji from './pages/TypyDanychIntegracji'
import AkcjeIntegracji from './pages/AkcjeIntegracji'
import WysylkiIntegracji from './pages/WysylkiIntegracji'
import ZamowieniaIntegracji from './pages/ZamowieniaIntegracji'
import DokumentyIntegracji from './pages/DokumentyIntegracji'

//import Empty from './pages/Empty'
import _isEmpty from "lodash/isEmpty"

import MenuUstawienia from './components/MenuUstawienia'
import MenuSlowniki from './components/MenuSlowniki'

Vue.use(VueRouter)

function requireAuth (to, from, next) {
  function proceed () {
    if ( store.getters.getUserLoadStatus() == 2 ) {

      if(!_isEmpty(store.getters.getUser)){//zalogowany
        
        if(!store.getters.getUser.oneTimePassword){//hasło jednorazowe

          //sprawdzanie uprawnien
          if(to.meta.module==='')next();
          else if( store.getters.getUser.permissions[to.meta.module] >= 5 )next();
          else next('/pulpit');

        }else{
          next('/jednorazowe');
        }

      }else{
        next('/logowanie');
      }
    }
  }

  

  if ( store.getters.getUserLoadStatus() != 2 ) {
    store.dispatch( 'loadUser' );
    store.watch( store.getters.getUserLoadStatus, function(){
          if( store.getters.getUserLoadStatus() == 2 ){
              proceed();
          }
    });
  } else {
    proceed()
  }
}

function requireAuthOneTime (to, from, next) {
  function proceed () {
    
    if ( store.getters.getUserLoadStatus() == 2 ) {
      if( !_isEmpty(store.getters.getUser) ){//zalogowany

           //sprawdzanie uprawnien
           if(to.meta.module==='')next();
           else if( store.getters.getUser.permissions[to.meta.module] >= 5 )next();
           else next('/pulpit');
  
      }else{
        next('/logowanie');
      }
    }
  }

  if ( store.getters.getUserLoadStatus() != 2 ) {
    store.dispatch( 'loadUser' );
    store.watch( store.getters.getUserLoadStatus, function(){
          if( store.getters.getUserLoadStatus() == 2 ){
              proceed();
          }
    });
  } else {
    proceed()
  }
}

const routes = [
  
  { path: '/',
    redirect: { name: 'ustawienia.firma' },
    name: 'Layout',
    component:Layout,
    children:[
      {
        path: '/logowanie', name: 'logowanie', component: Logowanie
      },
      {path: '/jednorazowe', name: 'jednorazowe', component: PierwszaZmianaHasla, beforeEnter: requireAuthOneTime, meta: { module: ''}},
      {
        path: '/haslo',
        name: 'haslo',
        component: ZmianaHasla,
        beforeEnter: requireAuth,
        meta: {
          module: ''
        }
      },
      {
        path: '/ustawienia/firma',
        name: 'ustawienia.firma',
        components: {
          default: Firma,
          menu: MenuUstawienia
        },
        beforeEnter: requireAuth,
        meta: {
          module: 'setting'
        }
      },
      {
        path: '/ustawienia/centrala',
        name: 'ustawienia.centrala',
        components: {
          default: Centrala,
          menu: MenuUstawienia
        },
        beforeEnter: requireAuth,
        meta: {
          module: 'setting'
        }
      },
      {
        path: '/ustawienia/uzytkownicy',
        name: 'ustawienia.uzytkownicy',
        components: {
          default: Uzytkownicy,
          menu: MenuUstawienia
        },
        beforeEnter: requireAuth,
        meta: {
          module: 'setting'
        }
      },
      {
        path: '/ustawienia/stanowiska',
        name: 'ustawienia.stanowiska',
        components: {
          default: Stanowiska,
          menu: MenuUstawienia
        },
        beforeEnter: requireAuth,
        meta: {
          module: 'setting'
        }
      },
      {
        path: '/slowniki/dokumenty',
        name: 'slowniki.dokumenty',
        components: {
          default: Dokumenty,
          menu: MenuSlowniki
        },
        beforeEnter: requireAuth,
        meta: {
          module: 'dictionary'
        }
      },
      {
        path: '/zamowienia',
        name: 'zamowienia',
        component: ZamowieniaIntegracji,
        beforeEnter: requireAuth,
        meta: {
          module: 'order'
        }
      },
      {
        path: '/wysylki',
        name: 'wysylki',
        component: WysylkiIntegracji,
        beforeEnter: requireAuth,
        meta: {
          module: 'shipment'
        }
      },
      {
        path: '/dokumenty',
        name: 'dokumenty',
        component: DokumentyIntegracji,
        beforeEnter: requireAuth,
        meta: {
          module: 'document'
        }
      },
      {
        path: '/payu',
        name: 'payu',
        component: KontrolaPayU,
        beforeEnter: requireAuth,
        meta: {
          module: 'setting'
        }
      },
      {
        path: '/tpay',
        name: 'tpay',
        component: KontrolaTPay,
        beforeEnter: requireAuth,
        meta: {
          module: 'setting'
        }
      },
      {
        path: '/storn',
        name: 'storn',
        component: KontrolaStorn,
        beforeEnter: requireAuth,
        meta: {
          module: 'setting'
        }
      },
       {
        path: '/ustawienia/zadania',
        name: 'ustawienia.zadania',
        component: ZadaniaIntegracji,
        beforeEnter: requireAuth,
        meta: {
          module: 'setting'
        }
      },
      {
        path: '/ustawienia/ustawienia',
        name: 'ustawienia.ustawienia',
        component: UstawieniaIntegracji,
        beforeEnter: requireAuth,
        meta: {
          module: 'setting'
        }
      },
      {
        path: '/ustawienia/produkty',
        name: 'ustawienia.produkty',
        component: ProduktyIntegracji,
        beforeEnter: requireAuth,
        meta: {
          module: 'setting'
        }
      },
      {
        path: '/integracje/typy',
        name: 'integracje.typy',
        component: TypyDanychIntegracji,
        beforeEnter: requireAuth,
        meta: {
          module: 'setting'
        }
      },
      {
        path: '/integracje/akcje',
        name: 'integracje.akcje',
        component: AkcjeIntegracji,
        beforeEnter: requireAuth,
        meta: {
          module: 'setting'
        }
      },
    ]
  },
  
  
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
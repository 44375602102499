import { FLOREX_CONFIG } from '../config'
import axios from 'axios'

export default {

    /*
        GET     /api/sequences
    */
    getSequences: function(options){
        return axios.get( FLOREX_CONFIG.API_URL + '/sequences',{
            params:{
                offset:options.offset,
                itemsPerPage:options.itemsPerPage,
                orderBy:options.orderBy,
                orderDirection:options.orderDirection,
                showDeleted:options.showDeleted,
        }});
    },

    /*
        GET     /api/sequences/search
    */
   searchSequences: function(options){
    return axios.get( FLOREX_CONFIG.API_URL + '/sequences/search',{
        params:{
            showDeleted:options.showDeleted,
            filterableHeaders:options.filterableHeaders,
            q:options.q
    }});
},

    /*
		POST 	/api/sequences
	*/
    postAddNewSequence: function( type, name, from, to, mask){
        return axios.post( FLOREX_CONFIG.API_URL + '/sequences',
            {
                type: type,
                name: name,
                from: from,
                to: to,
                mask: mask
            }
        );
    },

    /*
		GET 	/api/sequences/{slug}/edit
	*/
    getSequenceEdit: function( slug ){
		return axios.get( FLOREX_CONFIG.API_URL + '/sequences/' + slug + '/edit' );
	},

    /*
		PUT 	/api/sequences/{slug}
	*/
    putEditSequence: function(slug, type, name, from, to, next, mask){
        return axios.put( FLOREX_CONFIG.API_URL + '/sequences/'+ slug,
            {
                type: bank,
                name: name,
                from: from,
                to: to,
                next: next,
                mask: mask
            }
        );
    },

     /*
		GET 	/api/sequences/reactivate/{slug}
	*/
    putReactivateSequence: function(slug){
        return axios.get( FLOREX_CONFIG.API_URL + '/sequences/reactivate/' + slug);
    },

    /*
		DELETE /api/sequences/{slug}
	*/
    deleteSequence: function( slug ){
		return axios.delete( FLOREX_CONFIG.API_URL + '/sequences/' + slug );
    },
    
    /*
		DELETE /api/sequences/{slug}/force
	*/
    forceDeleteSequence: function( slug ){
		return axios.delete( FLOREX_CONFIG.API_URL + '/sequences/' + slug + '/force' );
	}
}
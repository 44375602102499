import IntegrationOrderAPI from "../api/integrationOrder";
import i18n from "../main";

export const integrationOrders = {
    state: {
        integrationOrders: [],
        integrationOrderOptions:{
            lineCount:0,
            orderBy:undefined,
            orderDirection:undefined,
            itemsPerPage:20,
            itemAdded:false,
            accountId:undefined,
            statusId:undefined,
            showActive:true,
            fromDate:undefined,
            toDate:undefined,
            sourceId:undefined,
            paymentType:undefined,
            withAmountError:false,
            return:false,
            returned:false
        },


        integrationOrdersLoadStatus: 0,
        integrationOrderAddStatus: 0,
        integrationOrderAddText: "",

        integrationOrderEdit: {
            independentItem:true,
            id: 0,
            lp: 0,
            order:[],
            buyer:{
                buyer_login:null,
                delivery_company_name:null,
                delivery_first_name:null,
                delivery_last_name:null,
                delivery_street:null,
                delivery_post_code:null,
                delivery_city:null,
                delivery_country_code:'PL',
                contact_phone:null,
                contact_email:null,
            },
            status:0,
            amount:undefined,
            amount_net:undefined,
            amount_currency:'PLN',
            logs:[],
            shipments:[]
        },
        integrationOrderEditDefault: {
            independentItem:true,
            id: 0,
            lp: 0,
            order:[],
            buyer:{
                buyer_login:null,
                delivery_company_name:null,
                delivery_first_name:null,
                delivery_last_name:null,
                delivery_street:null,
                delivery_post_code:null,
                delivery_city:null,
                delivery_country_code:'PL',
                contact_phone:null,
                contact_email:null,
            },
            status:0,
            amount:undefined,
            amount_net:undefined,
            amount_currency:'PLN',
            logs:[],
            shipments:[]
        },
        integrationOrderEditLoadStatus: 0,
        integrationOrderEditStatus: 0,
        integrationOrderEditText: "",

        integrationOrderDeleteStatus: 0,
        integrationOrderLabelLoadStatus: 0,
        integrationOrderDeleteText: "",

        integrationOrderPayUNote:{
            date:null,
            amount:null,
            currency:null,
            status:null,
            desc:null
        }
    },

    actions: {
        loadIntegrationOrders({ state, commit, dispatch,rootState }) {
            commit("setIntegrationOrdersLoadStatus", 1);
                IntegrationOrderAPI.getIntegrationOrders({
                    offset:0,
                    itemsPerPage:state.integrationOrderOptions.itemsPerPage, 
                    orderBy:state.integrationOrderOptions.orderBy?state.integrationOrderOptions.orderBy:rootState.lookups.orderSettings.DEFAULT_ORDER_BY,
                    orderDirection:state.integrationOrderOptions.orderDirection?state.integrationOrderOptions.orderDirection:rootState.lookups.orderSettings.DEFAULT_ORDER_DIRECTION,
                    accountId:state.integrationOrderOptions.accountId,
                    statusId:state.integrationOrderOptions.statusId,
                    showActive:state.integrationOrderOptions.showActive,
                    fromDate:state.integrationOrderOptions.fromDate,
                    toDate:state.integrationOrderOptions.toDate,
                    sourceId:state.integrationOrderOptions.sourceId,
                    paymentType:state.integrationOrderOptions.paymentType,
                    withAmountError:state.integrationOrderOptions.withAmountError,
                    return:state.integrationOrderOptions.return,
                    returned:state.integrationOrderOptions.returned
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setIntegrationOrderLineCount", response.data.lineCount);
                    commit("setIntegrationOrders", response.data.integrationOrders);
                    commit("setIntegrationOrdersCategories", response.data.integrationOrdersCategories);
                    dispatch("applyIndexOnIntegrationOrders");
                    commit("setIntegrationOrdersLoadStatus", 2);
                })
                .catch(function() {
                    commit("setIntegrationOrders", []);
                    commit("setIntegrationOrdersLoadStatus", 3);
                });
         
        },
        loadMoreIntegrationOrders({ state, commit, dispatch,rootState  }) {
            commit("setIntegrationOrdersLoadStatus", 1);
                IntegrationOrderAPI.getIntegrationOrders({
                    offset:state.integrationOrders.length,
                    itemsPerPage:state.integrationOrderOptions.itemsPerPage, 
                    orderBy:state.integrationOrderOptions.orderBy?state.integrationOrderOptions.orderBy:rootState.lookups.orderSettings.DEFAULT_ORDER_BY,
                    orderDirection:state.integrationOrderOptions.orderDirection?state.integrationOrderOptions.orderDirection:rootState.lookups.orderSettings.DEFAULT_ORDER_DIRECTION,
                    accountId:state.integrationOrderOptions.accountId,
                    statusId:state.integrationOrderOptions.statusId,
                    showActive:state.integrationOrderOptions.showActive,
                    fromDate:state.integrationOrderOptions.fromDate,
                    toDate:state.integrationOrderOptions.toDate,
                    sourceId:state.integrationOrderOptions.sourceId,
                    paymentType:state.integrationOrderOptions.paymentType,
                    withAmountError:state.integrationOrderOptions.withAmountError,
                    return:state.integrationOrderOptions.return,
                    returned:state.integrationOrderOptions.returned
                })
                .then(function(response) {
                    commit("setIntegrationOrderLineCount", response.data.lineCount);
                    commit("setIntegrationOrders", _.concat(state.integrationOrders, response.data.integrationOrders));
                    dispatch("applyIndexOnIntegrationOrders");
                    commit("setIntegrationOrdersLoadStatus", 2);
                })
                .catch(function() {
                    commit("setIntegrationOrders", []);
                    commit("setIntegrationOrdersLoadStatus", 3);
                });
         
        },
        loadAllIntegrationOrders({ state, commit, dispatch,rootState  }) {
            commit("setIntegrationOrdersLoadStatus", 1);
                IntegrationOrderAPI.getIntegrationOrders({
                    offset:state.integrationOrders.length,
                    itemsPerPage:9999999, 
                    orderBy:state.integrationOrderOptions.orderBy?state.integrationOrderOptions.orderBy:rootState.lookups.orderSettings.DEFAULT_ORDER_BY,
                    orderDirection:state.integrationOrderOptions.orderDirection?state.integrationOrderOptions.orderDirection:rootState.lookups.orderSettings.DEFAULT_ORDER_DIRECTION,
                    accountId:state.integrationOrderOptions.accountId,
                    statusId:state.integrationOrderOptions.statusId,
                    showActive:state.integrationOrderOptions.showActive,
                    fromDate:state.integrationOrderOptions.fromDate,
                    toDate:state.integrationOrderOptions.toDate,
                    sourceId:state.integrationOrderOptions.sourceId,
                    paymentType:state.integrationOrderOptions.paymentType,
                    withAmountError:state.integrationOrderOptions.withAmountError,
                    return:state.integrationOrderOptions.return,
                    returned:state.integrationOrderOptions.returned
                })
                .then(function(response) {
                    commit("setIntegrationOrderLineCount", response.data.lineCount);
                    commit("setIntegrationOrders", _.concat(state.integrationOrders, response.data.integrationOrders));
                    dispatch("applyIndexOnIntegrationOrders");
                    commit("setIntegrationOrdersLoadStatus", 2);
                })
                .catch(function() {
                    commit("setIntegrationOrders", []);
                    commit("setIntegrationOrdersLoadStatus", 3);
                });
         
        },
        searchIntegrationOrders({ state, commit, dispatch },data) {
            commit("setIntegrationOrdersLoadStatus", 1);
                IntegrationOrderAPI.searchIntegrationOrders({
                    integrationId:state.integrationOrderOptions.integrationId,
                    filterableHeaders:data.filterableHeaders,
                    q:data.q
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setIntegrationOrderSearchedTrue");
                    commit("setIntegrationOrderLineCount", response.data.lineCount);
                    commit("setIntegrationOrders", response.data.integrationOrders);
                    dispatch("applyIndexOnIntegrationOrders");
                    commit("setIntegrationOrdersLoadStatus", 2);
                })
                .catch(function() {
                    commit("setIntegrationOrders", []);
                    commit("setIntegrationOrdersLoadStatus", 3);
                });
         
        },
        setIntegrationOrderOptions({ state, commit, dispatch },data){
            commit("setIntegrationOrderOptions",data.options);
            dispatch("loadIntegrationOrders");
        },
        applyIndexOnIntegrationOrders({ commit, state, dispatch }) {
            let localIntegrationOrders = state.integrationOrders;

            var i = 1;
            localIntegrationOrders.forEach(integrationOrder => {
                // if(_.isNumber(integrationOrder.status)){
                //     integrationOrder.status = _.find(state.integrationOrderStatus, { 'value': integrationOrder.status }).text;
                // }
                integrationOrder.lp = i;
                i++;
            });

            commit("setIntegrationOrders",localIntegrationOrders);
        },
        loadIntegrationOrderEdit({ commit }, data) {
            commit("setIntegrationOrderEditLoadStatus", 1);
            commit("setIntegrationOrderEditStatus", 0);
            commit("setIntegrationOrderEditText", "");

            IntegrationOrderAPI.getIntegrationOrderEdit(data.slug)
                .then(function(response) {
                    commit("setIntegrationOrderEdit", response.data);
                    commit("setIntegrationOrderEditLoadStatus", 2);
                })
                .catch(function() {
                    commit("setIntegrationOrderEdit", {});
                    commit("setIntegrationOrderEditLoadStatus", 3);
                });
        },
        loadIntegrationOrderEditDefault({ commit }) {
            commit("setIntegrationOrderEditDefault");
        },
        loadIntegrationOrderAddDefault({ commit }) {
            commit("setIntegrationOrderAddStatus", 0);
            commit("setIntegrationOrderAddText", "");
            commit("setIntegrationOrderEditStatus", 0);
            commit("setIntegrationOrderEditText", "");
        },
       
        editIntegrationOrderOrderStatus({ commit, state, dispatch }, data) {
                commit("setIntegrationOrderEditStatus", 1);
    
                IntegrationOrderAPI.putEditIntegrationOrderOrderStatus(
                    data.slug,
                    data.order_id
                )
                    .then(function(response) {
                        commit("setIntegrationOrderEditStatus", 2);
                        commit("setIntegrationOrderEdit", response.data);
                        commit("setEditedIntegrationOrder",response.data)
                    })
                    .catch(function(error) {
                        commit("setIntegrationOrderEditStatus", 3);
                        commit("setIntegrationOrderEditText", error.response.data.errors);
                    });
        },
        editIntegrationOrderMove({ commit, state, dispatch }, data) {
            commit("setIntegrationOrderEditStatus", 1);

            IntegrationOrderAPI.putEditIntegrationOrderMove(
                data.slug,
                data.selected
            )
                .then(function(response) {
                    commit("setIntegrationOrderEditStatus", 2);
                    commit("setIntegrationOrderEdit", response.data);
                    commit("setEditedIntegrationOrder",response.data)
                })
                .catch(function(error) {
                    commit("setIntegrationOrderEditStatus", 3);
                    commit("setIntegrationOrderEditText", error.response.data.errors);
                });
        },
       
        editIntegrationOrder({ commit, state, dispatch }, data) {
            commit("setIntegrationOrderEditStatus", 1);

            IntegrationOrderAPI.putEditIntegrationOrder(
                data.slug,
                data.order,
                data.buyer,
                data.amount,
                data.amount_net,
                data.amount_currency,
            )
                .then(function(response) {
                    commit("setIntegrationOrderEditStatus", 2);
                    commit("setIntegrationOrderEdit", response.data);
                    commit("setEditedIntegrationOrder",response.data)
                })
                .catch(function(error) {
                    commit("setIntegrationOrderEditStatus", 3);
                    commit("setIntegrationOrderEditText", error.response.data.errors);
                });
        },
        addIntegrationOrder({ commit, state, dispatch }, data) {
            commit("setIntegrationOrderAddStatus", 1);

            IntegrationOrderAPI.postNewIntegrationOrder(
                data.order,
                data.buyer,
                data.amount,
                data.amount_net,
                data.amount_currency,
            )
                .then(function(response) {
                    commit("setIntegrationOrderAddStatus", 2);
                    //commit("setIntegrationOrderEdit", response.data);
                    commit("setAddedIntegrationOrder",response.data)
                    dispatch("applyIndexOnIntegrationOrders");
                    dispatch( 'loadIntegrationOrderEdit', {slug:  response.data.id});
                })
                .catch(function(error) {
                    commit("setIntegrationOrderAddStatus", 3);
                    commit("setIntegrationOrderEditText", error.response.data.errors);
                });
        },
        forceDeleteIntegrationOrder({ commit, state, dispatch }, data) {
            commit("setIntegrationOrderDeleteStatus", 1);

            IntegrationOrderAPI.forceDeleteIntegrationOrder(data.slug)
                .then(function(response) {
                    commit("setIntegrationOrderDeleteStatus", 2);
                    //commit("setIntegrationOrderLineCount", state.integrationOrderOptions.lineCount - 1);
                    //commit("setDeletedIntegrationOrder",response.data)
                    dispatch('loadIntegrationOrders');
                    //dispatch("applyIndexOnIntegrationOrders");
                })
                .catch(function() {
                    commit("setIntegrationOrderDeleteStatus", 3);
                });
        },
    },
    mutations: {
        setIntegrationOrderLabelLoadStatus(state, status) {
            state.integrationOrderLabelLoadStatus = status;
        },
        setIntegrationOrderLoadStatus(state, status) {
            state.integrationOrderLoadStatus = status;
        },
        setIntegrationOrder(state, integrationOrder) {
            state.integrationOrder = integrationOrder;
        },
        //#############
        setIntegrationOrderOptions(state,options){
            state.integrationOrderOptions = options;
        },
        setIntegrationOrdersLoadStatus(state, status) {
            state.integrationOrdersLoadStatus = status;
        },
        setIntegrationOrders(state, integrationOrders) {
            state.integrationOrders = integrationOrders;
        },
        setIntegrationOrdersCategories(state, integrationOrdersCategories) {
            state.integrationOrdersCategories = integrationOrdersCategories;
        },
        setIntegrationOrderLineCount(state, lineCount) {
            state.integrationOrderOptions.lineCount = lineCount;
        },
        setIntegrationOrderUpdateCount(state, updateCount) {
            state.integrationOrderOptions.updateCount = updateCount;
        },
        setIntegrationOrderAddStatus(state, status) {
            state.integrationOrderAddStatus = status;
        },
        setIntegrationOrderAddText(state, text) {
            state.integrationOrderAddText = text;
        },
        setIntegrationOrderEdit(state, integrationOrder) {
            state.integrationOrderEdit = integrationOrder;

            
            //state.integrationOrderEdit.weight = i18n.n(state.integrationOrderEdit.weight, 'number', 'pl-PL');
            //if(state.integrationOrderEdit.volume)state.integrationOrderEdit.volume = i18n.n(state.integrationOrderEdit.volume, 'number', 'pl-PL');

            //state.integrationOrderEdit.muc_list.forEach((value, index, self) => {
                //if(!integrationOrder.muc[index]){
                //    state.integrationOrderEdit.muc[index] = null;
                //}
            //});
        },
        setIntegrationOrderEditMucList(state, integrationOrder) {
            state.integrationOrderEdit.muc_list = integrationOrder.muc_list;
        },
        setEditedIntegrationOrder(state, integrationOrder) {
            var lp = _.find(state.integrationOrders,{'id':integrationOrder.id}).lp;
            var index = _.findIndex(state.integrationOrders,{'id':integrationOrder.id});
            
            state.integrationOrders[index] = integrationOrder;
            state.integrationOrders[index].lp = lp;
        },
        setAddedIntegrationOrder(state, integrationOrder) {
            state.integrationOrderOptions.itemAdded = true;
            state.integrationOrders.push(integrationOrder);
        },
        setIntegrationOrderSearchedTrue(state) {
            state.integrationOrderOptions.itemSearched = true;
        },
        setOptionsItemAddedAndSearchedFalse(state) {
            state.integrationOrderOptions.itemAdded = false;
            state.integrationOrderOptions.itemSearched = false;
        },
        setDeletedIntegrationOrder(state, integrationOrder) {
            var index = _.findIndex(state.integrationOrders,{'id':integrationOrder.id});
            state.integrationOrders.splice(index,1);
        },
        setIntegrationOrderEditDefault(state) {
            state.integrationOrderEdit =  JSON.parse(JSON.stringify(state.integrationOrderEditDefault)); //{ ...state.integrationOrderEditDefault };
        },
        setIntegrationOrderEditStatus(state, status) {
            state.integrationOrderEditStatus = status;
        },
        setIntegrationOrderEditText(state, text) {
            state.integrationOrderEditText = text;
        },
        setIntegrationOrderEditLoadStatus(state, status) {
            state.integrationOrderEditLoadStatus = status;
        },
        setIntegrationOrderDeleteStatus(state, status) {
            state.integrationOrderDeleteStatus = status;
        },
        setIntegrationOrderDeletedText(state, text) {
            state.integrationOrderDeleteText = text;
        },
        setIntegrationOrderPayUNote(state,note){
            state.integrationOrderPayUNote = note;
        },
        setIntegrationOrderPayUNoteEmpty(state){
            state.integrationOrderPayUNote = {
                date:null,
                amount:null,
                currency:null,
                status:null,
                desc:null
            }
        }
    },

    getters: {
        getIntegrationOrderLoadStatus(state) {
            return state.integrationOrderLoadStatus;
        },
        getIntegrationOrder(state) {
            return state.integrationOrder;
        },
        getIntegrationOrderOptions(state){
            return state.integrationOrderOptions;
        },
        getIntegrationOrdersLoadStatus(state) {
            return state.integrationOrdersLoadStatus;
        },
        getIntegrationOrders(state) {
            return state.integrationOrders;
        },
        getIntegrationOrdersCategories(state) {
            return state.integrationOrdersCategories;
        },
        getIntegrationOrderAddStatus(state) {
            return function(){
                return state.integrationOrderAddStatus;
            } 
        },
        getIntegrationOrderLabelLoadStatus(state) {
            return function(){
                return state.integrationOrderLabelLoadStatus;
            } 
        },
        getIntegrationOrderAddText(state) {
            return state.integrationOrderAddText;
        },
        getIntegrationOrderEdit(state) {
            return state.integrationOrderEdit;
        },
        getIntegrationOrderEditStatus(state) {
          return function(){
            return state.integrationOrderEditStatus;
          }
        },
        getIntegrationOrderEditText(state) {
            return state.integrationOrderEditText;
        },
        getIntegrationOrderEditLoadStatus(state) {
            return function(){
                return state.integrationOrderEditLoadStatus;
            }
        },
        getIntegrationOrderDeleteStatus(state) {
            return function(){
                return state.integrationOrderDeleteStatus;
            }
        },
        getIntegrationOrderDeleteText(state) {
            return state.integrationOrderDeleteText;
        },
        getIntegrationOrderPayUNote(state){
            return state.integrationOrderPayUNote;
        }
    }
};

<template>
<v-row no-gutters>
      <v-col>
          <v-card flat>
            <v-data-table
                dense
                :key="componentKey"
                hide-default-footer
                :headers="computedHeaders"
                :items="tableItems"
                class="elevation-1"
                :options.sync="tableOptions"
                :server-items-length="options.lineCount"
                :loading="integrationOrdersLoadStatus===1 || integrationOrderEditLoadStatus===1"
                must-sort
                :show-select="multiEdit"
                v-model="tableItemsSelected"
            >
                <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-toolbar-title>Zamówienia</v-toolbar-title>
                    
                    <v-divider
                    class="mx-4"
                    inset
                    vertical
                    ></v-divider>
                    
                    <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-on="on"
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Wyszukaj"
                          single-line
                          hide-details
                          class="search"
                          @keydown.enter="searchIntegrationOrders"
                         
                      ></v-text-field>
                      </template>
                        <span>Wpisz min 3 znaki i naciśnij Enter</span>
                    </v-tooltip>

                    <v-spacer></v-spacer>

                    <v-switch class="mt-4" v-model="multiEdit" label="Zaznaczanie" @change="multiEditChange"></v-switch>
                    <v-divider
                      class="mx-4"
                      inset
                      vertical
                    ></v-divider>

                    <v-progress-circular v-if="autoRefresh" class="mr-2" color="primary" width="3" size="24" :value="100-Math.round(refreshCount/60*100)"></v-progress-circular>
                    <v-switch v-model="autoRefresh" class="mt-5" label="Automatyczne odświeżanie"></v-switch>
                

                    <v-dialog v-model="dialog" :max-width="dialogWidth">
                      <orderEditor v-on:close="dialog=false" v-on:updated="loadIntegrationOrders()" />
                    </v-dialog>

                     <!-- <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-btn @click="newItem()" v-on="on" small class="ml-3 mb-2 mt-2" fab dark color="primary">
                          <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                      </template>
                        <span>Dodaj wysyłkę nizależną</span>
                    </v-tooltip> -->
                    
                </v-toolbar>
                </template>
                
                <template v-slot:item.amount="{ item }">
                  {{item.amount?$n(item.amount,'number','pl-PL'):''}} {{item.amount?item.amount_currency:''}}

                  <v-tooltip v-if="item.amount_paid && item.amount_paid!=item.amount" right>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                  v-on="on"
                                  size="25"
                                  class="mr-2"
                                  color="error"
                                >
                                  mdi-alert-circle
                              </v-icon>
                            </template>
                            <span>Klient zapłacił {{$n(item.amount_paid,'number','pl-PL')}} {{item.amount_currency}}</span>
                    </v-tooltip>

                </template>
                <template v-slot:item.amount_delivery="{ item }">
                  {{item.DeliveryCost?$n(item.DeliveryCost,'number','pl-PL'):''}} {{item.amount?item.amount_currency:''}}
                  <v-tooltip v-if="item.DeliveryCost && item.amount_shipping_paid && item.amount_shipping_paid!=item.DeliveryCost" right>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                  v-on="on"
                                  size="25"
                                  class="mr-2"
                                  color="error"
                                >
                                  mdi-alert-circle
                              </v-icon>
                            </template>
                            <span>Klient zapłacił {{$n(item.amount_shipping_paid,'number','pl-PL')}} {{item.amount_currency}}</span>
                    </v-tooltip>
                </template>
                 
                <template v-slot:item.status="{ item }">

                 <v-tooltip right>
                      <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        :color="_.find(lookups.integrationOrderStatus,{'value':item.status}).color"
                      >
                        {{_.find(lookups.integrationOrderStatus,{'value':item.status}).icon}}
                      </v-icon>
                      </template>
                      <span>{{_.find(lookups.integrationOrderStatus,{'value':item.status}).text}}</span>
                </v-tooltip>

                </template>
                <template v-slot:item.source="{ item }">
                      <v-icon
                        size="22"
                        class="mr-2"
                      >
                        {{_.find(lookups.integrationOrderSource,{'value':item.ext_order_source}).icon}}
                      </v-icon>
                </template>
                <template v-slot:item.details="{ item }">

                      <v-tooltip v-if="item.INFO" left>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                  v-on="on"
                                  size="25"
                                  class="mr-2"
                                  color="blue lighten-3"
                                >
                                  mdi-message-text
                              </v-icon>
                            </template>
                            <span>{{item.INFO}}</span>
                        </v-tooltip>

                      <v-icon
                        v-if="item.COD"
                        size="28"
                        class="mr-2"
                        color="green darken-4"
                      >
                        mdi-cash-100
                      </v-icon>
                      <v-icon
                        v-if="item.FV"
                        size="24"
                        class="mr-2"
                        color="orange darken-4"
                      >
                        mdi-file-document
                      </v-icon>
                      <!-- <v-icon
                        v-if="!item.ORDERS"
                        size="24"
                        class="mr-2"
                        color="purple darken-2"
                      >
                        mdi-basket-off
                      </v-icon> -->
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn color="primary" x-small @click="editItem(item)">Otwórz</v-btn>
                </template>
            </v-data-table> 
            <div class="text-center pt-2">
            <v-btn @click="loadMore" v-if="!options.itemAdded && options.lineCount-tableItems.length>0 && integrationOrdersLoadStatus===2" small color="primary"><v-icon left>mdi-download</v-icon>Załaduj więcej ({{ options.lineCount-tableItems.length }})</v-btn>
            <v-btn @click="loadAll(options.lineCount-tableItems.length)" v-if="!options.itemAdded && options.lineCount-tableItems.length>0 && integrationOrdersLoadStatus===2" small color="error" class="ml-4"><v-icon left>mdi-download-lock</v-icon>Załaduj wszystkie ({{ options.lineCount-tableItems.length }})</v-btn>
            
            <v-btn @click="loadIntegrationOrders" v-else-if="options.itemAdded && options.lineCount-tableItems.length>0" small color="primary"><v-icon left>mdi-reload</v-icon>Odśwież tabele aby móc załadować więcej ({{ options.lineCount-tableItems.length }})</v-btn>
            <v-btn @click="loadIntegrationOrders" v-else-if="options.itemSearched" small color="primary"><v-icon left>mdi-reload</v-icon>Anuluj wyszukiwanie</v-btn>
          
          </div>
            <confirm ref="confirm"></confirm>
          </v-card>
      </v-col>
      <v-col md="auto">
        <menuIntegracjeZamowienia>
          <template v-slot:actions>
            <v-btn @click="multiDelete" v-if="multiEdit" :disabled="tableItemsSelected.length<=0" large color="primary"  width="250" active-class="aa" exact>
                Usuń wybrane
            </v-btn>
            <!-- <v-btn @click="mergeItems" v-if="multiEdit" :disabled="tableItemsSelected.length<=0 || !selectedItemsCanBeMerged" large color="primary"  width="250" active-class="aa" exact>
                Połącz wybrane
            </v-btn> -->
          </template>
        </menuIntegracjeZamowienia>
      </v-col>
    </v-row>
</template>

<script>
  import confirm from '../components/Confirm'
  import menuIntegracjeZamowienia from '../components/MenuIntegracjeZamowienia'
  import orderEditor from '../components/IntegrationOrderEditor'

  export default {
    components:{
      confirm,
      menuIntegracjeZamowienia,
      orderEditor
    },
    props:['aId'],
    data: () => ({
      componentKey:0,
      autoRefresh:false,
      refreshCount:0,
      multiEdit:false,
      tableOptions: {},
      tableItemsSelected:[],
      search:'',
      dialog: false,
      itemEditing:false,
      dialogWidth:1500,
      headers: [
        { text: 'Lp',align: 'start',sortable: false,value: 'lp',filterable:false,visible:true},
        { text: 'Status', value: 'status',sortable:true,filterable:false,filterColumn:"status",visible:true  },
        { text: 'Nr CODIDO', value: 'ext_order_id',sortable: true,filterable:true,filterColumn:"ext_order_id",visible:true },
        { text: 'Data Zam.', value: 'order_date',sortable: true,filterable:false,filterColumn:"order_date",visible:true },
        { text: 'Suma zamówienia', value: 'amount',sortable: true,filterable:true,filterColumn:"amount",visible:true },
        { text: 'Kwota dostawy', value: 'amount_delivery',sortable: false,filterable:false,visible:true },
        { text: 'Kupujący', value: 'buyer.contact_name',sortable: false,filterable:false,filterColumn:"contact_name",visible:true },
        { text: 'Kupujący', value: 'buyer',sortable: false,filterable:true,filterColumn:"buyer",visible:false },
        { text: 'Zamówienie', value: 'order',sortable: false,filterable:true,filterColumn:"order",visible:false },
        { text: 'Żródło', value: 'source',sortable: false,filterable:false,visible:true },
        // { text: 'Produkty', value: 'products',sortable: false,filterable:true,filterColumn:"products",visible:true },
        { text: '', value: 'details',align: 'center', sortable: false,filterable:false,visible:true},
        { text: '', value: 'actions', sortable: false,filterable:false,visible:true},
      ],
    }),
    validations() {
      
    },
    beforeRouteLeave (to, from, next) {
      clearInterval(this.interval);
      next();
    },
    watch: {
      autoRefresh(val){
        if(val){
          this.refreshCount = 0;
          this.interval = setInterval(() => {
            this.refreshCount++;
            if(this.refreshCount>=60){
              this.loadIntegrationOrders();
              this.refreshCount = 0;
            }
          }, 1000);
        }else{
          clearInterval(this.interval);
        }
      },
      tableOptions: {
        handler () {
          var currentOptions = {...this.options};
          const { sortBy, sortDesc } = this.tableOptions
          //zmiana sortowania
          if (sortBy.length === 1 && sortDesc.length === 1) {
            currentOptions.orderBy = sortBy[0];
            currentOptions.orderDirection = sortDesc[0]?'desc':'asc';
            this.options = currentOptions;
          } 
        },
        deep: true,
      },
      dialog (val) {
        //this.$v.$reset()
        if(val){

        }else{
          this.close()
        }
      },

      // integrationOrderEditStatusParent(val){
      //   if(val===2){
      //     //this.componentKey++;
      //   }
      // },
      integrationOrderDeleteStatus(val){
        if(val===2){
          this.tableItemsSelected = [];
        }
      },
      integrationOrderEditLoadStatus(val){
        if(val===2){
          this.dialog = true;
        }
      },
      integrationOrderAddStatus(val){
        if(val===2){
          this.dialogWidth = 1500;
          this.itemEditing = true
          //this.dialog = false;
        }
      }
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      lookups(){
        return this.$store.getters.getLookups;
      },
      options:{
        get () {  
          return this.$store.getters.getIntegrationOrderOptions;
        },
        set (value) {
          this.search='';
          this.$store.dispatch( 'setIntegrationOrderOptions', {options:value} )
        }
      },
      accountId: {
        get () {
          return this.$store.getters.getIntegrationOrderOptions.accountId;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.accountId = value;
          this.options = currentOptions;
        }
      },
      integrationOrder(){
        return this.$store.getters.getIntegrationOrder;
      },
      editedItem(){
        return this.$store.getters.getIntegrationOrderEdit;
      },
      tableItems(){
        return this.$store.getters.getIntegrationOrders;
      },
      // roles(){
      //   return _.filter(this.$store.getters.getGroups,function(o) { return o.value!=4; });
      // },
      // allRoles(){
      //   return this.$store.getters.getGroups;
      // },
      integrationOrdersLoadStatus(){
        return this.$store.getters.getIntegrationOrdersLoadStatus;
      },
      integrationOrderEditLoadStatus(){
        return this.$store.getters.getIntegrationOrderEditLoadStatus()
      },
      // integrationOrderEditStatus(){
      //   return this.$store.getters.getIntegrationOrderEditStatus();
      // },
      integrationOrderEditText(){
        return this.$store.getters.getIntegrationOrderEditText
      },
      integrationOrderAddStatus(){
        return this.$store.getters.getIntegrationOrderAddStatus();
      },
      integrationOrderAddText(){
        return this.$store.getters.getIntegrationOrderAddText;
      },
      integrationOrderDeleteStatus(){
        return this.$store.getters.getIntegrationOrderDeleteStatus();
      },
      
      computedHeaders(){
        return _.filter(this.headers,function(o) { return o.visible; })
      },
      
    },
    mounted(){
      //console.log(window.selected_device)
      if(this.lookups.integrations.length>0){
        this.loadIntegrationOrders();
      }else{
        this.$store.dispatch( 'loadLookups' );
        setTimeout(() => {
          this.loadIntegrationOrders();
        }, 1000)
      }
    },
    methods: {
      multiEditChange(){
        if(!this.multiEdit){
          this.tableItemsSelected = [];
        }
      },
      
      loadIntegrationOrders(){
        this.search='';
        this.accountId = this.aId;//pobiera z propsów

        //this.$store.dispatch( 'loadIntegrationOrders' );
      },
      loadMore(){
        this.$store.dispatch( 'loadMoreIntegrationOrders' );
      },
      loadAll(len){
        this.$refs.confirm.open('Uwaga', 'Czy na pewno chcesz pobrać resztę rekordów? ('+len+')', { color: 'warning' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'loadAllIntegrationOrders' );
            }
        })
        
      },
      errorMessageArray(val) {
        const errors = val;
        var err = [];
        if(!errors)return [];

        if(errors.email){
          errors.email.forEach(e => {
            err.push(e);
          });
        }

        if(errors.name){
          errors.name.forEach(e => {
            err.push(e);
          });
        }
      
        return err;
      },     
     
      editItem (item) {
        this.dialogWidth = 1500;
        this.itemEditing = true
        this.$store.dispatch( 'loadIntegrationOrderEdit', {slug:  item.id});  
        //this.dialog = true
      },
      newItem(){
        //this.$v.$reset()
        this.dialogWidth = 1000;
        this.itemEditing = true;
        this.dialog = true;
      },
      searchIntegrationOrders(){
        if(this.search.length>2){
          const filterableHeaders = _.join(_.map(_.filter(this.headers,{'filterable':true}),'filterColumn'),';');
          this.$store.dispatch( 'searchIntegrationOrders',{filterableHeaders:filterableHeaders,q:this.search});
        }
      },
     
      // mergeItems () {
      //   this.$refs.confirm.open('Usuń', 'Czy na pewno chcesz połączyć wybrane wysyłki? (koszty wysyłek też zostaną zsumowane)', { color: 'error' }).then((confirm) => {
      //       if(confirm){
      //          this.$store.dispatch( 'editIntegrationOrderMerge', {slug:  _.map(this.tableItemsSelected,'id')});
      //          this.tableItemsSelected = [];
      //       }
      //   })
      // },
      multiDelete(){
        this.$refs.confirm.open('Usuń', 'Czy na pewno chcesz usunać zamówienia?', { color: 'error' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'forceDeleteIntegrationOrder', {slug:  _.map(this.tableItemsSelected,'id')});
            }
        })
      },
      close () {
        this.dialog = false
        //this.$v.$reset()
        setTimeout(() => {
          this.$store.dispatch( 'loadIntegrationOrderEditDefault'); 
          this.$store.dispatch( 'loadIntegrationOrderAddDefault');
          this.itemEditing = false
        }, 300)
      },
      handleSubmit () {
        this.$v.$touch()
        if (this.$v.$invalid) {

        } else {
         
           
    
        }
       
      },
    },
  }
</script>


<style scoped>
.search{
      max-width: 250px;
}
</style>

const numberFormats = {
  'pl-PL': {
    currency: {
      style: 'currency', currency: 'PLN'
    },
    number:{
      minimumFractionDigits:2
    }
  },
}

import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueI18n from 'vue-i18n'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Vuelidate from 'vuelidate'
import './styles/global.css'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VueClipboard from 'vue-clipboard2'

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.withCredentials = true
axios.defaults.timeout = 1800000;

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)
Vue.use(VueAxios, axios)
Vue.use(Vuelidate)
Vue.use(VueI18n)
Vue.use(VueLodash, { lodash: lodash })

const i18n = new VueI18n({
  numberFormats
})

Vue.config.productionTip = false
//Vue.config.performance = true;
//const host = window.location.host;

Vue.directive('focus', {
  inserted: function (el) {
    let childData = el.querySelectorAll("input")[0]; 
    //childData.focus()
      //el.focus();
      Vue.nextTick(() => childData.focus()); 
  }
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
}).$mount('#app')

export default i18n;
<template>
                  <v-card  :loading="accountEditLoadStatus===1 || accountEditStatus===1">
                    <v-card-title>
                      <img class="mr-3" src="/logo_listek.png" />
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        
                          <v-row>                   
                           <v-col cols="12">
                                    <v-image-input
                                      clearable
                                      v-model="editedItem.logo"
                                      :fullWidth="true"
                                      :fullHeight="true"
                                      :imageMaxScaling="2"
                                      imageMinScaling='contain'
                                      :image-quality="0.85"
                                      image-format="png"
                                    />
                            </v-col>
                          </v-row>
                        
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="gray" text @click="close">Anuluj</v-btn>
                      <v-btn color="primary darken-1" text @click="handleSubmit" >Zapisz</v-btn>
                    </v-card-actions>
                  </v-card>
</template>

<script>
  import required from 'vuelidate/lib/validators/required'
  import VImageInput from 'vuetify-image-input';

  export default {
    components:{
      VImageInput
    },
    data: () => ({

    }),
    props:['itemEditing'],
    // validations: {
    //   imageData: {
    //       required,
    //     },
    // },
    watch: {
    
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      editedItem(){
        return this.$store.getters.getAccountEdit;
      },
      accountEditLoadStatus(){
        return this.$store.getters.getAccountEditLoadStatus
      },
      accountEditStatus(){
        return this.$store.getters.getAccountEditStatus();
      },
      accountEditText(){
        return this.$store.getters.getAccountEditText
      },
      accountAddStatus(){
        return this.$store.getters.getAccountAddStatus();
      },
      accountAddText(){
        return this.$store.getters.getAccountAddText;
      },
    
      formTitle () {
        return !this.itemEditing ? 'Nowe logo' : 'Edycja loga'
      },
      logoErrors () {
        const errors = []
        if (!this.$v.editedItem.logo.$dirty) return errors
        !this.$v.editedItem.logo.required && errors.push('Logo jest wymagane')
        return errors
      },
    },
 
    methods: {
      close(){
        //this.$v.$reset();
        this.$emit('close');
      },
       handleSubmit () {
        //this.$v.$touch()
        //if (this.$v.$invalid) {

        //} else {
            if (this.itemEditing) {
              this.$store.dispatch( 'editAccount', {
                slug: this.editedItem.id,
                nip:  this.editedItem.nip,
                regon:  this.editedItem.regon,
                krs:  this.editedItem.krs,
                logo: this.editedItem.logo
              });
            }
        //}
      },
    },
  }
</script>


<template>
                  <v-card  :loading="departmentAddStatus===1 || departmentEditLoadStatus===1 || departmentEditStatus===1">
                    <v-card-title>
                      <img class="mr-3" src="/logo_listek.png" />
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        
                          <v-row>                   
                            <v-col cols="12" sm="10" md="10">
                              <v-text-field v-model="editedItem.name" :error-messages="nameErrors" required @input="$v.editedItem.name.$touch()"  @blur="$v.editedItem.name.$touch()" label="Nazwa firmy/oddziału"></v-text-field>                            
                            </v-col>
                            <v-col cols="12" sm="2" md="2">
                              <v-checkbox
                                :disabled="itemEditing && itemWasMain"
                                v-model="editedItem.main"
                                label="Centrala"
                              ></v-checkbox>
                              
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="editedItem.post" :error-messages="postErrors" required @input="$v.editedItem.post.$touch()"  @blur="$v.editedItem.post.$touch()" label="Kod pocztowy"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="editedItem.city" :error-messages="cityErrors" required @input="$v.editedItem.city.$touch()"  @blur="$v.editedItem.city.$touch()" label="Miasto"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="editedItem.street" :error-messages="streetErrors" required @input="$v.editedItem.street.$touch()"  @blur="$v.editedItem.street.$touch()" label="Ulica"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="editedItem.phone" :error-messages="phoneErrors" required @input="$v.editedItem.phone.$touch()"  @blur="$v.editedItem.phone.$touch()" label="Telefon"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="editedItem.email" :error-messages="emailErrors" required @input="$v.editedItem.email.$touch()"  @blur="$v.editedItem.email.$touch()" label="E-mail"></v-text-field>
                            </v-col>
                          </v-row>
                        
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="gray" text @click="close()">Anuluj</v-btn>
                      <v-btn color="primary darken-1" text @click="handleSubmit" >Zapisz</v-btn>
                    </v-card-actions>
                  </v-card>
</template>

<script>
  import required from 'vuelidate/lib/validators/required'
  import minLength from 'vuelidate/lib/validators/minLength'
  import maxLength from 'vuelidate/lib/validators/maxLength'
  import numeric from 'vuelidate/lib/validators/numeric'
  import email from 'vuelidate/lib/validators/email'

  export default {
    data: () => ({
    
    }),
    props:['itemEditing','itemWasMain'],
    validations: {
      editedItem:{
        name: {
          required,
        },
        city:{
          required
        },
        street:{
          required
        },
        post:{
          required
        },
        phone:{
          required
        },
        email:{
          email
        }
      }
    },
    watch: {
    
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      editedItem(){
        return this.$store.getters.getDepartmentEdit;
      },
      departmentEditLoadStatus(){
        return this.$store.getters.getDepartmentEditLoadStatus
      },
      departmentEditStatus(){
        return this.$store.getters.getDepartmentEditStatus();
      },
      departmentEditText(){
        return this.$store.getters.getDepartmentEditText
      },
      departmentAddStatus(){
        return this.$store.getters.getDepartmentAddStatus();
      },
      departmentAddText(){
        return this.$store.getters.getDepartmentAddText;
      },
    
      formTitle () {
        return !this.itemEditing ? 'Nowa firma/oddział' : 'Edycja firmy/oddziału'
      },
      nameErrors () {
        const errors = []
        if (!this.$v.editedItem.name.$dirty) return errors
        !this.$v.editedItem.name.required && errors.push('Nazwa jest wymagana')
        return errors
      },
      cityErrors () {
        const errors = []
        if (!this.$v.editedItem.city.$dirty) return errors
        !this.$v.editedItem.city.required && errors.push('Miasto jest wymagane')
        return errors
      },
      streetErrors () {
        const errors = []
        if (!this.$v.editedItem.street.$dirty) return errors
        !this.$v.editedItem.street.required && errors.push('Adres jest wymagany')
        return errors
      },
      postErrors () {
        const errors = []
        if (!this.$v.editedItem.post.$dirty) return errors
        !this.$v.editedItem.post.required && errors.push('Adres jest wymagany')
        return errors
      },
      emailErrors () {
        const errors = []
        if (!this.$v.editedItem.email.$dirty) return errors
        !this.$v.editedItem.email.email && errors.push('E-mail jest niepoprawny')
        return errors
      },
      phoneErrors () {
        const errors = []
        if (!this.$v.editedItem.phone.$dirty) return errors
        !this.$v.editedItem.phone.required && errors.push('Telefon jest wymagany')
        //!this.$v.editedItem.phone.numeric && errors.push('Telefon może zawierać tylko cyfry')
        return errors
      },
    },
 
    methods: {
      close(){
        this.$v.$reset();
        this.$emit('close');
      },
       handleSubmit () {
        this.$v.$touch()
        if (this.$v.$invalid) {

        } else {
         
            if (this.itemEditing) {
              //edycja
              this.$store.dispatch( 'editDepartment', {
                slug: this.editedItem.id,
                name:  this.editedItem.name,
                city:  this.editedItem.city,
                street:  this.editedItem.street,
                post: this.editedItem.post,
                email: this.editedItem.email,
                phone:  this.editedItem.phone,
                main:  this.editedItem.main, 
              });
            } else {//new item
              this.$store.dispatch( 'addDepartment', {
                  name:  this.editedItem.name,
                  city:  this.editedItem.city,
                  street:  this.editedItem.street,
                  post: this.editedItem.post,
                  email: this.editedItem.email,
                  phone:  this.editedItem.phone,
                  main:  this.editedItem.main,
              });

            }
        }
      },
    },
  }
</script>


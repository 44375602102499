import IntegrationShipmentAPI from "../api/integrationShipment";
import i18n from "../main";

export const integrationShipments = {
    state: {
        integrationShipments: [],
        integrationShipmentOptions:{
            lineCount:0,
            orderBy:undefined,
            orderDirection:undefined,
            itemsPerPage:20,
            itemAdded:false,
            integrationId:undefined,
            accountId:undefined,
            statusId:undefined,
            showActive:true,
            fromDate:undefined,
            toDate:undefined,
            pickupDate:undefined
        },


        integrationShipmentsLoadStatus: 0,
        integrationShipmentAddStatus: 0,
        integrationShipmentAddText: "",

        integrationShipmentEdit: {
            independentItem:true,
            id: 0,
            lp: 0,
            order:[],
            custom_order:null,
            pickup_list:[],
            buyer:{
                buyer_login:null,
                delivery_company_name:null,
                delivery_first_name:null,
                delivery_last_name:null,
                delivery_street:null,
                delivery_post_code:null,
                delivery_city:null,
                delivery_country_code:'PL',
                contact_phone:null,
                contact_email:null,
            },
            carrier:{service_list:[], settings:{}},
            status:0,
            amount:undefined,
            amount_currency:'PLN',
            pickup_date:undefined,
            shipment_id:undefined,
            logs:[]
        },
        integrationShipmentEditDefault: {
            independentItem:true,
            id: 0,
            lp: 0,
            order:[],
            custom_order:null,
            pickup_list:[],
            buyer:{
                buyer_login:null,
                delivery_company_name:null,
                delivery_first_name:null,
                delivery_last_name:null,
                delivery_street:null,
                delivery_post_code:null,
                delivery_city:null,
                delivery_country_code:'PL',
                contact_phone:null,
                contact_email:null,
            },
            carrier:{service_list:[], settings:{}},
            status:0,
            amount:undefined,
            amount_currency:'PLN',
            pickup_date:undefined,
            shipment_id:undefined,
            logs:[]
        },
        integrationShipmentEditLoadStatus: 0,
        integrationShipmentEditStatus: 0,
        integrationShipmentEditText: "",

        integrationShipmentDeleteStatus: 0,
        integrationShipmentLabelLoadStatus: 0,
        integrationShipmentDeleteText: "",

    },

    actions: {
        loadIntegrationShipments({ state, commit, dispatch,rootState }) {
            commit("setIntegrationShipmentsLoadStatus", 1);
                IntegrationShipmentAPI.getIntegrationShipments({
                    offset:0,
                    itemsPerPage:state.integrationShipmentOptions.itemsPerPage, 
                    orderBy:state.integrationShipmentOptions.orderBy?state.integrationShipmentOptions.orderBy:rootState.lookups.dispSettings.DEFAULT_ORDER_BY,
                    orderDirection:state.integrationShipmentOptions.orderDirection?state.integrationShipmentOptions.orderDirection:rootState.lookups.dispSettings.DEFAULT_ORDER_DIRECTION,
                    integrationId:state.integrationShipmentOptions.integrationId,
                    accountId:state.integrationShipmentOptions.accountId,
                    statusId:state.integrationShipmentOptions.statusId,
                    showActive:state.integrationShipmentOptions.showActive,
                    fromDate:state.integrationShipmentOptions.fromDate,
                    toDate:state.integrationShipmentOptions.toDate,
                    pickupDate:state.integrationShipmentOptions.pickupDate,
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setIntegrationShipmentLineCount", response.data.lineCount);
                    commit("setIntegrationShipments", response.data.integrationShipments);
                    commit("setIntegrationShipmentsCategories", response.data.integrationShipmentsCategories);
                    dispatch("applyIndexOnIntegrationShipments");
                    commit("setIntegrationShipmentsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setIntegrationShipments", []);
                    commit("setIntegrationShipmentsLoadStatus", 3);
                });
         
        },
        loadMoreIntegrationShipments({ state, commit, dispatch,rootState  }) {
            commit("setIntegrationShipmentsLoadStatus", 1);
                IntegrationShipmentAPI.getIntegrationShipments({
                    offset:state.integrationShipments.length,
                    itemsPerPage:state.integrationShipmentOptions.itemsPerPage, 
                    orderBy:state.integrationShipmentOptions.orderBy?state.integrationShipmentOptions.orderBy:rootState.lookups.dispSettings.DEFAULT_ORDER_BY,
                    orderDirection:state.integrationShipmentOptions.orderDirection?state.integrationShipmentOptions.orderDirection:rootState.lookups.dispSettings.DEFAULT_ORDER_DIRECTION,
                    integrationId:state.integrationShipmentOptions.integrationId,
                    accountId:state.integrationShipmentOptions.accountId,
                    statusId:state.integrationShipmentOptions.statusId,
                    showActive:state.integrationShipmentOptions.showActive,
                    fromDate:state.integrationShipmentOptions.fromDate,
                    toDate:state.integrationShipmentOptions.toDate,
                    pickupDate:state.integrationShipmentOptions.pickupDate,
                })
                .then(function(response) {
                    commit("setIntegrationShipmentLineCount", response.data.lineCount);
                    commit("setIntegrationShipments", _.concat(state.integrationShipments, response.data.integrationShipments));
                    dispatch("applyIndexOnIntegrationShipments");
                    commit("setIntegrationShipmentsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setIntegrationShipments", []);
                    commit("setIntegrationShipmentsLoadStatus", 3);
                });
         
        },
        loadAllIntegrationShipments({ state, commit, dispatch,rootState  }) {
            commit("setIntegrationShipmentsLoadStatus", 1);
                IntegrationShipmentAPI.getIntegrationShipments({
                    offset:state.integrationShipments.length,
                    itemsPerPage:9999999, 
                    orderBy:state.integrationShipmentOptions.orderBy?state.integrationShipmentOptions.orderBy:rootState.lookups.dispSettings.DEFAULT_ORDER_BY,
                    orderDirection:state.integrationShipmentOptions.orderDirection?state.integrationShipmentOptions.orderDirection:rootState.lookups.dispSettings.DEFAULT_ORDER_DIRECTION,
                    integrationId:state.integrationShipmentOptions.integrationId,
                    accountId:state.integrationShipmentOptions.accountId,
                    statusId:state.integrationShipmentOptions.statusId,
                    showActive:state.integrationShipmentOptions.showActive,
                    fromDate:state.integrationShipmentOptions.fromDate,
                    toDate:state.integrationShipmentOptions.toDate,
                    pickupDate:state.integrationShipmentOptions.pickupDate,
                })
                .then(function(response) {
                    commit("setIntegrationShipmentLineCount", response.data.lineCount);
                    commit("setIntegrationShipments", _.concat(state.integrationShipments, response.data.integrationShipments));
                    dispatch("applyIndexOnIntegrationShipments");
                    commit("setIntegrationShipmentsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setIntegrationShipments", []);
                    commit("setIntegrationShipmentsLoadStatus", 3);
                });
         
        },
        searchIntegrationShipments({ state, commit, dispatch },data) {
            commit("setIntegrationShipmentsLoadStatus", 1);
                IntegrationShipmentAPI.searchIntegrationShipments({
                    integrationId:state.integrationShipmentOptions.integrationId,
                    filterableHeaders:data.filterableHeaders,
                    q:data.q
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setIntegrationShipmentSearchedTrue");
                    commit("setIntegrationShipmentLineCount", response.data.lineCount);
                    commit("setIntegrationShipments", response.data.integrationShipments);
                    dispatch("applyIndexOnIntegrationShipments");
                    commit("setIntegrationShipmentsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setIntegrationShipments", []);
                    commit("setIntegrationShipmentsLoadStatus", 3);
                });
         
        },
        setIntegrationShipmentOptions({ state, commit, dispatch },data){
            commit("setIntegrationShipmentOptions",data.options);
            dispatch("loadIntegrationShipments");
        },
        applyIndexOnIntegrationShipments({ commit, state, dispatch }) {
            let localIntegrationShipments = state.integrationShipments;

            var i = 1;
            localIntegrationShipments.forEach(integrationShipment => {
                // if(_.isNumber(integrationShipment.status)){
                //     integrationShipment.status = _.find(state.integrationShipmentStatus, { 'value': integrationShipment.status }).text;
                // }
                integrationShipment.lp = i;
                i++;
            });

            commit("setIntegrationShipments",localIntegrationShipments);
        },
        loadIntegrationShipmentEdit({ commit }, data) {
            commit("setIntegrationShipmentEditLoadStatus", 1);
            commit("setIntegrationShipmentEditStatus", 0);
            commit("setIntegrationShipmentEditText", "");

            IntegrationShipmentAPI.getIntegrationShipmentEdit(data.slug)
                .then(function(response) {
                    commit("setIntegrationShipmentEdit", response.data);
                    commit("setIntegrationShipmentEditLoadStatus", 2);
                })
                .catch(function() {
                    commit("setIntegrationShipmentEdit", {});
                    commit("setIntegrationShipmentEditLoadStatus", 3);
                });
        },
        loadIntegrationShipmentEditDefault({ commit }) {
            commit("setIntegrationShipmentEditDefault");
        },
        loadIntegrationShipmentAddDefault({ commit }) {
            commit("setIntegrationShipmentAddStatus", 0);
            commit("setIntegrationShipmentAddText", "");
            commit("setIntegrationShipmentEditStatus", 0);
            commit("setIntegrationShipmentEditText", "");
        },
        getIntegrationShipmentLabel({ commit }, data) {
            commit("setIntegrationShipmentLabelLoadStatus", 1);

            IntegrationShipmentAPI.getIntegrationShipmentLabel(data.slug, data.position, data.type)
                .then(function(response) {
                    commit("setIntegrationShipmentLabelLoadStatus", 2);
                    if(window.selected_device && (response.data.FileType==='zpl' || response.data.FileType==='epl')){
                        window.selected_device.send(atob(response.data.FileContent), function(success){console.log("Wysłano do drukarki");}, function(error){console.error("Błąd:" + error);});
                    }else{
                        let pdfWindow = window.open("");
                        pdfWindow.document.write("<html<head><title>"+response.data.FileName+"</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>");
                        pdfWindow.document.write("<body><embed width='100%' height='100%' src='data:"+ response.data.ContentType+";base64, " + encodeURI(response.data.FileContent)+"#toolbar=0&navpanes=0&scrollbar=0'></embed></body></html>");
                        pdfWindow.document.close();
                    }
                    
                    //pdfWindow.focus();
                    //pdfWindow.print();
                    //pdfWindow.close();

                    // var fileLink = document.createElement('a');
                    // fileLink.href = "data:application/pdf;base64," + response.data.FileContent;
                    // //fileLink.setAttribute('download', response.data.FileName);
                    // document.body.appendChild(fileLink);
                    // fileLink.click();
                })
                .catch(function() {
                    commit("setIntegrationShipmentLabelLoadStatus", 3);
                });
        },
        editIntegrationShipmentOrderStatus({ commit, state, dispatch }, data) {
                commit("setIntegrationShipmentEditStatus", 1);
    
                IntegrationShipmentAPI.putEditIntegrationShipmentOrderStatus(
                    data.slug,
                    data.order_id
                )
                    .then(function(response) {
                        commit("setIntegrationShipmentEditStatus", 2);
                        commit("setIntegrationShipmentEdit", response.data);
                        commit("setEditedIntegrationShipment",response.data)
                    })
                    .catch(function(error) {
                        commit("setIntegrationShipmentEditStatus", 3);
                        commit("setIntegrationShipmentEditText", error.response.data.errors);
                    });
        },
        editIntegrationShipmentMove({ commit, state, dispatch }, data) {
            commit("setIntegrationShipmentEditStatus", 1);

            IntegrationShipmentAPI.putEditIntegrationShipmentMove(
                data.slug,
                data.selected
            )
                .then(function(response) {
                    commit("setIntegrationShipmentEditStatus", 2);
                    commit("setIntegrationShipmentEdit", response.data);
                    commit("setEditedIntegrationShipment",response.data)
                })
                .catch(function(error) {
                    commit("setIntegrationShipmentEditStatus", 3);
                    commit("setIntegrationShipmentEditText", error.response.data.errors);
                });
        },
        editIntegrationShipmentMerge({ commit, state, dispatch }, data) {
            commit("setIntegrationShipmentEditStatus", 1);

            IntegrationShipmentAPI.putEditIntegrationShipmentMerge(
                data.slug,
            )
                .then(function(response) {
                    commit("setIntegrationShipmentEditStatus", 2);
                    dispatch('loadIntegrationShipments');
                })
                .catch(function(error) {
                    commit("setIntegrationShipmentEditStatus", 3);
                    commit("setIntegrationShipmentEditText", error.response.data.errors);
                });
        },
        editIntegrationShipmentCancel({ commit, state, dispatch }, data) {
            commit("setIntegrationShipmentEditStatus", 1);

            IntegrationShipmentAPI.getEditIntegrationShipmentCancel(
                data.slug,
            )
                .then(function(response) {
                    commit("setIntegrationShipmentEditStatus", 2);
                    commit("setIntegrationShipmentEdit", response.data);
                    commit("setEditedIntegrationShipment",response.data)
                })
                .catch(function(error) {
                    commit("setIntegrationShipmentEditStatus", 3);
                    commit("setIntegrationShipmentEditText", error.response.data.errors);
                });
        },
        editIntegrationShipmentIntegrationId({ commit, state, dispatch }, data) {
            commit("setIntegrationShipmentEditStatus", 1);

            IntegrationShipmentAPI.putEditIntegrationShipmentIntegrationId(
                data.slug,
                data.integration_id
            )
                .then(function(response) {
                    commit("setIntegrationShipmentEditStatus", 2);
                    commit("setIntegrationShipmentEdit", response.data);
                    commit("setEditedIntegrationShipment",response.data)
                })
                .catch(function(error) {
                    commit("setIntegrationShipmentEditStatus", 3);
                    commit("setIntegrationShipmentEditText", error.response.data.errors);
                });
        },
        editIntegrationShipment({ commit, state, dispatch }, data) {
            commit("setIntegrationShipmentEditStatus", 1);

            IntegrationShipmentAPI.putEditIntegrationShipment(
                data.slug,
                data.order,
                data.carrier,
                data.pickup_list,
                data.buyer,
                data.amount,
                data.amount_currency,
                data.custom_order
            )
                .then(function(response) {
                    commit("setIntegrationShipmentEditStatus", 2);
                    commit("setIntegrationShipmentEdit", response.data);
                    commit("setEditedIntegrationShipment",response.data)
                })
                .catch(function(error) {
                    commit("setIntegrationShipmentEditStatus", 3);
                    commit("setIntegrationShipmentEditText", error.response.data.errors);
                });
        },
        addIntegrationShipment({ commit, state, dispatch }, data) {
            commit("setIntegrationShipmentAddStatus", 1);

            IntegrationShipmentAPI.postNewIntegrationShipment(
                data.buyer,
                data.amount,
                data.amount_currency,
                data.custom_order
            )
                .then(function(response) {
                    commit("setIntegrationShipmentAddStatus", 2);
                    //commit("setIntegrationShipmentEdit", response.data);
                    commit("setAddedIntegrationShipment",response.data)
                    dispatch("applyIndexOnIntegrationShipments");
                    dispatch( 'loadIntegrationShipmentEdit', {slug:  response.data.id});
                })
                .catch(function(error) {
                    commit("setIntegrationShipmentAddStatus", 3);
                    commit("setIntegrationShipmentEditText", error.response.data.errors);
                });
        },
        editIntegrationShipmentSend({ commit, state, dispatch }, data) {
            commit("setIntegrationShipmentEditStatus", 1);

            IntegrationShipmentAPI.putEditIntegrationShipmentSend(
                data.slug,
                data.order,
                data.carrier,
                data.pickup_list,
                data.buyer
            )
                .then(function(response) {
                    commit("setIntegrationShipmentEditStatus", 2);
                    commit("setIntegrationShipmentEdit", response.data);
                    commit("setEditedIntegrationShipment",response.data)
                })
                .catch(function(error) {
                    commit("setIntegrationShipmentEditStatus", 3);
                    commit("setIntegrationShipmentEditText", error.response.data.errors);
                });
        },
        forceDeleteIntegrationShipment({ commit, state, dispatch }, data) {
            commit("setIntegrationShipmentDeleteStatus", 1);

            IntegrationShipmentAPI.forceDeleteIntegrationShipment(data.slug)
                .then(function(response) {
                    commit("setIntegrationShipmentDeleteStatus", 2);
                    //commit("setIntegrationShipmentLineCount", state.integrationShipmentOptions.lineCount - 1);
                    //commit("setDeletedIntegrationShipment",response.data)
                    dispatch('loadIntegrationShipments');
                    //dispatch("applyIndexOnIntegrationShipments");
                })
                .catch(function() {
                    commit("setIntegrationShipmentDeleteStatus", 3);
                });
        },
    },
    mutations: {
        setIntegrationShipmentLabelLoadStatus(state, status) {
            state.integrationShipmentLabelLoadStatus = status;
        },
        setIntegrationShipmentLoadStatus(state, status) {
            state.integrationShipmentLoadStatus = status;
        },
        setIntegrationShipment(state, integrationShipment) {
            state.integrationShipment = integrationShipment;
        },
        //#############
        setIntegrationShipmentOptions(state,options){
            state.integrationShipmentOptions = options;
        },
        setIntegrationShipmentsLoadStatus(state, status) {
            state.integrationShipmentsLoadStatus = status;
        },
        setIntegrationShipments(state, integrationShipments) {
            state.integrationShipments = integrationShipments;
        },
        setIntegrationShipmentsCategories(state, integrationShipmentsCategories) {
            state.integrationShipmentsCategories = integrationShipmentsCategories;
        },
        setIntegrationShipmentLineCount(state, lineCount) {
            state.integrationShipmentOptions.lineCount = lineCount;
        },
        setIntegrationShipmentUpdateCount(state, updateCount) {
            state.integrationShipmentOptions.updateCount = updateCount;
        },
        setIntegrationShipmentAddStatus(state, status) {
            state.integrationShipmentAddStatus = status;
        },
        setIntegrationShipmentAddText(state, text) {
            state.integrationShipmentAddText = text;
        },
        setIntegrationShipmentEdit(state, integrationShipment) {
            state.integrationShipmentEdit = integrationShipment;

            // if(state.integrationShipmentEdit.carrier){
            //     state.integrationShipmentEdit.carrier.service_list.forEach(service => {
            //         service.parameters.forEach(par => {
            //             if(par.type=='float' && par.value)par.value = i18n.n(par.value, 'number', 'pl-PL');
            //         });
            //     });
            // }
            
            // if(state.integrationShipmentEdit.pickup_list.length>0){
            //     state.integrationShipmentEdit.pickup_list.forEach(pl => {
            //         if(pl.weight)pl.weight = i18n.n(pl.weight, 'number', 'pl-PL');
            //         if(pl.volume)pl.volume = i18n.n(pl.volume, 'number', 'pl-PL');
            //     });
            // }
            
            //state.integrationShipmentEdit.weight = i18n.n(state.integrationShipmentEdit.weight, 'number', 'pl-PL');
            //if(state.integrationShipmentEdit.volume)state.integrationShipmentEdit.volume = i18n.n(state.integrationShipmentEdit.volume, 'number', 'pl-PL');

            //state.integrationShipmentEdit.muc_list.forEach((value, index, self) => {
                //if(!integrationShipment.muc[index]){
                //    state.integrationShipmentEdit.muc[index] = null;
                //}
            //});
        },
        setIntegrationShipmentEditMucList(state, integrationShipment) {
            state.integrationShipmentEdit.muc_list = integrationShipment.muc_list;
        },
        setEditedIntegrationShipment(state, integrationShipment) {
            var lp = _.find(state.integrationShipments,{'id':integrationShipment.id}).lp;
            var index = _.findIndex(state.integrationShipments,{'id':integrationShipment.id});
            
            state.integrationShipments[index] = integrationShipment;
            state.integrationShipments[index].lp = lp;
        },
        setAddedIntegrationShipment(state, integrationShipment) {
            state.integrationShipmentOptions.itemAdded = true;
            state.integrationShipments.push(integrationShipment);
        },
        setIntegrationShipmentSearchedTrue(state) {
            state.integrationShipmentOptions.itemSearched = true;
        },
        setOptionsItemAddedAndSearchedFalse(state) {
            state.integrationShipmentOptions.itemAdded = false;
            state.integrationShipmentOptions.itemSearched = false;
        },
        setDeletedIntegrationShipment(state, integrationShipment) {
            var index = _.findIndex(state.integrationShipments,{'id':integrationShipment.id});
            state.integrationShipments.splice(index,1);
        },
        setIntegrationShipmentEditDefault(state) {
            state.integrationShipmentEdit =  JSON.parse(JSON.stringify(state.integrationShipmentEditDefault)); //{ ...state.integrationShipmentEditDefault };
        },
        setIntegrationShipmentEditStatus(state, status) {
            state.integrationShipmentEditStatus = status;
        },
        setIntegrationShipmentEditText(state, text) {
            state.integrationShipmentEditText = text;
        },
        setIntegrationShipmentEditLoadStatus(state, status) {
            state.integrationShipmentEditLoadStatus = status;
        },
        setIntegrationShipmentDeleteStatus(state, status) {
            state.integrationShipmentDeleteStatus = status;
        },
        setIntegrationShipmentDeletedText(state, text) {
            state.integrationShipmentDeleteText = text;
        }
    },

    getters: {
        getIntegrationShipmentLoadStatus(state) {
            return state.integrationShipmentLoadStatus;
        },
        getIntegrationShipment(state) {
            return state.integrationShipment;
        },
        getIntegrationShipmentOptions(state){
            return state.integrationShipmentOptions;
        },
        getIntegrationShipmentsLoadStatus(state) {
            return state.integrationShipmentsLoadStatus;
        },
        getIntegrationShipments(state) {
            return state.integrationShipments;
        },
        getIntegrationShipmentsCategories(state) {
            return state.integrationShipmentsCategories;
        },
        getIntegrationShipmentAddStatus(state) {
            return function(){
                return state.integrationShipmentAddStatus;
            } 
        },
        getIntegrationShipmentLabelLoadStatus(state) {
            return function(){
                return state.integrationShipmentLabelLoadStatus;
            } 
        },
        getIntegrationShipmentAddText(state) {
            return state.integrationShipmentAddText;
        },
        getIntegrationShipmentEdit(state) {
            return state.integrationShipmentEdit;
        },
        getIntegrationShipmentEditStatus(state) {
          return function(){
            return state.integrationShipmentEditStatus;
          }
        },
        getIntegrationShipmentEditText(state) {
            return state.integrationShipmentEditText;
        },
        getIntegrationShipmentEditLoadStatus(state) {
            return function(){
                return state.integrationShipmentEditLoadStatus;
            }
        },
        getIntegrationShipmentDeleteStatus(state) {
            return function(){
                return state.integrationShipmentDeleteStatus;
            }
        },
        getIntegrationShipmentDeleteText(state) {
            return state.integrationShipmentDeleteText;
        }
    }
};

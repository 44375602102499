import IntegrationDocumentAPI from "../api/integrationDocument";

export const payuChecks = {
    state: {
        PayuChecks: [],
        PayuCheckOptions:{
            orderBy:undefined,
            orderDirection:undefined,
            accountId:undefined,
            statusId:undefined,
            showActive:true,
            fromDate:undefined,
            toDate:undefined,
            typeId:undefined
        },
        PayuChecksLoadStatus: 0,
    },

    actions: {
        loadPayuChecks({ state, commit, dispatch,rootState },data) {
            commit("setPayuChecksLoadStatus", 1);
                IntegrationDocumentAPI.postPayuChecks({
                    accountId:state.PayuCheckOptions.accountId,
                    statusId:state.PayuCheckOptions.statusId,
                    showActive:state.PayuCheckOptions.showActive,
                    typeId:state.PayuCheckOptions.typeId,
                    file_content:data.file_content
                })
                .then(function(response) {
                    commit("setPayuCheckLineCount", response.data.lineCount);
                    commit("setPayuChecks", response.data.PayuChecks);
                    dispatch("applyIndexOnPayuChecks");
                    commit("setPayuChecksLoadStatus", 2);
                })
                .catch(function() {
                    commit("setPayuChecks", []);
                    commit("setPayuChecksLoadStatus", 3);
                });
         
        },
        setPayuCheckOptions({ state, commit, dispatch },data){
            commit("setPayuCheckOptions",data.options);
            //dispatch("loadPayuChecks");
        },
        applyIndexOnPayuChecks({ commit, state, dispatch, rootState }) {
            let localPayuChecks = state.PayuChecks;

            var i = 1;
            localPayuChecks.forEach(PayuCheck => {
                // if(_.isNumber(PayuCheck.type)){
                //     PayuCheck.type = _.find(rootState.lookups.PayuCheckTypes, { 'value': PayuCheck.type }).text;
                // }
                PayuCheck.lp = i;
                i++;
            });

            commit("setPayuChecks",localPayuChecks);
        },
        
    },
    mutations: {
        setPayuCheckOptions(state,options){
            state.PayuCheckOptions = options;
        },
        setPayuChecksLoadStatus(state, status) {
            state.PayuChecksLoadStatus = status;
        },
        setPayuChecks(state, PayuChecks) {
            state.PayuChecks = PayuChecks;
        },
    
        setPayuCheckLineCount(state, lineCount) {
            state.PayuCheckOptions.lineCount = lineCount;
        },
    },

    getters: {
        getPayuCheckOptions(state){
            return state.PayuCheckOptions;
        },
        getPayuChecksLoadStatus(state) {
            return function(){
                return state.PayuChecksLoadStatus;
            }
        },
        getPayuChecks(state) {
            return state.PayuChecks;
        },
    }
};

import IntegrationProductAPI from "../api/integrationProduct";
import i18n from "../main";

export const integrationProducts = {
    state: {
        integrationProducts: [],
        integrationProductsCategories: [],
        integrationProductOptions:{
            lineCount:0,
            orderBy:'created_at',
            orderDirection:'desc',
            itemsPerPage:20,
            itemAdded:false,
            integrationId:0,
            categoryId:undefined
        },


        integrationProductsLoadStatus: 0,
        integrationProductAddStatus: 0,
        integrationProductAddText: "",

        integrationProductEdit: {
            id: 0,
            lp: 0,
            category_id:undefined,
            category_path:undefined,
            external_id:undefined,
            height:undefined,
            length:undefined,
            width:undefined,
            weight:undefined,
            muc:[],
            adr:undefined,
            muc_list:[]
        },
        integrationProductEditDefault: {
            id: 0,
            lp: 0,
            category_id:undefined,
            category_path:undefined,
            external_id:undefined,
            height:undefined,
            length:undefined,
            width:undefined,
            weight:undefined,
            muc:[],
            adr:undefined,
            muc_list:[]
        },
        integrationProductEditLoadStatus: 0,
        integrationProductEditStatus: 0,
        integrationProductEditText: "",

        integrationProductDeleteStatus: 0,
        integrationProductDeleteText: "",

    },

    actions: {
        loadIntegrationProducts({ state, commit, dispatch }) {
            commit("setIntegrationProductsLoadStatus", 1);
                IntegrationProductAPI.getIntegrationProducts({
                    offset:0,
                    itemsPerPage:state.integrationProductOptions.itemsPerPage, 
                    orderBy:state.integrationProductOptions.orderBy,
                    orderDirection:state.integrationProductOptions.orderDirection,
                    integrationId:state.integrationProductOptions.integrationId,
                    categoryId:state.integrationProductOptions.categoryId
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setIntegrationProductLineCount", response.data.lineCount);
                    commit("setIntegrationProducts", response.data.integrationProducts);
                    commit("setIntegrationProductsCategories", response.data.integrationProductsCategories);
                    dispatch("applyIndexOnIntegrationProducts");
                    commit("setIntegrationProductsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setIntegrationProducts", []);
                    commit("setIntegrationProductsLoadStatus", 3);
                });
         
        },
        loadMoreIntegrationProducts({ state, commit, dispatch }) {
            commit("setIntegrationProductsLoadStatus", 1);
                IntegrationProductAPI.getIntegrationProducts({
                    offset:state.integrationProducts.length,
                    itemsPerPage:state.integrationProductOptions.itemsPerPage, 
                    orderBy:state.integrationProductOptions.orderBy,
                    orderDirection:state.integrationProductOptions.orderDirection,
                    integrationId:state.integrationProductOptions.integrationId,
                    categoryId:state.integrationProductOptions.categoryId
                })
                .then(function(response) {
                    commit("setIntegrationProductLineCount", response.data.lineCount);
                    commit("setIntegrationProducts", _.concat(state.integrationProducts, response.data.integrationProducts));
                    dispatch("applyIndexOnIntegrationProducts");
                    commit("setIntegrationProductsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setIntegrationProducts", []);
                    commit("setIntegrationProductsLoadStatus", 3);
                });
         
        },
        searchIntegrationProducts({ state, commit, dispatch },data) {
            commit("setIntegrationProductsLoadStatus", 1);
                IntegrationProductAPI.searchIntegrationProducts({
                    integrationId:state.integrationProductOptions.integrationId,
                    categoryId:state.integrationProductOptions.categoryId,
                    filterableHeaders:data.filterableHeaders,
                    q:data.q
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setIntegrationProductSearchedTrue");
                    commit("setIntegrationProductLineCount", response.data.lineCount);
                    commit("setIntegrationProducts", response.data.integrationProducts);
                    dispatch("applyIndexOnIntegrationProducts");
                    commit("setIntegrationProductsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setIntegrationProducts", []);
                    commit("setIntegrationProductsLoadStatus", 3);
                });
         
        },
        setIntegrationProductOptions({ state, commit, dispatch },data){
            commit("setIntegrationProductOptions",data.options);
            dispatch("loadIntegrationProducts");
        },
        applyIndexOnIntegrationProducts({ commit, state, dispatch }) {
            let localIntegrationProducts = state.integrationProducts;

            var i = 1;
            localIntegrationProducts.forEach(integrationProduct => {
                // if(_.isNumber(integrationProduct.status)){
                //     integrationProduct.status = _.find(state.integrationProductStatus, { 'value': integrationProduct.status }).text;
                // }
                integrationProduct.lp = i;
                i++;
            });

            commit("setIntegrationProducts",localIntegrationProducts);
        },
        updateIntegrationProducts({ commit, state, dispatch }, data) {
            commit("setIntegrationProductAddStatus", 1);
            commit("setIntegrationProductAddText", "");

            IntegrationProductAPI.getUpdateIntegrationProducts(
                data.slug,
                data.options,
                data.count
            )
                .then(function(response) {
                    commit("setIntegrationProductAddStatus", response.data.updateCount>-1?4:2);
                    //dispatch("loadIntegrationProducts");
                    //commit("setAddedIntegrationProduct",response.data);
                    commit("setIntegrationProductUpdateCount", response.data.updateCount);
                    commit("setIntegrationProductDeliveryMethods", response.data.deliveryMethods);
                    dispatch("applyIndexOnIntegrationProducts");
                })
                .catch(function(error) {
                    commit("setIntegrationProductAddStatus", 3);
                    commit("setIntegrationProductAddText", error.response.data.errors);
                });
        },
        loadIntegrationProductEdit({ commit }, data) {
            commit("setIntegrationProductEditLoadStatus", 1);
            commit("setIntegrationProductEditStatus", 0);
            commit("setIntegrationProductEditText", "");

            IntegrationProductAPI.getIntegrationProductEdit(data.slug)
                .then(function(response) {
                    commit("setIntegrationProductEdit", response.data);
                    commit("setIntegrationProductEditLoadStatus", 2);
                })
                .catch(function() {
                    commit("setIntegrationProductEdit", {});
                    commit("setIntegrationProductEditLoadStatus", 3);
                });
        },
        loadIntegrationProductEditMucList({ commit }) {
            commit("setIntegrationProductEditLoadStatus", 1);
            commit("setIntegrationProductEditStatus", 0);
            commit("setIntegrationProductEditText", "");

            IntegrationProductAPI.getIntegrationProductEditMucList()
                .then(function(response) {
                    commit("setIntegrationProductEditMucList", response.data);
                    commit("setIntegrationProductEditLoadStatus", 2);
                })
                .catch(function() {
                    commit("setIntegrationProductEdit", {});
                    commit("setIntegrationProductEditLoadStatus", 3);
                });
        },
        loadIntegrationProductEditDefault({ commit }) {
            commit("setIntegrationProductEditDefault");
        },
        loadIntegrationProductAddDefault({ commit }) {
            commit("setIntegrationProductAddStatus", 0);
            commit("setIntegrationProductAddText", "");
            commit("setIntegrationProductEditStatus", 0);
            commit("setIntegrationProductEditText", "");
        },
        editIntegrationProduct({ commit, state, dispatch }, data) {
            commit("setIntegrationProductEditStatus", 1);

            IntegrationProductAPI.putEditIntegrationProduct(
                data.slug,
                data.height,
                data.length,
                data.width,
                data.weight,
                data.muc,
                data.adr
            )
                .then(function(response) {
           
                    commit("setIntegrationProductEditStatus", 2);
                    commit("setEditedIntegrationProduct",response.data)
                    dispatch("applyIndexOnIntegrationProducts");
                })
                .catch(function(error) {
                    commit("setIntegrationProductEditStatus", 3);
                    commit("setIntegrationProductEditText", error.response.data.errors);
                });
        },
        editIntegrationProducts({ commit, state, dispatch }, data) {
            commit("setIntegrationProductEditStatus", 1);

            IntegrationProductAPI.putEditIntegrationProducts(
                data.slug,
                data.height,
                data.length,
                data.width,
                data.weight,
                data.muc,
                data.adr
            )
                .then(function(response) {
           
                    commit("setIntegrationProductEditStatus", 2);
                    dispatch("loadIntegrationProducts");
                    //commit("setEditedIntegrationProduct",response.data)
                    //dispatch("applyIndexOnIntegrationProducts");
                })
                .catch(function(error) {
                    commit("setIntegrationProductEditStatus", 3);
                    commit("setIntegrationProductEditText", error.response.data.errors);
                });
        },
        
        forceDeleteIntegrationProduct({ commit, state, dispatch }, data) {
            commit("setIntegrationProductDeleteStatus", 1);

            IntegrationProductAPI.forceDeleteIntegrationProduct(data.slug)
                .then(function(response) {
                    commit("setIntegrationProductDeleteStatus", 2);
                    commit("setIntegrationProductLineCount", state.integrationProductOptions.lineCount - 1);
                    commit("setDeletedIntegrationProduct",response.data)
                    dispatch("applyIndexOnIntegrationProducts");
                })
                .catch(function() {
                    commit("setIntegrationProductDeleteStatus", 3);
                });
        },
    },
    mutations: {
        setIntegrationProductLoadStatus(state, status) {
            state.integrationProductLoadStatus = status;
        },
        setIntegrationProduct(state, integrationProduct) {
            state.integrationProduct = integrationProduct;
        },
        //#############
        setIntegrationProductOptions(state,options){
            state.integrationProductOptions = options;
        },
        setIntegrationProductsLoadStatus(state, status) {
            state.integrationProductsLoadStatus = status;
        },
        setIntegrationProducts(state, integrationProducts) {
            state.integrationProducts = integrationProducts;
        },
        setIntegrationProductsCategories(state, integrationProductsCategories) {
            state.integrationProductsCategories = integrationProductsCategories;
        },
        setIntegrationProductLineCount(state, lineCount) {
            state.integrationProductOptions.lineCount = lineCount;
        },
        setIntegrationProductUpdateCount(state, updateCount) {
            state.integrationProductOptions.updateCount = updateCount;
        },
        setIntegrationProductDeliveryMethods(state, deliveryMethods) {
            state.integrationProductOptions.deliveryMethods = deliveryMethods;
        },
        setIntegrationProductAddStatus(state, status) {
            state.integrationProductAddStatus = status;
        },
        setIntegrationProductAddText(state, text) {
            state.integrationProductAddText = text;
        },
        setIntegrationProductEdit(state, integrationProduct) {
            state.integrationProductEdit = integrationProduct;
            if(state.integrationProductEdit.weight)state.integrationProductEdit.weight = i18n.n(state.integrationProductEdit.weight, 'number', 'pl-PL');

            //state.integrationProductEdit.muc_list.forEach((value, index, self) => {
                //if(!integrationProduct.muc[index]){
                //    state.integrationProductEdit.muc[index] = null;
                //}
            //});
        },
        setIntegrationProductEditMucList(state, integrationProduct) {
            state.integrationProductEdit.muc_list = integrationProduct.muc_list;
        },
        setEditedIntegrationProduct(state, integrationProduct) {
            var lp = _.find(state.integrationProducts,{'id':integrationProduct.id}).lp;
            var index = _.findIndex(state.integrationProducts,{'id':integrationProduct.id});
            
            state.integrationProducts[index] = integrationProduct;
            state.integrationProducts[index].lp = lp;
        },
        setAddedIntegrationProduct(state, integrationProduct) {
            state.integrationProductOptions.itemAdded = true;
            state.integrationProducts.push(integrationProduct);
        },
        setIntegrationProductSearchedTrue(state) {
            state.integrationProductOptions.itemSearched = true;
        },
        setOptionsItemAddedAndSearchedFalse(state) {
            state.integrationProductOptions.itemAdded = false;
            state.integrationProductOptions.itemSearched = false;
        },
        setDeletedIntegrationProduct(state, integrationProduct) {
            var index = _.findIndex(state.integrationProducts,{'id':integrationProduct.id});
            state.integrationProducts.splice(index,1);
        },
        setIntegrationProductEditDefault(state) {
            state.integrationProductEdit =  JSON.parse(JSON.stringify(state.integrationProductEditDefault)); //{ ...state.integrationProductEditDefault };
        },
        setIntegrationProductEditStatus(state, status) {
            state.integrationProductEditStatus = status;
        },
        setIntegrationProductEditText(state, text) {
            state.integrationProductEditText = text;
        },
        setIntegrationProductEditLoadStatus(state, status) {
            state.integrationProductEditLoadStatus = status;
        },
        setIntegrationProductDeleteStatus(state, status) {
            state.integrationProductDeleteStatus = status;
        },
        setIntegrationProductDeletedText(state, text) {
            state.integrationProductDeleteText = text;
        }
    },

    getters: {
        getIntegrationProductLoadStatus(state) {
            return state.integrationProductLoadStatus;
        },
        getIntegrationProduct(state) {
            return state.integrationProduct;
        },
        getIntegrationProductOptions(state){
            return state.integrationProductOptions;
        },
        getIntegrationProductsLoadStatus(state) {
            return state.integrationProductsLoadStatus;
        },
        getIntegrationProducts(state) {
            return state.integrationProducts;
        },
        getIntegrationProductsCategories(state) {
            return state.integrationProductsCategories;
        },
        getIntegrationProductAddStatus(state) {
            return function(){
                return state.integrationProductAddStatus;
            } 
        },
        getIntegrationProductAddText(state) {
            return state.integrationProductAddText;
        },
        getIntegrationProductEdit(state) {
            return state.integrationProductEdit;
        },
        getIntegrationProductEditStatus(state) {
          return function(){
            return state.integrationProductEditStatus;
          }
        },
        getIntegrationProductEditText(state) {
            return state.integrationProductEditText;
        },
        getIntegrationProductEditLoadStatus(state) {
            return state.integrationProductEditLoadStatus;
            
        },
        getIntegrationProductDeleteStatus(state) {
            return function(){
                return state.integrationProductDeleteStatus;
            }
        },
        getIntegrationProductDeleteText(state) {
            return state.integrationProductDeleteText;
        }
    }
};

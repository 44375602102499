import GroupAPI from "../api/group";

export const groups = {
    state: {
        groups: [],
        groupOptions:{
            lineCount:0,
            showDeleted:false,
            orderBy:'name',
            orderDirection:'asc',
            itemsPerPage:10,
            itemAdded:false,
            itemSearched:false
        },
        groupsLoadStatus: 0,
        groupAddStatus: 0,
        groupAddText: "",

        groupEdit: {
            id: 0,
            lp: 0,
            name: '',
            order:0,
            shipment:0,
            document:0,
            desktop:0,
            dictionary:0,
            setting:0,
        },
        groupEditDefault: {
            id: 0,
            lp: 0,
            name: '',
            order:0,
            shipment:0,
            document:0,
            desktop:0,
            dictionary:0,
            setting:0,
        },
        groupEditLoadStatus: 0,
        groupEditStatus: 0,
        groupEditText: "",

        groupDeleteStatus: 0,
        groupDeleteText: "",

    },

    actions: {
        loadGroups({ state, commit, dispatch }) {
            commit("setGroupsLoadStatus", 1);
                GroupAPI.getGroups({
                    offset:0,
                    itemsPerPage:state.groupOptions.itemsPerPage, 
                    orderBy:state.groupOptions.orderBy,
                    orderDirection:state.groupOptions.orderDirection,
                    showDeleted:state.groupOptions.showDeleted,
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setGroupLineCount", response.data.lineCount);
                    commit("setGroups", response.data.groups);
                    dispatch("applyIndexOnGroups");
                    commit("setGroupsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setGroups", []);
                    commit("setGroupsLoadStatus", 3);
                });
         
        },
        loadMoreGroups({ state, commit, dispatch }) {
            commit("setGroupsLoadStatus", 1);
                GroupAPI.getGroups({
                    offset:state.groups.length,
                    itemsPerPage:state.groupOptions.itemsPerPage, 
                    orderBy:state.groupOptions.orderBy,
                    orderDirection:state.groupOptions.orderDirection,
                    showDeleted:state.groupOptions.showDeleted,
                })
                .then(function(response) {
                    commit("setGroupLineCount", response.data.lineCount);
                    commit("setGroups", _.concat(state.groups, response.data.groups));
                    dispatch("applyIndexOnGroups");
                    commit("setGroupsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setGroups", []);
                    commit("setGroupsLoadStatus", 3);
                });
         
        },
        searchGroups({ state, commit, dispatch },data) {
            commit("setGroupsLoadStatus", 1);
                GroupAPI.searchGroups({
                    showDeleted:state.groupOptions.showDeleted,
                    filterableHeaders:data.filterableHeaders,
                    q:data.q
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setGroupSearchedTrue");
                    commit("setGroupLineCount", response.data.lineCount);
                    commit("setGroups", response.data.groups);
                    dispatch("applyIndexOnGroups");
                    commit("setGroupsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setGroups", []);
                    commit("setGroupsLoadStatus", 3);
                });
         
        },
        setGroupOptions({ state, commit, dispatch },data){
            commit("setGroupOptions",data.options);
            dispatch("loadGroups");
        },
        applyIndexOnGroups({ commit, state, rootState }) {
            let localGroups = state.groups;

            var i = 1;
            localGroups.forEach(group => {
                if(_.isNumber(group.desktop)){
                    group.order = _.find(rootState.lookups.permissions, { 'value': group.order }).text;
                    group.shipment = _.find(rootState.lookups.permissions, { 'value': group.shipment }).text;
                    group.document = _.find(rootState.lookups.permissions, { 'value': group.document }).text;
                    group.desktop = _.find(rootState.lookups.permissions, { 'value': group.desktop }).text;
                    group.dictionary = _.find(rootState.lookups.permissions, { 'value': group.dictionary }).text;
                    group.setting = _.find(rootState.lookups.permissions, { 'value': group.setting }).text;
                }
                group.lp = i;
                i++;
            });

            commit("setGroups",localGroups);
        },
        addGroup({ commit, state, dispatch }, data) {
            commit("setGroupAddStatus", 1);
            commit("setGroupAddText", "");

            GroupAPI.postAddNewGroup(
                data.name,
                data.order,
                data.shipment,
                data.document,
                data.desktop,
                data.dictionary,
                data.setting,
            )
                .then(function(response) {
                    commit("setGroupAddStatus", 2);
                    commit("setAddedGroup",response.data);
                    commit("setGroupLineCount", state.groupOptions.lineCount + 1);
                    
                    dispatch("loadGroupLookup");
                    dispatch("applyIndexOnGroups");
                })
                .catch(function(error) {
                    commit("setGroupAddStatus", 3);
                    commit("setGroupAddText", error.response.data.errors);
                });
        },

        loadGroupEdit({ commit }, data) {
            commit("setGroupEditLoadStatus", 1);
            commit("setGroupEditStatus", 0);
            commit("setGroupEditText", "");

            GroupAPI.getGroupEdit(data.slug)
                .then(function(response) {
                    commit("setGroupEdit", response.data);
                    commit("setGroupEditLoadStatus", 2);
                })
                .catch(function() {
                    commit("setGroupEdit", {});
                    commit("setGroupEditLoadStatus", 3);
                });
        },
        loadGroupEditDefault({ commit }) {
            commit("setGroupEditDefault");
        },
        loadGroupAddDefault({ commit }) {
            commit("setGroupAddStatus", 0);
            commit("setGroupAddText", "");
        },
        editGroup({ commit, state, dispatch }, data) {
            commit("setGroupEditStatus", 1);

            GroupAPI.putEditGroup(
                data.slug,
                data.name,
                data.order,
                data.shipment,
                data.document,
                data.desktop,
                data.dictionary,
                data.setting,
            )
                .then(function(response) {
           
                    commit("setGroupEditStatus", 2);
                    commit("setEditedGroup",response.data)
                    dispatch("applyIndexOnGroups");
                    dispatch("loadGroupLookup");
                })
                .catch(function(error) {
                    commit("setGroupEditStatus", 3);
                    commit("setGroupEditText", error.response.data.errors);
                });
        },
        editGroupReactivate({ commit, state, dispatch }, data) {
            commit("setGroupEditStatus", 1);
  
            GroupAPI.putReactivateGroup(data.slug)
                .then(function(response) {
                    commit("setGroupEditStatus", 2);
                    commit("setEditedGroup",response.data);
                    
                    dispatch("loadGroupLookup");
                    dispatch("applyIndexOnGroups");
                })
                .catch(function(error) {
                    commit("setGroupEditStatus", 3);
                });
        },
        deleteGroup({ commit, state, dispatch }, data) {
            commit("setGroupDeleteStatus", 1);

            GroupAPI.deleteGroup(data.slug)
                .then(function(response) {
                    commit("setGroupDeleteStatus", 2);
                    if(state.groupOptions.showDeleted){
                        commit("setEditedGroup",response.data)
                    }else{
                        commit("setGroupLineCount", state.groupOptions.lineCount - 1);
                        commit("setDeletedGroup",response.data) 
                    }
                    
                    dispatch("loadGroupLookup");
                    dispatch("applyIndexOnGroups");
                })
                .catch(function() {
                    commit("setGroupDeleteStatus", 3);
                });
        },
        forceDeleteGroup({ commit, state, dispatch }, data) {
            commit("setGroupDeleteStatus", 1);

            GroupAPI.forceDeleteGroup(data.slug)
                .then(function(response) {
                    commit("setGroupDeleteStatus", 2);
                    commit("setGroupLineCount", state.groupOptions.lineCount - 1);
                    commit("setDeletedGroup",response.data)
                    dispatch("applyIndexOnGroups");
                })
                .catch(function() {
                    commit("setGroupDeleteStatus", 3);
                });
        },
    },
    mutations: {
        setGroupOptions(state,options){
            state.groupOptions = options;
        },
        setGroupsLoadStatus(state, status) {
            state.groupsLoadStatus = status;
        },
        setGroups(state, groups) {
            state.groups = groups;
        },
        setGroupLineCount(state, lineCount) {
            state.groupOptions.lineCount = lineCount;
        },
        setGroupAddStatus(state, status) {
            state.groupAddStatus = status;
        },
        setGroupAddText(state, text) {
            state.groupAddText = text;
        },
        setGroupEdit(state, group) {
            state.groupEdit = group;
        },
        setEditedGroup(state, group) {
            var lp = _.find(state.groups,{'id':group.id}).lp;
            var index = _.findIndex(state.groups,{'id':group.id});
            state.groups[index] = group;
            state.groups[index].lp = lp;
        },
        setAddedGroup(state, group) {
            state.groupOptions.itemAdded = true;
            state.groups.push(group);
        },
        setGroupSearchedTrue(state) {
            state.groupOptions.itemSearched = true;
        },
        setOptionsItemAddedAndSearchedFalse(state) {
            state.groupOptions.itemAdded = false;
            state.groupOptions.itemSearched = false;
        },
        setDeletedGroup(state, group) {
            var index = _.findIndex(state.groups,{'id':group.id});
            state.groups.splice(index,1);
        },
        setGroupEditDefault(state) {
            state.groupEdit = { ...state.groupEditDefault };
        },
        setGroupEditStatus(state, status) {
            state.groupEditStatus = status;
        },
        setGroupEditText(state, text) {
            state.groupEditText = text;
        },
        setGroupEditLoadStatus(state, status) {
            state.groupEditLoadStatus = status;
        },
        setGroupDeleteStatus(state, status) {
            state.groupDeleteStatus = status;
        },
        setGroupDeletedText(state, text) {
            state.groupDeleteText = text;
        }
    },

    getters: {
        getGroupOptions(state){
            return state.groupOptions;
        },
        getGroupsLoadStatus(state) {
            return state.groupsLoadStatus;
        },
        getGroups(state) {
            return state.groups;
        },
        getGroupAddStatus(state) {
            return function(){
                return state.groupAddStatus;
            } 
        },
        getGroupAddText(state) {
            return state.groupAddText;
        },
        getGroupEdit(state) {
            return state.groupEdit;
        },
        getGroupEditStatus(state) {
          return function(){
            return state.groupEditStatus;
          }
        },
        getGroupEditing(state) {
            return state.groupEdit.id > 0;
        },
        getGroupEditText(state) {
            return state.groupEditText;
        },
        getGroupEditLoadStatus(state) {
            return state.groupEditLoadStatus;
            
        },
        getGroupDeleteStatus(state) {
            return function(){
                return state.groupDeleteStatus;
            }
        },
        getGroupDeleteText(state) {
            return state.groupDeleteText;
        }
    }
};

<template>
                  <v-card  :loading="accountEditLoadStatus===1 || accountEditStatus===1">
                    <v-card-title>
                      <img class="mr-3" src="/logo_listek.png" />
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        
                          <v-row>                   
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="editedItem.nip" :error-messages="nipErrors" required @input="$v.editedItem.nip.$touch()"  @blur="$v.editedItem.nip.$touch()" label="Numer NIP"></v-text-field>                            
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="editedItem.regon" :error-messages="regonErrors" required @input="$v.editedItem.regon.$touch()"  @blur="$v.editedItem.regon.$touch()" label="Nr REGON"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="editedItem.krs" :error-messages="krsErrors" required @input="$v.editedItem.krs.$touch()"  @blur="$v.editedItem.krs.$touch()" label="Nr KRS"></v-text-field>
                            </v-col>
                          </v-row>
                        
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="gray" text @click="close()">Anuluj</v-btn>
                      <v-btn color="primary darken-1" text @click="handleSubmit" >Zapisz</v-btn>
                    </v-card-actions>
                  </v-card>
</template>

<script>
  import required from 'vuelidate/lib/validators/required'
  import minLength from 'vuelidate/lib/validators/minLength'
  import maxLength from 'vuelidate/lib/validators/maxLength'
  import numeric from 'vuelidate/lib/validators/numeric'
  import email from 'vuelidate/lib/validators/email'

  export default {
    data: () => ({
    
    }),
    props:['itemEditing'],
    validations: {
      editedItem:{
        nip: {
          required,
        },
        regon:{
          required
        },
        krs:{
          required
        },
      }
    },
    watch: {
    
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      editedItem(){
        return this.$store.getters.getAccountEdit;
      },
      accountEditLoadStatus(){
        return this.$store.getters.getAccountEditLoadStatus
      },
      accountEditStatus(){
        return this.$store.getters.getAccountEditStatus();
      },
      accountEditText(){
        return this.$store.getters.getAccountEditText
      },
      accountAddStatus(){
        return this.$store.getters.getAccountAddStatus();
      },
      accountAddText(){
        return this.$store.getters.getAccountAddText;
      },
    
      formTitle () {
        return !this.itemEditing ? 'Nowe konto' : 'Edycja konta'
      },
      nipErrors () {
        const errors = []
        if (!this.$v.editedItem.nip.$dirty) return errors
        !this.$v.editedItem.nip.required && errors.push('NIP jest wymagany')
        return errors
      },
      regonErrors () {
        const errors = []
        if (!this.$v.editedItem.regon.$dirty) return errors
        !this.$v.editedItem.regon.required && errors.push('REGON jest wymagany')
        return errors
      },
      krsErrors () {
        const errors = []
        if (!this.$v.editedItem.krs.$dirty) return errors
        !this.$v.editedItem.krs.required && errors.push('KRS jest wymagany')
        return errors
      },
    },
 
    methods: {
      close(){
        this.$v.$reset();
        this.$emit('close');
      },
       handleSubmit () {
        this.$v.$touch()
        if (this.$v.$invalid) {

        } else {
            if (this.itemEditing) {
              this.$store.dispatch( 'editAccount', {
                slug: this.editedItem.id,
                nip:  this.editedItem.nip,
                regon:  this.editedItem.regon,
                krs:  this.editedItem.krs,
                logo: this.editedItem.logo
              });
            }
        }
      },
    },
  }
</script>


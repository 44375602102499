<template>
          <v-sheet flat>

          <v-snackbar
            v-model="integrationOrderSnackbar"
            timeout="5000"
            top
          >
            {{integrationOrderEditText}}
          </v-snackbar>

          <!-- <v-snackbar
            v-model="integrationTaskSnackbar"
            timeout="5000"
            top
          >
            {{integrationTaskResponseText}}
            
            <template v-slot:action="{ attrs }">
              <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="openTaskLog"
              >
                Szczegóły
              </v-btn>
            </template>
          </v-snackbar> -->

          <v-card flat :loading="integrationOrderEditStatus===1 || integrationOrderLabelLoadStatus===1 || integrationTaskRunningStatus===1">
            <v-toolbar flat color="white">
                    <v-toolbar-title>{{independentItem?'Nowe niezależne zamówienie':''}}</v-toolbar-title>
                    <v-toolbar-title>{{editedItem.order_date}}</v-toolbar-title>

                    <v-divider
                    v-if="editedItem.order_date"
                    class="mx-4"
                    inset
                    vertical
                    ></v-divider>
                    <v-toolbar-title>{{editedItem.buyer.contact_name}}</v-toolbar-title>

                    <v-divider
                    v-if="editedItem.amount"
                    class="mx-4"
                    inset
                    vertical
                    ></v-divider>
                    <v-toolbar-title v-if="editedItem.amount">{{$n(editedItem.amount,'number','pl-PL')}} {{editedItem.amount_currency}}</v-toolbar-title>

                    <v-chip v-if="editedItem.amount_paid && editedItem.amount_paid!=editedItem.amount" dense color="error" class="ml-2">
                        Klient zapłacił {{$n(editedItem.amount_paid,'number','pl-PL')}} {{editedItem.amount_currency}}
                    </v-chip>   
                    <v-spacer></v-spacer>

                    <v-menu
                        v-model="orderActionsMenu"
                        :close-on-content-click="false"
                        offset-y
                        
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" small color="red" dark>
                              Akcje
                          </v-btn>
                        </template>
                        <v-card>
                            <v-row class="px-5">
                              <v-list>
                                <v-list-item @click="refreshOrderAction">
                                  <v-list-item-title>Aktualizuj zamówienie</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="reloadOrderAction">
                                  <v-list-item-title>Pobierz zamówienie (tworzy zaliczkę)</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="reloadShipmentsOrderAction">
                                  <v-list-item-title>Pobierz wysyłki i utwórz storna</v-list-item-title>
                                </v-list-item>
                                <!-- <v-list-item v-if="tableItemsSelected.length>0" @click="selectedCorrection">
                                  <v-list-item-title>Wybrane wiersze</v-list-item-title>
                                </v-list-item>
                                <v-list-item v-if="unsendItems.length>0" @click="unsendCorrection">
                                  <v-list-item-title>Niewysłane pozycje</v-list-item-title>
                                </v-list-item>
                                <v-list-item v-for="s in editedItem.shipments" :key="s.id" @click="shipmentCorrection(s.id)">
                                  <v-list-item-title>Wysyłka nr {{s.ext_shipment_id}}</v-list-item-title>
                                </v-list-item> -->
                              </v-list>
                            </v-row>
                            <!-- <v-row>
                               <v-menu
                                          v-model="selectedDayMenu"
                                          :close-on-content-click="false"
                                          :nudge-right="40"
                                          transition="scale-transition"
                                          offset-y
                                          min-width="150px"
                                        >
                                          <template v-slot:activator="{ on }">
                                            <v-text-field
                                              v-model="correctionDate"
                                              readonly
                                              v-on="on"
                                              label="Data wystawienia"
                                              hide-details
                                            ></v-text-field>
                                          </template>
                                          <v-date-picker no-title first-day-of-week="1" v-model="correctionDate" @input="selectedDayMenu = false"></v-date-picker>
                              </v-menu>
                           
                            </v-row> -->
                        </v-card>
                        
                    </v-menu>

                    <v-divider
                    class="mx-4"
                    inset
                    vertical
                    ></v-divider>

                    <v-tooltip v-if="editedItem.status" left>
                      <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            size="42"
                            :color="_.find(lookups.integrationOrderStatus,{'value':editedItem.status}).color"
                          >
                            {{_.find(lookups.integrationOrderStatus,{'value':editedItem.status}).icon}}
                        </v-icon>
                      </template>
                      <span>{{_.find(lookups.integrationOrderStatus,{'value':editedItem.status}).text}}</span>
                    </v-tooltip>
                    
                    
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col class="col-9" v-if="!independentItem">
                    <v-card class="mb-5" v-if="editedItem.order.length===0">
                        <v-toolbar flat dense color="white">
                            <v-toolbar-title>Zamówienie</v-toolbar-title>
                        </v-toolbar>
                          <v-container class="pt-0">
                           <v-row>
                            <v-col class="col-2"><v-text-field :readonly="editedItem.Closed" filled dense single-line hide-details="auto" v-model="editedItem.amount" label="Kwota zamówienia"></v-text-field></v-col>
                            <v-col class="col-1"><v-text-field :readonly="editedItem.Closed" filled dense single-line hide-details="auto" v-model="editedItem.amount_currency" label="Waluta"></v-text-field></v-col>
                            <v-col class="col-6"></v-col>
                            <v-col class="col-3"><v-text-field :readonly="editedItem.Closed" filled dense single-line hide-details="auto" v-model="editedItem.buyer.buyer_login" label="Kupujący"></v-text-field></v-col>
                          </v-row>
                          </v-container>
                    </v-card>

                    <v-data-table
                        v-for="order in editedItem.order"
                        :key="order.order_id"
                        :hide-default-footer="order.item_list.length<11"
                        :headers="computedOrderHeaders"
                        :items="order.item_list"
                        class="elevation-1 mb-5"
                        dense
                        :item-class="itemRowBackground"
                    >
                      <template v-slot:top>
                      <v-toolbar flat dense color="white">
                          <v-toolbar-title>
                            {{_.find(lookups.integrations,{'value':order.integration_id}).text}} | {{order.order_id}}
                          </v-toolbar-title>
                          <v-toolbar-title class="ml-5" v-if="order.presta_order_id">
                            Presta | {{order.presta_order_id}}
                          </v-toolbar-title>
                          <!-- <v-chip
                              color="tertiary"
                              text-color="white"
                              class="ml-3"
                            >
                              {{order.order_status.text}}
                              <v-icon size="20" @click="refreshOrderStatus(order)" right>
                                mdi-reload
                              </v-icon>
                          </v-chip> -->

                          <v-icon size="28" class="ml-5" color="orange darken-4" v-if="order.invoice_required">
                                mdi-file-document
                          </v-icon>

                          <v-tooltip v-if="order.order_message" right>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                  v-on="on"
                                  size="28"
                                  class="ml-5"
                                  color="blue lighten-3"
                                >
                                  mdi-message-text
                              </v-icon>
                            </template>
                            <span>{{order.order_message}}</span>
                          </v-tooltip>
                        
                          <v-spacer></v-spacer>
                          <v-toolbar-title>{{order.payment_type.text}} {{order.payment_provider?'('+order.payment_provider+')':''}}</v-toolbar-title>
                          <v-divider
                            class="mx-4"
                            inset
                            vertical
                          ></v-divider>
                          <v-toolbar-title>{{order.delivery_method_name}} ({{$n(order.delivery_cost_amount,'number','pl-PL')}} {{order.delivery_cost_currency}})
                              <v-tooltip v-if="editedItem.amount_shipping_paid && editedItem.amount_shipping_paid!=order.delivery_cost_amount" right>
                                <template v-slot:activator="{ on }">
                                    <v-icon
                                      v-on="on"
                                      size="25"
                                      class="mr-2"
                                      color="error"
                                    >
                                      mdi-alert-circle
                                  </v-icon>
                                </template>
                              <span>Klient zapłacił {{$n(editedItem.amount_shipping_paid,'number','pl-PL')}} {{editedItem.amount_currency}}</span>
                            </v-tooltip>
                          </v-toolbar-title>
                      </v-toolbar>
                      </template>
                      <template v-slot:item.selection="{ item }">       
                        <v-simple-checkbox class="mt-0 mb-0 mr-0 ml-0" hide-details="auto" single-line v-model="item.selection" label=""></v-simple-checkbox>
                      </template>
                      <template v-slot:item.item_name="{ item }">       
                       {{item.item_name}}
                      </template>
                      <template v-slot:item.item_price="{ item }">
                        {{$n(item.item_price,'number','pl-PL')}} {{item.item_price_currency}}
                        <v-tooltip v-if="item.hasOwnProperty('item_price_orig') && item.item_price!=item.item_price_orig && item.item_price_net!=item.item_price_net_orig" right>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                  v-on="on"
                                  size="20"
                                  color="error"
                                >
                                  mdi-alert-circle
                              </v-icon>
                            </template>
                            <span>Klient zapłacił {{$n(item.item_price_orig,'number','pl-PL')}} {{item.item_price_currency}}</span>
                        </v-tooltip>
                      </template>
                      <template v-slot:item.item_price_net="{ item }">       
                        {{$n(item.item_price_net,'number','pl-PL')}} {{item.item_price_currency}}
                        <v-tooltip v-if="item.hasOwnProperty('item_price_orig') && item.item_price!=item.item_price_orig && item.item_price_net!=item.item_price_net_orig" right>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                  v-on="on"
                                  size="20"
                                  color="error"
                                >
                                  mdi-alert-circle
                              </v-icon>
                            </template>
                            <span>Klient zapłacił {{$n(item.item_price_net_orig,'number','pl-PL')}} {{item.item_price_currency}}</span>
                        </v-tooltip>
                      </template>
                      <template v-slot:item.item_price_sum="{ item }">       
                        {{$n(item.item_price*item.item_quantity,'number','pl-PL')}} {{item.item_price_currency}}
                      </template>
                      <template v-slot:item.item_tax_rate="{ item }">       
                        {{item.item_tax_rate}}%
                      </template>

                      <template v-slot:item.actions="{ item }">       
                        <!-- <v-btn color="primary" x-small @click="editShipmentItem(item)">Detale</v-btn> -->
                      </template> 
                  </v-data-table>
                  <div class="text-center mb-5" v-if="!_.isEmpty(selectedItems)">
                    <v-btn v-if="!_.isEmpty(selectedItems)"  @click="moveItems()" color="primary" small>Wydziel do nowego zamówienia</v-btn>
                  </div>
                 
                 <v-data-table
                        hide-default-footer
                        :headers="shipmentheaders"
                        :items="editedItem.shipments"
                        class="elevation-1 mb-5"
                        dense
                    >
                      <template v-slot:top>
                      <v-toolbar flat dense color="white">
                          <v-toolbar-title>Wysyłki</v-toolbar-title>
                                <v-dialog v-model="dialogShipments" :max-width="dialogWidth">
                                  <shipmentEditor v-on:close="dialogShipments=false"  />
                                </v-dialog>
                      </v-toolbar>
                      </template>
                      <template v-slot:item.amount="{ item }">
                          {{item.amount?$n(item.amount + (item.carrier.delivery_cost_amount?item.carrier.delivery_cost_amount:0),'number','pl-PL'):''}} {{item.amount?item.amount_currency:''}}
                        </template>
                        <template v-slot:item.delivery_cost_amount="{ item }">
                          {{item.carrier.delivery_cost_amount?$n(item.carrier.delivery_cost_amount,'number','pl-PL'):''}} {{item.carrier.delivery_cost_amount?item.amount_currency:''}}
                        </template>
                        <template v-slot:item.status="{ item }">

                        <v-tooltip right>
                              <template v-slot:activator="{ on }">
                              <v-icon
                                v-on="on"
                                :color="_.find(lookups.integrationShipmentStatus,{'value':item.status}).color"
                              >
                                {{_.find(lookups.integrationShipmentStatus,{'value':item.status}).icon}}
                              </v-icon>
                              </template>
                              <span>{{_.find(lookups.integrationShipmentStatus,{'value':item.status}).text}}</span>
                        </v-tooltip>

                        </template>

                      <template v-slot:item.actions="{ item }">       
                        <v-btn color="primary" x-small @click="editShipmentItem(item)">Detale</v-btn>
                      </template> 
                </v-data-table>

                <v-data-table
                        hide-default-footer
                        :headers="documentHeaders"
                        :items="editedItem.documents"
                        class="elevation-1 mb-5"
                        disable-pagination
                        dense
                    >
                      <template v-slot:top>
                      <v-toolbar flat dense color="white">
                          <v-toolbar-title>Dokumenty</v-toolbar-title>
                                <v-dialog v-model="dialogDocuments" :max-width="dialogWidth">
                                  <documentEditor v-on:close="dialogDocuments=false" @updated="reloadOrder" />
                                </v-dialog>
                      </v-toolbar>
                      </template>
                     <template v-slot:item.type="{ item }">
                        {{_.find(lookups.integrationDocumentTypes, { 'value': item.type }).text}}
                      </template>
                     <template v-slot:item.amount="{ item }">
                        {{item.amount?$n(item.amount,'number','pl-PL'):''}} {{item.amount?item.amount_currency:''}}
                      </template>
                      <template v-slot:item.amount_net="{ item }">
                        {{item.amount_net?$n(item.amount_net,'number','pl-PL'):''}} {{item.amount_net?item.amount_currency:''}}
                      </template>
                      <template v-slot:item.amount_payed="{ item }">
                        {{item.amount_payed?$n(item.amount_payed,'number','pl-PL'):''}} {{item.amount_payed?item.amount_currency:''}}
                      </template>
                      
                      <template v-slot:item.status="{ item }">
                      <v-tooltip right>
                            <template v-slot:activator="{ on }">
                            <v-icon
                              v-on="on"
                              :color="_.find(lookups.integrationDocumentStatus,{'value':item.status}).color"
                            >
                              {{_.find(lookups.integrationDocumentStatus,{'value':item.status}).icon}}
                            </v-icon>
                            </template>
                            <span>{{_.find(lookups.integrationDocumentStatus,{'value':item.status}).text}}</span>
                      </v-tooltip>
                      </template>

                      <template v-slot:item.actions="{ item }">       
                        <v-btn  color="primary" x-small @click="editDocumentItem(item)">Detale</v-btn>
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            size="18"
                            color="red"
                            class="ml-1"
                            @click="deleteDocument(item.id)"
                          >
                            mdi-delete
                          </v-icon>
                          </template>
                          <span>Usuń dokument</span>
                        </v-tooltip>
                      </template> 
                      <template v-if="editedItem.documents.length>0" slot="body.append">
                          <tr class="v-data-table__selected font-weight-medium subheading">
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>Suma</td>
                                  <td>{{ $n(sumField(editedItem.documents,'amount'),'number','pl-PL')}}</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                          </tr>
                      </template>
                </v-data-table>
                
                <v-row class="mt-0" >
                  <v-col class="col-7" v-if="editedItem.logs.length>1">
                      <v-data-table
                        
                        hide-default-footer
                        :headers="logHeaders"
                        :items="editedItem.logs"
                        class="elevation-1 mb-5"
                        dense
                      >
                      <template v-slot:top>
                        <v-toolbar flat dense color="white">
                            <v-toolbar-title>Historia statusów</v-toolbar-title>
                        </v-toolbar>
                      </template>
                      <template v-slot:item.statusIcon="{ item }">
                                <v-icon
                                  :color="_.find(lookups.integrationOrderStatus,{'value':item.status}).color"
                                >
                                  {{_.find(lookups.integrationOrderStatus,{'value':item.status}).icon}}
                                </v-icon>
                      </template>
                      <template v-slot:item.status="{ item }">
                          {{_.find(lookups.integrationOrderStatus,{'value':item.status}).text}}
                      </template>
                      </v-data-table>
                  </v-col>
                  
                </v-row>
                </v-col>
                <v-col class="col-6" v-if="independentItem">
                  <v-card>
                        <v-toolbar flat dense color="white">
                            <v-toolbar-title>Zamówienie</v-toolbar-title>
                        </v-toolbar>
                        <v-container class="pt-0">

                          <v-row>
                            <v-col class="col-8"><v-text-field filled dense single-line hide-details="auto" v-model="editedItem.amount" label="Kwota zamówienia"></v-text-field></v-col>
                            <v-col class="col-4"><v-text-field filled dense single-line hide-details="auto" v-model="editedItem.amount_currency" label="Waluta"></v-text-field></v-col>
                          </v-row>

                        </v-container>
                  </v-card>
                </v-col>
                <v-col :class="independentItem?'col-6':'col-3'">
                  
                    <v-card class="mb-5">
                        <v-toolbar flat dense color="white">
                            <v-toolbar-title>Odbiorca</v-toolbar-title>
                            <v-tooltip v-if="editedItem.buyer.contact_email" right>
                              <template v-slot:activator="{ on }">
                                  <v-icon
                                    v-on="on"
                                    class="pl-2"
                                    @click="doCopy"
                                  >
                                    mdi-email-plus-outline
                                </v-icon>
                              </template>
                              <span>Skopiuj adres e-mail</span>
                            </v-tooltip>
                            <v-spacer></v-spacer>
                            <v-switch v-if="!editedItem.Closed && !this.independentItem" v-model="buyerEdit" class="mt-4"></v-switch>
                        </v-toolbar>
                        <v-container v-if="buyerEdit && !editedItem.Closed"  class="pt-0">
                          <v-row>
                            <v-col class="col-12 pl-3 pr-3 pt-0 pb-2">
                              <v-text-field filled dense single-line hide-details  v-model="editedItem.buyer.delivery_company_name" label="Firma"></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="col-5 pl-3 pr-2 pt-0 pb-2">
                              <v-text-field filled dense single-line hide-details v-model="editedItem.buyer.delivery_first_name" :background-color="getColorIfError('delivery_first_name')"
                              @blur="$v.editedItem.buyer.delivery_first_name.$touch()"  label="Imię"></v-text-field>
                            </v-col>
                            <v-col class="col-7 pl-0 pr-3 pt-0 pb-2">
                              <v-text-field filled dense single-line hide-details v-model="editedItem.buyer.delivery_last_name" :background-color="getColorIfError('delivery_last_name')"
                              @blur="$v.editedItem.buyer.delivery_last_name.$touch()" label="Nazwisko"></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="col-12 pl-3 pr-3 pt-0 pb-2">
                              <v-text-field filled dense single-line hide-details v-model="editedItem.buyer.delivery_street" :background-color="getColorIfError('delivery_street')" 
                                  @blur="$v.editedItem.buyer.delivery_street.$touch()"  label="Ulica"></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="col-3 pl-3 pr-0 pt-0 pb-2">
                              <v-text-field filled dense single-line hide-details v-model="editedItem.buyer.delivery_post_code" :background-color="getColorIfError('delivery_post_code')"  
                                  @blur="$v.editedItem.buyer.delivery_post_code.$touch()" label="Kod pocztowy"></v-text-field>
                            </v-col>
                            <v-col class="col-7 pl-2 pr-2 pt-0 pb-2">
                              <v-text-field filled dense single-line hide-details v-model="editedItem.buyer.delivery_city" :background-color="getColorIfError('delivery_city')"   
                                  @blur="$v.editedItem.buyer.delivery_city.$touch()" label="Miasto"></v-text-field>
                            </v-col>
                            <v-col class="col-2 pl-0 pr-3 pt-0 pb-2">
                              <v-text-field filled dense single-line hide-details v-model="editedItem.buyer.delivery_country_code" :background-color="getColorIfError('delivery_country_code')"   
                                  @blur="$v.editedItem.buyer.delivery_country_code.$touch()" label="Kod kraju"></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row v-if="independentItem || editedItem.order.length===0">
                            <v-col class="col-12 pl-3 pr-3 pt-0 pb-2">
                              <v-text-field filled dense single-line hide-details v-model="editedItem.buyer.contact_email" :background-color="getColorIfError('contact_email')" 
                                  @blur="$v.editedItem.buyer.contact_email.$touch()"  label="Adres e-mail"></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="col-12 pl-3 pr-3 pt-0 pb-2">
                              <v-text-field filled dense single-line hide-details v-model="editedItem.buyer.contact_phone" :background-color="getColorIfError('contact_phone')" 
                                  @blur="$v.editedItem.buyer.contact_phone.$touch()"  label="Numer telefonu"></v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                        <v-container v-else  class="pt-0">
                          <v-row class="pl-5 pr-5 d-flex flex-column">
                            <h2>{{editedItem.buyer.delivery_company_name}}</h2>
                            <h2>{{editedItem.buyer.delivery_first_name}} {{editedItem.buyer.delivery_last_name}}</h2>
                            <h2>{{editedItem.buyer.delivery_street}}</h2>
                            <h2>{{editedItem.buyer.delivery_post_code}} {{editedItem.buyer.delivery_city}} {{editedItem.buyer.delivery_country_code}}</h2>
                            <h2>{{editedItem.buyer.contact_phone}}</h2>
                          </v-row>
                        </v-container>
                      </v-card>

                      <v-card v-for="invoice in computedInvoices" :key="invoice.invoice_post_code">
                        <v-toolbar flat dense color="white">
                            <v-toolbar-title>Faktura</v-toolbar-title>
                        </v-toolbar>
                        <v-container class="pt-0">
                          <v-row class="pl-5 pr-5 d-flex flex-column">
                            <h2>{{invoice.invoice_company_name}}</h2>
                            <h2>{{invoice.invoice_first_name}} {{invoice.invoice_last_name}}</h2>
                            <h2>{{invoice.invoice_street}}</h2>
                            <h2>{{invoice.invoice_post_code}} {{invoice.invoice_city}} {{invoice.invoice_country_code}}</h2>
                            <h2 v-if="invoice.invoice_company_vat">NIP: {{invoice.invoice_company_vat}}</h2>
                          </v-row>
                        </v-container>
                      </v-card>
                      
                      <v-card v-if="$route.name=='payu' || $route.name=='tpay'" color="orange lighten-4">
                        <v-toolbar flat dense color="orange lighten-4">
                            <v-toolbar-title>Kontrola PayU/TPay</v-toolbar-title>
                        </v-toolbar>
                        <v-container class="pt-0">
                          <v-row class="pl-5 pr-5 d-flex flex-column">
                            <h3>Data: {{integrationOrderPayUNote.date}}</h3>
                            <h3>Kwota: {{$n(integrationOrderPayUNote.amount,'number','pl-PL')}} {{integrationOrderPayUNote.currency}}</h3>
                            <h3>Status: {{_.find(lookups.payUCheckStaus,{'value':integrationOrderPayUNote.status}).text}}</h3>
                            <h3>Opis: {{integrationOrderPayUNote.desc}}</h3>
                          </v-row>
                        </v-container>
                      </v-card>

                      <v-card v-if="$route.name=='storn'" color="orange lighten-4">
                        <v-toolbar flat dense color="orange lighten-4">
                            <v-toolbar-title>Kontrola Storn</v-toolbar-title>
                        </v-toolbar>
                        <v-container class="pt-0">
                          <v-row class="pl-5 pr-5 d-flex flex-column">
                            <h3>Data: {{integrationOrderPayUNote.date}}</h3>
                            <h3>Kwota: {{$n(integrationOrderPayUNote.amount,'number','pl-PL')}} {{integrationOrderPayUNote.currency}}</h3>
                            <h3>Status: {{_.find(lookups.stornCheckStaus,{'value':integrationOrderPayUNote.status}).text}}</h3>
                            <h3>Opis: {{integrationOrderPayUNote.desc}}</h3>
                          </v-row>
                        </v-container>
                      </v-card>
                </v-col>
              </v-row>
                

            </v-card-text>
            <v-card-actions>
                          <p class="text--secondary caption ml-5">Ostatnia aktualizacja: {{editedItem.updated_at}}</p>
                          <v-spacer></v-spacer>
                          <v-btn color="grey" text @click="close()">Zamknij</v-btn>

                          <v-btn v-if="independentItem" color="primary darken-1" text @click="handleSubmit">Dodaj</v-btn>
                          <v-btn v-else-if="!editedItem.Closed" color="primary darken-1" text @click="handleSubmit">Zapisz</v-btn>
             </v-card-actions>
            <confirm ref="confirm"></confirm>
          </v-card>
          </v-sheet>
</template>

<script>
  import confirm from '../components/Confirm'
  import required from 'vuelidate/lib/validators/required'
  import email from 'vuelidate/lib/validators/email'
  import integer from 'vuelidate/lib/validators/integer'
  import requiredIf from 'vuelidate/lib/validators/requiredIf'
  import { helpers } from 'vuelidate/lib/validators'
  const decimalPL = helpers.regex('decimalPL', /^[-]?\d*(\,\d+)?$/)
  import shipmentEditor from '../components/IntegrationShipmentEditor'
  import documentEditor from '../components/IntegrationDocumentEditor'

  import i18n from "../main";

  export default {
    components:{
      confirm,
      shipmentEditor,
      documentEditor
    },
    data: () => ({
      integrationOrderSnackbar:false, 
      componentKey:0,
      datePickupMenu:false,
      itemEditing:false,
      bEdit:false,
      orderActionsMenu:false,
      //integrationTaskSnackbar:false,
      //selectedTaskId:undefined,
      //integrationTaskResponseText:'',
      labelPrintMenu:false,
      dialogWidth:1500,
      dialogShipments:false,
      dialogDocuments:false,
      orderHeaders: [
        { text: '', value: 'selection',sortable: false },
        { text: 'Kod',align: 'start', value: 'item_external_id',sortable: true },
        { text: 'Towar',align: 'start', value: 'item_name',sortable: true },
        { text: 'Ilość', value: 'item_quantity',sortable: true },
        { text: 'Cena jedn.', value: 'item_price',sortable: true },
        { text: 'Cena jedn. netto', value: 'item_price_net',sortable: true },
        { text: 'Suma', value: 'item_price_sum',sortable: true },
        { text: 'Stawka VAT', value: 'item_tax_rate',sortable: true },
        { text: 'Wysłanych', value: 'item_quantity_send',sortable: true },
        { text: '', value: 'actions', sortable: false,filterable:false},
      ],
      shipmentheaders: [
        { text: '', value: 'status',sortable:true,filterable:true,filterColumn:"status",visible:true  },
        { text: 'Nr CODIDO', value: 'ext_shipment_id',sortable: true,filterable:true,filterColumn:"ext_shipment_id",visible:true },
        { text: 'Suma wysyłki', value: 'amount',sortable: true,filterable:true,filterColumn:"amount",visible:true },
        { text: 'Koszt dostawy', value: 'delivery_cost_amount',sortable: false,filterable:false,visible:true },
        { text: 'Nr nadania', value: 'shipment_id',sortable:true,filterable:true,filterColumn:"shipment_id",visible:true  },
        { text: 'Data wysłania', value: 'pickup_date',sortable:true,filterable:true,filterColumn:"pickup_date",visible:true  },
        { text: '', value: 'actions', sortable: false,filterable:false,visible:true},
      ],
      documentHeaders: [
        { text: '', value: 'status',sortable:true,filterable:true,filterColumn:"status",visible:true  },
        { text: 'Typ', value: 'type',sortable:true,filterable:true,filterColumn:"type",visible:true  },
        { text: 'Nr Dokumentu', value: 'document_number',sortable: true,filterable:true,filterColumn:"document_number",visible:true },
        { text: 'Data wystawienia', value: 'document_date',sortable: true,filterable:true,filterColumn:"document_date",visible:true },
        { text: 'Kwota dokumentu', value: 'amount',sortable: true,filterable:true,filterColumn:"amount",visible:true },
        { text: 'Kwota opłacona', value: 'amount_payed',sortable: true,filterable:true,filterColumn:"amount_payed",visible:true },
        { text: 'Informacja', value: 'status_message', sortable: false,filterable:false,visible:true},
        { text: '', value: 'actions', sortable: false,filterable:false,visible:true},
      ],
      logHeaders: [
        { text: '',align: 'start', value: 'statusIcon',sortable: false },
        { text: 'Data',align: 'start', value: 'created_at',sortable: false },
        { text: 'Status', value: 'status',sortable: false },
        { text: '', value: 'status_message',sortable:false},
      ],
    }),
    validations(){
      return this.inputValidation;
    },
    watch: {
      integrationOrderEditStatus(val){
        if(val===2){
          this.$emit('updated');
        }else if(val===3){
          this.integrationOrderSnackbar = true;
          //this.dialog = false;
        }
      },
      integrationDocumentDeleteStatus(val){
        if(val===2){
          this.reloadOrder();
        }
      },
       integrationShipmentEditLoadStatus(val){
        if(val===2){
          this.dialogShipments = true;
        }
      },
      integrationDocumentEditLoadStatus(val){
        if(val===2){
          this.dialogDocuments = true;
        }
      },
      integrationTaskRunningStatus(val){
        if(val===2){
          // if(this.taskLogItem.status===1){
          //   this.integrationTaskResponseText = 'Akcja zakończona sukcesem'
          // }else{
          //   this.integrationTaskResponseText = 'Akcja zakończona błędem'
          // }
          //  this.integrationTaskSnackbar = true;
          // this.dialogDocuments = false;
          // this.$store.dispatch( 'loadIntegrationOrderEdit', {slug:  this.editedItem.id});  
          this.reloadOrder();
        }
      },
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      lookups(){
        return this.$store.getters.getLookups;
      },
      editedItem(){
        return this.$store.getters.getIntegrationOrderEdit;
      },
      carrierIntegrations(){
        return _.filter(this.lookups.integrations,{'type':2})
      },
      // taskLogItem(){
      //   return this.$store.getters.getIntegrationTaskLog;
      // },
      buyerEdit:{
        get () {  
          return this.bEdit || this.independentItem;
        },
        set (value) {
          this.bEdit = value;
        }
      },
      inputValidation(){
        let validationObject = {
          editedItem:{
          buyer:{
            delivery_first_name:{
              required: requiredIf(function (nestedModel) {
                return !this.editedItem.buyer.delivery_company_name;
              })
            },
             delivery_last_name:{
              required: requiredIf(function (nestedModel) {
                return !this.editedItem.buyer.delivery_company_name;
              })
            },
            delivery_city:{required},
            delivery_post_code:{required},
            delivery_street:{required},
            delivery_country_code:{required},
            contact_email:{required},
            contact_phone:{required}
          },
          carrier:{
            service_list:{

            },
            settings:{
              pickup_date:{required}
            }
          },
          pickup_list:{
            $each:{
              type:{required},
              length:{required,integer},
              width:{required,integer},
              height:{required,integer},
              quantity:{required,integer},
              weight:{required,decimalPL}
            }
          }
        }}

        if(this.independentItem)validationObject.editedItem.carrier = {}
  
        var i = 0;
        if(this.independentItem || !this.editedItem.carrier) return validationObject;

        this.editedItem.carrier.service_list.forEach((service_value,service_index) => {
          //console.log(service_value.parameters.length);
           let serviceIndex = service_index;
           let serviceValue = service_value;

           let ob = {parameters:{}};
            _.forIn(serviceValue.parameters, function(value, key, object) {
              if(value.required && value.type=='float'){
                 ob.parameters[key] = {value:{required, decimalPL}}
              }else if(value.required && value.type=='number'){
                 ob.parameters[key] = {value:{required, integer}}
              }else if(value.required){
                 ob.parameters[key] = {value:{required}}
              }

            });
            if(serviceValue.selected)validationObject.editedItem.carrier.service_list[serviceIndex] = ob;
        });

        return validationObject;
      },
      computedInvoices(){
        var invoices = [];
        this.editedItem.order.forEach(o => {
          if(o.invoice_required)invoices.push({
             "invoice_company_name":o.invoice_company_name,
             "invoice_first_name":o.invoice_first_name,
             "invoice_last_name":o.invoice_last_name,
             "invoice_street":o.invoice_street,
             "invoice_post_code":o.invoice_post_code,
             "invoice_city":o.invoice_city,
             "invoice_country_code":o.invoice_country_code,
             "invoice_company_vat":o.invoice_company_vat
          });
        });
        return _.uniqWith(invoices, _.isEqual);
      },
      computedOrderHeaders(){
        if(this.editedItem.integration_id || this.editedItem.total_item_line_count<2)return _.filter(this.orderHeaders,function(o) { return o.value!='selection'; })
        else return this.orderHeaders;
      },
      selectedItems(){
        var items = {}
        this.editedItem.order.forEach(o => {
          let or =  [];
          o.item_list.forEach(i => {
            if(i.selection)or.push(i.item_reference);
          });
          if(or.length>0){
            items[o.order_id] = or;
          }
        });
        return items;
      },
      tableItems(){
        return this.$store.getters.getIntegrationOrders;
      },
      integrationOrder(){
        return this.$store.getters.getIntegrationOrder;
      },
      integrationOrderEditStatus(){
        return this.$store.getters.getIntegrationOrderEditStatus();
      },
       integrationDocumentDeleteStatus(){
        return this.$store.getters.getIntegrationDocumentDeleteStatus();
      },
      integrationOrderLabelLoadStatus(){
        return this.$store.getters.getIntegrationOrderLabelLoadStatus();
      },
      integrationShipmentEditLoadStatus(){
        return this.$store.getters.getIntegrationShipmentEditLoadStatus()
      },
      integrationDocumentEditLoadStatus(){
        return this.$store.getters.getIntegrationDocumentEditLoadStatus()
      },
      integrationOrderEditText(){
        return this.$store.getters.getIntegrationOrderEditText
      },
      selectedPrinter(){
        return window.selected_device;
      },
      independentItem(){
        return this.editedItem.hasOwnProperty('independentItem');
      },
      integrationOrderPayUNote(){
        return this.$store.getters.getIntegrationOrderPayUNote;
      },
      integrationTaskRunningStatus(){
        return this.$store.getters.getIntegrationTaskRunningStatus();
      },
    },
    mounted(){
      //this.buyerEdit = this.independentItem;
      // if(this.lookups.integrationOrders.length>0){
      //   this.loadIntegrationOrders();
      // }else{
      //   this.$store.dispatch( 'loadLookups' );
      //   setTimeout(() => {
      //     this.loadIntegrationOrders();
      //   }, 1000)
      // }
    },
    methods: {
      reloadOrder(){
        this.$store.dispatch( 'loadIntegrationOrderEdit', {slug:  this.editedItem.id}); 
      },
      itemRowBackground: function (item) {
        if(this.editedItem.shipments.length>0){
          return item.item_quantity==item.item_quantity_send ? 'rowCorrect' : 'rowInCorrect'
        }
        return '';
      },
      sumField(table,key) {
        return table.reduce((a, b) => a + ((b && b[key])?b[key]:0 || 0), 0);
      },
      editShipmentItem (item) {
        this.dialogWidth = 1500;
        this.itemEditing = true
        this.$store.dispatch( 'loadIntegrationShipmentEdit', {slug:  item.id});  
        //this.dialog = true
      },
      editDocumentItem (item) {
        this.dialogWidth = 1500;
        this.itemEditing = true
        this.$store.dispatch( 'loadIntegrationDocumentEdit', {slug:  item.id});  
      },
      refreshOrderStatus(order){
        this.$store.dispatch( 'editIntegrationOrderOrderStatus', {slug:this.editedItem.id,order_id:  order.order_id});
      },
      integrationIdChange(){
        this.$store.dispatch( 'editIntegrationOrderIntegrationId', {slug:this.editedItem.id, integration_id:  this.editedItem.integration_id});
      },
      requiredCommorErrors(service_index,parameter_index) {
        const errors = []
        if (!this.$v.editedItem.carrier || 
        !this.$v.editedItem.carrier.service_list[service_index] || 
        !this.$v.editedItem.carrier.service_list[service_index]['parameters'][parameter_index] || 
        !this.$v.editedItem.carrier.service_list[service_index]['parameters'][parameter_index].value.$dirty) return errors
        !this.$v.editedItem.carrier.service_list[service_index]['parameters'][parameter_index].value.required && errors.push('Parametr jest wymagany')
        return errors
      },
      getColorIfErrorCommon(service_index,parameter_index) {
        if (!this.$v.editedItem.carrier || 
        !this.$v.editedItem.carrier.service_list[service_index] || 
        !this.$v.editedItem.carrier.service_list[service_index]['parameters'][parameter_index] || 
        !this.$v.editedItem.carrier.service_list[service_index]['parameters'][parameter_index].value.$dirty) return '';
        if(this.$v.editedItem.carrier.service_list[service_index]['parameters'][parameter_index].value.$error) return 'red accent-1';
      },
      getColorIfErrorPickupDate() {
        if(!this.$v.editedItem.carrier || !this.$v.editedItem.carrier.settings.$dirty) return ''
        if(this.$v.editedItem.carrier.settings.pickup_date.$error) return 'red accent-1'
      },
      getColorIfError(parameter) {
        if(!this.$v.editedItem.buyer[parameter] || !this.$v.editedItem.buyer[parameter].$dirty) return ''
        if(!this.$v.editedItem.buyer[parameter].required) return 'red accent-1'
      },
      getColorIfErrorPickupList(list_index,parametr) {
        if(!this.$v.editedItem.pickup_list.$each[list_index][parametr] || !this.$v.editedItem.pickup_list.$each[list_index][parametr].$dirty) return ''
        if(this.$v.editedItem.pickup_list.$each[list_index][parametr].$error) return 'red accent-1'
      },
      doCopy: function () {
        const container = document.querySelector('.v-dialog');
        this.$copyText(this.editedItem.buyer.contact_email, container);
      },
      changeMUC(item){
        if(this.editedItem.carrier && this.editedItem.carrier.muc_list){
          var defaults = _.find(this.editedItem.carrier.muc_list,{'value':item.type}).defaults;
          item.width = defaults?defaults.width:null;
          item.length = defaults?defaults.length:null;
          this.calculateVolume(item);
        }
      },
      calculateVolume(item){
        this.$v.editedItem.pickup_list.$each[this.editedItem.pickup_list.indexOf(item)].$touch();

        if(!this.$v.editedItem.pickup_list.$each[this.editedItem.pickup_list.indexOf(item)].$error){
          item.volume = i18n.n(_.round(item.width/100 * item.length/100 * item.height/100,3), 'number', 'pl-PL');
        }else item.volume = null;
      },
      deletePickupItem(item){
        const index = this.editedItem.pickup_list.indexOf(item);
        this.editedItem.pickup_list.splice(index, 1);
      },
      addPickupItem(){
        this.editedItem.pickup_list.push({
          type:undefined,
          quantity:1,
          length:null,
          width:null,
          height:null,
          weight:null,
          volume:null
        });
      },
      putOrder(){
        this.$v.$touch()
         if (this.$v.$invalid) {
           
        } else {
            //console.log('makeSHipment');

            this.$store.dispatch( 'editIntegrationOrderSend', {
                slug:  this.editedItem.id,
                order:  this.editedItem.order,
                carrier:  this.prepareCarrier(),
                pickup_list:  this.preparePickupList(),
                buyer:  this.editedItem.buyer
           });
        }
      },
      cantBeSend(){
        if(!this.editedItem.pickup_list || this.editedItem.pickup_list.length==0)return true;
        return false;
      },
      downloadLabel(type,position){
        this.$store.dispatch( 'getIntegrationOrderLabel', {slug:this.editedItem.id,position:position, type:type});
      },
      moveItems(){
        this.$store.dispatch( 'editIntegrationOrderMove', {
                slug:  this.editedItem.id,
                selected:  this.selectedItems
        });
      },
      deleteDocument(id){
        this.$refs.confirm.open('Usuń', 'Czy na pewno chcesz usunać dokumenty?', { color: 'error' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'forceDeleteIntegrationDocument', {slug:  [id]});
            }
        })
      },
      cancelOrder(){
        this.$refs.confirm.open('Anuluj', 'Czy na pewno chcesz anulować nadanie wysyłki?', { color: 'error' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'editIntegrationOrderCancel', {slug:  this.editedItem.id});
            }
        })
      },
      //  openTaskLog(){
      //   this.selectedTaskId = this.taskLogItem.integration_task_id;
      //   this.dialogLog = true;
      // },
      close(){
        this.$v.$reset();
        this.$emit('close');
      },
      prepareCarrier(){
        let carrier = JSON.parse(JSON.stringify(this.editedItem.carrier));
           if(carrier && carrier.service_list){
             carrier.service_list.forEach(s_item => {
               if(s_item.parameters){
                 s_item.parameters.forEach(p_item => {
                   if(p_item.type=='float' && p_item.value)p_item.value = parseFloat(p_item.value.toString().replace(/\./g, "").replace(/,/, "."));
                 });
               }
             });
           }
        return carrier;
      },
      preparePickupList(){
        let pickup_list = JSON.parse(JSON.stringify(this.editedItem.pickup_list));
           if(pickup_list){
             pickup_list.forEach(p_item => {
               p_item.weight = parseFloat(p_item.weight.toString().replace(/\./g, "").replace(/,/, "."));
               p_item.volume = parseFloat(p_item.volume.toString().replace(/\./g, "").replace(/,/, "."));
             });
           }
        return pickup_list;
      },
      refreshOrderAction(){
        this.$store.dispatch( 'runCustomIntegrationTask', {
                        slug: 45,//Zadnie akkualizacja wybranego zamówienia
                        initialValues:{'codido_order_id':this.editedItem.ext_order_id}
        });
      },
      reloadOrderAction(){
        this.$store.dispatch( 'runCustomIntegrationTask', {
                        slug: 37,//Zadnie pobieranie wybranego zamówienia
                        initialValues:{'codido_order_id':this.editedItem.ext_order_id}
        });
      },
      reloadShipmentsOrderAction(){
        this.$store.dispatch( 'runCustomIntegrationTask', {
                        slug: 10022,//Zadnie pobieranie wysylek i zrobienia storn
                        initialValues:{'codido_order_id':this.editedItem.ext_order_id}
        });
      },
      handleSubmit () {
        this.$v.$touch()
         if (this.$v.$invalid) {
           
        } else {
            if(this.independentItem){//new item
               this.$store.dispatch( 'addIntegrationOrder', {
                buyer:  this.editedItem.buyer,
                amount: this.editedItem.amount,
                amount_currency: this.editedItem.amount_currency,
                custom_order: this.editedItem.custom_order
              });
            }else{
              this.$store.dispatch( 'editIntegrationOrder', {
                slug:  this.editedItem.id,
                order:  this.editedItem.order,
                carrier:  this.prepareCarrier(),
                pickup_list:  this.preparePickupList(),
                buyer:  this.editedItem.buyer,
                amount: this.editedItem.amount,
                amount_currency: this.editedItem.amount_currency,
                custom_order: this.editedItem.custom_order
              });
            }
        }
      }
    },
  }
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color:red;
  border-width: 2px;
}
a.offerLink{
  text-decoration: none;
  color: inherit;
}
a.offerLink:hover{
  text-decoration: underline;
}

</style>

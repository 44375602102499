<template>
      <v-col>
          <v-card flat class="elevation-1">
             <v-toolbar dense flat>
              <v-btn :to="{ name: 'ustawienia.firma' }" small color="primary">
                <v-icon>mdi-arrow-left</v-icon>
                Powrót do listy
              </v-btn>
              <v-spacer></v-spacer>
              <v-toolbar-title><strong>{{ department.name }}</strong></v-toolbar-title>
            </v-toolbar>

          <v-row class="pl-5 pr-5">
            <v-col class="col-5">
              <v-card flat class="elevation-1" style="background-color: #f4f4f4;">
                <v-toolbar dense flat style="background-color: #f4f4f4;">
                  <v-toolbar-title>Dane adresowe</v-toolbar-title>
                  <v-spacer></v-spacer>
                  
                  <v-dialog v-if="user.permissions['setting'] >= 10" v-model="dialogDepartment" max-width="700px">
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" @click="editDepartment" x-small color="primary">Edytuj</v-btn>
                    </template>
                    <firma-editor :item-editing="itemEditing" :item-was-main="itemWasMain" @close="closeDepartment"></firma-editor>
                  </v-dialog>
                  
                </v-toolbar>
                <v-card-text>
                  <v-row><v-col class="col-3 pl-5"><strong>Nazwa</strong></v-col><v-col class="col-9">{{department.name}}</v-col></v-row>
                  <v-row><v-col class="col-3 pl-5"><strong>Miasto</strong></v-col><v-col class="col-9">{{department.city}}</v-col></v-row>
                  <v-row><v-col class="col-3 pl-5"><strong>Kod pocztowy</strong></v-col><v-col class="col-9">{{department.post}}</v-col></v-row>
                  <v-row><v-col class="col-3 pl-5"><strong>Adres</strong></v-col><v-col class="col-9">{{department.street}}</v-col></v-row>
                  <v-row><v-col class="col-3 pl-5"><strong>Telefon</strong></v-col><v-col class="col-9">{{department.phone}}</v-col></v-row>
                  <v-row><v-col class="col-3 pl-5"><strong>E-mail</strong></v-col><v-col class="col-9">{{department.email}}</v-col></v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col class="col-4">
              <v-card flat class="elevation-1" style="background-color: #f4f4f4;" >
                 <v-toolbar dense flat style="background-color: #f4f4f4;">
                  <v-toolbar-title>Dane płatnika</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-dialog v-if="user.permissions['setting'] >= 10" v-model="dialogAccount" max-width="700px">
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" @click="editAccount" x-small color="primary">Edytuj</v-btn>
                    </template>
                    <account-editor :item-editing="itemEditing" @close="closeAccount"></account-editor>
                  </v-dialog>
                </v-toolbar>
                <v-card-text>
                  <v-row><v-col class="col-3 pl-5"><strong>NIP</strong></v-col><v-col class="col-9">{{department.account.nip}}</v-col></v-row>
                  <v-row><v-col class="col-3 pl-5"><strong>REGON</strong></v-col><v-col class="col-9">{{department.account.regon}}</v-col></v-row>
                  <v-row><v-col class="col-3 pl-5"><strong>KRS</strong></v-col><v-col class="col-9">{{department.account.krs}}</v-col></v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col class="col-3">
              <v-card flat class="elevation-1" style="background-color: #f4f4f4;" >
                 <v-toolbar dense flat style="background-color: #f4f4f4;">
                  <v-toolbar-title>Logo</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-dialog v-if="user.permissions['setting'] >= 10" v-model="dialogLogo" max-width="700px">
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" @click="editAccount" x-small color="primary">Edytuj</v-btn>
                    </template>
                    <account-logo-editor :item-editing="itemEditing" @close="closeLogo"></account-logo-editor>
                  </v-dialog>
                </v-toolbar>
                <v-card-text>
                  <v-row><v-col class="col-12 d-flex flex-column align-center"><v-img :src="department.account.logo"  class="ml-2 mr-2" width="200" height="200" ></v-img></v-col></v-row>
                </v-card-text>
              </v-card>
            </v-col>
         
        </v-row>
        <v-row class="pl-5 pr-5">
            <v-col class="col-12">
                <v-data-table
                style="background-color: #f4f4f4;"
                  :key="componentKey"
                  :headers="computedHeaders"
                  :items="tableItems"
                  class="elevation-1"
                  :loading="bankAccountsLoadStatus===1"
                  :server-items-length="options.lineCount"
                  :options.sync="tableOptions"
                  hide-default-footer
                  must-sort
                >
                  <template v-slot:top>
                    <v-toolbar flat  style="background-color: #f4f4f4;">
                      <v-toolbar-title>Rachunki bankowe</v-toolbar-title>
                      <v-spacer></v-spacer>

                      <v-checkbox v-model="showDeleted" class="mt-4" label="Pokaż wyłączone"></v-checkbox>

                      <v-divider
                        v-if="user.permissions['setting'] >= 10"
                        class="mx-4"
                        inset
                        vertical
                      ></v-divider>

                      <v-dialog v-if="user.permissions['setting'] >= 10" v-model="dialogBankAccount" max-width="700px">
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on"  small class="mb-2 mt-2" fab dark color="primary">
                            <v-icon dark>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <bank-account-editor :item-editing="itemEditing" :item-was-cod="itemWasCod" :item-was-document="itemWasDocument" @close="closeBankAccount"></bank-account-editor>
                      </v-dialog>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.actions="{ item }">
                      <v-tooltip v-if="item.status && user.permissions['setting'] >= 10" right>
                        <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          size="18"
                          class="mr-2"
                          @click="editItem(item)"
                        >
                          mdi-pencil
                        </v-icon>
                        </template>
                          <span>Edytuj</span>
                      </v-tooltip>

                      <v-tooltip v-if="item.status && item.can_delete && user.permissions['setting'] >= 10" right>
                        <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          size="18"
                          class=""
                          @click="deleteItem(item)"
                        >
                          mdi-delete
                        </v-icon>
                        </template>
                        <span>Wyłącz</span>
                      </v-tooltip>

                      <v-tooltip v-if="!item.status  && user.permissions['setting'] >= 20" right>
                        <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          size="18"
                          class="mr-2"
                          @click="reactivateItem(item)"
                        >
                          mdi-delete-restore
                        </v-icon>
                        </template>
                        <span>Przywróć</span>
                      </v-tooltip>

                      <v-tooltip v-if="!item.status && item.can_delete  && user.permissions['setting'] >= 20" right>
                        <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          size="18"
                          class=""
                          color="error"
                          @click="forceDeleteItem(item)"
                        >
                          mdi-delete-forever
                        </v-icon>
                        </template>
                        <span>Usuń na stałe</span>
                      </v-tooltip>

                      
                  </template>


                  <template v-slot:item.cod="{ item }">
                    <v-simple-checkbox v-model="item.cod" disabled></v-simple-checkbox>
                  </template>      
                  <template v-slot:item.document="{ item }">
                    <v-simple-checkbox v-model="item.document" disabled></v-simple-checkbox>
                  </template>      

                  <template v-slot:item.status="{ item }">
                    <v-simple-checkbox v-model="item.status" disabled></v-simple-checkbox>
                  </template>

                </v-data-table> 

            </v-col>

        </v-row>
         
          </v-card>
      
      <confirm ref="confirm"></confirm>
      </v-col>
      

</template>

<script>
  import confirm from '../components/Confirm'
  import firmaEditor from '../components/FirmaEditor'
  import accountEditor from '../components/AccountEditor'
  import bankAccountEditor from '../components/BankAccountEditor'
  import accountLogoEditor from '../components/AccountLogoEditor'

  export default {
    components:{
      confirm,
      firmaEditor,
      accountEditor,
      accountLogoEditor,
      bankAccountEditor
    },
    data: () => ({
      componentKey:0,
      tableOptions: {},
      dialogDepartment: false,
      dialogAccount: false,
      dialogBankAccount:false,
      dialogLogo: false,
      itemEditing:false,
      itemWasMain:false,
      itemWasCod:false,
      itemWasDocument:false,
      headers: [
        { text: '',align: 'start',sortable: false,value: 'lp',filterable:false,visible:true},
        { text: 'Waluta', value: 'currency',filterable:true,filterColumn:"currency",visible:true  },
        { text: 'Bank', value: 'bank',filterable:true,filterColumn:"bank",visible:true  },
        { text: 'IBAN', value: 'iban',filterable:true,filterColumn:"iban",visible:true  },
        { text: 'Swift', value: 'swift',filterable:true,filterColumn:"swift",visible:true  },
        { text: 'COD', value: 'cod',filterable:false,visible:true  },
        { text: 'Dokument', value: 'document',filterable:false,visible:true  },
        { text: 'Status', value: 'status',filterable:false,visible:true  },
        { text: 'Akcje', value: 'actions', sortable: false,filterable:false,visible:true },
      ],
    }),
    watch: {
      tableOptions: {
        handler () {
          var currentOptions = {...this.options};
          const { sortBy, sortDesc } = this.tableOptions
        
          //zmiana sortowania
          if (sortBy.length === 1 && sortDesc.length === 1) {
            currentOptions.orderBy = sortBy[0];
            currentOptions.orderDirection = sortDesc[0]?'desc':'asc';
            this.options = currentOptions;
          } 
        },
        deep: true,
      },
      departmentEditStatus(val){
        if(val===2){
          this.loadDepartment();
          this.closeDepartment();
        }
      },
      accountEditStatus(val){
        if(val===2){
          this.loadDepartment();
          this.closeAccount();
          this.closeLogo();
        }
      },
      bankAccountEditStatus(val){
        if(val===2){
          //this.loadDepartment();
          this.componentKey += 1;
          this.closeBankAccount();
        }
      },
      bankAccountAddStatus(val){
        if(val===2){
          this.closeBankAccount();
          this.componentKey += 1;
        }
      },
      bankAccountDeleteStatus(val){
        if(val===2){
          this.closeBankAccount();
          this.componentKey += 1;
        }
      }
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      options:{
        get () {  
          return this.$store.getters.getBankAccountOptions;
        },
        set (value) {
          this.$store.dispatch( 'setBankAccountOptions', {options:value} )
        }
      },
      showDeleted: {
        get () {
          return this.$store.getters.getBankAccountOptions.showDeleted;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.showDeleted = value;
          this.options = currentOptions;
        }
      },
      department(){
        return this.$store.getters.getDepartment;
      },
      tableItems(){
        return this.$store.getters.getBankAccounts;
      },
      bankAccountsLoadStatus(){
        return this.$store.getters.getBankAccountsLoadStatus;
      },
      departmentEditLoadStatus(){
        return this.$store.getters.getDepartmentEditLoadStatus
      },
      departmentEditStatus(){
        return this.$store.getters.getDepartmentEditStatus();
      },
      accountEditStatus(){
        return this.$store.getters.getAccountEditStatus();
      },
      departmentAddStatus(){
        return this.$store.getters.getDepartmentAddStatus();
      },
      departmentDeleteStatus(){
        return this.$store.getters.getDepartmentDeleteStatus();
      },
     
      bankAccountEditLoadStatus(){
        return this.$store.getters.getBankAccountEditLoadStatus
      },
      bankAccountEditStatus(){
        return this.$store.getters.getBankAccountEditStatus();
      },
      bankAccountAddStatus(){
        return this.$store.getters.getBankAccountAddStatus();
      },
      bankAccountDeleteStatus(){
        return this.$store.getters.getBankAccountDeleteStatus();
      },
      computedHeaders () {
        var h = _.filter(this.headers,function(o) { return o.visible; })

        if(!this.showDeleted && this.user.permissions['setting'] <= 5) return h.filter(h => h.value!=='status' && h.value!=='actions')
        else if(this.showDeleted && this.user.permissions['setting'] <= 5) return h.filter(h => h.value!=='actions')
        else if(!this.showDeleted) return h.filter(h => h.value!=='status')
        else return h
      },
    },
    mounted(){
      this.loadDepartment();
      this.loadBankAccounts();
    },
    methods: {
      loadDepartment(){
        this.$store.dispatch( 'loadMainDepartmentEdit');  
      },
      loadBankAccounts(){
        this.$store.dispatch( 'loadBankAccounts');  
      },
      editDepartment(){
        this.itemWasMain = true;
        this.$store.dispatch( 'loadDepartmentEdit', {slug:  this.department.id});  
        this.itemEditing = true;
      },
      editAccount(){
        this.$store.dispatch( 'loadAccountEdit', {slug:  this.department.account.id});  
        this.itemEditing = true;
      },
      editItem (item) {//bank accounts
        this.itemWasCod = item.cod;
        this.itemWasDocument = item.document;
        this.itemEditing = true
        this.$store.dispatch( 'loadBankAccountEdit', {slug:  item.id});  
        this.dialogBankAccount = true
      },
      deleteItem (item) {
        this.$refs.confirm.open('Usuń', 'Czy na pewno chcesz wyłączyć rachunek?', { color: 'error' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'deleteBankAccount', {slug:  item.id});
            }
        })
      },
      reactivateItem(item){
        this.$store.dispatch( 'editBankAccountReactivate',{slug:item.id});
      },
      forceDeleteItem (item) {
        this.$refs.confirm.open('Usuń', 'Czy na pewno chcesz usunać rachunek na stałe?', { color: 'error' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'forceDeleteBankAccount', {slug:  item.id});
            }
        })
      },
      closeDepartment () {
        this.dialogDepartment = false
      },
      closeAccount() {
        this.dialogAccount = false
      },
      closeLogo() {
        this.dialogLogo = false
      },
      closeBankAccount() {
        this.dialogBankAccount = false
        setTimeout(() => {
          this.$store.dispatch( 'loadBankAccountEditDefault'); 
          this.$store.dispatch( 'loadBankAccountAddDefault');
          this.itemEditing = false
        }, 300)
      },
    },
  }
</script>
import IntegrationAPI from "../api/integration";

export const integrations = {
    state: {
        integration:{},
        integrationLoadStatus: 0,

        integrations: [],
        integrationOptions:{
            itemAdded:false,
            accountId:undefined,
            orderBy:'id',
            orderDirection:'desc',
        },


        integrationsLoadStatus: 0,
        integrationAddStatus: 0,
        integrationAddText: "",
        integrationTestStatus: 0,
        integrationTestText: "",

        integrationEdit: {
            id: 0,
            lp: 0,
            integrable:{client_id:undefined,client_secret:undefined,api_key:undefined, 
                settings:{
                    DEFAULT_INTEGRATION_ID:undefined,
                    DEFAULT_LANGUAGE_ID:undefined,
                    DEFAULT_PICKUP_DATE:undefined,
                    DEFAULT_ORDER_BY:undefined,
                    DEFAULT_ORDER_DIRECTION:undefined
                }
            },
            type:{name:undefined,category:undefined}
        },
        integrationEditDefault: {
            id: 0,
            lp: 0,
            integrable:{client_id:undefined,client_secret:undefined,api_key:undefined, 
                settings:{
                    DEFAULT_INTEGRATION_ID:undefined,
                    DEFAULT_LANGUAGE_ID:undefined,
                    DEFAULT_PICKUP_DATE:undefined,
                    DEFAULT_ORDER_BY:undefined,
                    DEFAULT_ORDER_DIRECTION:undefined
                }
            },
            type:{name:undefined,category:undefined}
        },
        integrationEditLoadStatus: 0,
        integrationEditStatus: 0,
        integrationEditText: "",

        integrationDeleteStatus: 0,
        integrationDeleteText: "",

    },

    actions: {
        loadIntegration({ state, commit, dispatch },data) {
            commit("setIntegrationLoadStatus", 1);
                IntegrationAPI.getIntegration(data.slug)
                .then(function(response) {
                    commit("setIntegration", response.data);
                    dispatch("applyIndexOnIntegration");
                    commit("setIntegrationLoadStatus", 2);
                })
                .catch(function() {
                    commit("setIntegration", {invoices:[]});
                    commit("setIntegrationLoadStatus", 3);
                });
         
        },
        
        
        editIntegrationStatus({ commit, state, dispatch }, data) {
            commit("setIntegrationEditStatus", 1);

            IntegrationAPI.putIntegrationStatus(
                data.slug,
                data.status,
            )
                .then(function(response) {
                    commit("setIntegrationEditStatus", 2);
                    dispatch("loadUser");
                    dispatch("loadIntegrations");
                    dispatch("applyIndexOnIntegrations");
                })
                .catch(function(error) {
                    commit("setIntegrationEditStatus", 3);
                    commit("setIntegrationEditText", error.response.data.errors);
                });
        },
      
        loadIntegrations({ state, commit, dispatch }) {
            commit("setIntegrationsLoadStatus", 1);
                IntegrationAPI.getIntegrations({
                    orderBy:state.integrationOptions.orderBy,
                    orderDirection:state.integrationOptions.orderDirection,
                    accountId:state.integrationOptions.accountId,
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setIntegrations", response.data);
                    dispatch("applyIndexOnIntegrations");
                    commit("setIntegrationsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setIntegrations", []);
                    commit("setIntegrationsLoadStatus", 3);
                });
         
        },
      
       
        
        setIntegrationOptions({ state, commit, dispatch },data){
            commit("setIntegrationOptions",data.options);
            dispatch("loadIntegrations");
        },
        applyIndexOnIntegrations({ commit, state, dispatch }) {
            let localIntegrations = state.integrations;

            var i = 1;
            localIntegrations.forEach(integration => {
                // if(_.isNumber(integration.status)){
                //     integration.status = _.find(state.integrationStatus, { 'value': integration.status }).text;
                // }
                //integration.integrable_type = integration.integrable_type.replace('App\\','').replace('Integration','');
                integration.lp = i;
                i++;
            });

            commit("setIntegrations",localIntegrations);
        },
       
        addIntegration({ commit, state, dispatch }, data) {
            commit("setIntegrationAddStatus", 1);
            commit("setIntegrationAddText", "");

            IntegrationAPI.postAddNewIntegration(
                data.type
            )
                .then(function(response) {
                    commit("setIntegrationAddStatus", 2);
                    commit("setIntegrationEdit", response.data);
                    commit("setAddedIntegration",response.data);
                    dispatch("loadIntegrationLookup");
                    dispatch("loadAvailableActionLookup");
                    dispatch("applyIndexOnIntegrations");
                })
                .catch(function(error) {
                    commit("setIntegrationAddStatus", 3);
                    commit("setIntegrationAddText", error.response.data.errors);
                });
        },

        testIntegration({ commit, state, dispatch }, data) {
            commit("setIntegrationTestStatus", 1);

            IntegrationAPI.getConnectionTest(data.slug)
                .then(function(response) {
                    commit("setIntegrationTestStatus", 2);
                })
                .catch(function(error) {
                    commit("setIntegrationTestStatus", 3);
                    commit("setIntegrationTestText", error.response.data);
                });
        },
    
        loadIntegrationEdit({ commit }, data) {
            commit("setIntegrationEditLoadStatus", 1);
            commit("setIntegrationEditStatus", 0);
            commit("setIntegrationEditText", "");

            IntegrationAPI.getIntegrationEdit(data.slug)
                .then(function(response) {
                    commit("setIntegrationEdit", response.data);
                    commit("setIntegrationEditLoadStatus", 2);
                })
                .catch(function() {
                    commit("setIntegrationEdit", {});
                    commit("setIntegrationEditLoadStatus", 3);
                });
        },
        loadIntegrationEditDefault({ commit }) {
            commit("setIntegrationEditDefault");
        },
        loadIntegrationAddDefault({ commit }) {
            commit("setIntegrationAddStatus", 0);
            commit("setIntegrationAddText", "");
            commit("setIntegrationEditStatus", 0);
            commit("setIntegrationEditText", "");
            commit("setIntegrationTestStatus", 0);
            commit("setIntegrationTestText", "");
        },
        editIntegration({ commit, state, dispatch }, data) {
            commit("setIntegrationEditStatus", 1);

            IntegrationAPI.putEditIntegration(
                data.slug,
                data.name,
                data.integrable_type,
                data.integrable
            )
                .then(function(response) {
                    
                    commit("setIntegrationEditStatus", 2);
                    commit("setEditedIntegration",response.data)
                    dispatch("loadIntegrationLookup");
                    dispatch("loadAvailableActionLookup");
                    dispatch("applyIndexOnIntegrations");
                })
                .catch(function(error) {
                    commit("setIntegrationEditStatus", 3);
                    commit("setIntegrationEditText", error.response.data.errors);
                });
        },

        
        forceDeleteIntegration({ commit, state, dispatch }, data) {
            commit("setIntegrationDeleteStatus", 1);

            IntegrationAPI.forceDeleteIntegration(data.slug)
                .then(function(response) {
                    commit("setIntegrationDeleteStatus", 2);
                    commit("setDeletedIntegration",response.data)
                    dispatch("loadIntegrationLookup");
                    dispatch("loadAvailableActionLookup");
                    dispatch("applyIndexOnIntegrations");
                })
                .catch(function() {
                    commit("setIntegrationDeleteStatus", 3);
                });
        },


        //############# allegro
        putAllegroCode({ commit, state, dispatch }, data) {
            commit("setIntegrationAddStatus", 1);

            IntegrationAPI.putAllegroCode(
                data.id,
                data.code,
            )
                .then(function(response) {
                    commit("setIntegrationAddStatus", 2);
                    commit("setIntegrationEdit", response.data);
                    //commit("setEditedIntegration",response.data)
                    //dispatch("applyIndexOnIntegrations");
                })
                .catch(function(error) {
                    commit("setIntegrationAddStatus", 3);
                    commit("setIntegrationEditText", error.response.data.errors);
                });
        },
    },
    mutations: {
        setIntegrationLoadStatus(state, status) {
            state.integrationLoadStatus = status;
        },
        setIntegration(state, integration) {
            state.integration = integration;
        },
        //#############
        setIntegrationOptions(state,options){
            state.integrationOptions = options;
        },
        setIntegrationsLoadStatus(state, status) {
            state.integrationsLoadStatus = status;
        },
        setIntegrations(state, integrations) {
            state.integrations = integrations;
        },
        setIntegrationLineCount(state, lineCount) {
            state.integrationOptions.lineCount = lineCount;
        },
        setIntegrationAddStatus(state, status) {
            state.integrationAddStatus = status;
        },
        setIntegrationAddText(state, text) {
            state.integrationAddText = text;
        },
        setIntegrationTestStatus(state, status) {
            state.integrationTestStatus = status;
        },
        setIntegrationTestText(state, text) {
            state.integrationTestText = text;
        },
        setIntegrationEdit(state, integration) {
            state.integrationEdit = integration;
        },
        setEditedIntegration(state, integration) {
            var lp = _.find(state.integrations,{'id':integration.id}).lp;
            var index = _.findIndex(state.integrations,{'id':integration.id});
            
            state.integrations[index] = integration;
            state.integrations[index].lp = lp;
        },
        setAddedIntegration(state, integration) {
            state.integrationOptions.itemAdded = true;
            state.integrations.push(integration);
        },
        setIntegrationSearchedTrue(state) {
            state.integrationOptions.itemSearched = true;
        },
        setOptionsItemAddedAndSearchedFalse(state) {
            state.integrationOptions.itemAdded = false;
            state.integrationOptions.itemSearched = false;
        },
        setDeletedIntegration(state, integration) {
            var index = _.findIndex(state.integrations,{'id':integration.id});
            state.integrations.splice(index,1);
        },
        setIntegrationEditDefault(state) {
            state.integrationEdit = { ...state.integrationEditDefault };
        },
        setIntegrationEditStatus(state, status) {
            state.integrationEditStatus = status;
        },
        setIntegrationEditText(state, text) {
            state.integrationEditText = text;
        },
        setIntegrationEditLoadStatus(state, status) {
            state.integrationEditLoadStatus = status;
        },
        setIntegrationDeleteStatus(state, status) {
            state.integrationDeleteStatus = status;
        },
        setIntegrationDeletedText(state, text) {
            state.integrationDeleteText = text;
        }
    },

    getters: {
        getIntegrationLoadStatus(state) {
            return state.integrationLoadStatus;
        },
        getIntegration(state) {
            return state.integration;
        },
        //########
        getIntegrationOptions(state){
            return state.integrationOptions;
        },
        getIntegrationsLoadStatus(state) {
            return function(){
                return state.integrationsLoadStatus;
            }
        },
        getIntegrations(state) {
            return state.integrations;
        },
        getIntegrationAddStatus(state) {
            return function(){
                return state.integrationAddStatus;
            } 
        },
        getIntegrationAddText(state) {
            return state.integrationAddText;
        },
        getIntegrationTestStatus(state) {
            return function(){
                return state.integrationTestStatus;
            } 
        },
        getIntegrationTestText(state) {
            return state.integrationTestText;
        },
        getIntegrationEdit(state) {
            return state.integrationEdit;
        },
        getIntegrationEditStatus(state) {
          return function(){
            return state.integrationEditStatus;
          }
        },
        getIntegrationEditing(state) {
            return state.integrationEdit.id > 0;
        },
        getIntegrationEditText(state) {
            return state.integrationEditText;
        },
        getIntegrationEditLoadStatus(state) {
            return function(){
                return state.integrationEditLoadStatus;
            }
        },
        getIntegrationDeleteStatus(state) {
            return function(){
                return state.integrationDeleteStatus;
            }
        },
        getIntegrationDeleteText(state) {
            return state.integrationDeleteText;
        }
    }
};

<template>
      <v-col>
          <v-card flat>
         <v-data-table
            :key="componentKey"
            :headers="computedHeaders"
            :items="tableItems"
            class="elevation-1"
            :loading="groupsLoadStatus===1"
            :options.sync="tableOptions"
            :server-items-length="options.lineCount"
            hide-default-footer
            must-sort
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>Stanowiska</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-checkbox v-model="showDeleted" class="mt-4" label="Pokaż wyłączone"></v-checkbox>

                <v-divider
                  v-if="user.permissions['setting'] >= 10"
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>
                <v-dialog v-if="user.permissions['setting'] >= 10" v-model="dialog" max-width="700px">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" small class="mb-2 mt-2" fab dark color="primary">
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <v-card  :loading="groupAddStatus===1 || groupEditLoadStatus===1 || groupEditStatus===1">
                    <v-card-title>
                      <img class="mr-3" src="/logo_listek.png"></img>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        
                          <v-row>                   
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="editedItem.name" :error-messages="nameErrors" required @input="$v.editedItem.name.$touch()"  @blur="$v.editedItem.name.$touch()" counter="20" label="Nazwa stanowiska"></v-text-field>                            
                            </v-col>
                             <v-col cols="12" sm="6" md="4">
                               <v-select v-model="editedItem.desktop" :items="permissions" label="Moduł pulpit"></v-select>
                            </v-col>
                            
                            <v-col cols="12" sm="6" md="4">
                               <v-select v-model="editedItem.order" :items="permissions" label="Moduł Zamówienia"></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                               <v-select v-model="editedItem.shipment" :items="permissions" label="Moduł Wysyłki"></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                               <v-select v-model="editedItem.document" :items="permissions" label="Moduł Documenty"></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                               <v-select v-model="editedItem.dictionary" :items="permissions" label="Moduł Słowniki"></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                               <v-select v-model="editedItem.setting" :items="permissions" label="Moduł Ustawienia"></v-select>
                            </v-col>
                            <!-- <v-col cols="12" sm="6" md="4">
                               <v-select v-model="editedItem.mobile" :items="permissions" label="Moduł Mobilny"></v-select>
                            </v-col> -->
                            <!-- <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="editedItem.adress" :error-messages="adressErrors" required @input="$v.editedItem.adress.$touch()"  @blur="$v.editedItem.adress.$touch()" label="Adres"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="editedItem.phone" :error-messages="phoneErrors" required @input="$v.editedItem.phone.$touch()"  @blur="$v.editedItem.phone.$touch()" label="Telefon"></v-text-field>
                            </v-col> -->
                          </v-row>
                        
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="gray" text @click="close">Anuluj</v-btn>
                      <v-btn color="primary darken-1" text @click="handleSubmit" >Zapisz</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip v-if="item.status && user.permissions['setting'] >= 10" right>
                  <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    size="18"
                    class="mr-2"
                    @click="editItem(item)"
                  >
                    mdi-pencil
                  </v-icon>
                  </template>
                    <span>Edytuj</span>
                </v-tooltip>

                <v-tooltip v-if="item.status && item.can_delete && user.permissions['setting'] >= 10" right>
                  <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    size="18"
                    class=""
                    @click="deleteItem(item)"
                  >
                    mdi-delete
                  </v-icon>
                  </template>
                  <span>Wyłącz</span>
                </v-tooltip>

                <v-tooltip v-if="!item.status  && user.permissions['setting'] >= 20" right>
                  <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    size="18"
                    class="mr-2"
                    @click="reactivateItem(item)"
                  >
                    mdi-delete-restore
                  </v-icon>
                  </template>
                  <span>Przywróć</span>
                </v-tooltip>

                <v-tooltip v-if="!item.status && item.can_delete  && user.permissions['setting'] >= 20" right>
                  <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    size="18"
                    class=""
                    color="error"
                    @click="forceDeleteItem(item)"
                  >
                    mdi-delete-forever
                  </v-icon>
                  </template>
                  <span>Usuń na stałe</span>
                </v-tooltip>

                
            </template>

            <template v-slot:item.status="{ item }">
              <v-simple-checkbox v-model="item.status" disabled></v-simple-checkbox>
            </template>

          </v-data-table> 
          <div class="text-center pt-2">
            <v-btn @click="loadMore" v-if="!options.itemAdded && options.lineCount-tableItems.length>0 && groupsLoadStatus===2" small color="primary"><v-icon left>mdi-download</v-icon>Załaduj więcej ({{ options.lineCount-tableItems.length }})</v-btn>
            <v-btn @click="loadGroups" v-else-if="options.itemAdded && options.lineCount-tableItems.length>0" small color="primary"><v-icon left>mdi-reload</v-icon>Odśwież tabele aby móc załadować więcej ({{ options.lineCount-tableItems.length }})</v-btn>
            <v-btn @click="loadGroups" v-else-if="options.itemSearched" small color="primary"><v-icon left>mdi-reload</v-icon>Anuluj wyszukiwanie</v-btn>
          
          </div>

          </v-card>
      <confirm ref="confirm"></confirm>
      </v-col>
</template>

<script>
  import confirm from '../components/Confirm'

  import required from 'vuelidate/lib/validators/required'
  import minLength from 'vuelidate/lib/validators/minLength'
  import maxLength from 'vuelidate/lib/validators/maxLength'
  import numeric from 'vuelidate/lib/validators/numeric'

  export default {
    components:{
      confirm
    },
    data: () => ({
      componentKey:0,
      tableOptions: {},
      dialog: false,
      itemEditing:false,
      search:'',
      headers: [
        { text: '',align: 'start',sortable: false,value: 'lp', filterable:false},
        { text: 'Stanowisko', value: 'name', filterable:true },
        { text: 'Pulpit', value: 'desktop', filterable:false },
        { text: 'Zamówienia', value: 'order', filterable:false },
        { text: 'Wysyłki', value: 'shipment', filterable:false },
        { text: 'Dokumenty', value: 'document', filterable:false },
        { text: 'Słowniki', value: 'dictionary', filterable:false },
        { text: 'Ustawienia', value: 'setting', filterable:false },
        { text: 'Status', value: 'status', filterable:false },
        { text: 'Akcje', value: 'actions', sortable: false, filterable:false },
      ],
    }),
    validations: {
      editedItem:{
        name: {
          required,
          maxLength:maxLength(20),
        },
      }
    },
    watch: {
      tableOptions: {
        handler () {
          var currentOptions = {...this.options};
          const { sortBy, sortDesc } = this.tableOptions
        
          //zmiana sortowania
          if (sortBy.length === 1 && sortDesc.length === 1) {
            currentOptions.orderBy = sortBy[0];
            currentOptions.orderDirection = sortDesc[0]?'desc':'asc';
            this.options = currentOptions;
          } 
        },
        deep: true,
      },
    
      dialog (val) {
        this.$v.$reset()
        val || this.close()
      },
      groupEditStatus(val){
        if(val===2){
          this.close();
          this.componentKey += 1;
        }
      },
      groupAddStatus(val){
        if(val===2){
          this.close();
          this.componentKey += 1;
        }
      },
      groupDeleteStatus(val){
        if(val===2){
          this.componentKey += 1;
        }
      }
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      options:{
        get () {  
          return this.$store.getters.getGroupOptions;
        },
        set (value) {
          this.search='';
          this.$store.dispatch( 'setGroupOptions', {options:value} )
        }
      },
      showDeleted: {
        get () {
          return this.$store.getters.getGroupOptions.showDeleted;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.showDeleted = value;
          this.options = currentOptions;
        }
      },
      permissions(){
        return this.$store.getters.getLookups.permissions;
      },
      editedItem(){
        return this.$store.getters.getGroupEdit;
      },
      tableItems(){
        return this.$store.getters.getGroups;
      },
      groupsLoadStatus(){
        return this.$store.getters.getGroupsLoadStatus;
      },
      groupEditLoadStatus(){
        return this.$store.getters.getGroupEditLoadStatus
      },
      groupEditStatus(){
        return this.$store.getters.getGroupEditStatus();
      },
      groupEditText(){
        return this.$store.getters.getGroupEditText
      },
      groupAddStatus(){
        return this.$store.getters.getGroupAddStatus();
      },
      groupAddText(){
        return this.$store.getters.getGroupAddText;
      },
      groupDeleteStatus(){
        return this.$store.getters.getGroupDeleteStatus();
      },
      computedHeaders () {
        if(!this.showDeleted && this.user.permissions['setting'] <= 5) return this.headers.filter(h => h.value!=='status' && h.value!=='actions')
        else if(this.showDeleted && this.user.permissions['setting'] <= 5) return this.headers.filter(h => h.value!=='actions')
        else if(!this.showDeleted) return this.headers.filter(h => h.value!=='status')
        else return this.headers
      },
      formTitle () {
        return !this.itemEditing ? 'Nowe stanowisko' : 'Edycja stanowiska'
      },
      nameErrors () {
        const errors = []
        if (!this.$v.editedItem.name.$dirty) return errors
        !this.$v.editedItem.name.required && errors.push('Nazwa jest wymagana')
        !this.$v.editedItem.name.maxLength && errors.push('Nazwa może mieć maksymalnie 20 znaków')
        return errors
      },
    },
    mounted(){
      this.loadGroups();
    },
    methods: {
      loadGroups(){
        this.search='';
        this.$store.dispatch( 'loadGroups' );
      },
      loadMore(){
        this.$store.dispatch( 'loadMoreGroups' );
      },
      reactivateItem(item){
        this.$store.dispatch( 'editGroupReactivate',{slug:item.id});
      },
      editItem (item) {
        this.itemEditing = true
        this.$store.dispatch( 'loadGroupEdit', {slug:  item.id});  
        this.dialog = true
      },
      deleteItem (item) {
        this.$refs.confirm.open('Usuń', 'Czy na pewno chcesz wyłączyć stanowisko?', { color: 'error' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'deleteGroup', {slug:  item.id});
            }
        })
      },
      forceDeleteItem (item) {
        this.$refs.confirm.open('Usuń', 'Czy na pewno chcesz usunać stanowisko na stałe?', { color: 'error' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'forceDeleteGroup', {slug:  item.id});
            }
        })
      },
      searchGroups(){
        if(this.search.length>2){
          const filterableHeaders = _.join(_.map(_.filter(this.headers,{'filterable':true}),'value'),';');
          this.$store.dispatch( 'searchGroups',{filterableHeaders:filterableHeaders,q:this.search});
        }
      },
      close () {
        this.dialog = false
        this.$v.$reset()
        setTimeout(() => {
          this.$store.dispatch( 'loadGroupEditDefault'); 
          this.$store.dispatch( 'loadGroupAddDefault');
          this.itemEditing = false
        }, 300)
      },
      handleSubmit () {
        this.$v.$touch()
        if (this.$v.$invalid) {

        } else {
         
            if (this.itemEditing) {
              //edycja
              this.$store.dispatch( 'editGroup', {
                slug: this.editedItem.id,
                name:  this.editedItem.name,
                order:  this.editedItem.order,
                shipment:  this.editedItem.shipment,
                document:  this.editedItem.document,
                desktop:  this.editedItem.desktop,
                dictionary:  this.editedItem.dictionary,
                setting: this.editedItem.setting,
              });
            } else {//new item
              this.$store.dispatch( 'addGroup', {
                  name:  this.editedItem.name,
                  order:  this.editedItem.order,
                  shipment:  this.editedItem.shipment,
                  document:  this.editedItem.document,
                  desktop:  this.editedItem.desktop,
                  dictionary:  this.editedItem.dictionary,
                  setting: this.editedItem.setting,
              });

            }
        }
      },
    },
  }
</script>


<style scoped>
.search{
      max-width: 250px;
}
</style>

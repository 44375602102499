<template>
    <v-container class="d-flex flex-column pa-0 ml-3">
          <v-btn :to="{name: 'slowniki.dokumenty' }" large :color="$route.name === 'slowniki.dokumenty'? selectedColor : defaultColor"  width="250" active-class="aa" exact>
            Dokumenty  
          </v-btn>
          <!-- 

            <v-btn :to="{name: 'slowniki.dzieci' }" x-large :color="$route.name === 'slowniki.dzieci' || $route.name === 'slowniki.dziecko' ? selectedColor : defaultColor" dark width="250">
            Dzieci  
              <v-chip
                class="ma-2"
                color="white"
                :text-color="defaultColor"
                small
              
              >
                <strong>{{menuCount.dictionaries.child}}</strong>
            </v-chip>
            </v-btn>

            <v-btn v-if="user.permissions['dictionary'] >= 10" :to="{name: 'slowniki.cenniki' }" x-large :color="$route.name === 'slowniki.cenniki' ? selectedColor : defaultColor" dark width="250">
            Cenniki  
              <v-chip
                class="ma-2"
                color="white"
                :text-color="defaultColor"
                small
              
              >
                <strong>{{menuCount.dictionaries.rate}}</strong>
            </v-chip>
            </v-btn>

             <v-btn v-if="user.permissions['dictionary'] >= 10" :to="{name: 'slowniki.lata' }" x-large :color="$route.name === 'slowniki.lata' ? selectedColor : defaultColor" dark width="250">
            Lata szkolne  
              <v-chip
                class="ma-2"
                color="white"
                :text-color="defaultColor"
                small
              
              >
                <strong>{{menuCount.dictionaries.year}}</strong>
            </v-chip>
            </v-btn> -->
    </v-container>
</template>

<script>
export default {
    data(){
        return{
            defaultColor:'secondary', 
            selectedColor:'primary'
        }
    },
    mounted(){
      //this.$store.dispatch( 'loadLookups' );
    },
    computed:{
      user(){
        return this.$store.getters.getUser;
      },
    },
}
</script>

<style scoped>
.v-btn{
  margin-bottom: 1em;
}
.v-chip{
  margin-left: 1em;
}
.v-chip:hover{
    cursor: pointer;
}

.v-btn:not(.v-btn--text):not(.v-btn--outlined).v-btn--active:before {
    opacity: 0;
}

</style>
<template>

    
      <v-col>
          <v-card flat>
         <v-data-table
            :key="componentKey"
            :headers="computedHeaders"
            :items="tableItems"
            class="elevation-1"
            :loading="departmentsLoadStatus===1"
            :options.sync="tableOptions"
            :server-items-length="options.lineCount"
            hide-default-footer
            must-sort
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>Firma</v-toolbar-title>
                
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>
                

                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-on="on"
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Wyszukaj"
                      single-line
                      hide-details
                      class="search"
                      @keydown.enter="searchDepartments"
                  ></v-text-field>
                  </template>
                    <span>Wpisz min 3 znaki i naciśnij Enter</span>
                </v-tooltip>
                 

                <v-spacer></v-spacer>

                <v-checkbox v-model="showDeleted" class="mt-4" label="Pokaż wyłączone"></v-checkbox>

                <v-divider
                  v-if="user.permissions['setting'] >= 10"
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>
                <v-dialog v-if="user.permissions['setting'] >= 10" v-model="dialog" max-width="700px">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" small class="mb-2 mt-2" fab dark color="primary">
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <firma-editor :item-editing="itemEditing" :item-was-main="itemWasMain" @close="close"></firma-editor>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip v-if="item.status && user.permissions['setting'] >= 10" right>
                  <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    size="18"
                    class="mr-2"
                    @click="editItem(item)"
                  >
                    mdi-pencil
                  </v-icon>
                  </template>
                    <span>Edytuj</span>
                </v-tooltip>

                <v-tooltip v-if="item.status && item.can_delete && user.permissions['setting'] >= 10" right>
                  <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    size="18"
                    class=""
                    @click="deleteItem(item)"
                  >
                    mdi-delete
                  </v-icon>
                  </template>
                  <span>Wyłącz</span>
                </v-tooltip>

                <v-tooltip v-if="!item.status  && user.permissions['setting'] >= 20" right>
                  <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    size="18"
                    class="mr-2"
                    @click="reactivateItem(item)"
                  >
                    mdi-delete-restore
                  </v-icon>
                  </template>
                  <span>Przywróć</span>
                </v-tooltip>

                <v-tooltip v-if="!item.status && item.can_delete  && user.permissions['setting'] >= 20" right>
                  <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    size="18"
                    class=""
                    color="error"
                    @click="forceDeleteItem(item)"
                  >
                    mdi-delete-forever
                  </v-icon>
                  </template>
                  <span>Usuń na stałe</span>
                </v-tooltip>

                
            </template>

            <template v-slot:item.address="{ item }">
              {{item.street}}, {{item.post}} {{item.city}}
            </template>

            <template v-slot:item.main="{ item }">
              <v-simple-checkbox v-model="item.main" disabled></v-simple-checkbox>
            </template>        

            <template v-slot:item.status="{ item }">
              <v-simple-checkbox v-model="item.status" disabled></v-simple-checkbox>
            </template>

            <template v-slot:item.details="{ item }">       
                <v-btn v-if="item.main" :to="{name:'ustawienia.centrala'}" color="primary" x-small>Detale</v-btn>
            </template> 

          </v-data-table> 
          <div class="text-center pt-2">
            <v-btn @click="loadMore" v-if="!options.itemAdded && options.lineCount-tableItems.length>0 && departmentsLoadStatus===2" small color="primary"><v-icon left>mdi-download</v-icon>Załaduj więcej ({{ options.lineCount-tableItems.length }})</v-btn>
            <v-btn @click="loadDepartments" v-else-if="options.itemAdded && options.lineCount-tableItems.length>0" small color="primary"><v-icon left>mdi-reload</v-icon>Odśwież tabele aby móc załadować więcej ({{ options.lineCount-tableItems.length }})</v-btn>
            <v-btn @click="loadDepartments" v-else-if="options.itemSearched" small color="primary"><v-icon left>mdi-reload</v-icon>Anuluj wyszukiwanie</v-btn>
          
          </div>

          </v-card>
      
      <confirm ref="confirm"></confirm>
      </v-col>
      

</template>

<script>
  import confirm from '../components/Confirm'
  import firmaEditor from '../components/FirmaEditor'

  export default {
    components:{
      confirm,
      firmaEditor
    },
    data: () => ({
      componentKey:0,
      tableOptions: {},
      dialog: false,
      itemEditing:false,
      search:'',
      itemWasMain:false,
      headers: [
        { text: '',align: 'start',sortable: false,value: 'lp',filterable:false,visible:true},
        { text: 'Nazwa firmy/oddziału', value: 'name',filterable:true,filterColumn:"name",visible:true  },
        { text: 'Centrala', value: 'main',filterable:false,visible:true  },
        { text: 'Adres', value: 'address',sortable: false,filterable:false,visible:true  },
        { text: 'Adres', value: 'city',filterable:true,filterColumn:"city",visible:false  },
        { text: 'Adres', value: 'post',filterable:true,filterColumn:"post",visible:false  },
        { text: 'Adres', value: 'street',filterable:true,filterColumn:"street",visible:false  },
        { text: 'Telefon', value: 'phone',filterable:true,filterColumn:"phone",visible:true  },
        { text: 'E-mail', value: 'email',filterable:true,filterColumn:"email",visible:true  },
        { text: 'Status', value: 'status',filterable:false,visible:true  },
        { text: 'Akcje', value: 'actions', sortable: false,filterable:false,visible:true },
        { text: '', value: 'details', sortable: false,filterable:false,visible:true },
      ],
    }),
    watch: {
      tableOptions: {
        handler () {
          var currentOptions = {...this.options};
          const { sortBy, sortDesc } = this.tableOptions
        
          //zmiana sortowania
          if (sortBy.length === 1 && sortDesc.length === 1) {
            currentOptions.orderBy = sortBy[0];
            currentOptions.orderDirection = sortDesc[0]?'desc':'asc';
            this.options = currentOptions;
          } 
        },
        deep: true,
      },
    
      dialog (val) {
        val || this.close()
      },
      departmentEditStatus(val){
        if(val===2){
          this.close();
          this.componentKey += 1;
        }
      },
      departmentAddStatus(val){
        if(val===2){
          this.close();
          this.componentKey += 1;
        }
      },
      departmentDeleteStatus(val){
        if(val===2){
          this.componentKey += 1;
        }
      }
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      options:{
        get () {  
          return this.$store.getters.getDepartmentOptions;
        },
        set (value) {
          this.search='';
          this.$store.dispatch( 'setDepartmentOptions', {options:value} )
        }
      },
      showDeleted: {
        get () {
          return this.$store.getters.getDepartmentOptions.showDeleted;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.showDeleted = value;
          this.options = currentOptions;
        }
      },
      editedItem(){
        return this.$store.getters.getDepartmentEdit;
      },
      tableItems(){
        return this.$store.getters.getDepartments;
      },
      departmentsLoadStatus(){
        return this.$store.getters.getDepartmentsLoadStatus;
      },
      departmentEditLoadStatus(){
        return this.$store.getters.getDepartmentEditLoadStatus
      },
      departmentEditStatus(){
        return this.$store.getters.getDepartmentEditStatus();
      },
      departmentAddStatus(){
        return this.$store.getters.getDepartmentAddStatus();
      },
      departmentDeleteStatus(){
        return this.$store.getters.getDepartmentDeleteStatus();
      },
      computedHeaders () {
        var h = _.filter(this.headers,function(o) { return o.visible; })

        if(!this.showDeleted && this.user.permissions['setting'] <= 5) return h.filter(h => h.value!=='status' && h.value!=='actions')
        else if(this.showDeleted && this.user.permissions['setting'] <= 5) return h.filter(h => h.value!=='actions')
        else if(!this.showDeleted) return h.filter(h => h.value!=='status')
        else return h
      },
    },
    mounted(){
      this.loadDepartments();
    },
    methods: {
      loadDepartments(){
        this.search='';
        this.$store.dispatch( 'loadDepartments' );
      },
      loadMore(){
        this.$store.dispatch( 'loadMoreDepartments' );
      },
      reactivateItem(item){
        this.$store.dispatch( 'editDepartmentReactivate',{slug:item.id});
      },
      editItem (item) {
        this.itemWasMain = item.main;
        this.itemEditing = true
        this.$store.dispatch( 'loadDepartmentEdit', {slug:  item.id});  
        this.dialog = true
      },
      deleteItem (item) {
        this.$refs.confirm.open('Usuń', 'Czy na pewno chcesz wyłączyć placówkę?', { color: 'error' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'deleteDepartment', {slug:  item.id});
            }
        })
      },
      forceDeleteItem (item) {
        this.$refs.confirm.open('Usuń', 'Czy na pewno chcesz usunać placówkę na stałe?', { color: 'error' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'forceDeleteDepartment', {slug:  item.id});
            }
        })
      },
      searchDepartments(){
        if(this.search.length>2){
          const filterableHeaders = _.join(_.map(_.filter(this.headers,{'filterable':true}),'filterColumn'),';');
          this.$store.dispatch( 'searchDepartments',{filterableHeaders:filterableHeaders,q:this.search});
        }
      },
      close () {
        this.dialog = false
        setTimeout(() => {
          this.$store.dispatch( 'loadDepartmentEditDefault'); 
          this.$store.dispatch( 'loadDepartmentAddDefault');
          this.itemEditing = false
        }, 300)
      },
    },
  }
</script>


<style scoped>
.search{
      max-width: 250px;
}
</style>
/*
  Adds the promise polyfill for IE 11
*/
require('es6-promise').polyfill();

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import { users } from './modules/users'
import { accounts } from './modules/accounts'
import { bankAccounts } from './modules/bankAccounts'
import { groups } from './modules/groups'
import { departments } from './modules/departments'
import { sequences } from './modules/sequences'
import { integrations } from './modules/integrations'
import { integrationTasks } from './modules/integrationTasks'
import { integrationProducts } from './modules/integrationProducts'
import { integrationDataTypes } from './modules/integrationDataTypes'
import { integrationActions } from './modules/integrationActions'
import { integrationShipments } from './modules/integrationShipments'
import { integrationOrders } from './modules/integrationOrders'
import { integrationDocuments } from './modules/integrationDocuments'
import { payuChecks } from './modules/payuChecks'
import { tpayChecks } from './modules/tpayChecks'
import { stornChecks } from './modules/stornChecks'

import SharedAPI from "./api/shared";

export default new Vuex.Store({
  modules: {
    accounts,
    users,
    groups,
    departments,
    bankAccounts,
    sequences,
    integrations,
    integrationTasks,
    integrationProducts,
    integrationDataTypes,
    integrationActions,
    integrationShipments,
    integrationOrders,
    integrationDocuments,
    payuChecks,
    tpayChecks,
    stornChecks
  },
  state: {
    lookups:{
        permissions: [
            { value: 0, text: "Brak dostępu" },
            { value: 5, text: "Dostęp ograniczony" },
            { value: 10, text: "Pełny dostęp" },
            { value: 20, text: "Administrator" }
        ],
        months:[
          { value: 1, text: "Styczeń" },
          { value: 2, text: "Luty" },
          { value: 3, text: "Marzec" },
          { value: 4, text: "Kwiecień" },
          { value: 5, text: "Maj" },
          { value: 6, text: "Czerwiec" },
          { value: 7, text: "Lipiec" },
          { value: 8, text: "Sierpień" },
          { value: 9, text: "Wrzesień" },
          { value: 10, text: "Pażdziernik" },
          { value: 11, text: "Listopad" },
          { value: 12, text: "Grudzień" },
        ],
        bankAccountCurrency:[
          { value: "PLN", text: "PLN" },
          { value: "EUR", text: "EUR" },
          { value: "USD", text: "USD" },
        ],
        sequenceType:[
          { value: 1, text: "Faktura" },
          { value: 5, text: "Faktura pro forma" },
          { value: 10, text: "Korekta" },
        ],
        dataTypes: [
          { value: "string", text: "string" },
          { value: "number", text: "number" },
          { value: "float", text: "float" },
          { value: "array", text: "array" },
          { value: "dynamic", text: "dynamic" },
          { value: "enum", text: "enum" },
        ],
        integrationTypes: [
          { value: 1, text: "Allegro" },
          { value: 2, text: "Geis" },
          { value: 3, text: "Wysyłki" },
        ],
        accountModules: [
          { value: "admin", text: "Admin" },
          { value: "routes", text: "Przeliczenia" },
          { value: "integrations", text: "Integracje" },
        ],
        integrationTaskLogStatus: [
          { value: 0, text: "Brak" },
          { value: 1, text: "OK" },
          { value: 10, text: "Błąd" },
        ],
        integrationTaskActionsLogStatus: [
          { value: 0, text: "Brak" },
          { value: 1, text: "OK" },
          { value: 10, text: "Błąd" },
          { value: 15, text: "Warunek" },
        ],
        integrationTaskActionsSchedules: [
          { value: 0, text: "Brak harmonogramu" },
          { value: 10, text: "Co 5 minut" },
          { value: 11, text: "Co 15 minut" },
          { value: 1, text: "Co 30 minut" },
          { value: 2, text: "Co godzinę" },
          { value: 3, text: "Co 3 godziny" },
          { value: 4, text: "Co 6 godzin" },
          { value: 5, text: "Codziennie o 24:00" },
          { value: 6, text: "Codziennie o 20:00" },
          { value: 7, text: "Codziennie o 21:00" },
          { value: 8, text: "Codziennie o 22:00" },
          { value: 9, text: "Codziennie o 23:00" }
        ],
        integrationShipmentStatus:[
          { value: 0, text: "Przygotowanie", icon:"mdi-circle-outline", color:"green" },
          { value: 1, text: "Skierowana do produkcji", icon:"mdi-circle-slice-2", color:"green"  },
          { value: 2, text: "Gotowe do wysyłki", icon:"mdi-circle-slice-6", color:"green"  },
          { value: 3, text: "Wysłane", icon:"mdi-circle-slice-8", color:"green"  },
          { value: 4, text: "Zapłacone", icon:"mdi-circle-double", color:"orange" },
          { value: 5, text: "W reklamacji", icon:"mdi-alpha-r-circle-outline", color:"red" },
          { value: 7, text: "Zwrot", icon:"mdi-close-circle-multiple", color:"red" },
        ],
        integrationOrderStatus:[
          { value: 1, text: "W realizacji", icon:"mdi-check-circle-outline", color:"green" },
          { value: 2, text: "Oczekujący", icon:"mdi-checkbox-blank-circle-outline", color:"red"  },
          { value: 3, text: "Zamknięte", icon:"mdi-circle-slice-8", color:"green"  },
          { value: 4, text: "Częściowo zrealizowane", icon:"mdi-circle-slice-4", color:"orange"  },
          { value: 5, text: "Anulowane", icon:"mdi-close-circle", color:"red" },
          { value: 6, text: "Przyjęte do realizacji", icon:"mdi-checkbox-blank-circle-outline", color:"green" },
        ],
        integrationOrderSource:[
          { value: 1, text: "Internetowe", icon:"mdi-basket", color:"green" },
          { value: 2, text: "Telefoniczne", icon:"mdi-phone", color:"orange"  },
          { value: 3, text: "Fax", icon:"mdi-fax", color:"green"  },
          { value: 4, text: "E-Mail", icon:"mdi-email-outline", color:"orange"  },
          { value: 5, text: "Poczta", icon:"mdi-email-edit-outline", color:"red" },
          { value: 6, text: "Nieznane", icon:"mdi-crop-free", color:"red" },
        ],
        orderDirections:[
          { value: "asc", text: "Rosnąco" },
          { value: "desc", text: "Malejąco" },
        ],
        integrationOrderPaymentType:[
          { value: "ONLINE", text: "Przedpłacone" },
          { value: "CASH_ON_DELIVERY", text: "Pobranie" },
        ],
        integrationDocumentTypes:[
          { value: 1, text: "P - Zaliczka"},
          //{ value: 3, text: "Faktura proforma"},
         // { value: 5, text: "Faktura VAT" },
          { value: 10, text: "PK - Storno"},
          { value: 11, text: "PZ - Zwroty"},
          //{ value: 100, text: "Inny"},
        ],
        integrationDocumentStatus:[
          { value: 1, text: "Wygenerowany", icon:"mdi-circle-outline", color:"green" },
          { value: 2, text: "Utworzony", icon:"mdi-circle-outline", color:"orange" },
          //{ value: 5, text: "Wysłany", icon:"mdi-circle-double", color:"green"  },
          { value: 10, text: "Zaksięgowany", icon:"mdi-circle-slice-8", color:"green"  },
          { value: 15, text: "Stornowany", icon:"mdi-close-circle-outline", color:"orange"  },
          { value: 20, text: "Anulowany", icon:"mdi-close-circle", color:"red" },
        ],
        payUCheckStaus:[
          { value: 1, text: "OK"},
          { value: 5, text: "Rozbieżności ceny"},
          { value: 10, text: "Brak zaliczki" },
          { value: 11, text: "Brak zamówienia"},
          { value: 12, text: "Brak nr PRESTA"},
          { value: 15, text: "Zwrot"},
        ],
        stornCheckStaus:[
          { value: 1, text: "OK"},
          { value: 5, text: "Rozbieżności ceny"},
          { value: 6, text: "Rozbieżności daty"},
          { value: 7, text: "Zdublowane storno"},
          { value: 8, text: "Brak paragonu"},
          { value: 10, text: "Brak storna" },
          { value: 11, text: "Brak wysyłki"},
          { value: 12, text: "Brak zamówienia"},
          { value: 13, text: "Brak zapłaty"},
        ],
        groups:[],
        departments:[],
        users:[],
        integrations:[],
        availableActions:[],
        dispSettings:{},
        orderSettings:{},
        docsSettings:{}
    },
    lookupsLoadStatus: 0,
  },
  actions: {
  
    loadLookups({ commit }) {
        commit("setLookupsLoadStatus", 1);

        SharedAPI.getLookups()
            .then(function(response) {
                commit("setGroupsLookup", response.data.groups);
                commit("setDepartmentsLookup", response.data.departments);
                commit("setUsersLookup", response.data.users);
                commit("setIntegrationsLookup", response.data.integrations);
                commit("setAvailableActionsLookup", response.data.availableActions);
                commit("setDispSettingsLookup",response.data.dispSettings);
                commit("setOrderSettingsLookup",response.data.orderSettings);
                commit("setDocsSettingsLookup",response.data.docsSettings);
            })
            .catch(function() {
                commit("setGroupsLookup", []);
                commit("setDepartmentsLookup", []);
                commit("setUsersLookup", []);
                commit("setLookupsLoadStatus", 3);
            });
    },
    loadGroupLookup({ commit }) {
      commit("setLookupsLoadStatus", 1);
      
      SharedAPI.getGroupLookup()
          .then(function(response) {
              commit("setGroupsLookup", response.data);
              commit("setLookupsLoadStatus", 2);
          })
          .catch(function() {
              commit("setGroupsLookup", []);
              commit("setLookupsLoadStatus", 3);
          });
    },
    loadDepartmentLookup({ commit }) {
      commit("setLookupsLoadStatus", 1);
      
      SharedAPI.getDepartmentLookup()
          .then(function(response) {
              commit("setDepartmentsLookup", response.data);
              commit("setLookupsLoadStatus", 2);
          })
          .catch(function() {
              commit("setDepartmentsLookup", []);
              commit("setLookupsLoadStatus", 3);
          });
    },
    loadUserLookup({ commit }) {
      commit("setLookupsLoadStatus", 1);
      
      SharedAPI.getUserLookup()
          .then(function(response) {
              commit("setUsersLookup", response.data);
              commit("setLookupsLoadStatus", 2);
          })
          .catch(function() {
              commit("setUsersLookup", []);
              commit("setLookupsLoadStatus", 3);
          });
    },
    loadIntegrationLookup({ commit }, data) {
      commit("setLookupsLoadStatus", 1);
      
      SharedAPI.getIntegrationLookup()
          .then(function(response) {
              commit("setIntegrationsLookup", response.data);
              commit("setLookupsLoadStatus", 2);
          })
          .catch(function() {
              commit("setIntegrationsLookup", []);
              commit("setLookupsLoadStatus", 3);
          });
    },
    loadAvailableActionLookup({ commit }, data) {
      commit("setLookupsLoadStatus", 1);
      
      SharedAPI.getAvailableActionLookup()
          .then(function(response) {
              commit("setAvailableActionsLookup", response.data);
              commit("setLookupsLoadStatus", 2);
          })
          .catch(function() {
              commit("setAvailableActionsLookup", []);
              commit("setLookupsLoadStatus", 3);
          });
    },

  },
  mutations: {
    setLookupsLoadStatus(state,status){
      state.lookupsLoadStatus = status;
    },
    setGroupsLookup(state,data){
      let local= data;
      local.forEach(l => {
          l.value = parseInt(l.value);
          
      });
      state.lookups.groups = local;
    },
    setDepartmentsLookup(state,data){
      let local= data;
      local.forEach(l => {
          l.value = parseInt(l.value);
      });
      state.lookups.departments = local;
    },
    setUsersLookup(state,data){
      let local= data;
      local.forEach(l => {
          l.value = parseInt(l.value);
      });
      state.lookups.users = local;
    },
    setIntegrationsLookup(state,data){
      let local= data;
      local.forEach(l => {
          l.value = parseInt(l.value);
          l.type = parseInt(l.type);
          l.type_id = parseInt(l.type_id);
      });
      state.lookups.integrations = local;
    },
    setAvailableActionsLookup(state,data){
      let local= data;
      local.forEach(l => {
          l.value = parseInt(l.value);
      });
      state.lookups.availableActions = local;
    },
    setDispSettingsLookup(state,data){
      state.lookups.dispSettings = data;
    },
    setOrderSettingsLookup(state,data){
      state.lookups.orderSettings = data;
    },
    setDocsSettingsLookup(state,data){
      state.lookups.docsSettings = data;
    },
  },
  getters: {
    getLookups(state){
      return state.lookups;
    },
    getLookupsLoadStatus(state){
      return state.lookupsLoadStatus;
    }
  }
})

import axios from 'axios'
import UserAPI from "../api/user";

export const users = {
    state: {
        users: [],
       
        usersLoadStatus: 0,
        userAddStatus: 0,
        userAddText: "",
        userOptions:{
            lineCount:0,
            showDeleted:false,
            orderBy:'surname',
            orderDirection:'asc',
            itemsPerPage:10,
            itemAdded:false,
            itemSearched:false
        },

        token:'',
        user: {},
        userLoadStatus: 0,
        userLoginStatus: 0,
        userUpdateStatus: 0,

        userEdit: {
            id: 0,
            lp: 0,
            login: "",
            name: "",
            surname: "",
            mobile:"",
            group_id: '',
            password: "",
            oneTimePassword: true,
            available_departments:[],
            departments:[]
        },
        userEditDefault: {
            id: 0,
            lp: 0,
            login: "",
            name: "",
            surname: "",
            mobile:"",
            group_id: '',
            password: "",
            oneTimePassword: true,
            available_departments:[],
            departments:[]
        },
        userEditLoadStatus: 0,
        userEditStatus: 0,
        userEditText: "",

        userDeleteStatus: 0,
        userDeleteText: ""
    },

    actions: {
        loginUser( { commit, dispatch }, data ){
          commit( 'setUserLoginStatus', 1 );
                UserAPI.getCookie()
                .then( function( response ){
                    UserAPI.loginUser(
                        data.login,
                        data.password,
                        data.code)
                        .then( function( response ){
                            if(response.status===205){//logowwanie ok, pin request
                                commit( 'setUserLoginStatus', 4 );
                            }else if(response.status===204){
                                dispatch('loadUser');
                                commit( 'setUserLoginStatus', 2 );
                            }
                        })
                        .catch( function(error){
                            if(error.response.data.error==='invalid_credentials'){//nieporawidłowy login i hasło
                                commit( 'setUserLoginStatus', 3 );
                            }else if(error.response.data.error==='invalid_token'){
                                commit( 'setUserLoginStatus', 5 );
                            }
                        });
                })
           
        },
        logoutUser( { commit } ){
                UserAPI.logoutUser()
                .then( function( response ){
                  commit( 'setUserLoginStatus', 0 );
                })
        },
        loadEmptyUser({commit}){
          commit( 'setUser', {} );
          commit( 'setUserLoadStatus', 0 );
          commit( 'setUserLoginStatus', 0 );
        },

        loadUser( { commit,state } ){
          commit( 'setUserLoadStatus', 1 );
          
          if(state.token!=''){
            axios.defaults.headers.common.Authorization = `Bearer ${state.token}`
          }

          UserAPI.getUser()
            .then( function( response ){
              commit( 'setUser', response.data );
              commit( 'setUserLoadStatus', 2 );
            })
            .catch( function(){
              commit( 'setUser', {} );
              commit( 'setUserLoadStatus', 3 );
            })
            .catch(function (err) {
              commit( 'setUser', {} );
              commit( 'setUserLoadStatus', 3 );
          });
        },
        
        //#############################################################################

        loadUsers({ state, commit, dispatch }) {
            commit("setUsersLoadStatus", 1);
                UserAPI.getUsers({
                    offset:0,
                    itemsPerPage:state.userOptions.itemsPerPage, 
                    orderBy:state.userOptions.orderBy,
                    orderDirection:state.userOptions.orderDirection,
                    showDeleted:state.userOptions.showDeleted,
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setUserLineCount", response.data.lineCount);
                    commit("setUsers", response.data.users);
                    dispatch("applyIndexOnUsers");
                    commit("setUsersLoadStatus", 2);
                })
                .catch(function() {
                    commit("setUsers", []);
                    commit("setUsersLoadStatus", 3);
                });
         
        },
        loadMoreUsers({ state, commit, dispatch }) {
            commit("setUsersLoadStatus", 1);
                UserAPI.getUsers({
                    offset:state.users.length,
                    itemsPerPage:state.userOptions.itemsPerPage, 
                    orderBy:state.userOptions.orderBy,
                    orderDirection:state.userOptions.orderDirection,
                    showDeleted:state.userOptions.showDeleted,
                })
                .then(function(response) {
                    commit("setUserLineCount", response.data.lineCount);
                    commit("setUsers", _.concat(state.users, response.data.users));
                    dispatch("applyIndexOnUsers");
                    commit("setUsersLoadStatus", 2);
                })
                .catch(function() {
                    commit("setUsers", []);
                    commit("setUsersLoadStatus", 3);
                });
         
        },
        searchUsers({ state, commit, dispatch },data) {
            commit("setUsersLoadStatus", 1);
                UserAPI.searchUsers({
                    showDeleted:state.userOptions.showDeleted,
                    filterableHeaders:data.filterableHeaders,
                    q:data.q
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setUserSearchedTrue");
                    commit("setUserLineCount", response.data.lineCount);
                    commit("setUsers", response.data.users);
                    dispatch("applyIndexOnUsers");
                    commit("setUsersLoadStatus", 2);
                })
                .catch(function() {
                    commit("setUsers", []);
                    commit("setUsersLoadStatus", 3);
                });
         
        },
        setUserOptions({ state, commit, dispatch },data){
            commit("setUserOptions",data.options);
            dispatch("loadUsers");
        },
        applyIndexOnUsers({ commit, state, rootState }) {
            let localUsers = state.users;

            var i = 1;
            localUsers.forEach(user => {
                // if(_.isNumber(user.group_id)){
                //     //user.group_id = _.find(rootState.lookups.groups, { 'value': user.group_id }).text;
                //     //user.department_id = _.find(rootState.lookups.departments, { 'value': user.department_id }).text;
                // }
                user.lp = i;
                i++;
            });

            commit("setUsers",localUsers);
        },
        addUser({ commit, state, dispatch }, data) {
            commit("setUserAddStatus", 1);
            commit("setUserAddText", "");

            UserAPI.postAddNewUser(
                data.name,
                data.surname,
                data.mobile,
                data.email,
                data.login,
                data.password,
                data.oneTimePassword,
                data.group_id,
                data.departments
            )
                .then(function(response) {
                    commit("setUserAddStatus", 2);
                    commit("setAddedUser",response.data);
                    commit("setUserLineCount", state.userOptions.lineCount + 1);
                    
                    dispatch("loadUserLookup");
                    dispatch("applyIndexOnUsers");
                })
                .catch(function(error) {
                    commit("setUserAddStatus", 3);
                    commit("setUserAddText", error.response.data.errors);
                });
        },

        loadUserEdit({ commit }, data) {
            commit("setUserEditLoadStatus", 1);
            commit("setUserEditStatus", 0);
            commit("setUserEditText", "");

            UserAPI.getUserEdit(data.slug)
                .then(function(response) {
                    commit("setUserEdit", response.data);
                    commit("setUserEditLoadStatus", 2);
                })
                .catch(function() {
                    commit("setUserEdit", {});
                    commit("setUserEditLoadStatus", 3);
                });
        },
        loadUserEditDefault({ commit }) {
            commit("setUserEditDefault");
        },
        loadUserAddDefault({ commit }) {
            commit("setUserAddStatus", 0);
            commit("setUserAddText", "");
        },
        editUser({ commit, state, dispatch }, data) {
            commit("setUserEditStatus", 1);

            UserAPI.putEditUser(
                data.slug,
                data.name,
                data.surname,
                data.mobile,
                data.email,
                data.login,
                data.group_id,
                data.departments
            )
                .then(function(response) {
           
                    commit("setUserEditStatus", 2);
                    commit("setEditedUser",response.data)
                    dispatch("applyIndexOnUsers");
                    dispatch("loadUserLookup");
                })
                .catch(function(error) {
                    commit("setUserEditStatus", 3);
                    commit("setUserEditText", error.response.data.errors);
                });
        },
        editUserReactivate({ commit, state, dispatch }, data) {
            commit("setUserEditStatus", 1);
  
            UserAPI.putReactivateUser(data.slug)
                .then(function(response) {
                    commit("setUserEditStatus", 2);
                    commit("setEditedUser",response.data);
                    
                    dispatch("applyIndexOnUsers");
                    dispatch("loadUserLookup");
                })
                .catch(function(error) {
                    commit("setUserEditStatus", 3);
                });
        },
        deleteUser({ commit, state, dispatch }, data) {
            commit("setUserDeleteStatus", 1);

            UserAPI.deleteUser(data.slug)
                .then(function(response) {
                    commit("setUserDeleteStatus", 2);
                    if(state.userOptions.showDeleted){
                        commit("setEditedUser",response.data)
                    }else{
                        commit("setUserLineCount", state.userOptions.lineCount - 1);
                        commit("setDeletedUser",response.data) 
                    }
                    
                    dispatch("loadUserLookup");
                    dispatch("applyIndexOnUsers");
                })
                .catch(function() {
                    commit("setUserDeleteStatus", 3);
                });
        },
        forceDeleteUser({ commit, state, dispatch }, data) {
            commit("setUserDeleteStatus", 1);

            UserAPI.forceDeleteUser(data.slug)
                .then(function(response) {
                    commit("setUserDeleteStatus", 2);
                    commit("setUserLineCount", state.userOptions.lineCount - 1);
                    commit("setDeletedUser",response.data)
                    dispatch("applyIndexOnUsers");
                })
                .catch(function() {
                    commit("setUserDeleteStatus", 3);
                });
        },    
        editUserPassword({ commit, state, dispatch }, data) {
          commit("setUserEditStatus", 1);

          UserAPI.putEditUserPassword(
              data.slug,
              data.password,
              data.oneTimePassword,
          )
              .then(function(response) {
                  commit("setUserEditStatus", 2);
                  //dispatch("loadUsers");
              })
              .catch(function(error) {
                  commit("setUserEditStatus", 3);
              });
        },
        editUserOneTimePassword({ commit, state, dispatch }, data) {
          commit("setUserEditStatus", 1);

          UserAPI.putEditUserOneTimePassword(
              data.slug,
              data.password,
              data.oldPassword,
          )
              .then(function(response) {
                  commit("setUserEditStatus", 2);
                  dispatch("loadUser");
              })
              .catch(function(error) {
                  commit("setUserEditStatus", 3);
              });
        },
    },
    mutations: {
        setToken( state, token ){
            state.token = token;
          },
        setUserLoadStatus( state, status ){
          state.userLoadStatus = status;
        },
        setUserLoginStatus( state, status ){
          state.userLoginStatus = status;
        },
        setUser( state, user ){
            if(user){
                state.user = user;
            }else{
                state.user = {};
            }
        },
        setUserUpdateStatus( state, status ){
          state.userUpdateStatus = status;
        },

        setUserOptions(state,options){
            state.userOptions = options;
        },

        //##############################################
        
        setUsersLoadStatus(state, status) {
            state.usersLoadStatus = status;
        },
        setUsers(state, users) {
            state.users = users;
        },
        setUserLineCount(state, lineCount) {
            state.userOptions.lineCount = lineCount;
        },
        setUserAddStatus(state, status) {
            state.userAddStatus = status;
        },
        setUserAddText(state, text) {
            state.userAddText = text;
        },
        setUserEdit(state, user) {
            state.userEdit = user;
        },
        setEditedUser(state, user) {
            var lp = _.find(state.users,{'id':user.id}).lp;
            var index = _.findIndex(state.users,{'id':user.id});
            state.users[index] = user;
            state.users[index].lp = lp;
        },
        setAddedUser(state, user) {
            state.userOptions.itemAdded = true;
            state.users.push(user);
        },
        setUserSearchedTrue(state) {
            state.userOptions.itemSearched = true;
        },
        setOptionsItemAddedAndSearchedFalse(state) {
            state.userOptions.itemAdded = false;
            state.userOptions.itemSearched = false;
        },
        setDeletedUser(state, user) {
            var index = _.findIndex(state.users,{'id':user.id});
            state.users.splice(index,1);
        },
        setUserEditDefault(state) {
            state.userEdit = { ...state.userEditDefault };
        },
        setUserEditStatus(state, status) {
            state.userEditStatus = status;
        },
        setUserEditText(state, text) {
            state.userEditText = text;
        },
        setUserEditLoadStatus(state, status) {
            state.userEditLoadStatus = status;
        },
        setUserDeleteStatus(state, status) {
            state.userDeleteStatus = status;
        },
        setUserDeletedText(state, text) {
            state.userDeleteText = text;
        }
    },

    getters: {
        getUserLoadStatus( state ){
          return function(){
            return state.userLoadStatus;
          }
        },
        getUserLoginStatus( state ){
            return state.userLoginStatus;
        },
        getUser( state ){
          return state.user;
        },
        getUserOneTime( state ){
            return function(){
                return state.user.oneTimePassword;
            }  
        },
        getUserUpdateStatus( state, status ){
          return state.userUpdateStatus;
        },

        //#################################################

        getUserOptions(state){
            return state.userOptions;
        },
        getUsersLoadStatus(state) {
            return state.usersLoadStatus;
        },
        getUsers(state) {
            return state.users;
        },
        getUserAddStatus(state) {
            return function(){
                return state.userAddStatus;
            } 
        },
        getUserAddText(state) {
            return state.userAddText;
        },
        getUserEdit(state) {
            return state.userEdit;
        },
        getUserEditStatus(state) {
          return function(){
            return state.userEditStatus;
          }
        },
        getUserEditing(state) {
            return state.userEdit.id > 0;
        },
        getUserEditText(state) {
            return state.userEditText;
        },
        getUserEditLoadStatus(state) {
            return state.userEditLoadStatus;
            
        },
        getUserDeleteStatus(state) {
            return function(){
                return state.userDeleteStatus;
            }
        },
        getUserDeleteText(state) {
            return state.userDeleteText;
        }
    }
};

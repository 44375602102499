<template>
      <v-col>
          <v-card flat>
            <v-data-table
                :key="componentKey"
                :headers="computedHeaders"
                :items="tableItems"
                class="elevation-1"
                :loading="usersLoadStatus===1"
                :options.sync="tableOptions"
                :server-items-length="options.lineCount"
                hide-default-footer
                must-sort
            >
                <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-toolbar-title>Użytkownicy</v-toolbar-title>
                    
                    <v-divider
                    class="mx-4"
                    inset
                    vertical
                    ></v-divider>
                    
                     <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-on="on"
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Wyszukaj"
                          single-line
                          hide-details
                          class="search"
                          @keydown.enter="searchUsers"
                      ></v-text-field>
                      </template>
                        <span>Wpisz min 3 znaki i naciśnij Enter</span>
                    </v-tooltip>

                   <v-spacer></v-spacer>
                   <v-checkbox v-model="showDeleted" class="mt-4" label="Pokaż wyłączone"></v-checkbox>   

                   <v-divider
                    v-if="user.permissions['setting'] >= 10"
                    class="mx-4"
                    inset
                    vertical
                    ></v-divider>

                    <v-dialog  v-if="user.permissions['setting'] >= 10" v-model="dialog" max-width="700px">
                    <template v-slot:activator="{ on }">
                        <v-btn @click="newItem" v-on="on" small class="mb-2 mt-2" fab dark color="primary">
                        <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <v-card :loading="userAddStatus===1 || userEditLoadStatus===1 || userEditStatus===1">
                        <v-card-title>
                          <img class="mr-3" src="/logo_listek.png"></img>
                           <span class="headline">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="4" md="4">
                                <v-text-field v-model="editedItem.name" :error-messages="nameErrors" required @input="$v.editedItem.name.$touch()"  @blur="$v.editedItem.name.$touch()"  label="Imię"></v-text-field>                            
                                </v-col>

                                <v-col cols="12" sm="4" md="4">
                                <v-text-field v-model="editedItem.surname" :error-messages="surnameErrors" required @input="$v.editedItem.surname.$touch()"  @blur="$v.editedItem.surname.$touch()"  label="Nazwisko"></v-text-field>                            
                                </v-col>

                                <v-col cols="12" sm="4" md="4">
                                <v-text-field 
                                 v-model="editedItem.login" 
                                 :error-messages="loginErrors"
                                 required 
                                 append-icon="mdi-face"
                                 @click:append="generateLogin"
                                 @input="$v.editedItem.login.$touch()"  
                                 @blur="$v.editedItem.login.$touch()" 
                                 label="Login"></v-text-field>                            
                                </v-col>
                                      
                                <v-col cols="12" sm="4" md="4">
                                <v-text-field counter="9" v-model="editedItem.mobile" :error-messages="mobileErrors" required @input="$v.editedItem.mobile.$touch()"  @blur="$v.editedItem.mobile.$touch()"  label="Telefon"></v-text-field>                            
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                  <v-text-field v-model="editedItem.email" :error-messages="emailErrors" required @input="$v.editedItem.email.$touch()"  @blur="$v.editedItem.email.$touch()" label="E-mail"></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                <v-select v-model="editedItem.group_id" :items="lookups.groups" :error-messages="groupErrors" required @input="$v.editedItem.group_id.$touch()"  @blur="$v.editedItem.group_id.$touch()" label="Stanowisko"></v-select>
                                </v-col>

                                <!-- <v-col cols="12" sm="6" md="4">
                                <v-select v-model="editedItem.department_id" :items="lookups.departments" :error-messages="departmentErrors" required @input="$v.editedItem.department_id.$touch()"  @blur="$v.editedItem.department_id.$touch()" label="Placówka"></v-select>
                                </v-col> -->

                                <v-col v-if="!itemEditing" cols="12" sm="6" md="4">
                                <v-text-field 
                                  v-if="!itemEditing"
                                  append-icon="mdi-refresh"
                                  @click:append="generatePassword"
                                  v-model="editedItem.password" 
                                  :error-messages="passwordErrors" 
                                  required 
                                  @input="$v.editedItem.password.$touch()"  
                                  @blur="$v.editedItem.password.$touch()"  
                                  label="Hasło"></v-text-field>                            
                                </v-col>

                                <v-col v-if="!itemEditing" cols="12" sm="8" md="8">
                                  <v-checkbox
                                    v-model="editedItem.oneTimePassword"
                                    label="Wymagana zmiana hasła przy pierwszym logowaniu"
                                  ></v-checkbox> 
                            </v-col>

                            </v-row>

                            <v-row>
                              <v-col class="col-12 pt-0 pl-0 pr-0">
                              <v-data-table
                                  v-model="editedItem.departments"
                                  :headers="departmentHeaders"
                                  :items="editedItem.available_departments"
                                  class="elevation-1"
                                  hide-default-footer
                                  must-sort
                                  dense
                                  show-select
                              >
                                  <template v-slot:top>
                                  <v-toolbar flat dense color="white">
                                      <v-toolbar-title>Praca w oddziale</v-toolbar-title>
                                      <v-spacer></v-spacer>
                                      <v-chip v-if="departmentsErrors.length>0" label outlined dense color="error">{{departmentsErrors.length?departmentsErrors[0]:''}}</v-chip>
                                  </v-toolbar>
                                  </template>

                              <template v-slot:item.main="{ item }">
                                <v-simple-checkbox v-model="item.main" disabled></v-simple-checkbox>
                              </template>
                              <template v-slot:item.address="{ item }">
                                {{item.street}}, {{item.post}} {{item.city}}
                              </template>
                              </v-data-table>
                              </v-col>
                            </v-row>

                            <v-row v-if="userEditStatus===3">
                                    <v-col class="d-flex flex-column align-center">
                                        <v-alert v-for="err in errorMessageArray(userEditText)" :key="err.index" dense outlined type="error">
                                          {{err}}<v-btn v-if="err.includes('został usunięty')" @click="reactivateUser(editedItem.email)" small class="ml-1" text color="primary">Przywróć</v-btn>
                                        </v-alert>    
                                    </v-col>
                            </v-row>   
                            <v-row v-if="userAddStatus===3">
                                    <v-col class="d-flex flex-column align-center">
                                        <v-alert v-for="err in errorMessageArray(userAddText)" :key="err.index" dense outlined type="error">
                                          {{err}}<v-btn v-if="err.includes('został usunięty')" @click="reactivateUser(editedItem.email)" small class="ml-1" text color="primary">Przywróć</v-btn>
                                        </v-alert>    
                                    </v-col>
                            </v-row>                      
                        </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <p class="text--secondary caption ml-5">Minimalne wymagania hasła <br />Minimum 8 znaków, 1 duża litera, 1 cyfra</p>
                          <v-spacer></v-spacer>
                          <v-btn color="grey" text @click="close">Anuluj</v-btn>
                          <v-btn color="primary darken-1" text @click="handleSubmit" >Zapisz</v-btn>
                        </v-card-actions>
                    </v-card>

                    </v-dialog>
                    <v-dialog v-model="dialogPassword" max-width="700px">
                    <v-card>
                        <v-card-title>
                        <span class="headline">Zmiana hasła</span>
                        </v-card-title>

                        <v-card-text>
                        <v-container>
                            <v-row>     
                             
                                <v-col cols="12" sm="6" md="6">
                                <v-text-field 
                                  append-icon="mdi-refresh"
                                  @click:append="generatePassword"
                                  v-model="editedItem.password" 
                                  :error-messages="passwordErrors" 
                                  hide-details="auto"
                                  required 
                                  @input="$v.editedItem.password.$touch()"  
                                  @blur="$v.editedItem.password.$touch()"  
                                  label="Nowe hasło"></v-text-field>                            
                                </v-col>

                                <v-col cols="12" sm="12" md="12">
                                  <v-checkbox
                                    v-model="editedItem.oneTimePassword"
                                    hide-details="auto"
                                    label="Wymagana zmiana hasła przy pierwszym logowaniu"
                                  ></v-checkbox> 
                            </v-col>

                            </v-row>                      
                        </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <p class="text--secondary caption ml-5">Minimalne wymagania hasła <br />Minimum 8 znaków, 1 duża litera, 1 cyfra</p>
                          <v-spacer></v-spacer>
                          <v-btn color="grey" text @click="closePassword">Anuluj</v-btn>
                          <v-btn color="primary darken-1" text @click="handleSubmitPassword" >Zapisz</v-btn>
                        </v-card-actions>
                    </v-card>
                    </v-dialog>
                </v-toolbar>
                </template>

                <!-- <template v-slot:item.department_id="{ item }">
                  <span :class="item.department.status?'':'deleted'">{{item.department.name}}</span>
                </template> -->


                <template v-slot:item.actions="{ item }">
                <v-tooltip v-if="item.status && user.permissions['setting'] >= 10" right>
                  <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    size="18"
                    class="mr-2"
                    @click="editItem(item)"
                  >
                    mdi-pencil
                  </v-icon>
                  </template>
                    <span>Edytuj</span>
                </v-tooltip>

                <v-tooltip v-if="item.status && user.permissions['setting'] >= 10" right>
                  <template v-slot:activator="{ on }">
                  <v-icon
                      v-on="on"
                      size="18"
                      class="mr-2"
                      @click="editItemPassword(item)"
                  >
                      mdi-key
                  </v-icon>
                </template>
                    <span>Zmień hasło</span>
                </v-tooltip>

                <v-tooltip v-if="item.status && item.can_delete && user.permissions['setting'] >= 10" right>
                  <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    size="18"
                    class=""
                    @click="deleteItem(item)"
                  >
                    mdi-delete
                  </v-icon>
                  </template>
                  <span>Wyłącz</span>
                </v-tooltip>

                <v-tooltip v-if="!item.status  && user.permissions['setting'] >= 20" right>
                  <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    size="18"
                    class="mr-2"
                    @click="reactivateItem(item)"
                  >
                    mdi-delete-restore
                  </v-icon>
                  </template>
                  <span>Przywróć</span>
                </v-tooltip>

                <v-tooltip v-if="!item.status && item.can_delete  && user.permissions['setting'] >= 20" right>
                  <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    size="18"
                    class=""
                    color="error"
                    @click="forceDeleteItem(item)"
                  >
                    mdi-delete-forever
                  </v-icon>
                  </template>
                  <span>Usuń na stałe</span>
                </v-tooltip>

                
            </template>

            <template v-slot:item.status="{ item }">
              <v-simple-checkbox v-model="item.status" disabled></v-simple-checkbox>
            </template>
            </v-data-table> 
            <div class="text-center pt-2">
            <v-btn @click="loadMore" v-if="!options.itemAdded && options.lineCount-tableItems.length>0 && usersLoadStatus===2" small color="primary"><v-icon left>mdi-download</v-icon>Załaduj więcej ({{ options.lineCount-tableItems.length }})</v-btn>
            <v-btn @click="loadUsers" v-else-if="options.itemAdded && options.lineCount-tableItems.length>0" small color="primary"><v-icon left>mdi-reload</v-icon>Odśwież tabele aby móc załadować więcej ({{ options.lineCount-tableItems.length }})</v-btn>
            <v-btn @click="loadUsers" v-else-if="options.itemSearched" small color="primary"><v-icon left>mdi-reload</v-icon>Anuluj wyszukiwanie</v-btn>
          
          </div>
          </v-card>
          <confirm ref="confirm"></confirm>
      </v-col>
  

</template>

<script>
  import confirm from '../components/Confirm'
  import required from 'vuelidate/lib/validators/required'
  import minLength from 'vuelidate/lib/validators/minLength'
  import maxLength from 'vuelidate/lib/validators/maxLength'
  import numeric from 'vuelidate/lib/validators/numeric'
  import email from 'vuelidate/lib/validators/email'
  import passwordComplexity from '../plugins/passwordComplexity'

  export default {
    components:{
      confirm
    },
    data: () => ({
      componentKey:0,
      tableOptions: {},
      search:'',
      dialog: false,
      dialogPassword:false,
      itemEditing:false,
      itemEditingPassword:false,
      headers: [
        { text: '',align: 'start',sortable: false,value: 'lp',filterable:false,},
        { text: 'Nazwisko', value: 'surname',filterable:true,filterColumn:"surname" },
        { text: 'Imię', value: 'name',filterable:true,filterColumn:"name" },
        { text: 'Login', value: 'login',filterable:true,filterColumn:"login" },
        { text: 'Telefon', value: 'mobile',filterable:true,filterColumn:"mobile" },
        { text: 'E-mail', value: 'email',filterable:true,filterColumn:"email" },
        { text: 'Stanowisko', value: 'group_id',filterable:true,filterColumn:"group.name" },
        { text: 'Status', value: 'status', sortable: false,filterable:false  },
        { text: 'Akcje', value: 'actions', sortable: false,filterable:false },
      ],
      departmentHeaders: [
        { text: 'Nazwa firmy/oddziału', value: 'name',filterable:true,filterColumn:"name",visible:true  },
        { text: 'Centrala', value: 'main',filterable:false,visible:true  },
        { text: 'Adres', value: 'address',sortable: false,filterable:false,visible:true  },
        { text: '',value:'data-table-select',sortable: false}
      ],
    }),
    validations() {
      if(this.itemEditingPassword){
        return {
        editedItem:{
          password:{
            required,
            minLength: minLength(8),
            passwordComplexity
          },
        }}
      }

      if(this.itemEditing){
        return {
        editedItem:{
          name: {
            required,
          },
          surname: {
            required,
          },
          group_id:{
            required
          },
          departments:{
            required,
            minLength:minLength(1),
          },
          login:{
            required
          },
          email:{
            email
          },
          mobile:{
            required,
            numeric,
            minLength:minLength(9),
            maxLength:maxLength(9)
          },
        }}
      }else{
        return {
        editedItem:{
          name: {
            required,
          },
          surname: {
            required,
          },
          group_id:{
            required
          },
          login:{
            required
          },
          departments:{
            required,
            minLength:minLength(1),
          },
          email:{
            email
          },
          mobile:{
            required,
            numeric,
            minLength:minLength(9),
            maxLength:maxLength(9)
          },
          password:{
            required,
            minLength: minLength(8),
            passwordComplexity
          },
        }}
      }
      
    },
    watch: {
      tableOptions: {
        handler () {
          var currentOptions = {...this.options};
          const { sortBy, sortDesc } = this.tableOptions
        
          //zmiana sortowania
          if (sortBy.length === 1 && sortDesc.length === 1) {
            currentOptions.orderBy = sortBy[0];
            currentOptions.orderDirection = sortDesc[0]?'desc':'asc';
            this.options = currentOptions;
          } 
        },
        deep: true,
      },
      dialog (val) {
        this.$v.$reset()
        if(val){
          this.editedItem.password = this.randString()
        }else{
          this.close()
        }
      },
      dialogPassword (val) {
        this.$v.$reset()
        if(val){
          this.editedItem.oneTimePassword = true
          this.editedItem.password = this.randString()
        }else{
          this.close()
        }
      },
      userEditStatus(val){
        if(val===2){
          this.close();
          this.componentKey += 1;
        }
      },
      userAddStatus(val){
        if(val===2){
          this.close();
          this.componentKey += 1;
        }
      },
      userDeleteStatus(val){
        if(val===2){
          this.componentKey += 1;
        }
      }
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      lookups(){
        return this.$store.getters.getLookups;
      },
      options:{
        get () {  
          return this.$store.getters.getUserOptions;
        },
        set (value) {
          this.search='';
          this.$store.dispatch( 'setUserOptions', {options:value} )
        }
      },
      showDeleted: {
        get () {
          return this.$store.getters.getUserOptions.showDeleted;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.showDeleted = value;
          this.options = currentOptions;
        }
      },
      editedItem(){
        return this.$store.getters.getUserEdit;
      },
      tableItems(){
        return this.$store.getters.getUsers;
      },
      roles(){
        return this.$store.getters.getGroups;
      },
      usersLoadStatus(){
        return this.$store.getters.getUsersLoadStatus;
      },
      userEditLoadStatus(){
        return this.$store.getters.getUserEditLoadStatus
      },
      userEditStatus(){
        return this.$store.getters.getUserEditStatus();
      },
      userEditText(){
        return this.$store.getters.getUserEditText
      },
      userAddStatus(){
        return this.$store.getters.getUserAddStatus();
      },
      userAddText(){
        return this.$store.getters.getUserAddText;
      },
      userDeleteStatus(){
        return this.$store.getters.getUserDeleteStatus();
      },
      computedHeaders () {
        if(!this.showDeleted && this.user.permissions['setting'] <= 5) return this.headers.filter(h => h.value!=='status' && h.value!=='actions')
        else if(this.showDeleted && this.user.permissions['setting'] <= 5) return this.headers.filter(h => h.value!=='actions')
        else if(!this.showDeleted) return this.headers.filter(h => h.value!=='status')
        else return this.headers
      },
      formTitle () {
        return !this.itemEditing ? 'Nowy użytkownik' : 'Edycja użytkownika'
      },
      nameErrors () {
        const errors = []
        if (this.itemEditingPassword || !this.$v.editedItem.name.$dirty) return errors
        !this.$v.editedItem.name.required && errors.push('Imię jest wymagane')
        return errors
      },
      surnameErrors () {
        const errors = []
        if (this.itemEditingPassword || !this.$v.editedItem.surname.$dirty) return errors
        !this.$v.editedItem.surname.required && errors.push('Nazwisko jest wymagane')
        return errors
      },
     
      groupErrors () {
        const errors = []
        if (this.itemEditingPassword || !this.$v.editedItem.group_id.$dirty) return errors
        !this.$v.editedItem.group_id.required && errors.push('Stanowisko jest wymagane')
        return errors
      },
      emailErrors () {
        const errors = []
        if (this.itemEditingPassword || !this.$v.editedItem.email.$dirty) return errors
        !this.$v.editedItem.email.email && errors.push('E-mail jest niepoprawny')
        return errors
      },
      passwordErrors () {
        const errors = []
        if (this.itemEditing || !this.$v.editedItem.password.$dirty) return errors
        !this.itemEditing && !this.$v.editedItem.password.required && errors.push('Hasło jest wymagane')
        !this.itemEditing && !this.$v.editedItem.password.minLength && errors.push('Hasło jest za krótkie')
        !this.itemEditing && !this.$v.editedItem.password.passwordComplexity && errors.push('Hasło jest za proste')
        return errors
      },
      mobileErrors () {
        const errors = []
        if (this.itemEditingPassword || !this.$v.editedItem.mobile.$dirty) return errors
        !this.$v.editedItem.mobile.required && errors.push('Telefon jest wymagany')
        !this.$v.editedItem.mobile.numeric && errors.push('Telefon może zawierać tylko cyfry')
        !this.$v.editedItem.mobile.minLength && errors.push('Telefon musi mieć 9 cyfr')
        !this.$v.editedItem.mobile.maxLength && errors.push('Telefon musi mieć 9 cyfr')
        return errors
      },
      departmentsErrors () {
        const errors = []
        if (this.itemEditingPassword || !this.$v.editedItem.departments.$dirty) return errors
        !this.$v.editedItem.departments.required && errors.push('Wybierz co najmniej jeden oddział')
        !this.$v.editedItem.departments.minLength && errors.push('Wybierz co najmniej jeden oddział')
        return errors
      },
      loginErrors () {
        const errors = []
        if (this.itemEditingPassword || !this.$v.editedItem.login.$dirty) return errors
        !this.$v.editedItem.login.required && errors.push('Login jest wymagany')
        return errors
      },
      
    },
    mounted(){
       if(this.lookups.departments.length>0){
        this.loadUsers();
      }else{
        this.$store.dispatch( 'loadLookups' );
        setTimeout(() => {
          this.loadUsers();
        }, 1000)
      }
    },
    methods: {
      loadUsers(){
        this.search='';
        this.$store.dispatch( 'loadUsers' );
      },
      loadMore(){
        this.$store.dispatch( 'loadMoreUsers' );
      },
      reactivateItem(item){
        this.$store.dispatch( 'editUserReactivate',{slug:item.id});
      },
      errorMessageArray(val) {
        const errors = val;
        var err = [];
        if(!errors)return [];

        if(errors.login){
          errors.login.forEach(e => {
            err.push(e);
          });
        }

        if(errors.name){
          errors.name.forEach(e => {
            err.push(e);
          });
        }
      
        return err;
      },     
      generatePassword(){
        this.editedItem.password = this.randString()
      },
      generateLogin(){
        if(this.editedItem.name && this.editedItem.surname){
          this.editedItem.login = _.toLower(this.noPL(this.editedItem.name)).substring(0,1)
           + '.' + _.toLower(_.replace(this.noPL(this.editedItem.surname)))
        }
      },
      noPL(val){
        return val.replace(/ą/g, 'a').replace(/Ą/g, 'A')
        .replace(/ć/g, 'c').replace(/Ć/g, 'C')
        .replace(/ę/g, 'e').replace(/Ę/g, 'E')
        .replace(/ł/g, 'l').replace(/Ł/g, 'L')
        .replace(/ń/g, 'n').replace(/Ń/g, 'N')
        .replace(/ó/g, 'o').replace(/Ó/g, 'O')
        .replace(/ś/g, 's').replace(/Ś/g, 'S')
        .replace(/ż/g, 'z').replace(/Ż/g, 'Z')
        .replace(/ź/g, 'z').replace(/Ź/g, 'Z');
      },
      randString(){
        var possible = 'ABCDEFGHIJKLMNPQRSTUVWXYZ123456789';
        var possibleNumber = '23456789';
        var text = '';
        for(var i=0; i < 7; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        text += possibleNumber.charAt(Math.floor(Math.random() * possibleNumber.length));
        return text;
      },
      editItem (item) {
        this.itemEditing = true
        this.$store.dispatch( 'loadUserEdit', {slug:  item.id});  
        this.dialog = true
      },
      newItem(){
        this.$store.dispatch( 'loadUserEdit', {slug:  0});
      },
      editItemPassword (item) {  
        this.itemEditingPassword = true
        this.editedItem.id = item.id
        this.dialogPassword = true
      },

      deleteItem (item) {
        this.$refs.confirm.open('Usuń', 'Czy na pewno chcesz usunać użytkownika?', { color: 'error' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'deleteUser', {slug:  item.id});
            }
        })
      },
      reactivateUser(email){//######################################################
        //this.$store.dispatch( 'editUserReactivate',{email:email,password:this.randString()});
      },
      forceDeleteItem (item) {
        this.$refs.confirm.open('Usuń', 'Czy na pewno chcesz usunać użytkownika na stałe?', { color: 'error' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'forceDeleteUser', {slug:  item.id});
            }
        })
      },
      searchUsers(){
        if(this.search.length>2){
          const filterableHeaders = _.join(_.map(_.filter(this.headers,{'filterable':true}),'filterColumn'),';');
          this.$store.dispatch( 'searchUsers',{filterableHeaders:filterableHeaders,q:this.search});
        }
      },
      close () {
        this.dialog = false
        this.$v.$reset()
        setTimeout(() => {
          this.$store.dispatch( 'loadUserEditDefault'); 
          this.$store.dispatch( 'loadUserAddDefault');
          this.itemEditing = false
        }, 300)
      },
      closePassword () {
        this.dialogPassword = false
        this.$v.$reset()
        setTimeout(() => {
          this.itemEditingPassword = false
        }, 300)
      },
      handleSubmit () {
        this.$v.$touch()
        if (this.$v.$invalid) {

        } else {
         
            if (this.itemEditing) {
              //edycja
              this.$store.dispatch( 'editUser', {
                slug: this.editedItem.id,
                name:  this.editedItem.name,
                surname:  this.editedItem.surname,
                mobile:  this.editedItem.mobile,
                email:  this.editedItem.email,
                login: this.editedItem.login,
                group_id:  this.editedItem.group_id,
                departments: _.map(this.editedItem.departments,'id')
              });
            } else {//new item

              this.$store.dispatch( 'addUser', {
                  name:  this.editedItem.name,
                  surname:  this.editedItem.surname,
                  mobile:  this.editedItem.mobile,
                  email:  this.editedItem.email,
                  login: this.editedItem.login,
                  group_id:  this.editedItem.group_id,
                  password:  this.editedItem.password,
                  oneTimePassword: this.editedItem.oneTimePassword,
                  departments: _.map(this.editedItem.departments,'id')
              });

            }
            //this.close()
    
        }
       
      },
       handleSubmitPassword () {
        this.$v.$touch()
        if (this.$v.$invalid) {

        } else {
              this.$store.dispatch( 'editUserPassword', {
                slug: this.editedItem.id,
                password:  this.editedItem.password,
                oneTimePassword: this.editedItem.oneTimePassword
              });
            
            this.closePassword()
  
        }
       
      },
    },
  }
</script>


<style scoped>
.search{
      max-width: 250px;
}
</style>

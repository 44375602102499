import IntegrationActionAPI from "../api/integrationAction";
import i18n from "../main";

export const integrationActions = {
    state: {
        integrationActions: [],
        integrationActionOptions:{
            lineCount:0,
            orderBy:'name',
            orderDirection:'asc',
            itemsPerPage:20,
            itemAdded:false,
        },

        integrationActionsLoadStatus: 0,
        integrationActionAddStatus: 0,
        integrationActionAddText: "",

        integrationActionEdit: {
            id:0,
            lp: 0,
            integration_type_id:undefined,
            name:undefined,
            version:undefined,
            label:undefined,
            description:undefined,
            input:null,
            output:null,
            filters:null,
            conditions:null,
        },
        integrationActionEditDefault: {
            id:0,
            lp: 0,
            integration_type_id:undefined,
            name:undefined,
            version:undefined,
            label:undefined,
            description:undefined,
            input:null,
            output:null,
            filters:null,
            conditions:null,
        },
        integrationActionAddLoadStatus: 0,
        integrationActionAddStatus: 0,
        integrationActionAddText: "",
        
        integrationActionEditLoadStatus: 0,
        integrationActionEditStatus: 0,
        integrationActionEditText: "",

        integrationActionDeleteStatus: 0,
        integrationActionDeleteText: "",

    },

    actions: {
        loadIntegrationActions({ state, commit, dispatch }) {
            commit("setIntegrationActionsLoadStatus", 1);
                IntegrationActionAPI.getIntegrationActions({
                    offset:0,
                    itemsPerPage:state.integrationActionOptions.itemsPerPage, 
                    orderBy:state.integrationActionOptions.orderBy,
                    orderDirection:state.integrationActionOptions.orderDirection,
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setIntegrationActionLineCount", response.data.lineCount);
                    commit("setIntegrationActions", response.data.integrationActions);
                    dispatch("applyIndexOnIntegrationActions");
                    commit("setIntegrationActionsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setIntegrationActions", []);
                    commit("setIntegrationActionsLoadStatus", 3);
                });
         
        },
        loadMoreIntegrationActions({ state, commit, dispatch }) {
            commit("setIntegrationActionsLoadStatus", 1);
                IntegrationActionAPI.getIntegrationActions({
                    offset:state.integrationActions.length,
                    itemsPerPage:state.integrationActionOptions.itemsPerPage, 
                    orderBy:state.integrationActionOptions.orderBy,
                    orderDirection:state.integrationActionOptions.orderDirection,
                })
                .then(function(response) {
                    commit("setIntegrationActionLineCount", response.data.lineCount);
                    commit("setIntegrationActions", _.concat(state.integrationActions, response.data.integrationActions));
                    dispatch("applyIndexOnIntegrationActions");
                    commit("setIntegrationActionsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setIntegrationActions", []);
                    commit("setIntegrationActionsLoadStatus", 3);
                });
         
        },
        searchIntegrationActions({ state, commit, dispatch },data) {
            commit("setIntegrationActionsLoadStatus", 1);
                IntegrationActionAPI.searchIntegrationActions({
                    integrationId:state.integrationActionOptions.integrationId,
                    filterableHeaders:data.filterableHeaders,
                    q:data.q
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setIntegrationActionSearchedTrue");
                    commit("setIntegrationActionLineCount", response.data.lineCount);
                    commit("setIntegrationActions", response.data.integrationActions);
                    dispatch("applyIndexOnIntegrationActions");
                    commit("setIntegrationActionsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setIntegrationActions", []);
                    commit("setIntegrationActionsLoadStatus", 3);
                });
         
        },
        setIntegrationActionOptions({ state, commit, dispatch },data){
            commit("setIntegrationActionOptions",data.options);
            dispatch("loadIntegrationActions");
        },
        applyIndexOnIntegrationActions({ commit, state, dispatch, rootState }) {
            let localIntegrationActions = state.integrationActions;

            var i = 1;
            localIntegrationActions.forEach(integrationAction => {
                if(_.isNumber(integrationAction.integration_type_id)){
                    integrationAction.integration_type_id = _.find(rootState.lookups.integrationTypes, { 'value': integrationAction.integration_type_id }).text;
                }
                integrationAction.lp = i;
                i++;
            });

            commit("setIntegrationActions",localIntegrationActions);
        },
      
        loadIntegrationActionEdit({ commit }, data) {
            commit("setIntegrationActionEditLoadStatus", 1);
            commit("setIntegrationActionEditStatus", 0);
            commit("setIntegrationActionEditText", "");

            IntegrationActionAPI.getIntegrationActionEdit(data.slug)
                .then(function(response) {
                    commit("setIntegrationActionEdit", response.data);
                    commit("setIntegrationActionEditLoadStatus", 2);
                })
                .catch(function() {
                    commit("setIntegrationActionEdit", {});
                    commit("setIntegrationActionEditLoadStatus", 3);
                });
        },
        loadIntegrationActionEditDefault({ commit }) {
            commit("setIntegrationActionEditDefault");
        },
        loadIntegrationActionAddDefault({ commit }) {
            commit("setIntegrationActionAddStatus", 0);
            commit("setIntegrationActionAddText", "");
            commit("setIntegrationActionEditStatus", 0);
            commit("setIntegrationActionEditText", "");
        },
        editIntegrationAction({ commit, state, dispatch }, data) {
            commit("setIntegrationActionEditStatus", 1);

            IntegrationActionAPI.putEditIntegrationAction(
                data.slug,
                data.integration_type_id,
                data.name,
                data.version,
                data.label,
                data.description,
                data.input,
                data.output,
                data.filters
            )
                .then(function(response) {
           
                    commit("setIntegrationActionEditStatus", 2);
                    commit("setEditedIntegrationAction",response.data)
                    dispatch("applyIndexOnIntegrationActions");
                })
                .catch(function(error) {
                    commit("setIntegrationActionEditStatus", 3);
                    commit("setIntegrationActionEditText", error.response.data.errors);
                });
        },

        newIntegrationAction({ commit, state, dispatch }, data) {
            commit("setIntegrationActionAddStatus", 1);

            IntegrationActionAPI.postNewIntegrationAction(
                data.integration_type_id,
                data.name,
                data.version,
                data.label,
                data.description,
                data.input,
                data.output,
                data.filters
            )
                .then(function(response) {
           
                    commit("setIntegrationActionAddStatus", 2);
                    commit("setAddedIntegrationAction",response.data)
                    dispatch("applyIndexOnIntegrationActions");
                })
                .catch(function(error) {
                    commit("setIntegrationActionAddStatus", 3);
                    commit("setIntegrationActionAddText", error.response.data.errors);
                });
        },
        
        forceDeleteIntegrationAction({ commit, state, dispatch }, data) {
            commit("setIntegrationActionDeleteStatus", 1);

            IntegrationActionAPI.forceDeleteIntegrationAction(data.slug)
                .then(function(response) {
                    commit("setIntegrationActionDeleteStatus", 2);
                    commit("setIntegrationActionLineCount", state.integrationActionOptions.lineCount - 1);
                    commit("setDeletedIntegrationAction",response.data)
                    dispatch("applyIndexOnIntegrationActions");
                })
                .catch(function() {
                    commit("setIntegrationActionDeleteStatus", 3);
                });
        },
    },
    mutations: {
        setIntegrationActionLoadStatus(state, status) {
            state.integrationActionLoadStatus = status;
        },
        setIntegrationAction(state, integrationAction) {
            state.integrationAction = integrationAction;
        },
        //#############
        setIntegrationActionOptions(state,options){
            state.integrationActionOptions = options;
        },
        setIntegrationActionsLoadStatus(state, status) {
            state.integrationActionsLoadStatus = status;
        },
        setIntegrationActions(state, integrationActions) {
            state.integrationActions = integrationActions;
        },
        setIntegrationActionLineCount(state, lineCount) {
            state.integrationActionOptions.lineCount = lineCount;
        },
        setIntegrationActionAddStatus(state, status) {
            state.integrationActionAddStatus = status;
        },
        setIntegrationActionAddText(state, text) {
            state.integrationActionAddText = text;
        },
        setIntegrationActionEdit(state, integrationAction) {
            state.integrationActionEdit = integrationAction;
        },
        setEditedIntegrationAction(state, integrationAction) {
            var lp = _.find(state.integrationActions,{'id':integrationAction.id}).lp;
            var index = _.findIndex(state.integrationActions,{'id':integrationAction.id});
            
            state.integrationActions[index] = integrationAction;
            state.integrationActions[index].lp = lp;
        },
        setAddedIntegrationAction(state, integrationAction) {
            state.integrationActionOptions.itemAdded = true;
            state.integrationActions.push(integrationAction);
        },
        setIntegrationActionSearchedTrue(state) {
            state.integrationActionOptions.itemSearched = true;
        },
        setOptionsItemAddedAndSearchedFalse(state) {
            state.integrationActionOptions.itemAdded = false;
            state.integrationActionOptions.itemSearched = false;
        },
        setDeletedIntegrationAction(state, integrationAction) {
            var index = _.findIndex(state.integrationActions,{'id':integrationAction.id});
            state.integrationActions.splice(index,1);
        },
        setIntegrationActionEditDefault(state) {
            state.integrationActionEdit =  JSON.parse(JSON.stringify(state.integrationActionEditDefault)); //{ ...state.integrationActionEditDefault };
        },
        setIntegrationActionEditStatus(state, status) {
            state.integrationActionEditStatus = status;
        },
        setIntegrationActionEditText(state, text) {
            state.integrationActionEditText = text;
        },
        setIntegrationActionEditLoadStatus(state, status) {
            state.integrationActionEditLoadStatus = status;
        },
        setIntegrationActionDeleteStatus(state, status) {
            state.integrationActionDeleteStatus = status;
        },
        setIntegrationActionDeletedText(state, text) {
            state.integrationActionDeleteText = text;
        }
    },

    getters: {
        getIntegrationActionLoadStatus(state) {
            return state.integrationActionLoadStatus;
        },
        getIntegrationAction(state) {
            return state.integrationAction;
        },
        getIntegrationActionOptions(state){
            return state.integrationActionOptions;
        },
        getIntegrationActionsLoadStatus(state) {
            return state.integrationActionsLoadStatus;
        },
        getIntegrationActions(state) {
            return state.integrationActions;
        },
        getIntegrationActionAddStatus(state) {
            return function(){
                return state.integrationActionAddStatus;
            } 
        },
        getIntegrationActionAddText(state) {
            return state.integrationActionAddText;
        },
        getIntegrationActionEdit(state) {
            return state.integrationActionEdit;
        },
        getIntegrationActionEditStatus(state) {
          return function(){
            return state.integrationActionEditStatus;
          }
        },
        getIntegrationActionEditText(state) {
            return state.integrationActionEditText;
        },
        getIntegrationActionEditLoadStatus(state) {
            return state.integrationActionEditLoadStatus;
            
        },
        getIntegrationActionDeleteStatus(state) {
            return function(){
                return state.integrationActionDeleteStatus;
            }
        },
        getIntegrationActionDeleteText(state) {
            return state.integrationActionDeleteText;
        }
    }
};

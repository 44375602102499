<template>
    <v-row no-gutters>
      <v-col>
          <integrationTasks />
      </v-col>
      <v-col md="auto">
        <menuUstawienia />
      </v-col>
    </v-row>
</template>

<script>
  import integrationTasks from "../components/IntegrationTasks";
  import menuUstawienia from '../components/MenuUstawienia'

  export default {
    components:{
      integrationTasks,
      menuUstawienia
    },
  }
</script>

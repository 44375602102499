var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{key:_vm.componentKey,staticClass:"elevation-1",attrs:{"dense":"","hide-default-footer":"","headers":_vm.computedHeaders,"items":_vm.tableItems,"items-per-page":-1,"options":_vm.tableOptions},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Kontrola Tpay")]),_c('v-dialog',{attrs:{"max-width":_vm.dialogWidth},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('orderEditor',{on:{"close":function($event){_vm.dialog=false}}})],1),_c('v-spacer')],1)]},proxy:true},{key:"item.Kwota",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Kwota?_vm.$n(item.Kwota,'number','pl-PL'):'')+" "+_vm._s(item.Kwota?item.Waluta:'')+" ")]}},{key:"item.Status",fn:function(ref){
var item = ref.item;
return [(item.Status===1)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle-outline")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-circle-outline")]),_c('span',{staticClass:"font-weight-medium ml-2"},[_vm._v(_vm._s(_vm._.find(_vm.lookups.payUCheckStaus,{'value':item.Status}).text))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item['Spedix Order ID'])?_c('v-btn',{attrs:{"color":"primary","x-small":""},on:{"click":function($event){return _vm.openOrder(item['Spedix Order ID'],item['Kwota'])}}},[_vm._v("Zamówienie")]):_vm._e()]}}]),model:{value:(_vm.tableItemsSelected),callback:function ($$v) {_vm.tableItemsSelected=$$v},expression:"tableItemsSelected"}}),_c('confirm',{ref:"confirm"})],1)],1),_c('v-col',{attrs:{"md":"auto"}},[_c('v-container',{staticClass:"d-flex flex-column pa-0 ml-3"},[_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.tableItems.length!=0,"large":"","color":"primary","width":"250","active-class":"aa","exact":""}},on),[_vm._v("Wczytaj plik CSV")])]}}]),model:{value:(_vm.dialogImport),callback:function ($$v) {_vm.dialogImport=$$v},expression:"dialogImport"}},[_c('import-tpay',{on:{"close":_vm.closeImport}})],1),_c('v-btn',{staticClass:"mt-5",attrs:{"disabled":_vm.tableItems.length===0,"large":"","color":"primary","width":"250","active-class":"aa","exact":""},on:{"click":_vm.clearData}},[_vm._v(" Wyszyść dane ")]),_c('v-card',{staticClass:"mt-5",attrs:{"width":"250"}},[_c('v-card-text',[_c('div',[_c('v-container',{staticClass:"pa-0 widget-header"},[_c('strong',[_vm._v("Status")])]),_c('v-row',{attrs:{"no-gutters":"","dense":""}},[_c('v-checkbox',{attrs:{"disabled":_vm.statusId>0,"hide-details":"","label":"Tylko błędne"},model:{value:(_vm.showActive),callback:function ($$v) {_vm.showActive=$$v},expression:"showActive"}})],1),_c('v-row',{attrs:{"no-gutters":"","dense":""}},[_c('v-select',{attrs:{"hide-details":"","clearable":"","items":_vm.lookups.payUCheckStaus,"label":"Wybrany status"},model:{value:(_vm.statusId),callback:function ($$v) {_vm.statusId=$$v},expression:"statusId"}})],1)],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
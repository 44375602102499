import { FLOREX_CONFIG } from '../config'
import axios from 'axios'

export default {


    /*
		POST 	/api/integrationTasks
	*/
    postAddNewIntegrationTask: function( name, schedule_id, account_id){
        return axios.post( FLOREX_CONFIG.API_URL + '/integrationTasks',
            {
                name:name,
                schedule_id:schedule_id,
                account_id:account_id
            }
        );
    },

    /*
		GET 	/api/integrationTasks/{slug}/edit
	*/
    getIntegrationTaskEdit: function( slug ){
		return axios.get( FLOREX_CONFIG.API_URL + '/integrationTasks/' + slug + '/edit' );
    },
    

    /*
		GET 	/api/integrationTasks
	*/
    getIntegrationTasks: function( options ){
		return axios.get( FLOREX_CONFIG.API_URL + '/integrationTasks',{
                        params:{
                            orderBy:options.orderBy,
                            orderDirection:options.orderDirection,
                            accountId:options.accountId
                }});
    },

    /*
		PUT 	/api/integrationTasks/{slug}
	*/
    putEditIntegrationTask: function(slug, name,schedule_id,actions){
        return axios.put( FLOREX_CONFIG.API_URL + '/integrationTasks/'+ slug,
            {
                name: name,
                schedule_id:schedule_id,
                actions:actions
            }
        );
    },


    /*
		DELETE /api/integrationTasks/{slug}/force
	*/
    forceDeleteIntegrationTask: function( slug ){
		return axios.delete( FLOREX_CONFIG.API_URL + '/integrationTasks/' + slug + '/force' );
    },
    

    //############# actions


    /*
		POST 	/api/integrationTasks/addAction
	  */
    postAddNewIntegrationTaskAction : function( slug, action){
      return axios.post( FLOREX_CONFIG.API_URL + '/integrationTasks/addAction' ,
          {
              slug:slug,
              action:action,
          }
      );
  },
  
/*
		GET 	/api/integrationTasks/{slug}/copy
	  */
   getCopyIntegrationTask : function( slug){
    return axios.get( FLOREX_CONFIG.API_URL + '/integrationTasks/' + slug + '/copy');
},
 /*
		PUT 	/api/integrationTasks/moveAction/{slug}
	*/
  putIntegrationTaskActionMove: function(slug, direction){
    return axios.put( FLOREX_CONFIG.API_URL + '/integrationTasks/moveAction/'+ slug,
        {
            direction: direction
        }
    );
},

/*
		PUT 	/api/integrationTasks/updateAction/{slug}
	*/
  putIntegrationTaskActionUpdate: function(slug){
    return axios.put( FLOREX_CONFIG.API_URL + '/integrationTasks/updateAction/'+ slug);
},
  
    /*
		DELETE /api/integrationTasks/deleteAction/{slug}
	*/
  forceDeleteIntegrationTaskAction: function( slug ){
		return axios.delete( FLOREX_CONFIG.API_URL + '/integrationTasks/deleteAction/' + slug);
  },

   /*
		POST /api/integrationTasks/{slug}/run
	*/
  postRunIntegrationTask: function( options ){
		return axios.post( FLOREX_CONFIG.API_URL + '/integrationTasks/' + options.slug + '/run' ,{
           initialValues:options.initialValues,
        });
  },

  //####### logs

  /*
		GET 	/api/integrationTask/{slug}/logs
	*/
  getIntegrationTaskLogs: function( options ){
		return axios.get( FLOREX_CONFIG.API_URL + '/integrationTask/' + options.slug + '/logs',{
                        params:{
                            orderBy:options.orderBy,
                            orderDirection:options.orderDirection,
                            fromDate:options.fromDate,
                            toDate:options.toDate
                }});
    },

    /*
		GET 	/api/integrationTask/{slug}/actionsLogs
	*/
  getIntegrationTaskActionsLogs: function( slug ){
		return axios.get( FLOREX_CONFIG.API_URL + '/integrationTask/' + slug + '/actionsLogs');
    },
}
<template>
    <v-container class="d-flex flex-column pa-0 ml-3">

            <v-btn :to="{name: 'integracje.typy' }" large :color="$route.name === 'integracje.typy' ? selectedColor : defaultColor" dark width="250">
            Typy Danych  
            </v-btn>

            <v-btn :to="{name: 'integracje.akcje' }" large :color="$route.name === 'integracje.akcje' ? selectedColor : defaultColor" dark width="250">
            Akcje  
            </v-btn>

            <!-- <v-btn :to="{name: 'integracje.produkty' }" large :color="$route.name === 'integracje.produkty' ? selectedColor : defaultColor" dark width="250">
            Produkty  
            </v-btn> -->
    </v-container>
</template>

<script>
export default {
    data(){
        return{
            defaultColor:'tertiary', 
            selectedColor:'primary'
        }
    },
}
</script>

<style scoped>
.v-btn{
  margin-bottom: 1em;
}
.v-chip{
  margin-left: 1em;
}
.v-chip:hover{
    cursor: pointer;
}

.v-btn:not(.v-btn--text):not(.v-btn--outlined).v-btn--active:before {
    opacity: 0;
}

</style>
<template>
<v-card :loading="integrationAddStatus===1 || integrationTestStatus===1">
                       <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                        <v-spacer /><img src="/integration/email.png" height="50px" class="mr-3"/>
                        </v-card-title>
                        <v-card-text>
                              <v-container>
                                <v-row>     
                                              <v-col cols="12" sm="6" md="6">
                                                <v-text-field v-model="editedItemIntegration.name" :error-messages="nameErrors" @input="$v.editedItemIntegration.name.$touch()"  label="Nazwa konta"></v-text-field>                            
                                              </v-col>
                                </v-row> 
                                <!-- <v-row>     
                                              <h4 class="ml-3 mb-2">Ustawienia</h4>
                                </v-row> 
                                <v-row>     
                                  <v-col cols="12" sm="4" md="4">
                                    <v-text-field dense v-model="editedItem.settings.DEFAULT_ORDER_BY"  hide-details="auto" label="Kolumna sortowania"></v-text-field>                              
                                  </v-col>
                                  <v-col cols="12" sm="4" md="4">
                                    <v-select dense v-model="editedItem.settings.DEFAULT_ORDER_DIRECTION" :items="lookups.orderDirections" hide-details="auto" label="Kierunek sortowania"></v-select>                              
                                  </v-col>
                                </v-row>  -->
                                          
                                          <v-row v-if="query.error">
                                                  <v-col class="d-flex flex-column align-center">
                                                      <v-alert dense outlined type="error">
                                                        Błąd podczas łączenia konta
                                                      </v-alert>    
                                                  </v-col>
                                          </v-row>  
                                          <v-row v-if="integrationTestStatus===2">
                                                  <v-col class="d-flex flex-column align-center">
                                                      <v-alert dense outlined type="success">
                                                        Test połączenia zakończony sukcesem
                                                      </v-alert>    
                                                  </v-col>
                                          </v-row> 
                                          <v-row v-if="integrationTestStatus===3">
                                                  <v-col class="d-flex flex-column align-center">
                                                      <v-alert dense outlined type="error">
                                                        Nastąpił problem z połączeniem
                                                      </v-alert>   
                                                      <v-btn @click="allegroLogin" dark color="primary">Zaloguj się ponownie</v-btn>
                                                  </v-col>
                                          </v-row>   
                                          
                                                         
        </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="grey" text @click="testConnection">Testuj połączenie</v-btn>
                          <v-btn color="grey" text @click="closeFunction">Zamknij</v-btn>
                          <v-btn color="primary darken-1" text @click="submit" >Zapisz</v-btn>
                        </v-card-actions>
                    </v-card>
         
</template>

<script>
  import required from 'vuelidate/lib/validators/required'

  export default {
    data: () => ({

    }),
    props:['formTitle','closeFunction','submitFunction','query'],
    validations:{
      editedItemIntegration:{
          name:{
            required,
          },
      }
    },
    watch: {
     
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      lookups(){
        return this.$store.getters.getLookups;
      },
      editedItem(){
        return this.$store.getters.getIntegrationEdit.integrable;
      },
      editedItemIntegration(){
        return this.$store.getters.getIntegrationEdit;
      },
      integrationAddStatus(){
        return this.$store.getters.getIntegrationAddStatus();
      },
      integrationTestStatus(){
        return this.$store.getters.getIntegrationTestStatus();
      },
      nameErrors () {
        const errors = []
        if (!this.$v.editedItemIntegration.name.$dirty) return errors
        !this.$v.editedItemIntegration.name.required && errors.push('Nazwa konta jest wymagana')
        return errors
      },
      carrierIntegrations(){
        return _.filter(this.lookups.integrations,{'type':2})
      },
    },
    methods: {
      setDefault(event){
        console.log(event);
      },
      testConnection(){
         this.$store.dispatch( 'testIntegration', {
              slug:  this.editedItemIntegration.id
        });
      },
      submit(){
        this.$v.$touch()
        if (this.$v.$invalid) {

        } else {
          this.editedItem.link = undefined;
          //this.editedItem.integration = undefined;
          this.submitFunction();
        }
      }
    },
  }
</script>
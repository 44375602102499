import IntegrationTaskAPI from "../api/integrationTask";

export const integrationTasks = {
    state: {
        integrationTask:{},
        integrationTaskLoadStatus: 0,

        integrationTasks: [],
        integrationTaskOptions:{
            accountId:undefined,
            orderBy:'id',
            orderDirection:'desc',
            itemAdded:false
        },


        integrationTasksLoadStatus: 0,
        integrationTaskAddStatus: 0,
        integrationTaskAddText: "",

        integrationTaskEdit: {
            id: 0,
            lp: 0,
            name:undefined,
            schedule_id:0,
            actions:[]
        },
        integrationTaskEditDefault: {
            id: 0,
            lp: 0,
            name:undefined,
            schedule_id:0,
            actions:[]
        },
        integrationTaskEditLoadStatus: 0,
        integrationTaskEditStatus: 0,
        integrationTaskEditText: "",

        integrationTaskDeleteStatus: 0,
        integrationTaskRunningStatus: 0,
        integrationTaskDeleteText: "",

        integrationTaskActionAddStatus: 0,
        integrationTaskActionAddText: "",
        integrationTaskActionEditStatus: 0,
        integrationTaskActionEditText: "",
        integrationTaskActionDeleteStatus: 0,

        integrationTaskLog:{},
        integrationTaskLogs:[],
        integrationTaskLogsOptions:{
            taskId:undefined,
            orderBy:'created_at',
            orderDirection:'desc',
            lineCount:0,
            itemsPerPage:10,
            fromDate:'2019-06-01',
            toDate:'2021-06-01'
        },
        integrationTaskLogsLoadStatus:0,

        integrationTaskActionsLogs:[],
        integrationTaskActionsLogsLoadStatus:0,
    },

    actions: {
        loadIntegrationTasks({ state, commit, dispatch }) {
            commit("setIntegrationTasksLoadStatus", 1);
                IntegrationTaskAPI.getIntegrationTasks({
                    orderBy:state.integrationTaskOptions.orderBy,
                    orderDirection:state.integrationTaskOptions.orderDirection,
                    accountId:state.integrationTaskOptions.accountId,
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setIntegrationTasks", response.data);
                    dispatch("applyIndexOnIntegrationTasks");
                    commit("setIntegrationTasksLoadStatus", 2);
                })
                .catch(function() {
                    commit("setIntegrationTasks", []);
                    commit("setIntegrationTasksLoadStatus", 3);
                });
         
        },
        
        setIntegrationTaskOptions({ state, commit, dispatch },data){
            commit("setIntegrationTaskOptions",data.options);
            dispatch("loadIntegrationTasks");
        },
        applyIndexOnIntegrationTasks({ commit, state, rootState }) {
            let localIntegrationTasks = state.integrationTasks;

            var i = 1;
            localIntegrationTasks.forEach(integrationTask => {
                if(_.isNumber(integrationTask.schedule_id)){
                    integrationTask.schedule = _.find(rootState.lookups.integrationTaskActionsSchedules, { 'value': integrationTask.schedule_id }).text;
                }
                //integrationTask.integrable_type = integrationTask.integrable_type.replace('App\\','').replace('IntegrationTask','');
                integrationTask.lp = i;
                i++;
            });

            commit("setIntegrationTasks",localIntegrationTasks);
        },
       
        addIntegrationTask({ commit, state, dispatch }, data) {
            commit("setIntegrationTaskAddStatus", 1);
            commit("setIntegrationTaskAddText", "");

            IntegrationTaskAPI.postAddNewIntegrationTask(
                data.name,
                data.schedule_id,
                data.account_id
            )
                .then(function(response) {
                    commit("setIntegrationTaskAddStatus", 2);
                    commit("setIntegrationTaskEdit", response.data);
                    commit("setAddedIntegrationTask",response.data);
                    //dispatch("loadIntegrationTaskLookup");
                    dispatch("applyIndexOnIntegrationTasks");
                })
                .catch(function(error) {
                    commit("setIntegrationTaskAddStatus", 3);
                    commit("setIntegrationTaskAddText", error.response.data.errors);
                });
        },

        copyIntegrationTask({ commit, state, dispatch }, data) {
            commit("setIntegrationTaskAddStatus", 1);
            commit("setIntegrationTaskAddText", "");

            IntegrationTaskAPI.getCopyIntegrationTask(data.slug)
                .then(function(response) {
                    commit("setIntegrationTaskAddStatus", 2);
                    commit("setIntegrationTaskEdit", response.data);
                    commit("setAddedIntegrationTask",response.data);
                    //dispatch("loadIntegrationTaskLookup");
                    dispatch("applyIndexOnIntegrationTasks");
                })
                .catch(function(error) {
                    commit("setIntegrationTaskAddStatus", 3);
                    commit("setIntegrationTaskAddText", error.response.data.errors);
                });
        },

        loadIntegrationTaskEdit({ commit, dispatch }, data) {
            commit("setIntegrationTaskEditLoadStatus", 1);
            commit("setIntegrationTaskEditStatus", 0);
            commit("setIntegrationTaskEditText", "");

            IntegrationTaskAPI.getIntegrationTaskEdit(data.slug)
                .then(function(response) {
                    commit("setIntegrationTaskEdit", response.data);
                    commit("setIntegrationTaskEditLoadStatus", 2);
                    dispatch("applyIndexOnIntegrationTaskActions");
                })
                .catch(function() {
                    commit("setIntegrationTaskEdit", {});
                    commit("setIntegrationTaskEditLoadStatus", 3);
                });
        },
        loadIntegrationTaskEditDefault({ commit }) {
            commit("setIntegrationTaskEditDefault");
        },
        loadIntegrationTaskAddDefault({ commit }) {
            commit("setIntegrationTaskAddStatus", 0);
            commit("setIntegrationTaskAddText", "");
            commit("setIntegrationTaskEditStatus", 0);
            commit("setIntegrationTaskEditText", "");
        },
        editIntegrationTask({ commit, state, dispatch }, data) {
            commit("setIntegrationTaskEditStatus", 1);

            IntegrationTaskAPI.putEditIntegrationTask(
                data.slug,
                data.name,
                data.schedule_id,
                data.actions
            )
                .then(function(response) {
                    
                    commit("setIntegrationTaskEditStatus", 2);
                    commit("setEditedIntegrationTask",response.data)
                    //dispatch("loadIntegrationTaskLookup");
                    dispatch("applyIndexOnIntegrationTasks");
                })
                .catch(function(error) {
                    commit("setIntegrationTaskEditStatus", 3);
                    commit("setIntegrationTaskEditText", error.response.data.errors);
                });
        },

        
        forceDeleteIntegrationTask({ commit, state, dispatch }, data) {
            commit("setIntegrationTaskDeleteStatus", 1);

            IntegrationTaskAPI.forceDeleteIntegrationTask(data.slug)
                .then(function(response) {
                    commit("setIntegrationTaskDeleteStatus", 2);
                    commit("setDeletedIntegrationTask",response.data)
                    //dispatch("loadIntegrationTaskLookup");
                    dispatch("applyIndexOnIntegrationTasks");
                })
                .catch(function() {
                    commit("setIntegrationTaskDeleteStatus", 3);
                });
        },

        runIntegrationTask({ commit, state, dispatch }, data) {
            commit("setIntegrationTaskRunningStatus", 1);

            IntegrationTaskAPI.postRunIntegrationTask({
                slug:data.slug,
                initialValues:data.initialValues,
            })
                .then(function(response) {
                    commit("setEditedIntegrationTask",response.data)
                    commit("setIntegrationTaskLog", response.data.log);
                    dispatch("applyIndexOnIntegrationTasks");
                    commit("setIntegrationTaskRunningStatus", 2);
                })
                .catch(function() {
                    commit("setIntegrationTaskRunningStatus", 3);
                });
        },
        runCustomIntegrationTask({ commit, state, dispatch }, data) {
            commit("setIntegrationTaskRunningStatus", 1);

            IntegrationTaskAPI.postRunIntegrationTask({
                slug:data.slug,
                initialValues:data.initialValues,
            })
                .then(function(response) {
                    commit("setIntegrationTaskLog", response.data.log);
                    commit("setIntegrationTaskRunningStatus", 2);
                })
                .catch(function() {
                    commit("setIntegrationTaskRunningStatus", 3);
                });
        },

        //### actions
        addIntegrationTaskAction({ commit, state, dispatch }, data) {
            commit("setIntegrationTaskActionAddStatus", 1);
            commit("setIntegrationTaskActionAddText", "");

            IntegrationTaskAPI.postAddNewIntegrationTaskAction(
                data.slug,
                data.action
                //data.account_id
            )
                .then(function(response) {
                    commit("setIntegrationTaskActionAddStatus", 2);
                    //commit("setIntegrationTaskEdit", response.data);
                    commit("setAddedIntegrationTaskAction",response.data);
                    dispatch("applyIndexOnIntegrationTaskActions");
                })
                .catch(function(error) {
                    commit("setIntegrationTaskActionAddStatus", 3);
                    commit("setIntegrationTaskActionAddText", error.response.data.errors);
                });
        },
        moveIntegrationTaskAction({ commit, state, dispatch }, data) {
            commit("setIntegrationTaskEditLoadStatus", 1);
            commit("setIntegrationTaskActionEditText", "");

            IntegrationTaskAPI.putIntegrationTaskActionMove(
                data.slug,
                data.direction
            )
                .then(function(response) {
                    commit("setIntegrationTaskEdit", response.data);
                    commit("setIntegrationTaskEditLoadStatus", 2);
                    dispatch("applyIndexOnIntegrationTaskActions");
                })
                .catch(function(error) {
                    commit("setIntegrationTaskEditLoadStatus", 3);
                    commit("setIntegrationTaskActionEditText", error.response.data.errors);
                });
        },
        updateIntegrationTaskAction({ commit, state, dispatch }, data) {
            commit("setIntegrationTaskEditLoadStatus", 1);
            commit("setIntegrationTaskActionEditText", "");

            IntegrationTaskAPI.putIntegrationTaskActionUpdate(
                data.slug
            )
                .then(function(response) {
                    commit("setIntegrationTaskEdit", response.data);
                    commit("setIntegrationTaskEditLoadStatus", 2);
                    dispatch("applyIndexOnIntegrationTaskActions");
                })
                .catch(function(error) {
                    commit("setIntegrationTaskEditLoadStatus", 3);
                    commit("setIntegrationTaskActionEditText", error.response.data.errors);
                });
        },
        forceDeleteIntegrationTaskAction({ commit, state, dispatch }, data) {
            commit("setIntegrationTaskActionDeleteStatus", 1);

            IntegrationTaskAPI.forceDeleteIntegrationTaskAction(data.slug)
                .then(function(response) {
                    commit("setIntegrationTaskActionDeleteStatus", 2);
                    commit("setDeletedIntegrationTaskAction",response.data)
                    dispatch("applyIndexOnIntegrationTaskActions");
                })
                .catch(function() {
                    commit("setIntegrationTaskActionDeleteStatus", 3);
                });
        },
        applyIndexOnIntegrationTaskActions({ commit, state, dispatch }) {
            let localActions = state.integrationTaskEdit.actions;

            var i = 1;
            localActions.forEach(action => {
                action.lp = i;
                i++;
            });

            commit("setIntegrationTaskActions",localActions);
        },

        //###### logs
        loadIntegrationTaskLogs({ state, commit, dispatch }) {
            commit("setIntegrationTaskActionsLogs", []);
            commit("setIntegrationTaskLogsLoadStatus", 1);
                IntegrationTaskAPI.getIntegrationTaskLogs({
                    slug:state.integrationTaskLogsOptions.taskId,
                    orderBy:state.integrationTaskLogsOptions.orderBy,
                    orderDirection:state.integrationTaskLogsOptions.orderDirection,
                    fromDate:state.integrationTaskLogsOptions.fromDate,
                    toDate:state.integrationTaskLogsOptions.toDate,
                })
                .then(function(response) {
                    commit("setIntegrationTaskLogs", response.data);
                    dispatch("applyIndexOnIntegrationTaskLogs");
                    commit("setIntegrationTaskLogsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setIntegrationTaskLogs", []);
                    commit("setIntegrationTaskLogsLoadStatus", 3);
                });
         
        },

        loadIntegrationTaskLogsEmpty({ state, commit, dispatch }) {
            commit("setIntegrationTaskLogs", [])
            commit("setIntegrationTaskActionsLogs", []);
        },
        
        setIntegrationTaskLogsOptions({ state, commit, dispatch },data){
            commit("setIntegrationTaskLogsOptions",data.options);
            dispatch("loadIntegrationTaskLogs");
        },
        applyIndexOnIntegrationTaskLogs({ commit, state, dispatch, rootState }) {
            let localLogs = state.integrationTaskLogs;

            localLogs.forEach(action => {
                action.status = _.find(rootState.lookups.integrationTaskLogStatus, { 'value': action.status }).text;
            });

            commit("setIntegrationTaskLogs",localLogs);
        },

        loadIntegrationTaskActionsLogs({ state, commit, dispatch },data) {
            commit("setIntegrationTaskActionsLogsLoadStatus", 1);
                IntegrationTaskAPI.getIntegrationTaskActionsLogs(data.slug)
                .then(function(response) {
                    commit("setIntegrationTaskActionsLogs", response.data);
                    dispatch("applyIndexOnIntegrationTaskActionsLogs");
                    commit("setIntegrationTaskActionsLogsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setIntegrationTasActionskLogs", []);
                    commit("setIntegrationTaskActionsLogsLoadStatus", 3);
                });
         
        },

        applyIndexOnIntegrationTaskActionsLogs({ commit, state, dispatch, rootState }) {
            let localLogs = state.integrationTaskActionsLogs;

            localLogs.forEach(action => {
                action.status = _.find(rootState.lookups.integrationTaskActionsLogStatus, { 'value': action.status }).text;
            });

            commit("setIntegrationTaskActionsLogs",localLogs);
        },
    },
    mutations: {
        setIntegrationTaskLoadStatus(state, status) {
            state.integrationTaskLoadStatus = status;
        },
        setIntegrationTask(state, integrationTask) {
            state.integrationTask = integrationTask;
            
        },
        //#############
        setIntegrationTaskOptions(state,options){
            state.integrationTaskOptions = options;
        },
        setIntegrationTasksLoadStatus(state, status) {
            state.integrationTasksLoadStatus = status;
        },
        setIntegrationTasks(state, integrationTasks) {
            state.integrationTasks = integrationTasks;
        },
        setIntegrationTaskLineCount(state, lineCount) {
            state.integrationTaskOptions.lineCount = lineCount;
        },
        setIntegrationTaskAddStatus(state, status) {
            state.integrationTaskAddStatus = status;
        },
        setIntegrationTaskRunningStatus(state, status) {
            state.integrationTaskRunningStatus = status;
        },
        setIntegrationTaskAddText(state, text) {
            state.integrationTaskAddText = text;
        },
        setIntegrationTaskEdit(state, integrationTask) {
            state.integrationTaskEdit = integrationTask;
            
        },
        setEditedIntegrationTask(state, integrationTask) {
            var lp = _.find(state.integrationTasks,{'id':integrationTask.id}).lp;
            var index = _.findIndex(state.integrationTasks,{'id':integrationTask.id});
            
            state.integrationTasks[index] = integrationTask;
            state.integrationTasks[index].lp = lp;
        },
        setAddedIntegrationTask(state, integrationTask) {
            state.integrationTaskOptions.itemAdded = true;
            state.integrationTasks.push(integrationTask);
        },
        setIntegrationTaskSearchedTrue(state) {
            state.integrationTaskOptions.itemSearched = true;
        },
        setOptionsItemAddedAndSearchedFalse(state) {
            state.integrationTaskOptions.itemAdded = false;
            state.integrationTaskOptions.itemSearched = false;
        },
        setDeletedIntegrationTask(state, integrationTask) {
            var index = _.findIndex(state.integrationTasks,{'id':integrationTask.id});
            state.integrationTasks.splice(index,1);
        },
        setIntegrationTaskEditDefault(state) {
            state.integrationTaskEdit = { ...state.integrationTaskEditDefault };
        },
        setIntegrationTaskEditStatus(state, status) {
            state.integrationTaskEditStatus = status;
        },
        setIntegrationTaskEditText(state, text) {
            state.integrationTaskEditText = text;
        },
        setIntegrationTaskEditLoadStatus(state, status) {
            state.integrationTaskEditLoadStatus = status;
        },
        setIntegrationTaskDeleteStatus(state, status) {
            state.integrationTaskDeleteStatus = status;
        },
        setIntegrationTaskDeletedText(state, text) {
            state.integrationTaskDeleteText = text;
        },
        //################ actions
        setIntegrationTaskActions(state, integrationTaskActions) {
            state.integrationTaskEdit.actions = integrationTaskActions;
            
        },
        setIntegrationTaskActionAddStatus(state, status) {
            state.integrationTaskActionAddStatus = status;
        },
        setIntegrationTaskActionAddText(state, text) {
            state.integrationTaskActionAddText = text;
        },
        setIntegrationTaskActionEditStatus(state, status) {
            state.integrationTaskActionEditStatus = status;
        },
        setIntegrationTaskActionEditText(state, text) {
            state.integrationTaskActionEditText = text;
        },
        setAddedIntegrationTaskAction(state, integrationTaskAction) {
            state.integrationTaskEdit.actions.push(integrationTaskAction);
        },
        setIntegrationTaskActionDeleteStatus(state, status) {
            state.integrationTaskActionDeleteStatus = status;
        },
        setDeletedIntegrationTaskAction(state, integrationTaskAction) {
            var index = _.findIndex(state.integrationTaskEdit.actions,{'id':integrationTaskAction.id});
            state.integrationTaskEdit.actions.splice(index,1);
        },

        //###### logs
        setIntegrationTaskLogsOptions(state,options){
            state.integrationTaskLogsOptions = options;
        },
        setIntegrationTaskLogsLoadStatus(state, status) {
            state.integrationTaskLogsLoadStatus = status;
        },
        setIntegrationTaskLog(state, integrationTask) {
            state.integrationTaskLog = integrationTask;
        },
        setIntegrationTaskLogs(state, integrationTasks) {
            state.integrationTaskLogs = integrationTasks;
        },

        setIntegrationTaskActionsLogsLoadStatus(state, status) {
            state.integrationTaskActionsLogsLoadStatus = status;
        },
        setIntegrationTaskActionsLogs(state, integrationTasks) {
            state.integrationTaskActionsLogs = integrationTasks;
        },
    },

    getters: {
        getIntegrationTaskLoadStatus(state) {
            return state.integrationTaskLoadStatus;
        },
        getIntegrationTask(state) {
            return state.integrationTask;
        },
        //########
        getIntegrationTaskOptions(state){
            return state.integrationTaskOptions;
        },
        getIntegrationTasksLoadStatus(state) {
            return function(){
                return state.integrationTasksLoadStatus;
            }
        },
        getIntegrationTaskRunningStatus(state) {
            return function(){
                return state.integrationTaskRunningStatus;
            }
        },
        getIntegrationTasks(state) {
            return state.integrationTasks;
        },
        getIntegrationTaskAddStatus(state) {
            return function(){
                return state.integrationTaskAddStatus;
            } 
        },
        getIntegrationTaskAddText(state) {
            return state.integrationTaskAddText;
        },
        getIntegrationTaskEdit(state) {
            return state.integrationTaskEdit;
        },
        getIntegrationTaskEditStatus(state) {
          return function(){
            return state.integrationTaskEditStatus;
          }
        },
        getIntegrationTaskEditing(state) {
            return state.integrationTaskEdit.id > 0;
        },
        getIntegrationTaskEditText(state) {
            return state.integrationTaskEditText;
        },
        getIntegrationTaskEditLoadStatus(state) {
            return function(){
                return state.integrationTaskEditLoadStatus;
            }
        },
        getIntegrationTaskDeleteStatus(state) {
            return function(){
                return state.integrationTaskDeleteStatus;
            }
        },
        getIntegrationTaskDeleteText(state) {
            return state.integrationTaskDeleteText;
        },
        //########## actions
        getIntegrationTaskActionAddStatus(state) {
            return function(){
                return state.integrationTaskActionAddStatus;
            } 
        },
        getIntegrationTaskActionAddText(state) {
            return state.integrationTaskActionAddText;
        },
        getIntegrationTaskActionEditStatus(state) {
            return function(){
                return state.integrationTaskActionEditStatus;
            } 
        },
        getIntegrationTaskActionEditText(state) {
            return state.integrationTaskActionEditText;
        },
        getIntegrationTaskActionDeleteStatus(state) {
            return function(){
                return state.integrationTaskActionDeleteStatus;
            }
        },
        //########## logs
        getIntegrationTaskLogsOptions(state){
            return state.integrationTaskLogsOptions;
        },
        getIntegrationTaskLogsLoadStatus(state) {
            return function(){
                return state.integrationTaskLogsLoadStatus;
            }
        },
        getIntegrationTaskLog(state) {
            return state.integrationTaskLog;
        },
        getIntegrationTaskLogs(state) {
            return state.integrationTaskLogs;
        },
        getIntegrationTaskActionsLogsLoadStatus(state) {
            return function(){
                return state.integrationTaskActionsLogsLoadStatus;
            }
        },
        getIntegrationTaskActionsLogs(state) {
            return state.integrationTaskActionsLogs;
        },
    }
};

<template>
<v-row no-gutters>
      <v-col>
          <v-card flat>
            <v-data-table
                dense
                :key="componentKey"
                hide-default-footer
                :headers="computedHeaders"
                :items="tableItems"
                class="elevation-1"
                :options.sync="tableOptions"
                :server-items-length="options.lineCount"
                :loading="integrationDocumentsLoadStatus===1 || integrationDocumentEditLoadStatus===1 || integrationDocumentCsvLoadStatus===1"
                must-sort
                :show-select="multiEdit"
                v-model="tableItemsSelected"
            >
                <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-toolbar-title>Dokumenty</v-toolbar-title>
                    
                    <v-divider
                    class="mx-4"
                    inset
                    vertical
                    ></v-divider>
                    
                    <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-on="on"
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Wyszukaj"
                          single-line
                          hide-details
                          class="search"
                          @keydown.enter="searchIntegrationDocuments"
                         
                      ></v-text-field>
                      </template>
                        <span>Wpisz min 3 znaki i naciśnij Enter</span>
                    </v-tooltip>

                    <v-spacer></v-spacer>

                    <v-switch class="mt-4" v-model="multiEdit" label="Zaznaczanie" @change="multiEditChange"></v-switch>
                    <v-divider
                      class="mx-4"
                      inset
                      vertical
                    ></v-divider>

                    <v-progress-circular v-if="autoRefresh" class="mr-2" color="primary" width="3" size="24" :value="100-Math.round(refreshCount/60*100)"></v-progress-circular>
                    <v-switch v-model="autoRefresh" class="mt-5" label="Automatyczne odświeżanie"></v-switch>
                

                    <v-dialog v-model="dialog" :max-width="dialogWidth">
                      <documentEditor v-on:close="dialog=false" v-on:updated="loadIntegrationDocuments()" />
                    </v-dialog>

                     <!-- <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-btn @click="newItem()" v-on="on" small class="ml-3 mb-2 mt-2" fab dark color="primary">
                          <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                      </template>
                        <span>Dodaj wysyłkę nizależną</span>
                    </v-tooltip> -->
                    
                </v-toolbar>
                </template>
                
                <template v-slot:item.amount="{ item }">
                  {{item.amount?$n(item.amount,'number','pl-PL'):''}} {{item.amount?item.amount_currency:''}}
                </template>
                 <template v-slot:item.amount_net="{ item }">
                  {{item.amount_net?$n(item.amount_net,'number','pl-PL'):''}} {{item.amount_net?item.amount_currency:''}}
                </template>
                <template v-slot:item.amount_payed="{ item }">
                  {{item.amount_payed?$n(item.amount_payed,'number','pl-PL'):''}} {{item.amount_payed?item.amount_currency:''}}
                </template>
                 
                <template v-slot:item.status="{ item }">
                 <v-tooltip right>
                      <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        :color="_.find(lookups.integrationDocumentStatus,{'value':item.status}).color"
                      >
                        {{_.find(lookups.integrationDocumentStatus,{'value':item.status}).icon}}
                      </v-icon>
                      </template>
                      <span>{{_.find(lookups.integrationDocumentStatus,{'value':item.status}).text}}</span>
                </v-tooltip>
                </template>
                
                <template v-slot:item.details="{ item }">

                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn color="primary" x-small @click="editItem(item)">Otwórz</v-btn>
                </template>
            </v-data-table> 
            <div class="text-center pt-2">
            <v-btn @click="loadMore" v-if="!options.itemAdded && options.lineCount-tableItems.length>0 && integrationDocumentsLoadStatus===2" small color="primary"><v-icon left>mdi-download</v-icon>Załaduj więcej ({{ options.lineCount-tableItems.length }})</v-btn>
            <v-btn @click="loadAll(options.lineCount-tableItems.length)" v-if="!options.itemAdded && options.lineCount-tableItems.length>0 && integrationDocumentsLoadStatus===2" small color="error" class="ml-4"><v-icon left>mdi-download-lock</v-icon>Załaduj wszystkie ({{ options.lineCount-tableItems.length }})</v-btn>
            
            <v-btn @click="loadIntegrationDocuments" v-else-if="options.itemAdded && options.lineCount-tableItems.length>0" small color="primary"><v-icon left>mdi-reload</v-icon>Odśwież tabele aby móc załadować więcej ({{ options.lineCount-tableItems.length }})</v-btn>
            <v-btn @click="loadIntegrationDocuments" v-else-if="options.itemSearched" small color="primary"><v-icon left>mdi-reload</v-icon>Anuluj wyszukiwanie</v-btn>
          
          </div>
            <confirm ref="confirm"></confirm>
          </v-card>
      </v-col>
      <v-col md="auto">
        <menuIntegracjeDokumenty>
          <template v-slot:actions>
            <v-btn @click="multiDelete" v-if="multiEdit" :disabled="tableItemsSelected.length<=0" large color="primary"  width="250" active-class="aa" exact>
                Usuń wybrane
            </v-btn>
            <v-btn @click="advanceCSV" v-if="multiEdit" :disabled="tableItemsSelected.length<=0 || _.difference(_.map(tableItemsSelected,'type'),[_.find(lookups.integrationDocumentTypes,{'value':1}).text]).length>0" large color="primary"  width="250" active-class="aa" exact>
                P - Zaliczki CSV
            </v-btn>
            <v-btn @click="stornCSV" v-if="multiEdit" :disabled="tableItemsSelected.length<=0 || _.difference(_.map(tableItemsSelected,'type'),[_.find(lookups.integrationDocumentTypes,{'value':10}).text]).length>0" large color="primary"  width="250" active-class="aa" exact>
                PK - Storno CSV
            </v-btn>
            <v-btn @click="stornCSV" v-if="multiEdit" :disabled="tableItemsSelected.length<=0 || _.difference(_.map(tableItemsSelected,'type'),[_.find(lookups.integrationDocumentTypes,{'value':11}).text]).length>0" large color="primary"  width="250" active-class="aa" exact>
                PZ - Zwroty CSV
            </v-btn>
            <!-- <v-btn @click="mergeItems" v-if="multiEdit" :disabled="tableItemsSelected.length<=0 || !selectedItemsCanBeMerged" large color="primary"  width="250" active-class="aa" exact>
                Połącz wybrane
            </v-btn> -->
          </template>
        </menuIntegracjeDokumenty>
      </v-col>
    </v-row>
</template>

<script>
  import confirm from '../components/Confirm'
  import menuIntegracjeDokumenty from '../components/MenuIntegracjeDokumenty'
  import documentEditor from '../components/IntegrationDocumentEditor'

  export default {
    components:{
      confirm,
      menuIntegracjeDokumenty,
      documentEditor
    },
    props:['aId'],
    data: () => ({
      componentKey:0,
      autoRefresh:false,
      refreshCount:0,
      multiEdit:false,
      tableOptions: {},
      tableItemsSelected:[],
      search:'',
      dialog: false,
      itemEditing:false,
      dialogWidth:1500,
      headers: [
        { text: 'Lp',align: 'start',sortable: false,value: 'lp',filterable:false,visible:true},
        { text: 'Status', value: 'status',sortable:true,filterable:true,filterColumn:"status",visible:true  },
        { text: 'Typ', value: 'type',sortable:true,filterable:true,filterColumn:"type",visible:true  },
        { text: 'Nr Dokumentu', value: 'document_number',sortable: true,filterable:true,filterColumn:"document_number",visible:true },
        { text: 'Data wystawienia', value: 'document_date',sortable: true,filterable:true,filterColumn:"document_date",visible:true },
        { text: 'Dokument', value: 'document',sortable: false,filterable:true,filterColumn:"document",visible:false },
        { text: 'Nabywca', value: 'buyer',sortable: false,filterable:true,filterColumn:"buyer",visible:true },
        { text: 'Kwota dokumentu', value: 'amount',sortable: true,filterable:true,filterColumn:"amount",visible:true },
        { text: 'Kwota opłacona', value: 'amount_payed',sortable: true,filterable:true,filterColumn:"amount_payed",visible:true },
        { text: '', value: 'details',align: 'center', sortable: false,filterable:false,visible:true},
        { text: '', value: 'actions', sortable: false,filterable:false,visible:true},
      ],
    }),
    validations() {
      
    },
    beforeRouteLeave (to, from, next) {
      clearInterval(this.interval);
      next();
    },
    watch: {
      autoRefresh(val){
        if(val){
          this.refreshCount = 0;
          this.interval = setInterval(() => {
            this.refreshCount++;
            if(this.refreshCount>=60){
              this.loadIntegrationDocuments();
              this.refreshCount = 0;
            }
          }, 1000);
        }else{
          clearInterval(this.interval);
        }
      },
      tableOptions: {
        handler () {
          var currentOptions = {...this.options};
          const { sortBy, sortDesc } = this.tableOptions
          //zmiana sortowania
          if (sortBy.length === 1 && sortDesc.length === 1) {
            currentOptions.orderBy = sortBy[0];
            currentOptions.orderDirection = sortDesc[0]?'desc':'asc';
            this.options = currentOptions;
          } 
        },
        deep: true,
      },
      dialog (val) {
        //this.$v.$reset()
        if(val){

        }else{
          this.close()
        }
      },
      integrationDocumentDeleteStatus(val){
        if(val===2){
          this.tableItemsSelected = [];
        }
      },
      integrationDocumentEditLoadStatus(val){
        if(val===2){
          this.dialog = true;
        }
      },
      integrationDocumentAddStatus(val){
        if(val===2){
          this.dialogWidth = 1500;
          this.itemEditing = true
          //this.dialog = false;
        }
      }
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      lookups(){
        return this.$store.getters.getLookups;
      },
      options:{
        get () {  
          return this.$store.getters.getIntegrationDocumentOptions;
        },
        set (value) {
          this.search='';
          this.$store.dispatch( 'setIntegrationDocumentOptions', {options:value} )
        }
      },
      accountId: {
        get () {
          return this.$store.getters.getIntegrationDocumentOptions.accountId;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.accountId = value;
          this.options = currentOptions;
        }
      },
      editedItem(){
        return this.$store.getters.getIntegrationDocumentEdit;
      },
      tableItems(){
        return this.$store.getters.getIntegrationDocuments;
      },
      integrationDocumentsLoadStatus(){
        return this.$store.getters.getIntegrationDocumentsLoadStatus;
      },
      integrationDocumentEditLoadStatus(){
        return this.$store.getters.getIntegrationDocumentEditLoadStatus()
      },
       integrationDocumentCsvLoadStatus(){
        return this.$store.getters.getIntegrationDocumentCsvLoadStatus()
      },
      integrationDocumentEditText(){
        return this.$store.getters.getIntegrationDocumentEditText
      },
      integrationDocumentAddStatus(){
        return this.$store.getters.getIntegrationDocumentAddStatus();
      },
      integrationDocumentAddText(){
        return this.$store.getters.getIntegrationDocumentAddText;
      },
      integrationDocumentDeleteStatus(){
        return this.$store.getters.getIntegrationDocumentDeleteStatus();
      },
      
      computedHeaders(){
        return _.filter(this.headers,function(o) { return o.visible; })
      },
      
    },
    mounted(){
      //console.log(window.selected_device)
      if(this.lookups.integrations.length>0){
        this.loadIntegrationDocuments();
      }else{
        this.$store.dispatch( 'loadLookups' );
        setTimeout(() => {
          this.loadIntegrationDocuments();
        }, 1000)
      }
    },
    methods: {
      multiEditChange(){
        if(!this.multiEdit){
          this.tableItemsSelected = [];
        }
      },
      
      loadIntegrationDocuments(){
        this.search='';
        this.accountId = this.aId;//pobiera z propsów

        //this.$store.dispatch( 'loadIntegrationDocuments' );
      },
      loadMore(){
        this.$store.dispatch( 'loadMoreIntegrationDocuments' );
      },
      loadAll(len){
        this.$refs.confirm.open('Uwaga', 'Czy na pewno chcesz pobrać resztę rekordów? ('+len+')', { color: 'warning' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'loadAllIntegrationDocuments' );
            }
        })
        
      },
      editItem (item) {
        this.dialogWidth = 1500;
        this.itemEditing = true
        this.$store.dispatch( 'loadIntegrationDocumentEdit', {slug:  item.id});  
        //this.dialog = true
      },
      newItem(){
        //this.$v.$reset()
        this.dialogWidth = 1000;
        this.itemEditing = true;
        this.dialog = true;
      },
      searchIntegrationDocuments(){
        if(this.search.length>2){
          const filterableHeaders = _.join(_.map(_.filter(this.headers,{'filterable':true}),'filterColumn'),';');
          this.$store.dispatch( 'searchIntegrationDocuments',{filterableHeaders:filterableHeaders,q:this.search});
        }
      },
     
      multiDelete(){
        this.$refs.confirm.open('Usuń', 'Czy na pewno chcesz usunać dokumenty?', { color: 'error' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'forceDeleteIntegrationDocument', {slug:  _.map(this.tableItemsSelected,'id')});
            }
        })
      },
      advanceCSV(){
        this.$store.dispatch( 'getIntegrationDocumentAdvanceCSV', {slug:  _.map(this.tableItemsSelected,'id')});
      },
      stornCSV(){
        this.$store.dispatch( 'getIntegrationDocumentStornCSV', {slug:  _.map(this.tableItemsSelected,'id')});
      },
      close () {
        this.dialog = false
        //this.$v.$reset()
        setTimeout(() => {
          this.$store.dispatch( 'loadIntegrationDocumentEditDefault'); 
          this.$store.dispatch( 'loadIntegrationDocumentAddDefault');
          this.itemEditing = false
        }, 300)
      },
      handleSubmit () {
        this.$v.$touch()
        if (this.$v.$invalid) {

        } else {
         
    
        }
       
      },
    },
  }
</script>


<style scoped>
.search{
      max-width: 250px;
}
</style>

<template>
          <v-card flat>
            <v-data-table
              :key="componentKey"
                hide-default-footer
                disable-pagination
                :headers="headers"
                :items="tableItems"
                class="elevation-1"
                :options.sync="tableOptions"
                :server-items-length="options.lineCount"
                :loading="integrationTasksLoadStatus===1"
                must-sort
            >
                <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-toolbar-title>Zadania</v-toolbar-title>
                    
                    <v-spacer></v-spacer>
                  
                    <v-dialog v-model="dialog" max-width="1300px" >
                    <template v-slot:activator="{ on }" v-if="user.permissions['setting'] >= 10">
                        <v-btn v-on="on" small class="mb-2 mt-2" fab dark color="primary">
                        <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <v-card :loading="integrationTaskAddStatus===1 || integrationTaskEditLoadStatus===1 || integrationTaskEditStatus===1">
                        <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                        <v-container>
                            <v-row>    
                              <v-col cols="12" sm="3" md="3">
                                <v-text-field v-model="editedItem.name" :error-messages="nameErrors" required @input="$v.editedItem.name.$touch()"  @blur="$v.editedItem.name.$touch()"  label="Nazwa zadania"></v-text-field>                            
                              </v-col>
                              <v-col cols="12" sm="3" md="3">
                                <v-select v-model="editedItem.schedule_id" :items="lookups.integrationTaskActionsSchedules" label="Harmonogram"></v-select>
                              </v-col>
                             
                            </v-row> 
                            <v-row v-if="itemEditing">
                              <v-col cols="12" sm="12" md="12">
                              <v-data-table
                                hide-default-footer
                                :headers="headersActions"
                                :items="editedItem.actions"
                                class="elevation-0"
                                must-sort
                                style="background-color: #f4f4f4;"
                                :loading="integrationTaskActionAddStatus===1 || integrationTaskActionDeleteStatus===1"
                                disable-sort
                                show-expand
                                :expanded.sync="expandedActions"
                                dense
                            >
                              <template v-slot:top>
                              <v-toolbar flat style="background-color: #f4f4f4;">
                                  <v-toolbar-title>Akcje</v-toolbar-title>
                                  
                                 
                                  <v-spacer></v-spacer>
                                
                                  <v-menu offset-y v-if="user.permissions['setting'] >= 10" max-width="300" :close-on-content-click="false">
                                    <template v-slot:activator="{ on }" >
                                      <v-btn
                                        color="primary"
                                        small
                                        v-on="on"
                                        class="text-none"
                                        :disabled="editedItem.id===0"
                                      >
                                      <v-icon left>mdi-plus</v-icon>Dodaj akcję
                                      </v-btn>
                                    </template>
                                    <v-expansion-panels accordion>
                                      <v-expansion-panel
                                        v-for="type in Object.keys(actionsGrouped)"
                                        :key="type"
                                      >
                                        <v-expansion-panel-header>
                                          {{type}}
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                          <v-list dense>
                                            <v-list-item v-for="item in actionsGrouped[type]"
                                              :key="item.value"
                                              @click="addAction(item.value)"
                                              dense
                                            >
                                              <v-list-item-title>{{item.text}}</v-list-item-title>
                                            </v-list-item>
                                          </v-list>
                                        </v-expansion-panel-content>
                                      </v-expansion-panel>
                                    </v-expansion-panels>
                                    <!-- <v-list dense>

                                      <v-list-item v-for="item in lookups.availableActions"
                                        :key="item.value"
                                        @click="addAction(item.value)"
                                        dense
                                      >
                                        <v-list-item-title>{{item.text}}</v-list-item-title>
                                      </v-list-item>

                                      
                                    </v-list> -->
                                  </v-menu>
                                  
                              </v-toolbar>
                              </template>
                              <template v-slot:expanded-item="{ headers, item }">
                                <td :colspan="headers.length" style="padding:0px 0px;">
                                  <v-tabs vertical class="my-tabs">
                                    <v-tab v-if="item.input">
                                      Parametry
                                    </v-tab>
                                    <v-tab v-if="item.filters">
                                      Filtry
                                    </v-tab>
                                    <v-tab v-if="item.available_parameters.length>0">
                                      Warunki
                                    </v-tab>
                                     <v-tab>
                                      Ustawienia
                                    </v-tab>
                                    <v-tab-item v-if="item.input">
                                      <v-card flat>
                                        <v-card-text>
                                          <v-row v-for="(key,value) in item.input" :key="key.label">
                                            <v-col class="col-7" style="padding:5px 5px;">
                                              <!-- <v-text-field v-model="key.value" :error-messages="requiredCommorErrors(editedItem.actions.indexOf(item),value)" :label="key.label" ></v-text-field> -->
                                              <v-select
                                                v-if="item.available_parameters.length>0"
                                                hide-details 
                                                outlined 
                                                dense 
                                                v-model="key.ref" 
                                                :items="item.available_parameters" 
                                                :label="key.label" 
                                                append-outer-icon="mdi-close"
                                                @click:append-outer="key.ref=null"
                                                @change="key.value=null"
                                              ></v-select>
                                              <v-select  v-else-if="(key.type=='enum' || key.type=='dynamic')"  
                                                outlined 
                                                dense 
                                                v-model="key.value" 
                                                :items="key.params.values" 
                                                :error-messages="requiredCommorErrors(editedItem.actions.indexOf(item),value)" 
                                                :label="key.label" 
                                                append-outer-icon="mdi-close"
                                                @click:append-outer="key.value=null"
                                              >
                                              </v-select>
                                             <v-text-field dense outlined v-else
                                                v-model="key.value" 
                                                :error-messages="requiredCommorErrors(editedItem.actions.indexOf(item),value)" 
                                                :label="key.label" 
                                              ></v-text-field>
                                            </v-col>
                                            <v-col class="col-5" style="padding:5px 5px;" v-if="item.available_parameters.length>0">
                                               <v-select  v-if="!key.ref && (key.type=='enum' || key.type=='dynamic')"  
                                                outlined 
                                                dense 
                                                v-model="key.value" 
                                                :items="key.params.values" 
                                                :error-messages="requiredCommorErrors(editedItem.actions.indexOf(item),value)" 
                                                :label="key.label" 
                                                append-outer-icon="mdi-close"
                                                @click:append-outer="key.value=null"
                                              >
                                              </v-select>
                                              <v-text-field dense outlined v-else-if="!key.ref"
                                                v-model="key.value" 
                                                :error-messages="requiredCommorErrors(editedItem.actions.indexOf(item),value)" 
                                                :label="key.label" 
                                              ></v-text-field>
                                            </v-col>
                                          </v-row>
                                        </v-card-text>
                                      </v-card>
                                    </v-tab-item>
                                    <v-tab-item  v-if="item.filters">
                                      <v-card flat>
                                        <v-card-text>
                                          <v-row v-for="(key,value) in item.filters" :key="key.label">
                                            <v-col class="col-7" style="padding:5px 5px;">
                                              <v-select v-if="key.type=='enum' || key.type=='dynamic'" :multiple="key.multiple" outlined clearable dense v-model="key.value" :items="key.params.values" :label="key.label" ></v-select>
                                              <v-text-field v-else dense outlined v-model="key.value" :label="key.label" ></v-text-field>
                                            </v-col>
                                          </v-row>
                                        </v-card-text>
                                      </v-card>
                                    </v-tab-item>
                                    <v-tab-item v-if="item.available_parameters.length>0">
                                      <v-card flat>
                                        <v-card-text>
                                          <v-row>
                                            <v-col class="col-12" style="padding:5px 5px;">
                                              <v-row v-for="(value, key, index) in item.conditions" :key="index" class="mb-1">
                                                <v-col class="col-5" style="padding:3px 3px;">
                                                  <!-- <v-select v-if="key.type=='enum' || key.type=='dynamic'"  outlined clearable dense v-model="key.value" :items="key.params.values" :label="key.label" ></v-select>
                                                  <v-text-field v-else dense outlined v-model="key.value" :label="key.label" ></v-text-field> -->
                                                  <v-select
                                                    hide-details outlined dense 
                                                    v-model="value.l_ref" 
                                                    :items="item.available_parameters" 
                                                    label="Referencja" single-line
                                                    append-outer-icon="mdi-close"
                                                    @click:append-outer="value.l_ref=null;value.l_label=null;"
                                                    @change="value.l_label=_.find(item.available_parameters,{'value':value.l_ref}).name;value.l_value=null;"
                                                  ></v-select>
                                                  <v-text-field :disabled="value.l_ref != null" dense outlined single-line hide-details v-model="value.l_value" class="col-6" label="lub wartość" ></v-text-field>
                                                </v-col>
                                                <v-col class="col-1" style="padding:3px 3px;">
                                                  <v-select class="conditionsItem"
                                                    hide-details outlined dense
                                                    v-model="value.relation" 
                                                    :items="relationItems" 
                                                  ></v-select>
                                                </v-col>
                                                <v-col class="col-5" style="padding:3px 3px;">
                                                  <v-select
                                                    hide-details outlined dense 
                                                    v-model="value.r_ref" 
                                                    label="Referencja" single-line
                                                    :items="item.available_parameters" 
                                                    append-outer-icon="mdi-close"
                                                    @click:append-outer="value.r_ref=null;value.r_label=null;"
                                                    @change="value.r_label=_.find(item.available_parameters,{'value':value.r_ref}).name;value.r_value=null;"
                                                  ></v-select>
                                                  <v-text-field :disabled="value.r_ref != null" dense outlined hide-details single-line v-model="value.r_value" class="col-6" label="lub wartość" ></v-text-field>
                                                </v-col>
                                                <v-col style="padding:3px 3px;">
                                                  <v-btn @click="item.conditions.splice(index,1)" small class="mt-1" color="primary">usuń</v-btn>
                                                </v-col>
                                              </v-row>
                                              <v-row>
                                                <v-col style="padding:5px 5px;">
                                                  <v-btn class="mr-2" v-if="item.order>1" @click="copyConditionFromPrevious(item)" small color="primary">Skopiuj z poprzedniej</v-btn>
                                                  <v-btn @click="!item.conditions?item.conditions=[Object.assign({}, emptyConditionItem)]:item.conditions.push(Object.assign({}, emptyConditionItem))" small color="primary">Dodaj</v-btn>
                                                </v-col>
                                              </v-row>
                                            </v-col>
                                          </v-row>
                                        </v-card-text>
                                      </v-card>
                                    </v-tab-item>
                                     <v-tab-item >
                                      <v-card flat>
                                        <v-card-text>
                                          <v-row>
                                            <v-col class="col-8" style="padding:5px 5px;">
                                              <v-select outlined clearable dense v-model="item.integration_id" :items="_.filter(lookups.integrations,{'type_id':item.integration_type_id})" label="Konto dostępowe" ></v-select>
                                              
                                            </v-col>
                                          </v-row>
                                          <v-row>
                                            <v-col class="col-3" style="padding:5px 5px;">
                                              <v-btn @click="updateAction(item)" small color="error">Aktualizuj akcję</v-btn>
                                            </v-col>
                                          </v-row>
                                        </v-card-text>
                                      </v-card>
                                    </v-tab-item>
                                  </v-tabs>
                                </td>
                              </template>

                                <template v-slot:item.details="{ item }">  
                                    <v-tooltip right v-if="editedItem.actions.length==item.lp">
                                      <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" size="18" class="mr-2" @click="deleteAction(item)">mdi-delete</v-icon>
                                      </template>
                                      <span>Usuń akcję</span>
                                    </v-tooltip>     
                                    
                                    <v-tooltip right v-if="item.lp>1">
                                      <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" size="18" class="mr-2" @click="moveUpAction(item)">mdi-arrow-up-thick</v-icon>
                                      </template>
                                      <span>Przesuń w górę</span>
                                    </v-tooltip> 
                                    <v-tooltip right v-if="editedItem.actions.length!=item.lp">
                                      <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" size="18" class="mr-2" @click="moveDownAction(item)">mdi-arrow-down-thick</v-icon>
                                      </template>
                                      <span>Przesuń w dół</span>
                                    </v-tooltip>   
                                    <v-tooltip right v-if="item.loop">
                                      <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" size="18" class="mr-2">mdi-reload</v-icon>
                                      </template>
                                      <span>Akcja cykliczna</span>
                                    </v-tooltip>   
                                    <v-tooltip right v-if="item.conditions">
                                      <template v-slot:activator="{ on }">
                                        <v-icon v-on="on"  size="18" class="mr-2">mdi-arrow-decision-outline</v-icon>
                                      </template>
                                      <span>Akcja warunkowa</span>
                                    </v-tooltip>     
                                </template> 
                            
                            </v-data-table> 
                              </v-col>
                            </v-row>

                            <v-row v-if="integrationTaskEditStatus===3 || integrationTaskAddStatus===3">
                                    <v-col class="d-flex flex-column align-center">
                                        <v-alert v-for="err in errorMessageArray(integrationTaskEditText)" :key="err.index" dense outlined type="error">
                                          {{err}}
                                        </v-alert>    
                                    </v-col>
                            </v-row>   
                            <v-row v-if="integrationTaskAddStatus===3">
                                    <v-col class="d-flex flex-column align-center">
                                        <v-alert v-for="err in errorMessageArray(integrationTaskAddText)" :key="err.index" dense outlined type="error">
                                          {{err}}
                                        </v-alert>    
                                    </v-col>
                            </v-row>   
                        </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <p class="text--secondary caption ml-5"></p>
                          <v-spacer></v-spacer>
                          <v-btn color="grey" text @click="dialog=false">Anuluj</v-btn>
                          <v-btn v-if="!itemEditing" color="primary darken-1" text @click="handleSubmit" >Dodaj</v-btn>
                          <v-btn v-else color="primary darken-1" text @click="handleSubmit" >Zapisz</v-btn>
                        </v-card-actions>
                    </v-card>

                    </v-dialog>
                    <v-dialog v-model="dialogLog" max-width="1400px">
                    <v-card>
                        <v-card-text>
                        
                            <integrationTaksLog :t-id="selectedTaskId"/>
                      
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="grey" text @click="dialogLog=false">Zamknij</v-btn>
                        </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
                </template>
                
               
                <template v-slot:item.details="{ item }">       
                  <v-tooltip right >
                                      <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" @click="openLog(item)" class="mr-2">mdi-history</v-icon>
                                      </template>
                                      <span>Historia</span>
                  </v-tooltip>
                   <v-tooltip right >
                                      <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" @click="editItem(item)" class="mr-2">mdi-playlist-edit</v-icon>
                                      </template>
                                      <span>Edytuj</span>
                  </v-tooltip>
                  <v-tooltip right >
                                      <template v-slot:activator="{ on }">
                                        <v-icon size="20" v-on="on" @click="copyItem(item)" class="mr-2">mdi-content-copy</v-icon>
                                      </template>
                                      <span>Skopiuj</span>
                  </v-tooltip>
                   <v-tooltip right v-if="item.can_delete">
                                      <template v-slot:activator="{ on }">
                                        <v-icon size="22" v-on="on" @click="deleteItem(item)" class="mr-2">mdi-delete</v-icon>
                                      </template>
                                      <span>Usuń</span>
                  </v-tooltip>
                  <v-tooltip right >
                                      <template v-slot:activator="{ on }">
                                        <v-icon size="22" v-if="runningTaskId===item.id && integrationTaskRunningStatus===1" color="primary" class="mr-2">mdi-loading mdi-spin</v-icon>
                                        <v-icon size="22" v-else v-on="on" @click="runItem(item)" class="mr-2">mdi-play-speed</v-icon>
                                      </template>
                                      <span>Uruchom</span>
                  </v-tooltip>
                </template> 

            </v-data-table> 
            <confirm ref="confirm"></confirm>
          </v-card>
</template>

<script>
  import confirm from '../components/Confirm'
  import integrationTaksLog from '../components/IntegrationTaskLog'
  import required from 'vuelidate/lib/validators/required'

  export default {
    components:{
      confirm,
      integrationTaksLog
    },
    props:['aId'],
    data: () => ({
      componentKey:0,
      tableOptions: {},
      search:'',
      dialog: false,
      dialogLog: false,
      itemEditing:false,
      expandedActions:[],
      runningTaskId:undefined,
      selectedTaskId:undefined,
      emptyConditionItem:{
        l_ref:null,
        l_label:null,
        l_value:null,
        r_ref:null,
        r_label:null,
        r_value:null,
        relation:"eq"
      },
      headers: [
        { text: 'Lp',align: 'start',sortable: false,value: 'lp',filterable:false},
        { text: 'Nazwa', value: 'name',filterable:true },
        { text: 'Harmonogram', value: 'schedule',filterable:true,sortable: false },
        { text: 'Ostatnio uruchomiono', value: 'last_run',sortable: false,filterable:false },
        { text: '', value: 'details', sortable: false },
      ],
      headersActions: [
        { text: 'Lp',align: 'start',sortable: false,value: 'lp',filterable:false},
        // { text: 'Order', value: 'order',filterable:true },
        { text: 'Nazwa', value: 'label',filterable:true },
        { text: '', value: 'details', sortable: false },
        { text: '',value:'data-table-expand',sortable: false}
      ],
      relationItems:[
        {value:"eq",text:"="},
        {value:"ne",text:"!="},
        {value:"lt",text:"<"},
        {value:"le",text:"<="},
        {value:"gt",text:">"},
        {value:"ge",text:">="},
        {value:"in",text:"IS NULL"},
        {value:"inn",text:"IS NOT NULL"}
      ]
    }),
    validations(){
      return this.inputValidation;
    },
    watch: {
      tableOptions: {
        handler () {
          var currentOptions = {...this.options};
          const { sortBy, sortDesc } = this.tableOptions
        
          //zmiana sortowania
          if (sortBy.length === 1 && sortDesc.length === 1) {
            currentOptions.orderBy = sortBy[0];
            currentOptions.orderDirection = sortDesc[0]?'desc':'asc';
            this.options = currentOptions;
          } 
        },
        deep: true,
      },
      dialog (val) {
        this.$v.$reset()
        if(val){

        }else{
          this.close()
        }
      },
      dialogLog (val) {
        if(val){

        }else{
          this.closeLog()
        }
      },
      integrationTaskEditStatus(val){
        if(val===2){
          this.dialog=false;
          this.componentKey++;
        }
      },
      integrationTaskAddStatus(val){
        if(val===2){
          this.itemEditing = true;
          //this.dialog=false;
          //this.componentKey++;
        }
      },
      integrationTaskRunningStatus(val){
        if(val===2){
          this.componentKey++;
        }
      }
    },
    computed: {
      inputValidation(){
        let validationObject = {
          editedItem:{
          name: {
            required
          },
          actions:{

          }
        }}
  
        var i = 0;
        this.editedItem.actions.forEach((a,index) => {
           let actionIndex = index;
           let ob = {input:{}};
            _.forIn(a.input, function(value, key, object) {//jeżeli jest ref to nie jest wymagane value
              if(value.ref===null && value.required){
                ob.input[key] = {value:{required}}
              }
            });
            validationObject.editedItem.actions[actionIndex] = ob;
        })
        return validationObject;
      },

      user(){
        return this.$store.getters.getUser;
      },
      lookups(){
        return this.$store.getters.getLookups;
      },
      options:{
        get () {  
          return this.$store.getters.getIntegrationTaskOptions;
        },
        set (value) {
          this.search='';
          this.$store.dispatch( 'setIntegrationTaskOptions', {options:value} )
        }
      },
      accountId: {
        get () {
          return this.$store.getters.getIntegrationTaskOptions.accountId;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.accountId = value;
          this.options = currentOptions;
        }
      },
      editedItem(){
        return this.$store.getters.getIntegrationTaskEdit;
      },
      tableItems(){
        return this.$store.getters.getIntegrationTasks;
      },
      integrationTasksLoadStatus(){
        return this.$store.getters.getIntegrationTasksLoadStatus();
      },
      integrationTaskEditLoadStatus(){
        return this.$store.getters.getIntegrationTaskEditLoadStatus();
      },
      integrationTaskEditStatus(){
        return this.$store.getters.getIntegrationTaskEditStatus();
      },
      integrationTaskRunningStatus(){
        return this.$store.getters.getIntegrationTaskRunningStatus();
      },
      integrationTaskEditText(){
        return this.$store.getters.getIntegrationTaskEditText
      },
      integrationTaskAddStatus(){
        return this.$store.getters.getIntegrationTaskAddStatus();
      },
      integrationTaskAddText(){
        return this.$store.getters.getIntegrationTaskAddText;
      },
      integrationTaskActionAddStatus(){
        return this.$store.getters.getIntegrationTaskActionAddStatus();
      },
      integrationTaskActionDeleteStatus(){
        return this.$store.getters.getIntegrationTaskActionDeleteStatus();
      },
      formTitle () {
        return !this.itemEditing ? 'Nowe zadanie' : 'Edycja zadania'
      },
      nameErrors () {
        const errors = []
        if (this.itemEditingPassword || !this.$v.editedItem.name.$dirty) return errors
        !this.$v.editedItem.name.required && errors.push('Nazwa zadania jest wymagana')
        return errors
      },
      actionsGrouped(){
        return _.groupBy(this.lookups.availableActions,x=>x.type);
      }
    },
    mounted(){
       if(this.lookups.integrations.length>0){
        this.loadIntegrationTasks();
      }else{
        this.$store.dispatch( 'loadLookups' );
        setTimeout(() => {
          this.loadIntegrationTasks();
        }, 1000)
      }
      
    },
    methods: {
      requiredCommorErrors(index,parameter) {
        const errors = []
        if (!this.$v.editedItem.actions[index]['input'][parameter] || !this.$v.editedItem.actions[index]['input'][parameter].value.$dirty) return errors
        !this.$v.editedItem.actions[index]['input'][parameter].value.required && errors.push('Parametr jest wymagany')
        return errors
      },
      loadIntegrationTasks(){
        this.search='';
        this.accountId = this.aId;//pobiera z propsów
      },
      errorMessageArray(val) {
        const errors = val;
        var err = [];
        if(!errors)return [];

        if(errors.marketplace_integration_id){
          errors.marketplace_integration_id.forEach(e => {
            err.push(e);
          });
        }

        return err;
      },     
      editItem (item) {
        this.itemEditing = true
        this.$store.dispatch( 'loadIntegrationTaskEdit', {slug:  item.id});  
        this.dialog = true
      },
      deleteItem (item) {
        this.$refs.confirm.open('Usuń','Czy na pewno chcesz usunać zadanie?', { color: 'error' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'forceDeleteIntegrationTask', {slug:  item.id});
            }
        })
      },
      runItem(item){
        this.runningTaskId = item.id;
        this.$store.dispatch( 'runIntegrationTask', {
                slug: item.id,
                initialValues:null
        });
      },
      copyItem(item){
        this.$store.dispatch( 'copyIntegrationTask', {
                slug: item.id
        });
      },
      copyConditionFromPrevious(item){
        //console.log(_.find(this.editedItem.actions,{'order':item.order -1}).conditions);
        item.conditions = JSON.parse(JSON.stringify(_.find(this.editedItem.actions,{'order':item.order -1}).conditions));
      },
      openLog(item){
        this.selectedTaskId = item.id;
        this.dialogLog = true;
      },
      addAction(id){
        this.$store.dispatch( 'addIntegrationTaskAction', {
                slug: this.editedItem.id,
                action:  id,
        });
      },
      deleteAction (item) {
        this.$refs.confirm.open('Usuń','Czy na pewno chcesz usunać akcję?', { color: 'error' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'forceDeleteIntegrationTaskAction', {slug:  item.id});
            }
        })
      },
      updateAction (item) {
        this.$refs.confirm.open('Usuń','Czy na pewno chcesz aktualizować akcję? Wartości wprowadzonych zmiennych zostaną utracone!', { color: 'error' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'updateIntegrationTaskAction', {slug:  item.id});
            }
        })
      },
      moveUpAction(item){
        this.$store.dispatch( 'moveIntegrationTaskAction', {
                slug: item.id,
                direction:  -1,
        });
      },
      moveDownAction(item){
        this.$store.dispatch( 'moveIntegrationTaskAction', {
                slug: item.id,
                direction:  1,
        });
      },
      close () {
        //this.dialog = false
        this.$v.$reset()
        setTimeout(() => {
          this.$store.dispatch( 'loadIntegrationTaskEditDefault'); 
          this.$store.dispatch( 'loadIntegrationTaskAddDefault');
          this.itemEditing = undefined
          this.expandedActions = [];
          //this.selectedAction = undefined;
        }, 300)
      },
      closeLog () {
        this.selectedTaskId = undefined;
        setTimeout(() => {
          this.$store.dispatch( 'loadIntegrationTaskLogsEmpty'); 
        }, 300)
      },
      expandActionOnError(){
        for(var i = 0;i<this.editedItem.actions.length;i++){
          if(this.$v.editedItem.actions[i].$invalid && !this.expandedActions.includes(this.editedItem.actions[i])){
              this.expandedActions.push(this.editedItem.actions[i]);
          }
        }
      },
      handleSubmit () {
        this.$v.$touch()
        if (this.$v.$invalid) {
          this.expandActionOnError()
        } else {
         
            if (this.itemEditing) {
              //edycja
              this.$store.dispatch( 'editIntegrationTask', {
                slug: this.editedItem.id,
                name:  this.editedItem.name,
                schedule_id:  this.editedItem.schedule_id,
                actions: this.editedItem.actions
              });
            } else {//new item

              this.$store.dispatch( 'addIntegrationTask', {
                  account_id: this.accountId,
                  name:  this.editedItem.name,
                  schedule_id:  this.editedItem.schedule_id,
              });

            }
        }
       
      },
    },
  }
</script>

<style scoped>
.select_actions{
      max-width: 350px;
}
div.my-tabs [role="tab"] {
  justify-content: flex-start;
}

</style>
<template>
  <v-card :loading="TpayChecksLoadStatus === 1">
    <v-card-title>
      <span class="headline">Import danych</span>
      <v-spacer />
      <v-chip
        v-if="fileContentErrors.length > 0"
        outlined
        small
        label
        color="error"
        >{{ fileContentErrors[0] }}</v-chip
      >
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-row dense>
          <v-col cols="12">
            <v-file-input
              clearable
              v-model="file"
              label="Wybierz plik CSV"
            ></v-file-input>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="gray" text @click="close">Anuluj</v-btn>
      <v-btn color="secondary darken-1" text @click="handleSubmit"
        >Importuj</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import required from "vuelidate/lib/validators/required";

export default {
  components: {},
  data: () => ({
    file: null,
    fileWithHeader: true,
    fileContent: null,
  }),
  validations: {
    fileContent: {
      required,
    },
  },
  watch: {
    file(val) {
      if (!val) {
        this.fileContent = null;
      } else {
        //this.createBase64File(val);
        //this.fileContent = val.name;
        this.handleFile(val);
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    TpayChecksLoadStatus(){
        return this.$store.getters.getTpayChecksLoadStatus();
      },

    fileContentErrors() {
      const errors = [];
      if (!this.$v.fileContent.$dirty) return errors;
      !this.$v.fileContent.required && errors.push("Plik jest wymagany");
      return errors;
    },
  },

  methods: {
    handleFile(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        //this.fileContent = reader.result
        this.fileContent = this.csvToArray(reader.result.replace(/"/g,''),';');
      };
      reader.onerror = (e) => {
        console.log(e);
      };
      reader.readAsText(file,'windows-1250');
    },
    csvToArray(str, delimiter = ",") {
      console.log(str.slice(str.indexOf("LP"), str.indexOf("\n", str.indexOf("LP") )));
      const headers = str.slice(str.indexOf("LP"), str.indexOf("\n", str.indexOf("LP")) - 1).split(delimiter);
      const rows = str.slice(str.indexOf("\n",str.indexOf("LP")) + 1).split("\n");
      const arr = rows.map(function (row) {
        const values = row.split(delimiter);
        const el = headers.reduce(function (object, header, index) {
          object[header] = values[index];
          return object;
        }, {});
        return el;
      });

      // return the array
      return arr;
    },
    close() {
      this.$v.$reset();
      this.$emit("close");
    },
    handleSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
      } else {
       //console.log(this.fileContent);
       this.$store.dispatch( 'loadTpayChecks',{
           file_content: this.fileContent,
       });
      }
    },
  },
};
</script>


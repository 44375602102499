<template>
    <v-container class="d-flex flex-column pa-0 ml-3">
        <slot name="actions"></slot>
        

        <v-card width="250">
              <v-card-text>
                <div>
                  <v-container class="pa-0 widget-header"><strong>Status</strong></v-container>
                  <v-row no-gutters dense><v-checkbox :disabled="statusId>0" hide-details v-model="showActive" label="Tylko aktywne"></v-checkbox></v-row>
                  <v-row no-gutters dense><v-select hide-details clearable v-model="statusId" :items="lookups.integrationDocumentStatus" label="Wybrany status"></v-select></v-row>
                </div>
              </v-card-text>
        </v-card>
        <v-card width="250" class="mt-5">
              <v-card-text>
                <div>
                  <v-container class="pa-0 widget-header"><strong>Typ dokumentu</strong></v-container>
                  <v-row no-gutters dense><v-select hide-details clearable v-model="typeId" :items="lookups.integrationDocumentTypes" label="Wybrany typ"></v-select></v-row>
                </div>
              </v-card-text>
        </v-card>
        <v-card width="250" class="mt-5">
              <v-card-text>
                <div>
                  <v-container class="pa-0 widget-header"><strong>Data dokumentu</strong></v-container>
                  <v-row no-gutters dense>
                      <v-menu
                                      v-model="dateFromMenu"
                                      :close-on-content-click="false"
                                      :nudge-right="40"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="150px"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                        hide-details
                                         clearable
                                          v-model="fromDate"
                                          label="Od"
                                          prepend-icon="mdi-calendar"
                                          readonly
                                          v-on="on"
                                          class="choose_date mt-6 mr-5"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker  first-day-of-week="1" no-title v-model="fromDate" @input="dateFromMenu = false"></v-date-picker>
                      </v-menu>
                  </v-row>
                  <v-row no-gutters dense>
                      <v-menu
                                      v-model="dateToMenu"
                                      :close-on-content-click="false"
                                      :nudge-right="40"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="150px"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                        hide-details
                                          clearable
                                          v-model="toDate"
                                          label="Do"
                                          prepend-icon="mdi-calendar"
                                          readonly
                                          v-on="on"
                                          class="choose_date mt-6 mr-5"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker  first-day-of-week="1" no-title v-model="toDate" @input="dateToMenu = false"></v-date-picker>
                      </v-menu>
                  </v-row>
                </div>
              </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
export default {
    data(){
        return{
            defaultColor:'tertiary', 
            selectedColor:'primary',
            dateFromMenu:false,
            dateToMenu:false,
        }
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      lookups(){
        return this.$store.getters.getLookups;
      },
      options:{
        get () {  
          return this.$store.getters.getIntegrationDocumentOptions;
        },
        set (value) {
          this.search='';
          this.$store.dispatch( 'setIntegrationDocumentOptions', {options:value} )
        }
      },
      statusId: {
        get () {
          return this.$store.getters.getIntegrationDocumentOptions.statusId;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.statusId = value;
          this.options = currentOptions;
        }
      },
      fromDate: {
        get () {
          return this.$store.getters.getIntegrationDocumentOptions.fromDate;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.fromDate = value;
          this.options = currentOptions;
        }
      },
      toDate: {
        get () {
          return this.$store.getters.getIntegrationDocumentOptions.toDate;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.toDate = value;
          this.options = currentOptions;
        }
      },
       typeId: {
        get () {
          return this.$store.getters.getIntegrationDocumentOptions.typeId;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.typeId = value;
          this.options = currentOptions;
        }
      },
      showActive: {
        get () {
          return this.$store.getters.getIntegrationDocumentOptions.showActive;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.showActive = value;
          this.options = currentOptions;
        }
      },
    }
}
</script>

<style scoped>
.v-btn{
  margin-bottom: 1em;
}
.v-chip{
  margin-left: 1em;
}
.v-chip:hover{
    cursor: pointer;
}

.v-btn:not(.v-btn--text):not(.v-btn--outlined).v-btn--active:before {
    opacity: 0;
}

</style>
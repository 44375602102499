import SequenceAPI from "../api/sequence";

export const sequences = {
    state: {
        sequences: [],
        sequenceOptions:{
            lineCount:0,
            showDeleted:false,
            orderBy:'name',
            orderDirection:'asc',
            itemsPerPage:100,
            itemAdded:false,
            itemSearched:false
        },
        sequencesLoadStatus: 0,
        sequenceAddStatus: 0,
        sequenceAddText: "",

        sequenceEdit: {
            id: 0,
            lp: 0,
            type:undefined,
            name:undefined,
            from:1,
            to:undefined,
            next:undefined,
            mask:undefined
        },
        sequenceEditDefault: {
            id: 0,
            lp: 0,
            type: undefined,
            name:undefined,
            from:1,
            to:undefined,
            next:undefined,
            mask:undefined
        },
        sequenceEditLoadStatus: 0,
        sequenceEditStatus: 0,
        sequenceEditText: "",

        sequenceDeleteStatus: 0,
        sequenceDeleteText: "",

    },

    actions: {
        loadSequences({ state, commit, dispatch }) {
            commit("setSequencesLoadStatus", 1);
                SequenceAPI.getSequences({
                    offset:0,
                    itemsPerPage:state.sequenceOptions.itemsPerPage, 
                    orderBy:state.sequenceOptions.orderBy,
                    orderDirection:state.sequenceOptions.orderDirection,
                    showDeleted:state.sequenceOptions.showDeleted,
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setSequenceLineCount", response.data.lineCount);
                    commit("setSequences", response.data.sequences);
                    dispatch("applyIndexOnSequences");
                    commit("setSequencesLoadStatus", 2);
                })
                .catch(function() {
                    commit("setSequences", []);
                    commit("setSequencesLoadStatus", 3);
                });
         
        },
        loadMoreSequences({ state, commit, dispatch }) {
            commit("setSequencesLoadStatus", 1);
                SequenceAPI.getSequences({
                    offset:state.sequences.length,
                    itemsPerPage:state.sequenceOptions.itemsPerPage, 
                    orderBy:state.sequenceOptions.orderBy,
                    orderDirection:state.sequenceOptions.orderDirection,
                    showDeleted:state.sequenceOptions.showDeleted,
                })
                .then(function(response) {
                    commit("setSequenceLineCount", response.data.lineCount);
                    commit("setSequences", _.concat(state.sequences, response.data.sequences));
                    dispatch("applyIndexOnSequences");
                    commit("setSequencesLoadStatus", 2);
                })
                .catch(function() {
                    commit("setSequences", []);
                    commit("setSequencesLoadStatus", 3);
                });
         
        },
        searchSequences({ state, commit, dispatch },data) {
            commit("setSequencesLoadStatus", 1);
                SequenceAPI.searchSequences({
                    showDeleted:state.sequenceOptions.showDeleted,
                    filterableHeaders:data.filterableHeaders,
                    q:data.q
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setSequenceSearchedTrue");
                    commit("setSequenceLineCount", response.data.lineCount);
                    commit("setSequences", response.data.sequences);
                    dispatch("applyIndexOnSequences");
                    commit("setSequencesLoadStatus", 2);
                })
                .catch(function() {
                    commit("setSequences", []);
                    commit("setSequencesLoadStatus", 3);
                });
         
        },
        setSequenceOptions({ state, commit, dispatch },data){
            commit("setSequenceOptions",data.options);
            dispatch("loadSequences");
        },
        applyIndexOnSequences({ commit, state, rootState }) {
            let localSequences = state.sequences;

            var i = 1;
            localSequences.forEach(sequence => {
                if(_.isNumber(sequence.type)){
                    sequence.type = _.find(rootState.lookups.sequenceType, { 'value': sequence.type }).text;
                }
                sequence.lp = i;
                i++;
            });

            commit("setSequences",localSequences);
        },
        addSequence({ commit, state, dispatch }, data) {
            commit("setSequenceAddStatus", 1);
            commit("setSequenceAddText", "");

            SequenceAPI.postAddNewSequence(
                data.type,
                data.name,
                data.from,
                data.to,
                data.mask
            )
                .then(function(response) {
                    commit("setSequenceAddStatus", 2);
                    commit("setAddedSequence",response.data);
                    commit("setSequenceLineCount", state.sequenceOptions.lineCount + 1);
                    
                    dispatch("applyIndexOnSequences");
                })
                .catch(function(error) {
                    commit("setSequenceAddStatus", 3);
                    commit("setSequenceAddText", error.response.data.errors);
                });
        },

        loadSequenceEdit({ commit }, data) {
            commit("setSequenceEditLoadStatus", 1);
            commit("setSequenceEditStatus", 0);
            commit("setSequenceEditText", "");

            SequenceAPI.getSequenceEdit(data.slug)
                .then(function(response) {
                    commit("setSequenceEdit", response.data);
                    commit("setSequenceEditLoadStatus", 2);
                })
                .catch(function() {
                    commit("setSequenceEdit", {});
                    commit("setSequenceEditLoadStatus", 3);
                });
        },
        loadSequenceEditDefault({ commit }) {
            commit("setSequenceEditDefault");
        },
        loadSequenceAddDefault({ commit }) {
            commit("setSequenceAddStatus", 0);
            commit("setSequenceAddText", "");
        },
        editSequence({ commit, state, dispatch }, data) {
            commit("setSequenceEditStatus", 1);

            SequenceAPI.putEditSequence(
                data.slug,
                data.type,
                data.name,
                data.from,
                data.to,
                data.next,
                data.mask
            )
                .then(function(response) {
           
                    commit("setSequenceEditStatus", 2);
                    commit("setEditedSequence",response.data)
                    dispatch("applyIndexOnSequences");
                })
                .catch(function(error) {
                    commit("setSequenceEditStatus", 3);
                    commit("setSequenceEditText", error.response.data.errors);
                });
        },
        editSequenceReactivate({ commit, state, dispatch }, data) {
            commit("setSequenceEditStatus", 1);
  
            SequenceAPI.putReactivateSequence(data.slug)
                .then(function(response) {
                    commit("setSequenceEditStatus", 2);
                    commit("setEditedSequence",response.data);
                    
                    dispatch("applyIndexOnSequences");
                })
                .catch(function(error) {
                    commit("setSequenceEditStatus", 3);
                });
        },
        deleteSequence({ commit, state, dispatch }, data) {
            commit("setSequenceDeleteStatus", 1);

            SequenceAPI.deleteSequence(data.slug)
                .then(function(response) {
                    commit("setSequenceDeleteStatus", 2);
                    if(state.sequenceOptions.showDeleted){
                        commit("setEditedSequence",response.data)
                    }else{
                        commit("setSequenceLineCount", state.sequenceOptions.lineCount - 1);
                        commit("setDeletedSequence",response.data) 
                    }
                    
                    dispatch("applyIndexOnSequences");
                })
                .catch(function() {
                    commit("setSequenceDeleteStatus", 3);
                });
        },
        forceDeleteSequence({ commit, state, dispatch }, data) {
            commit("setSequenceDeleteStatus", 1);

            SequenceAPI.forceDeleteSequence(data.slug)
                .then(function(response) {
                    commit("setSequenceDeleteStatus", 2);
                    commit("setSequenceLineCount", state.sequenceOptions.lineCount - 1);
                    commit("setDeletedSequence",response.data)
                    dispatch("applyIndexOnSequences");
                })
                .catch(function() {
                    commit("setSequenceDeleteStatus", 3);
                });
        },
    },
    mutations: {
        setSequenceOptions(state,options){
            state.sequenceOptions = options;
        },
        setSequencesLoadStatus(state, status) {
            state.sequencesLoadStatus = status;
        },
        setSequences(state, sequences) {
            state.sequences = sequences;
        },
        setSequenceLineCount(state, lineCount) {
            state.sequenceOptions.lineCount = lineCount;
        },
        setSequenceAddStatus(state, status) {
            state.sequenceAddStatus = status;
        },
        setSequenceAddText(state, text) {
            state.sequenceAddText = text;
        },
        setSequenceEdit(state, sequence) {
            state.sequenceEdit = sequence;
        },
        setEditedSequence(state, sequence) {
            var lp = _.find(state.sequences,{'id':sequence.id}).lp;
            var index = _.findIndex(state.sequences,{'id':sequence.id});
            state.sequences[index] = sequence;
            state.sequences[index].lp = lp;
        },
        setAddedSequence(state, sequence) {
            state.sequenceOptions.itemAdded = true;
            state.sequences.push(sequence);
        },
        setSequenceSearchedTrue(state) {
            state.sequenceOptions.itemSearched = true;
        },
        setOptionsItemAddedAndSearchedFalse(state) {
            state.sequenceOptions.itemAdded = false;
            state.sequenceOptions.itemSearched = false;
        },
        setDeletedSequence(state, sequence) {
            var index = _.findIndex(state.sequences,{'id':sequence.id});
            state.sequences.splice(index,1);
        },
        setSequenceEditDefault(state) {
            state.sequenceEdit = { ...state.sequenceEditDefault };
        },
        setSequenceEditStatus(state, status) {
            state.sequenceEditStatus = status;
        },
        setSequenceEditText(state, text) {
            state.sequenceEditText = text;
        },
        setSequenceEditLoadStatus(state, status) {
            state.sequenceEditLoadStatus = status;
        },
        setSequenceDeleteStatus(state, status) {
            state.sequenceDeleteStatus = status;
        },
        setSequenceDeletedText(state, text) {
            state.sequenceDeleteText = text;
        }
    },

    getters: {
        getSequenceOptions(state){
            return state.sequenceOptions;
        },
        getSequencesLoadStatus(state) {
            return state.sequencesLoadStatus;
        },
        getSequences(state) {
            return state.sequences;
        },
        getSequenceAddStatus(state) {
            return function(){
                return state.sequenceAddStatus;
            } 
        },
        getSequenceAddText(state) {
            return state.sequenceAddText;
        },
        getSequenceEdit(state) {
            return state.sequenceEdit;
        },
        getSequenceEditStatus(state) {
          return function(){
            return state.sequenceEditStatus;
          }
        },
        getSequenceEditing(state) {
            return state.sequenceEdit.id > 0;
        },
        getSequenceEditText(state) {
            return state.sequenceEditText;
        },
        getSequenceEditLoadStatus(state) {
            return state.sequenceEditLoadStatus;
            
        },
        getSequenceDeleteStatus(state) {
            return function(){
                return state.sequenceDeleteStatus;
            }
        },
        getSequenceDeleteText(state) {
            return state.sequenceDeleteText;
        }
    }
};

import { FLOREX_CONFIG } from '../config'
import axios from 'axios'


export default {

    
    /*
        GET     /api/integrationProducts
    */
    getIntegrationProducts: function(options){
        return axios.get( FLOREX_CONFIG.API_URL + '/integrationProducts',{
            params:{
                offset:options.offset,
                itemsPerPage:options.itemsPerPage,
                orderBy:options.orderBy,
                orderDirection:options.orderDirection,
                integrationId:options.integrationId,
                categoryId:options.categoryId
        }});
    },

    /*
        GET     /api/integrationProducts/search
    */
   searchIntegrationProducts: function(options){
        return axios.get( FLOREX_CONFIG.API_URL + '/integrationProducts/search',{
            params:{
                integrationId:options.integrationId,
                categoryId:options.categoryId,
                filterableHeaders:options.filterableHeaders,
                q:options.q
        }});
    },

    /*
		GET 	/api/integrationProducts/update
	*/
    getUpdateIntegrationProducts: function( slug, options, count ){
        return axios.get( FLOREX_CONFIG.API_URL + '/integrationProducts/' + slug + '/update',{
            params:{
                options:options,
                count:count
        }});
    },

     /*
		GET 	/api/integrationProducts/{slug}/{catId}/categories
	*/
    getCategoriesOfIntegrationProducts: function( slug, catId){
        return axios.get( FLOREX_CONFIG.API_URL + '/integrationProducts/' + slug + '/' + catId + '/categories');
    },

    /*
		GET 	/api/integrationProducts/{slug}/edit
	*/
    getIntegrationProductEdit: function( slug ){
		return axios.get( FLOREX_CONFIG.API_URL + '/integrationProducts/' + slug + '/edit' );
    },
    
    /*
		GET 	/api/integrationProducts/muclist
	*/
    getIntegrationProductEditMucList: function( ){
		return axios.get( FLOREX_CONFIG.API_URL + '/integrationProducts/muclist' );
	},

    /*
		PUT 	/api/integrationProducts/{slug}
	*/
    putEditIntegrationProduct: function(slug, height, length, width, weight, muc, adr){
        return axios.put( FLOREX_CONFIG.API_URL + '/integrationProducts/'+ slug,
            {
                height: height,
                length: length,
                width: width,
                weight: weight,
                muc: muc,
                adr: adr
            }
        );
    },

    /*
		PUT 	/api/integrationProducts/multi
	*/
    putEditIntegrationProducts: function(slug, height, length, width, weight, muc, adr){
        return axios.put( FLOREX_CONFIG.API_URL + '/integrationProducts/multi',
            {
                slug:slug,
                height: height,
                length: length,
                width: width,
                weight: weight,
                muc: muc,
                adr: adr
            }
        );
    },

    /*
		DELETE /api/integrationProducts/{slug}/force
	*/
    forceDeleteIntegrationProduct: function( slug ){
		return axios.delete( FLOREX_CONFIG.API_URL + '/integrationProducts/' + slug + '/force' );
    },
    
}
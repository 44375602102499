
import { FLOREX_CONFIG } from '../config'
import axios from 'axios'


export default {

     /*
        GET     /api/integrationDocuments/payu
    */
        postPayuChecks: function(options){
            return axios.post( FLOREX_CONFIG.API_URL + '/integrationDocuments/payu',{
                    accountId:options.accountId,
                    statusId:options.statusId,
                    showActive:options.showActive,
                    typeId:options.typeId,
                    file_content:options.file_content
            });
        },

          /*
        GET     /api/integrationDocuments/tpay
    */
        postTpayChecks: function(options){
            return axios.post( FLOREX_CONFIG.API_URL + '/integrationDocuments/tpay',{
                    accountId:options.accountId,
                    statusId:options.statusId,
                    showActive:options.showActive,
                    typeId:options.typeId,
                    file_content:options.file_content
            });
        },

         /*
        GET     /api/integrationDocuments/storn
    */
        postStornChecks: function(options){
            return axios.post( FLOREX_CONFIG.API_URL + '/integrationDocuments/storn',{
                    accountId:options.accountId,
                    statusId:options.statusId,
                    showActive:options.showActive,
                    typeId:options.typeId,
                    fromDate:options.fromDate,
                    toDate:options.toDate,
            });
        },
    
    /*
        GET     /api/integrationDocuments
    */
    getIntegrationDocuments: function(options){
        return axios.get( FLOREX_CONFIG.API_URL + '/integrationDocuments',{
            params:{
                offset:options.offset,
                itemsPerPage:options.itemsPerPage,
                orderBy:options.orderBy,
                orderDirection:options.orderDirection,
                accountId:options.accountId,
                statusId:options.statusId,
                showActive:options.showActive,
                fromDate:options.fromDate,
                toDate:options.toDate,
                typeId:options.typeId
        }});
    },

    /*
        GET     /api/integrationDocuments/search
    */
   searchIntegrationDocuments: function(options){
        return axios.get( FLOREX_CONFIG.API_URL + '/integrationDocuments/search',{
            params:{
                integrationId:options.integrationId,
                filterableHeaders:options.filterableHeaders,
                q:options.q
        }});
    },


    /*
		GET 	/api/integrationDocuments/{slug}/edit
	*/
    getIntegrationDocumentEdit: function( slug ){
		return axios.get( FLOREX_CONFIG.API_URL + '/integrationDocuments/' + slug + '/edit' );
    },


    /*
		PUT 	/api/integrationDocuments/{slug}
	*/
    putEditIntegrationDocument: function(slug, document, buyer,amount,amount_net,amount_net_payed,amount_currency){
        return axios.put( FLOREX_CONFIG.API_URL + '/integrationDocuments/'+ slug,
            {
                document: document,
                buyer: buyer,
                amount:amount,
                amount_net: amount_net,
                amount_net_payed:amount_net_payed,
                amount_currency:amount_currency,
            }
        );
    },

    /*
		POST 	/api/integrationDocuments/{slug}
	*/
    postNewIntegrationDocument: function(integration_order_id, type,integration_id,document,buyer,amount,amount_net,amount_payed,amount_currency){
        return axios.post( FLOREX_CONFIG.API_URL + '/integrationDocuments',
            {
                integration_order_id: integration_order_id,
                type: type,
                integration_id:integration_id,
                document:document,
                buyer:buyer,
                amount:amount,
                amount_net: amount_net,
                amount_payed:amount_payed,
                amount_currency:amount_currency,
            }
        );
    },

    /*
		GET 	/api/integrationDocuments/{slug}/pdf
	*/
    getIntegrationDocumentPdf: function( slug){
		return axios.get( FLOREX_CONFIG.API_URL + '/integrationDocuments/' + slug + '/pdf' );
    },

    /*
		PUT /api/integrationDocuments/force
	*/
    forceDeleteIntegrationDocument: function( slug ){
        return axios.put( FLOREX_CONFIG.API_URL + '/integrationDocuments/force',
        {
            slug: slug,
        } );
    },

    putIntegrationDocumentAdvanceCSV: function( slug ){
        return axios.put( FLOREX_CONFIG.API_URL + '/integrationDocuments/advance',
        {
            slug: slug,
        } );
    },
    putIntegrationDocumentStornCSV: function( slug ){
        return axios.put( FLOREX_CONFIG.API_URL + '/integrationDocuments/storn',
        {
            slug: slug,
        } );
    },
    
}
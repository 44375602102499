<template>
    <v-container class="d-flex flex-column pa-0 ml-3">
        <slot name="actions"></slot>
        

        <v-card width="250">
              <v-card-text>
                <div>
                  <v-container class="pa-0 widget-header"><strong>Status</strong></v-container>
                  <v-row no-gutters dense><v-checkbox :disabled="statusId>0" hide-details v-model="showActive" label="Tylko aktywne"></v-checkbox></v-row>
                  <v-row no-gutters dense><v-select hide-details clearable v-model="statusId" :items="lookups.integrationShipmentStatus" label="Wybrany status"></v-select></v-row>
                </div>
              </v-card-text>
        </v-card>
        <!-- <v-card width="250" class="mt-5">
              <v-card-text>
                <div>
                  <v-container class="pa-0 widget-header"><strong>Data zamówienia</strong></v-container>
                  <v-row no-gutters dense>
                      <v-menu
                                      v-model="dateFromMenu"
                                      :close-on-content-click="false"
                                      :nudge-right="40"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="150px"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                        hide-details
                                         clearable
                                          v-model="fromDate"
                                          label="Od"
                                          prepend-icon="mdi-calendar"
                                          readonly
                                          v-on="on"
                                          class="choose_date mt-6 mr-5"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker  first-day-of-week="1" no-title v-model="fromDate" @input="dateFromMenu = false"></v-date-picker>
                      </v-menu>
                  </v-row>
                  <v-row no-gutters dense>
                      <v-menu
                                      v-model="dateToMenu"
                                      :close-on-content-click="false"
                                      :nudge-right="40"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="150px"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                        hide-details
                                          clearable
                                          v-model="toDate"
                                          label="Do"
                                          prepend-icon="mdi-calendar"
                                          readonly
                                          v-on="on"
                                          class="choose_date mt-6 mr-5"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker  first-day-of-week="1" no-title v-model="toDate" @input="dateToMenu = false"></v-date-picker>
                      </v-menu>
                  </v-row>
                </div>
              </v-card-text>
        </v-card> -->
        <!-- <v-card width="250" class="mt-5">
              <v-card-text>
                <div>
                  <v-container class="pa-0 widget-header"><strong>Nadawca</strong></v-container>
                  <v-row no-gutters dense><v-select hide-details clearable v-model="integrationId" :items="carrierIntegrations" label="Wybrany nadawca"></v-select></v-row>
                </div>
              </v-card-text>
        </v-card> -->
        <v-card width="250" class="mt-5">
              <v-card-text>
                <div>
                  <v-container class="pa-0 widget-header"><strong>Data wysyłki</strong></v-container>
                  <v-row no-gutters dense>
                      <v-menu
                                      v-model="datePickupMenu"
                                      :close-on-content-click="false"
                                      :nudge-right="40"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="150px"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                        hide-details
                                         clearable
                                          v-model="pickupDate"
                                          label="Wybrana data"
                                          prepend-icon="mdi-calendar"
                                          readonly
                                          v-on="on"
                                          class="choose_date mt-6 mr-5"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker  first-day-of-week="1" no-title v-model="pickupDate" @input="datePickupMenu = false"></v-date-picker>
                      </v-menu>
                  </v-row>
                </div>
              </v-card-text>
        </v-card>
          <!-- <v-btn :to="{name: 'klienci' }" large :color="$route.name === 'klienci' || $route.name === 'klient' ? selectedColor : defaultColor" dark width="250" active-class="aa" exact>
            Klienci  
              
            </v-btn>

            <v-btn :to="{name: 'specjalne' }" large :color="$route.name === 'specjalne' ? selectedColor : defaultColor" dark width="250">
            Zam. Specjalne  
            </v-btn> -->
            <!-- <v-btn :to="{name: 'integracje.zadania' }" large :color="$route.name === 'integracje.zadania' ? selectedColor : defaultColor" dark width="250">
            Zadania  
            </v-btn>

            <v-btn :to="{name: 'integracje.ustawienia' }" large :color="$route.name === 'integracje.ustawienia' ? selectedColor : defaultColor" dark width="250">
            Ustawienia  
            </v-btn>

            <v-btn :to="{name: 'integracje.produkty' }" large :color="$route.name === 'integracje.produkty' ? selectedColor : defaultColor" dark width="250">
            Produkty  
            </v-btn> -->
    </v-container>
</template>

<script>
export default {
    data(){
        return{
            defaultColor:'tertiary', 
            selectedColor:'primary',
            dateFromMenu:false,
            dateToMenu:false,
            datePickupMenu:false,
        }
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      lookups(){
        return this.$store.getters.getLookups;
      },
      carrierIntegrations(){
        return _.filter(this.lookups.integrations,{'type':2})
      },
      options:{
        get () {  
          return this.$store.getters.getIntegrationShipmentOptions;
        },
        set (value) {
          this.search='';
          this.$store.dispatch( 'setIntegrationShipmentOptions', {options:value} )
        }
      },
      integrationId: {
        get () {
          return this.$store.getters.getIntegrationShipmentOptions.integrationId;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.integrationId = value;
          this.options = currentOptions;
        }
      },
      statusId: {
        get () {
          return this.$store.getters.getIntegrationShipmentOptions.statusId;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.statusId = value;
          this.options = currentOptions;
        }
      },
      fromDate: {
        get () {
          return this.$store.getters.getIntegrationShipmentOptions.fromDate;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.fromDate = value;
          this.options = currentOptions;
        }
      },
      toDate: {
        get () {
          return this.$store.getters.getIntegrationShipmentOptions.toDate;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.toDate = value;
          this.options = currentOptions;
        }
      },
       pickupDate: {
        get () {
          return this.$store.getters.getIntegrationShipmentOptions.pickupDate;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.pickupDate = value;
          this.options = currentOptions;
        }
      },
      showActive: {
        get () {
          return this.$store.getters.getIntegrationShipmentOptions.showActive;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.showActive = value;
          this.options = currentOptions;
        }
      },
    }
}
</script>

<style scoped>
.v-btn{
  margin-bottom: 1em;
}
.v-chip{
  margin-left: 1em;
}
.v-chip:hover{
    cursor: pointer;
}

.v-btn:not(.v-btn--text):not(.v-btn--outlined).v-btn--active:before {
    opacity: 0;
}

</style>
import { FLOREX_CONFIG } from '../config'
import axios from 'axios'

export default {


    /*
        GET     /api/shared/lookups
    */
    getLookups: function(){
            return axios.get( FLOREX_CONFIG.API_URL + '/shared/lookups' );
    },

    /*
        GET     /api/shared/lookups/group
    */
   getGroupLookup: function(){
        return axios.get( FLOREX_CONFIG.API_URL + '/shared/lookups/group' );
    },

    /*
        GET     /api/shared/lookups/department
    */
   getDepartmentLookup: function(){
        return axios.get( FLOREX_CONFIG.API_URL + '/shared/lookups/department' );
    },

/*
        GET     /api/shared/lookups/user
    */
   getUserLookup: function(){
        return axios.get( FLOREX_CONFIG.API_URL + '/shared/lookups/user' );
    },

   

    // /*
	// 	GET 	/api/schemas/{slug}/edit
	// */
    // getSchemaEdit: function( slug ){
	// 	return axios.get( FLOREX_CONFIG.API_URL + '/schemas/' + slug + '/edit' );
	// },
    
}
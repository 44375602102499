var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',[_c('v-card',{staticClass:"elevation-1",attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-btn',{attrs:{"to":{ name: 'ustawienia.firma' },"small":"","color":"primary"}},[_c('v-icon',[_vm._v("mdi-arrow-left")]),_vm._v(" Powrót do listy ")],1),_c('v-spacer'),_c('v-toolbar-title',[_c('strong',[_vm._v(_vm._s(_vm.department.name))])])],1),_c('v-row',{staticClass:"pl-5 pr-5"},[_c('v-col',{staticClass:"col-5"},[_c('v-card',{staticClass:"elevation-1",staticStyle:{"background-color":"#f4f4f4"},attrs:{"flat":""}},[_c('v-toolbar',{staticStyle:{"background-color":"#f4f4f4"},attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Dane adresowe")]),_c('v-spacer'),(_vm.user.permissions['setting'] >= 10)?_c('v-dialog',{attrs:{"max-width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","color":"primary"},on:{"click":_vm.editDepartment}},on),[_vm._v("Edytuj")])]}}],null,false,1833682840),model:{value:(_vm.dialogDepartment),callback:function ($$v) {_vm.dialogDepartment=$$v},expression:"dialogDepartment"}},[_c('firma-editor',{attrs:{"item-editing":_vm.itemEditing,"item-was-main":_vm.itemWasMain},on:{"close":_vm.closeDepartment}})],1):_vm._e()],1),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"col-3 pl-5"},[_c('strong',[_vm._v("Nazwa")])]),_c('v-col',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.department.name))])],1),_c('v-row',[_c('v-col',{staticClass:"col-3 pl-5"},[_c('strong',[_vm._v("Miasto")])]),_c('v-col',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.department.city))])],1),_c('v-row',[_c('v-col',{staticClass:"col-3 pl-5"},[_c('strong',[_vm._v("Kod pocztowy")])]),_c('v-col',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.department.post))])],1),_c('v-row',[_c('v-col',{staticClass:"col-3 pl-5"},[_c('strong',[_vm._v("Adres")])]),_c('v-col',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.department.street))])],1),_c('v-row',[_c('v-col',{staticClass:"col-3 pl-5"},[_c('strong',[_vm._v("Telefon")])]),_c('v-col',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.department.phone))])],1),_c('v-row',[_c('v-col',{staticClass:"col-3 pl-5"},[_c('strong',[_vm._v("E-mail")])]),_c('v-col',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.department.email))])],1)],1)],1)],1),_c('v-col',{staticClass:"col-4"},[_c('v-card',{staticClass:"elevation-1",staticStyle:{"background-color":"#f4f4f4"},attrs:{"flat":""}},[_c('v-toolbar',{staticStyle:{"background-color":"#f4f4f4"},attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Dane płatnika")]),_c('v-spacer'),(_vm.user.permissions['setting'] >= 10)?_c('v-dialog',{attrs:{"max-width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","color":"primary"},on:{"click":_vm.editAccount}},on),[_vm._v("Edytuj")])]}}],null,false,2008057597),model:{value:(_vm.dialogAccount),callback:function ($$v) {_vm.dialogAccount=$$v},expression:"dialogAccount"}},[_c('account-editor',{attrs:{"item-editing":_vm.itemEditing},on:{"close":_vm.closeAccount}})],1):_vm._e()],1),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"col-3 pl-5"},[_c('strong',[_vm._v("NIP")])]),_c('v-col',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.department.account.nip))])],1),_c('v-row',[_c('v-col',{staticClass:"col-3 pl-5"},[_c('strong',[_vm._v("REGON")])]),_c('v-col',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.department.account.regon))])],1),_c('v-row',[_c('v-col',{staticClass:"col-3 pl-5"},[_c('strong',[_vm._v("KRS")])]),_c('v-col',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.department.account.krs))])],1)],1)],1)],1),_c('v-col',{staticClass:"col-3"},[_c('v-card',{staticClass:"elevation-1",staticStyle:{"background-color":"#f4f4f4"},attrs:{"flat":""}},[_c('v-toolbar',{staticStyle:{"background-color":"#f4f4f4"},attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Logo")]),_c('v-spacer'),(_vm.user.permissions['setting'] >= 10)?_c('v-dialog',{attrs:{"max-width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","color":"primary"},on:{"click":_vm.editAccount}},on),[_vm._v("Edytuj")])]}}],null,false,2008057597),model:{value:(_vm.dialogLogo),callback:function ($$v) {_vm.dialogLogo=$$v},expression:"dialogLogo"}},[_c('account-logo-editor',{attrs:{"item-editing":_vm.itemEditing},on:{"close":_vm.closeLogo}})],1):_vm._e()],1),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"col-12 d-flex flex-column align-center"},[_c('v-img',{staticClass:"ml-2 mr-2",attrs:{"src":_vm.department.account.logo,"width":"200","height":"200"}})],1)],1)],1)],1)],1)],1),_c('v-row',{staticClass:"pl-5 pr-5"},[_c('v-col',{staticClass:"col-12"},[_c('v-data-table',{key:_vm.componentKey,staticClass:"elevation-1",staticStyle:{"background-color":"#f4f4f4"},attrs:{"headers":_vm.computedHeaders,"items":_vm.tableItems,"loading":_vm.bankAccountsLoadStatus===1,"server-items-length":_vm.options.lineCount,"options":_vm.tableOptions,"hide-default-footer":"","must-sort":""},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticStyle:{"background-color":"#f4f4f4"},attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Rachunki bankowe")]),_c('v-spacer'),_c('v-checkbox',{staticClass:"mt-4",attrs:{"label":"Pokaż wyłączone"},model:{value:(_vm.showDeleted),callback:function ($$v) {_vm.showDeleted=$$v},expression:"showDeleted"}}),(_vm.user.permissions['setting'] >= 10)?_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}):_vm._e(),(_vm.user.permissions['setting'] >= 10)?_c('v-dialog',{attrs:{"max-width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2 mt-2",attrs:{"small":"","fab":"","dark":"","color":"primary"}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)]}}],null,false,506460785),model:{value:(_vm.dialogBankAccount),callback:function ($$v) {_vm.dialogBankAccount=$$v},expression:"dialogBankAccount"}},[_c('bank-account-editor',{attrs:{"item-editing":_vm.itemEditing,"item-was-cod":_vm.itemWasCod,"item-was-document":_vm.itemWasDocument},on:{"close":_vm.closeBankAccount}})],1):_vm._e()],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.status && _vm.user.permissions['setting'] >= 10)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"size":"18"},on:{"click":function($event){return _vm.editItem(item)}}},on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Edytuj")])]):_vm._e(),(item.status && item.can_delete && _vm.user.permissions['setting'] >= 10)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"18"},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Wyłącz")])]):_vm._e(),(!item.status  && _vm.user.permissions['setting'] >= 20)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"size":"18"},on:{"click":function($event){return _vm.reactivateItem(item)}}},on),[_vm._v(" mdi-delete-restore ")])]}}],null,true)},[_c('span',[_vm._v("Przywróć")])]):_vm._e(),(!item.status && item.can_delete  && _vm.user.permissions['setting'] >= 20)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"18","color":"error"},on:{"click":function($event){return _vm.forceDeleteItem(item)}}},on),[_vm._v(" mdi-delete-forever ")])]}}],null,true)},[_c('span',[_vm._v("Usuń na stałe")])]):_vm._e()]}},{key:"item.cod",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.cod),callback:function ($$v) {_vm.$set(item, "cod", $$v)},expression:"item.cod"}})]}},{key:"item.document",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.document),callback:function ($$v) {_vm.$set(item, "document", $$v)},expression:"item.document"}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}}])})],1)],1)],1),_c('confirm',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
 <v-row no-gutters>
      <v-col>
        <v-snackbar
            v-model="integrationSnackbar"
            timeout="2000"
            top
          >
            Wystąpił problem z pobraniem listy produktów
          </v-snackbar>

          <v-sheet flat>
          
          <v-card flat>
              <v-data-table
                  :key="componentKey"
                  hide-default-footer
                  :headers="computedHeaders"
                  v-model="tableItemsSelected"
                  :items="tableItems"
                  class="elevation-1"
                  :options.sync="tableOptions"
                  :server-items-length="options.lineCount"
                  :loading="integrationProductsLoadStatus===1"
                  must-sort
                  dense
                  :show-select="multiEdit"
              >
                <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-toolbar-title>Produkty</v-toolbar-title>
                    <v-divider
                      class="mx-4"
                      inset
                      vertical
                    ></v-divider>
                    

                    <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-on="on"
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Wyszukaj"
                          single-line
                          hide-details
                          class="search"
                          @keydown.enter="searchIntegrationProducts"
                      ></v-text-field>
                      </template>
                        <span>Wpisz min 3 znaki i naciśnij Enter</span>
                    </v-tooltip>

                    <v-spacer></v-spacer>
                    
                    <v-switch class="mt-4" v-model="multiEdit" label="Edycja zbiorcza" @change="multiEditChange"></v-switch>
                    <v-divider
                      class="mx-4"
                      inset
                      vertical
                    ></v-divider>
                    <v-select dense class="mt-4 categories" clearable v-model="categoryId" :items="productsCategories" label="Wybierz kategorie">
                      <template v-slot:selection="{ item }">
                        {{_.last(_.split(item.text,'/'))}}
                      </template>
                    </v-select>
                    <v-divider
                      class="mx-4"
                      inset
                      vertical
                    ></v-divider>
                    <v-select class="search mt-4" v-model="integrationId" :items="marketplaceIntegrations" label="Konto integracji"></v-select>

                    <v-dialog v-model="dialogUpdate"  max-width="700px">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" small class="mb-2 mt-2 ml-5" fab dark color="primary">
                        <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <v-card :loading="integrationProductAddStatus===1">
                        <v-card-title>
                        <span class="headline">Wybór produktów/ofert</span>
                        <v-spacer></v-spacer>
                        <v-chip
                          v-if="options.updateCount > -1"
                          label
                          color="primary"
                        >{{options.updateCount}}</v-chip>
                        </v-card-title>

                        <v-card-text>
                        <v-container>
                             <v-radio-group
                                v-model="updateVariant"
                                mandatory
                                @change="updateRadioChange"
                              >
                                <v-radio
                                  value="all"
                                ><template v-slot:label><div :class="updateVariant=='all'?'font-weight-bold':''">Wszystkie
                                  </div></template></v-radio>
                                <v-radio
                                  value="filter"
                                ><template v-slot:label ><div :class="updateVariant=='filter'?'font-weight-bold':''">Wybrane</div></template></v-radio>

                                <v-container class="pt-0 pl-10 pr-10">
                                    <v-container class="d-flex pa-0">
                                        
                                        <v-text-field 
                                          readonly
                                          dense 
                                          :disabled="updateVariant!='filter'"  
                                          v-model="updateCategory.name" 
                                          label="Wybrana kategoria"></v-text-field>
                                        <v-menu offset-y>
                                        <template v-slot:activator="{ on }">
                                          <v-btn
                                            :disabled="updateVariant!='filter'"
                                            small color="primary" class="ml-3"
                                            v-on="on"
                                          >
                                            Zmień
                                          </v-btn>
                                        </template>
                                          <v-treeview
                                            :active.sync="updateCategories"
                                            :load-children="fetchCategories"
                                            class="white"
                                            :items="topCategories"
                                            dense
                                            activatable
                                            return-object
                                            
                                          ></v-treeview>
                                      </v-menu> 
                                    </v-container>
                                    <v-text-field clearable dense :disabled="updateVariant!='filter'"  v-model="updateMinPrice" label="Cena minimalna"></v-text-field> 
                                    <v-text-field clearable dense :disabled="updateVariant!='filter'"  v-model="updateMaxPrice" label="Cena maksymalna"></v-text-field> 
                                    <v-select v-if="isAllegro" clearable dense :disabled="updateVariant!='filter'"  v-model="updateDelivery" :items="options.deliveryMethods" label="Dostępna metoda dostawy"></v-select> 
                                    <v-text-field clearable dense :disabled="updateVariant!='filter'"  v-model="updateOneId"  label="Numer oferty"></v-text-field> 
                                </v-container>
                          
                              </v-radio-group>
                              

                            <v-divider></v-divider>   
                            <v-checkbox class="mt-5" dense v-model="updateDelete" label="Usuń pozostałe produkty z bazy SPEDIX"></v-checkbox>       
                            <v-checkbox v-if="isAllegro" class="mt-0" dense v-model="updateGetWeight" label="Pobierz wagę z pola 'Waga (z opakowaniem)'"></v-checkbox>
                            <v-checkbox v-if="isPresta" class="mt-0" dense v-model="updateGetWeight" label="Pobierz wymiary i wagę jeżeli są dostępne"></v-checkbox>
                            <v-checkbox class="mt-0" dense v-model="updateDeleteAll" label="Wyczyść bazę produktów"></v-checkbox>         
                        </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="grey" text @click="dialogUpdate = false">Anuluj</v-btn>
                          <v-btn color="primary darken-1" :disabled="options.updateCount<=0" text @click="updateProducts" >Dodaj</v-btn>
                        </v-card-actions>
                    </v-card>
                    </v-dialog>

                    <v-dialog v-model="dialog" max-width="700px">
                    <v-card :loading="integrationProductLoadStatus===1 || integrationProductEditStatus===1 || integrationProductEditLoadStatus===1">
                        <v-card-title>
                          <span class="headline">{{multiEdit?'Edycja wielu produktów':'Edycja produktu'}}</span>
                        </v-card-title>
                        <v-card-subtitle class="mt-1 pl-0 ml-6 pb-0">{{editedItem.category_path}}</v-card-subtitle>
                        <v-card-subtitle class="mt-1 pl-0 ml-6 pb-0">{{editedItem.external_id}}</v-card-subtitle>
                        <v-card-subtitle v-if="multiEdit" class="mt-1 pl-0 ml-6 pb-0">Zaznacz właściwości, które chcesz nadpisać</v-card-subtitle>
                        <v-card-text>
                        <v-container>
                            <v-row>     
                              <v-col cols="12" sm="4" md="4" class="d-flex">
                                  <v-checkbox v-if="multiEdit" v-model="multiEditOptions.height"></v-checkbox><v-text-field v-model="editedItem.height" :error-messages="heightErrors"  @input="$v.editedItem.height.$touch()"  @blur="$v.editedItem.height.$touch()"  label="Wysokość (cm)"></v-text-field>                            
                              </v-col>
                              <v-col cols="12" sm="4" md="4" class="d-flex">
                                  <v-checkbox v-if="multiEdit" v-model="multiEditOptions.length"></v-checkbox><v-text-field v-model="editedItem.length" :error-messages="lengthErrors"  @input="$v.editedItem.length.$touch()"  @blur="$v.editedItem.length.$touch()"  label="Długość (cm)"></v-text-field>                            
                              </v-col>
                              <v-col cols="12" sm="4" md="4" class="d-flex">
                                  <v-checkbox v-if="multiEdit" v-model="multiEditOptions.width"></v-checkbox><v-text-field v-model="editedItem.width" :error-messages="widthErrors"  @input="$v.editedItem.width.$touch()"  @blur="$v.editedItem.width.$touch()"  label="Szerokość (cm)"></v-text-field>                            
                              </v-col>
                              <v-col cols="12" sm="4" md="4" class="d-flex">
                                  <v-checkbox v-if="multiEdit" v-model="multiEditOptions.weight"></v-checkbox><v-text-field v-model="editedItem.weight" :error-messages="weightErrors"  @input="$v.editedItem.weight.$touch()"  @blur="$v.editedItem.weight.$touch()"  label="Waga (kg)"></v-text-field>                            
                              </v-col>
                            </v-row>
                            <v-row style="background-color: #f4f4f4;"> 
                              <v-col cols="12" sm="12" md="12" class="d-flex align-baseline">
                                <v-checkbox class="mt-0" hide-details v-if="multiEdit" v-model="multiEditOptions.muc"></v-checkbox><h3>Nośniki</h3>
                              </v-col>
                              <v-col cols="12" sm="6" md="6" >
                                  <v-select class="mt-2" @change="mucChange(c.value,editedItem.muc[c.value])" v-model="editedItem.muc[c.value]" dense v-for="c in carrierIntegrations" :items="editedItem.muc_list[c.value]" :key="c.value" :label="c.text" ></v-select>
                              </v-col>
                               
                            </v-row> 
                               
                        </v-container>
                        </v-card-text>

                           
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="grey" text @click="close">Zamknij</v-btn>
                          <v-btn color="primary darken-1" text @click="handleSubmit">Zapisz</v-btn>
                        </v-card-actions>
                    </v-card>
                    </v-dialog>
                </v-toolbar>
                </template>
                <template v-slot:item.name="{ item }">
                  <a :href="'https://allegro.pl/oferta/'+item.reference" target="_blank" class="offerLink">{{item.name}}</a> 
                </template>
                <template v-slot:item.category="{ item }">
                  {{_.last(_.split(item.category_path,'/'))}}
                </template>
                <template v-slot:item.height="{ item }">
                  {{item.height}} {{item.height?'cm':''}}      
                </template>
                <template v-slot:item.length="{ item }">
                  {{item.length}} {{item.length?'cm':''}}      
                </template>
                <template v-slot:item.width="{ item }">
                  {{item.width}} {{item.width?'cm':''}}      
                </template>
                <template v-slot:item.weight="{ item }">
                  {{item.weight?$n(item.weight, 'number', 'pl-PL'):'' }} {{item.weight?'kg':''}}      
                </template>
                <template v-slot:item.details="{ item }">
                  <v-btn v-if="!multiEdit"  @click="editItem(item)" color="primary" x-small>Edytuj</v-btn>       
                </template> 
            </v-data-table> 
            <div class="text-center pt-2">
            <v-btn @click="loadMore" v-if="!options.itemAdded && options.lineCount-tableItems.length>0 && integrationProductsLoadStatus===2" small color="primary"><v-icon left>mdi-download</v-icon>Załaduj więcej ({{ options.lineCount-tableItems.length }})</v-btn>
            <v-btn @click="loadIntegrationProductsDefault" v-else-if="options.itemAdded && options.lineCount-tableItems.length>0" small color="primary"><v-icon left>mdi-reload</v-icon>Odśwież tabele aby móc załadować więcej ({{ options.lineCount-tableItems.length }})</v-btn>
            <v-btn @click="loadIntegrationProductsDefault" v-else-if="options.itemSearched" small color="primary"><v-icon left>mdi-reload</v-icon>Anuluj wyszukiwanie</v-btn>
            <v-btn class="ml-5" v-if="multiEdit && tableItemsSelected.length>0"  @click="editItems()" color="primary" small>Edytuj wybrane</v-btn>
          </div>
            <confirm ref="confirm"></confirm>
          </v-card>
          </v-sheet>
          </v-col>
      <v-col md="auto">
        <menuUstawienia />
      </v-col>
    </v-row>
</template>

<script>
  import confirm from '../components/Confirm'
  import menuUstawienia from '../components/MenuUstawienia'
  import required from 'vuelidate/lib/validators/required'
  import numeric from 'vuelidate/lib/validators/numeric'
  import { helpers } from 'vuelidate/lib/validators'
  const decimalPL = helpers.regex('decimalPL', /^[-]?\d*(\,\d+)?$/)

  import IntegrationProductAPI from "../api/integrationProduct";

  export default {
    components:{
      confirm,
      menuUstawienia
    },
    data: () => ({
      topCategories:[],
      componentKey:0,
      tableOptions: {},
      search:'',
      dialog: false,
      dialogUpdate: false,
      updateVariant:'all',
      updateCategories:[],
      updateOneId:undefined,
      updateMinPrice:undefined,
      updateMaxPrice:undefined,
      updateDelivery:undefined,
      updateDelete:false,
      updateGetWeight:false,
      updateDeleteAll:false,
      itemEditing:false,
      integrationSnackbar:false,
      tableItemsSelected:[],
      multiEdit:false,
      multiEditOptions:{
        height:false,
        length:false,
        width:false,
        weight:false,
        muc:false
      },
      headers: [
        { text: 'Lp',align: 'start',sortable: false,value: 'lp',filterable:false,visible:true},
        { text: 'Nazwa', value: 'name',sortable: true,filterable:true,filterColumn:"name",visible:true },
        { text: 'Referencja', value: 'external_id',sortable: true,filterable:true,filterColumn:"external_id",visible:true },
        { text: 'Kategoria', value: 'category',sortable: true,filterable:false,visible:true },
        { text: 'Kategorie', value: 'category_path',sortable: false,filterable:true,filterColumn:"category_path",visible:true },
        // { text: 'Wys./Szer./Dł.', value: 'size',sortable: true,filterable:false,visible:true },
        { text: 'Wys.', value: 'height',sortable: true,filterable:true,filterColumn:"height",visible:false },
        { text: 'Dł.', value: 'length',sortable:true,filterable:true,filterColumn:"length",visible:false },
        { text: 'Szer.', value: 'width',sortable:true,filterable:true,filterColumn:"width",visible:false  },
        { text: 'Waga', value: 'weight',sortable:true,filterable:true,filterColumn:"weight",visible:true  },
        { text: '', value: 'details', sortable: false,filterable:false},
      ],
    }),
    validations:{
        editedItem:{
            height:{
              numeric
            },
            length:{
              numeric
            },
            width:{
              numeric
            },
            weight:{
              decimalPL
            },
        }
    },
    watch: {
      tableOptions: {
        handler () {
          var currentOptions = {...this.options};
          const { sortBy, sortDesc } = this.tableOptions
        
          //zmiana sortowania
          if (sortBy.length === 1 && sortDesc.length === 1) {
            currentOptions.orderBy = sortBy[0];
            currentOptions.orderDirection = sortDesc[0]?'desc':'asc';
            this.options = currentOptions;
          } 
        },
        deep: true,
      },
      dialog(val) {
        this.$v.$reset()
        if(val){
        }else{
          this.close()
        }
      },
      dialogUpdate(val) {
        if(val){
           this.$store.dispatch( 'updateIntegrationProducts', {
              slug:  this.integrationId, 
              options: {}, 
              count:true
            });
        }else{
          this.closeUpdate();
        }
      },
      integrationProductEditStatus(val){
        if(val===2){
          this.componentKey++;
          this.close();
        }
      },
      integrationProductAddStatus(val){
        if(val===3){
          this.integrationSnackbar = true;
          this.dialogUpdate = false;
        }else if(val===2){
          this.dialogUpdate = false;
        }
      },
      updateOneId(val){
       if(this.dialogUpdate){
          this.updateProductsWithFilters();
        }
      },
      updateCategory(val){
        if(this.dialogUpdate){
          this.updateProductsWithFilters();
        }
      },
      updateMinPrice(val){
        if(this.dialogUpdate){
          this.updateProductsWithFilters();
        }
      },
      updateDelivery(val){
        if(this.dialogUpdate){
          this.updateProductsWithFilters();
        }
      },
      updateMaxPrice(val){
        if(this.dialogUpdate){
          this.updateProductsWithFilters();
        }
      }
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      lookups(){
        return this.$store.getters.getLookups;
      },
      editedItem(){
        return this.$store.getters.getIntegrationProductEdit;
      },
      options:{
        get () {  
          return this.$store.getters.getIntegrationProductOptions;
        },
        set (value) {
          this.search='';
          this.$store.dispatch( 'setIntegrationProductOptions', {options:value} )
        }
      },
      integrationId: {
        get () {
          return this.$store.getters.getIntegrationProductOptions.integrationId;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.integrationId = value;
          this.options = currentOptions;

          //load top cat
          setTimeout(() => {
              var that = this;
              IntegrationProductAPI.getCategoriesOfIntegrationProducts(that.integrationId,null)
                  .then(res => res.data)
                  .then(json => (that.topCategories = [...json]))
                  .catch(err => console.warn(err))
            }, 1000)
        }
      },
       categoryId: {
        get () {
          return this.$store.getters.getIntegrationProductOptions.categoryId;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.categoryId = value;
          this.options = currentOptions;
        }
      },
      computedHeaders(){
        return _.filter(this.headers,function(o) { return o.value!='category_path'; });
      },
      marketplaceIntegrations(){
        return _.filter(this.lookups.integrations,{'type':1})
      },
      carrierIntegrations(){
        return _.filter(this.lookups.integrations,{'type':2})
      },
      isAllegro(){
        return _.find(this.lookups.integrations,{'value':this.integrationId}).type_id===1;
      },
      isPresta(){
        return _.find(this.lookups.integrations,{'value':this.integrationId}).type_id===4;
      },
      tableItems(){
        return this.$store.getters.getIntegrationProducts;
      },
      productsCategories(){
        return this.$store.getters.getIntegrationProductsCategories;
      },
      integrationProductLoadStatus(){
        return this.$store.getters.getIntegrationProductLoadStatus;
      },
      integrationProductsLoadStatus(){
        return this.$store.getters.getIntegrationProductsLoadStatus;
      },
      integrationProductEditLoadStatus(){
        return this.$store.getters.getIntegrationProductEditLoadStatus
      },
      integrationProductEditStatus(){
        return this.$store.getters.getIntegrationProductEditStatus();
      },
      integrationProductAddStatus(){
        return this.$store.getters.getIntegrationProductAddStatus();
      },
      integrationProductEditText(){
        return this.$store.getters.getIntegrationProductEditText
      },
      heightErrors () {
        const errors = []
        if (!this.$v.editedItem.height.$dirty) return errors
        !this.$v.editedItem.height.numeric && errors.push('Wysokość musi być liczbą')
        return errors
      },
      lengthErrors () {
        const errors = []
        if (!this.$v.editedItem.length.$dirty) return errors
        !this.$v.editedItem.length.numeric && errors.push('Długość musi być liczbą')
        return errors
      },
      widthErrors () {
        const errors = []
        if (!this.$v.editedItem.width.$dirty) return errors
        !this.$v.editedItem.width.numeric && errors.push('Szerokość musi być liczbą')
        return errors
      },
      weightErrors () {
        const errors = []
        if (!this.$v.editedItem.weight.$dirty) return errors
        !this.$v.editedItem.weight.decimalPL && errors.push('Waga musi być liczbą')
        return errors
      },
      updateCategory() {
        if (!this.updateCategories.length) return {"name":""}
        return this.updateCategories[0];
      },
    },
    mounted(){
      if(this.lookups.integrations.length>0){
        this.loadIntegrationProducts();
      }else{
        this.$store.dispatch( 'loadLookups' );
        setTimeout(() => {
          this.loadIntegrationProducts();
        }, 1000)
      }
      
      //load top cat
      setTimeout(() => {
          var that = this;
          IntegrationProductAPI.getCategoriesOfIntegrationProducts(that.integrationId,null)
              .then(res => res.data)
              .then(json => (that.topCategories = [...json]))
              .catch(err => console.warn(err))
        }, 1000)
      
    },
    methods: {
      mucChange(id,value){
        var defaults = _.find(this.editedItem.muc_list[id],{'value':value}).defaults;
        if(defaults){
          this.editedItem.length = defaults.length;
          this.editedItem.width = defaults.width;
        }
      },
      multiEditChange(){
        if(!this.multiEdit){
          this.tableItemsSelected = [];
        }
      },
      fetchCategories(item){
        return IntegrationProductAPI.getCategoriesOfIntegrationProducts(this.integrationId,item.id)
          .then(res => res.data)
          .then(json => (item.children.push(...json)))
          .catch(err => console.warn(err))
      },
      updateRadioChange(){
        if(this.updateVariant=='all'){
            this.$store.dispatch( 'updateIntegrationProducts', {
              slug:  this.integrationId, 
              options: {}, 
              count:true
            });
        }else if(this.updateVariant=='filter'){
          this.updateProductsWithFilters();
        }
      },
      updateProductsWithFilters(){
        this.$store.dispatch( 'updateIntegrationProducts', {
              slug:  this.integrationId, 
              options: {
                category:this.updateCategory.id,
                oneId:this.updateOneId,
                minPrice:this.updateMinPrice,
                maxPrice:this.updateMaxPrice,
                delivery:this.updateDelivery
              }, 
              count:true
        });
      },
      loadIntegrationProducts(){
        this.search='';
        if(this.marketplaceIntegrations.length>0){
          this.integrationId = this.marketplaceIntegrations[0].value;
        }
        //this.$store.dispatch( 'loadIntegrationProducts' );
      },
      loadMore(){
        this.$store.dispatch( 'loadMoreIntegrationProducts' );
      },
      loadIntegrationProductsDefault(){
        this.search=''
        this.$store.dispatch( 'loadIntegrationProducts' );
      },
      editItem(item) {
        this.itemEditing = true
        this.$store.dispatch( 'loadIntegrationProductEdit', {slug:  item.id}); 
        this.dialog = true
      },
      editItems(){
        this.itemEditing = true
        this.$store.dispatch( 'loadIntegrationProductEditMucList'); 
        this.dialog = true
      },
      searchIntegrationProducts(){
        if(this.search.length>2){
          const filterableHeaders = _.join(_.map(_.filter(this.headers,{'filterable':true}),'filterColumn'),';');
          this.$store.dispatch( 'searchIntegrationProducts',{filterableHeaders:filterableHeaders,q:this.search});
        }
      },

      updateProducts(){
        if(this.updateVariant=='all'){
            this.$store.dispatch( 'updateIntegrationProducts', {
              slug:  this.integrationId, 
              options: {
                delete:this.updateDelete,
                getWeight:this.updateGetWeight,
                deleteAll:this.updateDeleteAll
              }, 
              count:false
            });
        }else if(this.updateVariant=='filter'){
          this.$store.dispatch( 'updateIntegrationProducts', {
              slug:  this.integrationId, 
              options: {
                category:this.updateCategory.id,
                oneId:this.updateOneId,
                minPrice:this.updateMinPrice,
                maxPrice:this.updateMaxPrice,
                delivery:this.updateDelivery,
                delete:this.updateDelete,
                getWeight:this.updateGetWeight,
                deleteAll:this.updateDeleteAll
              },
              count:false
            });
        }
          
      },
      deleteItem (item) {
        this.$refs.confirm.open('Usuń', 'Czy na pewno chcesz usunać produkt?', { color: 'error' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'forceDeleteIntegrationProduct', {slug:  item.id});
            }
        })
      },
      close () {
        this.dialog = false
        this.$v.$reset()
        setTimeout(() => {
          this.$store.dispatch( 'loadIntegrationProductEditDefault'); 
          this.$store.dispatch( 'loadIntegrationProductAddDefault');
          this.itemEditing = false
          this.multiEditOptions = {
            height:false,
            length:false,
            width:false,
            weight:false,
            muc:false
          }
        }, 300)
      },
      closeUpdate () {
          this.$store.commit( 'setIntegrationProductUpdateCount', undefined); 
          this.$store.dispatch( 'loadIntegrationProducts');
          this.updateVariant = 'all';
          this.multiEdit = false;
          this.updateCategories = [];
          this.updateDelete = false;
          this.updateGetWeight = false;
          this.updateDeleteAll = false;
          this.updateDelivery = null;
          this.updateOneId = null;
          this.updateMinPrice = null;
          this.updateMaxPrice = null;
      },
      prepareMuc(){
        var muc = {}
        this.editedItem.muc.forEach((value, index, self) => {
          if(value)muc[index] = value;
        });
        return muc;
      },
      handleSubmit () {
        this.$v.$touch()
        if (this.$v.$invalid) {

        } else {
            if(this.multiEdit){
               
               this.$store.dispatch( 'editIntegrationProducts', {
                slug:  _.map(this.tableItemsSelected, 'id'),
                height:  this.multiEditOptions.height?this.editedItem.height:undefined,
                length:  this.multiEditOptions.length?this.editedItem.length:undefined,
                width:  this.multiEditOptions.width?this.editedItem.width:undefined,
                weight:  this.multiEditOptions.weight?(this.editedItem.weight?(this.editedItem.weight.toString().replace(/\./g, "").replace(/,/, ".")):null):undefined,
                muc:  this.multiEditOptions.muc?this.prepareMuc():undefined,
                adr:  this.editedItem.adr
              });
            }else{
              this.$store.dispatch( 'editIntegrationProduct', {
                slug:  this.editedItem.id,
                height:  this.editedItem.height,
                length:  this.editedItem.length,
                width:  this.editedItem.width,
                weight:  this.editedItem.weight?this.editedItem.weight.toString().replace(/\./g, "").replace(/,/, "."):null,
                muc:  this.editedItem.muc,
                adr:  this.editedItem.adr
              });
            }
              
        }
       
      },
    },
  }
</script>
<style scoped>
.search{
      max-width: 250px;
}

.categories{
      max-width: 350px;
}
a.offerLink{
  text-decoration: none;
  color: inherit;
}
a.offerLink:hover{
  text-decoration: underline;
}
</style>

import IntegrationDocumentAPI from "../api/integrationDocument";
import i18n from "../main";

export const integrationDocuments = {
    state: {
        integrationDocuments: [],
        integrationDocumentOptions:{
            lineCount:0,
            orderBy:undefined,
            orderDirection:undefined,
            itemsPerPage:20,
            itemAdded:false,
            accountId:undefined,
            statusId:undefined,
            showActive:true,
            fromDate:undefined,
            toDate:undefined,
            typeId:undefined
        },


        integrationDocumentsLoadStatus: 0,
        integrationDocumentAddStatus: 0,
        integrationDocumentAddText: "",

        integrationDocumentEdit: {
            id: 0,
            lp: 0,
            type:1,
            document:{},
            buyer:undefined,
            amount:undefined,
            amount_net:undefined,
            amount_payed:undefined,
            amount_currency:'PLN',
            status:1,
            logs:[],
            order:null,
            order_status:1
        },
        integrationDocumentEditDefault: {
            id: 0,
            lp: 0,
            type:1,
            document:{},
            buyer:undefined,
            amount:undefined,
            amount_net:undefined,
            amount_payed:undefined,
            amount_currency:'PLN',
            status:1,
            logs:[],
            order:null,
            order_status:1
        },
        integrationDocumentEditLoadStatus: 0,
        integrationDocumentPdfLoadStatus: 0,
        integrationDocumentCsvLoadStatus: 0,
        integrationDocumentEditStatus: 0,
        integrationDocumentEditText: "",

        integrationDocumentDeleteStatus: 0,
        integrationDocumentDeleteText: "",

    },

    actions: {
        loadIntegrationDocuments({ state, commit, dispatch,rootState }) {
            commit("setIntegrationDocumentsLoadStatus", 1);
                IntegrationDocumentAPI.getIntegrationDocuments({
                    offset:0,
                    itemsPerPage:state.integrationDocumentOptions.itemsPerPage, 
                    orderBy:state.integrationDocumentOptions.orderBy?state.integrationDocumentOptions.orderBy:rootState.lookups.docsSettings.DEFAULT_ORDER_BY,
                    orderDirection:state.integrationDocumentOptions.orderDirection?state.integrationDocumentOptions.orderDirection:rootState.lookups.docsSettings.DEFAULT_ORDER_DIRECTION,
                    accountId:state.integrationDocumentOptions.accountId,
                    statusId:state.integrationDocumentOptions.statusId,
                    showActive:state.integrationDocumentOptions.showActive,
                    fromDate:state.integrationDocumentOptions.fromDate,
                    toDate:state.integrationDocumentOptions.toDate,
                    typeId:state.integrationDocumentOptions.typeId
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setIntegrationDocumentLineCount", response.data.lineCount);
                    commit("setIntegrationDocuments", response.data.integrationDocuments);
                    dispatch("applyIndexOnIntegrationDocuments");
                    commit("setIntegrationDocumentsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setIntegrationDocuments", []);
                    commit("setIntegrationDocumentsLoadStatus", 3);
                });
         
        },
        loadMoreIntegrationDocuments({ state, commit, dispatch,rootState  }) {
            commit("setIntegrationDocumentsLoadStatus", 1);
                IntegrationDocumentAPI.getIntegrationDocuments({
                    offset:state.integrationDocuments.length,
                    itemsPerPage:state.integrationDocumentOptions.itemsPerPage, 
                    orderBy:state.integrationDocumentOptions.orderBy?state.integrationDocumentOptions.orderBy:rootState.lookups.docsSettings.DEFAULT_ORDER_BY,
                    orderDirection:state.integrationDocumentOptions.orderDirection?state.integrationDocumentOptions.orderDirection:rootState.lookups.docsSettings.DEFAULT_ORDER_DIRECTION,
                    accountId:state.integrationDocumentOptions.accountId,
                    statusId:state.integrationDocumentOptions.statusId,
                    showActive:state.integrationDocumentOptions.showActive,
                    fromDate:state.integrationDocumentOptions.fromDate,
                    toDate:state.integrationDocumentOptions.toDate,
                    typeId:state.integrationDocumentOptions.typeId
                })
                .then(function(response) {
                    commit("setIntegrationDocumentLineCount", response.data.lineCount);
                    commit("setIntegrationDocuments", _.concat(state.integrationDocuments, response.data.integrationDocuments));
                    dispatch("applyIndexOnIntegrationDocuments");
                    commit("setIntegrationDocumentsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setIntegrationDocuments", []);
                    commit("setIntegrationDocumentsLoadStatus", 3);
                });
         
        },
        loadAllIntegrationDocuments({ state, commit, dispatch,rootState  }) {
            commit("setIntegrationDocumentsLoadStatus", 1);
                IntegrationDocumentAPI.getIntegrationDocuments({
                    offset:state.integrationDocuments.length,
                    itemsPerPage:9999999, 
                    orderBy:state.integrationDocumentOptions.orderBy?state.integrationDocumentOptions.orderBy:rootState.lookups.docsSettings.DEFAULT_ORDER_BY,
                    orderDirection:state.integrationDocumentOptions.orderDirection?state.integrationDocumentOptions.orderDirection:rootState.lookups.docsSettings.DEFAULT_ORDER_DIRECTION,
                    accountId:state.integrationDocumentOptions.accountId,
                    statusId:state.integrationDocumentOptions.statusId,
                    showActive:state.integrationDocumentOptions.showActive,
                    fromDate:state.integrationDocumentOptions.fromDate,
                    toDate:state.integrationDocumentOptions.toDate,
                    typeId:state.integrationDocumentOptions.typeId
                })
                .then(function(response) {
                    commit("setIntegrationDocumentLineCount", response.data.lineCount);
                    commit("setIntegrationDocuments", _.concat(state.integrationDocuments, response.data.integrationDocuments));
                    dispatch("applyIndexOnIntegrationDocuments");
                    commit("setIntegrationDocumentsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setIntegrationDocuments", []);
                    commit("setIntegrationDocumentsLoadStatus", 3);
                });
         
        },
        searchIntegrationDocuments({ state, commit, dispatch },data) {
            commit("setIntegrationDocumentsLoadStatus", 1);
                IntegrationDocumentAPI.searchIntegrationDocuments({
                    integrationId:state.integrationDocumentOptions.integrationId,
                    filterableHeaders:data.filterableHeaders,
                    q:data.q
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setIntegrationDocumentSearchedTrue");
                    commit("setIntegrationDocumentLineCount", response.data.lineCount);
                    commit("setIntegrationDocuments", response.data.integrationDocuments);
                    dispatch("applyIndexOnIntegrationDocuments");
                    commit("setIntegrationDocumentsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setIntegrationDocuments", []);
                    commit("setIntegrationDocumentsLoadStatus", 3);
                });
         
        },
        setIntegrationDocumentOptions({ state, commit, dispatch },data){
            commit("setIntegrationDocumentOptions",data.options);
            dispatch("loadIntegrationDocuments");
        },
        applyIndexOnIntegrationDocuments({ commit, state, dispatch, rootState }) {
            let localIntegrationDocuments = state.integrationDocuments;

            var i = 1;
            localIntegrationDocuments.forEach(integrationDocument => {
                if(_.isNumber(integrationDocument.type)){
                    integrationDocument.type = _.find(rootState.lookups.integrationDocumentTypes, { 'value': integrationDocument.type }).text;
                }
                integrationDocument.lp = i;
                i++;
            });

            commit("setIntegrationDocuments",localIntegrationDocuments);
        },
        loadIntegrationDocumentEdit({ commit }, data) {
            commit("setIntegrationDocumentEditLoadStatus", 1);
            commit("setIntegrationDocumentEditStatus", 0);
            commit("setIntegrationDocumentEditText", "");

            IntegrationDocumentAPI.getIntegrationDocumentEdit(data.slug)
                .then(function(response) {
                    commit("setIntegrationDocumentEdit", response.data);
                    commit("setIntegrationDocumentEditLoadStatus", 2);
                })
                .catch(function() {
                    commit("setIntegrationDocumentEdit", {});
                    commit("setIntegrationDocumentEditLoadStatus", 3);
                });
        },
        loadIntegrationDocumentEditDefault({ commit }) {
            commit("setIntegrationDocumentEditDefault");
        },
        loadIntegrationDocumentAddDefault({ commit }) {
            commit("setIntegrationDocumentAddStatus", 0);
            commit("setIntegrationDocumentAddText", "");
            commit("setIntegrationDocumentEditStatus", 0);
            commit("setIntegrationDocumentEditText", "");
        },
       
        getIntegrationDocumentPdf({ commit }, data) {
            commit("setIntegrationDocumentPdfLoadStatus", 1);

            IntegrationDocumentAPI.getIntegrationDocumentPdf(data.slug)
                .then(function(response) {
                    commit("setIntegrationDocumentPdfLoadStatus", 2);
                    let pdfWindow = window.open("");
                    pdfWindow.document.write("<html<head><title>"+response.data.FileName+"</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>");
                    pdfWindow.document.write("<body><embed width='100%' height='100%' src='data:"+ response.data.ContentType+";base64, " + encodeURI(response.data.FileContent)+"#toolbar=0&navpanes=0&scrollbar=0'></embed></body></html>");
                    pdfWindow.document.close();
                })
                .catch(function() {
                    commit("setIntegrationDocumentPdfLoadStatus", 3);
                });
        },

        getIntegrationDocumentAdvanceCSV({ commit }, data) {
            commit("setIntegrationDocumentCsvLoadStatus", 1);

            IntegrationDocumentAPI.putIntegrationDocumentAdvanceCSV(data.slug)
                .then(function(response) {
                    commit("setIntegrationDocumentCsvLoadStatus", 2);
                    var fileLink = document.createElement('a');
                    fileLink.href = "data:application/pdf;base64," + response.data.FileContent;
                    fileLink.setAttribute('download', response.data.FileName);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch(function() {
                    commit("setIntegrationDocumentCsvLoadStatus", 3);
                });
        },

        getIntegrationDocumentStornCSV({ commit }, data) {
            commit("setIntegrationDocumentCsvLoadStatus", 1);

            IntegrationDocumentAPI.putIntegrationDocumentStornCSV(data.slug)
                .then(function(response) {
                    commit("setIntegrationDocumentCsvLoadStatus", 2);
                    var fileLink = document.createElement('a');
                    fileLink.href = "data:application/pdf;base64," + response.data.FileContent;
                    fileLink.setAttribute('download', response.data.FileName);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch(function() {
                    commit("setIntegrationDocumentCsvLoadStatus", 3);
                });
        },
       
        editIntegrationDocument({ commit, state, dispatch }, data) {
            commit("setIntegrationDocumentEditStatus", 1);

            IntegrationDocumentAPI.putEditIntegrationDocument(
                data.slug,
                data.document,
                data.buyer,
                data.amount,
                data.amount_net,
                data.amount_net_payed,
                data.amount_currency,
            )
                .then(function(response) {
                    commit("setIntegrationDocumentEditStatus", 2);
                    commit("setIntegrationDocumentEdit", response.data);
                    commit("setEditedIntegrationDocument",response.data)
                })
                .catch(function(error) {
                    commit("setIntegrationDocumentEditStatus", 3);
                    commit("setIntegrationDocumentEditText", error.response.data.errors);
                });
        },
        addIntegrationDocument({ commit, state, dispatch }, data) {
            commit("setIntegrationDocumentAddStatus", 1);

            IntegrationDocumentAPI.postNewIntegrationDocument(
                data.integration_order_id,
                data.type,
                data.integration_id,
                data.document,
                data.buyer,
                data.amount,
                data.amount_net,
                data.amount_net_payed,
                data.amount_currency,
            )
                .then(function(response) {
                    commit("setIntegrationDocumentAddStatus", 2);
                    //commit("setIntegrationDocumentEdit", response.data);
                    commit("setAddedIntegrationDocument",response.data)
                    dispatch("applyIndexOnIntegrationDocuments");
                    dispatch( 'loadIntegrationDocumentEdit', {slug:  response.data.id});
                })
                .catch(function(error) {
                    commit("setIntegrationDocumentAddStatus", 3);
                    commit("setIntegrationDocumentEditText", error.response.data.errors);
                });
        },
        forceDeleteIntegrationDocument({ commit, state, dispatch }, data) {
            commit("setIntegrationDocumentDeleteStatus", 1);

            IntegrationDocumentAPI.forceDeleteIntegrationDocument(data.slug)
                .then(function(response) {
                    commit("setIntegrationDocumentDeleteStatus", 2);
                    //commit("setIntegrationDocumentLineCount", state.integrationDocumentOptions.lineCount - 1);
                    //commit("setDeletedIntegrationDocument",response.data)
                    dispatch('loadIntegrationDocuments');
                    //dispatch("applyIndexOnIntegrationDocuments");
                })
                .catch(function() {
                    commit("setIntegrationDocumentDeleteStatus", 3);
                });
        },
    },
    mutations: {
        setIntegrationDocumentLabelLoadStatus(state, status) {
            state.integrationDocumentLabelLoadStatus = status;
        },
        setIntegrationDocumentLoadStatus(state, status) {
            state.integrationDocumentLoadStatus = status;
        },
        setIntegrationDocument(state, integrationDocument) {
            state.integrationDocument = integrationDocument;
        },
        //#############
        setIntegrationDocumentOptions(state,options){
            state.integrationDocumentOptions = options;
        },
        setIntegrationDocumentsLoadStatus(state, status) {
            state.integrationDocumentsLoadStatus = status;
        },
        setIntegrationDocumentPdfLoadStatus(state, status) {
            state.integrationDocumentPdfLoadStatus = status;
        },
        setIntegrationDocumentCsvLoadStatus(state,status){
            state.integrationDocumentCsvLoadStatus = status;
        },
        setIntegrationDocuments(state, integrationDocuments) {
            state.integrationDocuments = integrationDocuments;
        },
    
        setIntegrationDocumentLineCount(state, lineCount) {
            state.integrationDocumentOptions.lineCount = lineCount;
        },
        setIntegrationDocumentUpdateCount(state, updateCount) {
            state.integrationDocumentOptions.updateCount = updateCount;
        },
        setIntegrationDocumentAddStatus(state, status) {
            state.integrationDocumentAddStatus = status;
        },
        setIntegrationDocumentAddText(state, text) {
            state.integrationDocumentAddText = text;
        },
        setIntegrationDocumentEdit(state, integrationDocument) {
            state.integrationDocumentEdit = integrationDocument;

            
            //state.integrationDocumentEdit.weight = i18n.n(state.integrationDocumentEdit.weight, 'number', 'pl-PL');
            //if(state.integrationDocumentEdit.volume)state.integrationDocumentEdit.volume = i18n.n(state.integrationDocumentEdit.volume, 'number', 'pl-PL');

            //state.integrationDocumentEdit.muc_list.forEach((value, index, self) => {
                //if(!integrationDocument.muc[index]){
                //    state.integrationDocumentEdit.muc[index] = null;
                //}
            //});
        },
      
        setEditedIntegrationDocument(state, integrationDocument) {
            var lp = _.find(state.integrationDocuments,{'id':integrationDocument.id}).lp;
            var index = _.findIndex(state.integrationDocuments,{'id':integrationDocument.id});
            
            state.integrationDocuments[index] = integrationDocument;
            state.integrationDocuments[index].lp = lp;
        },
        setAddedIntegrationDocument(state, integrationDocument) {
            state.integrationDocumentOptions.itemAdded = true;
            state.integrationDocuments.push(integrationDocument);
        },
        setIntegrationDocumentSearchedTrue(state) {
            state.integrationDocumentOptions.itemSearched = true;
        },
        setOptionsItemAddedAndSearchedFalse(state) {
            state.integrationDocumentOptions.itemAdded = false;
            state.integrationDocumentOptions.itemSearched = false;
        },
        setDeletedIntegrationDocument(state, integrationDocument) {
            var index = _.findIndex(state.integrationDocuments,{'id':integrationDocument.id});
            state.integrationDocuments.splice(index,1);
        },
        setIntegrationDocumentEditDefault(state) {
            state.integrationDocumentEdit =  JSON.parse(JSON.stringify(state.integrationDocumentEditDefault)); //{ ...state.integrationDocumentEditDefault };
        },
        setIntegrationDocumentEditStatus(state, status) {
            state.integrationDocumentEditStatus = status;
        },
        setIntegrationDocumentEditText(state, text) {
            state.integrationDocumentEditText = text;
        },
        setIntegrationDocumentEditLoadStatus(state, status) {
            state.integrationDocumentEditLoadStatus = status;
        },
        setIntegrationDocumentDeleteStatus(state, status) {
            state.integrationDocumentDeleteStatus = status;
        },
        setIntegrationDocumentDeletedText(state, text) {
            state.integrationDocumentDeleteText = text;
        }
    },

    getters: {
        getIntegrationDocumentLoadStatus(state) {
            return state.integrationDocumentLoadStatus;
        },
        getIntegrationDocument(state) {
            return state.integrationDocument;
        },
        getIntegrationDocumentOptions(state){
            return state.integrationDocumentOptions;
        },
        getIntegrationDocumentsLoadStatus(state) {
            return state.integrationDocumentsLoadStatus;
        },
        getIntegrationDocuments(state) {
            return state.integrationDocuments;
        },
        getIntegrationDocumentAddStatus(state) {
            return function(){
                return state.integrationDocumentAddStatus;
            } 
        },
        getIntegrationDocumentLabelLoadStatus(state) {
            return function(){
                return state.integrationDocumentLabelLoadStatus;
            } 
        },
        getIntegrationDocumentPdfLoadStatus(state) {
            return function(){
                return state.integrationDocumentPdfLoadStatus;
            } 
        },
        getIntegrationDocumentCsvLoadStatus(state){
            return function(){
                return state.integrationDocumentCsvLoadStatus;
            } 
        },
        getIntegrationDocumentAddText(state) {
            return state.integrationDocumentAddText;
        },
        getIntegrationDocumentEdit(state) {
            return state.integrationDocumentEdit;
        },
        getIntegrationDocumentEditStatus(state) {
          return function(){
            return state.integrationDocumentEditStatus;
          }
        },
        getIntegrationDocumentEditText(state) {
            return state.integrationDocumentEditText;
        },
        getIntegrationDocumentEditLoadStatus(state) {
            return function(){
                return state.integrationDocumentEditLoadStatus;
            }
        },
        getIntegrationDocumentDeleteStatus(state) {
            return function(){
                return state.integrationDocumentDeleteStatus;
            }
        },
        getIntegrationDocumentDeleteText(state) {
            return state.integrationDocumentDeleteText;
        }
    }
};

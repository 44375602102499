import BankAccountAPI from "../api/bankAccount";

export const bankAccounts = {
    state: {
        bankAccounts: [],
        bankAccountOptions:{
            lineCount:0,
            showDeleted:false,
            orderBy:'bank',
            orderDirection:'asc',
            itemsPerPage:100,
            itemAdded:false,
            itemSearched:false
        },
        bankAccountsLoadStatus: 0,
        bankAccountAddStatus: 0,
        bankAccountAddText: "",

        bankAccountEdit: {
            id: 0,
            lp: 0,
            bank: undefined,
            iban:undefined,
            currency:'PLN',
            swift:undefined,
            cod:false,
            document:false
        },
        bankAccountEditDefault: {
            id: 0,
            lp: 0,
            bank: undefined,
            iban:undefined,
            currency:'PLN',
            swift:undefined,
            cod:false,
            document:false
        },
        bankAccountEditLoadStatus: 0,
        bankAccountEditStatus: 0,
        bankAccountEditText: "",

        bankAccountDeleteStatus: 0,
        bankAccountDeleteText: "",

    },

    actions: {
        loadBankAccounts({ state, commit, dispatch }) {
            commit("setBankAccountsLoadStatus", 1);
                BankAccountAPI.getBankAccounts({
                    offset:0,
                    itemsPerPage:state.bankAccountOptions.itemsPerPage, 
                    orderBy:state.bankAccountOptions.orderBy,
                    orderDirection:state.bankAccountOptions.orderDirection,
                    showDeleted:state.bankAccountOptions.showDeleted,
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setBankAccountLineCount", response.data.lineCount);
                    commit("setBankAccounts", response.data.bankAccounts);
                    dispatch("applyIndexOnBankAccounts");
                    commit("setBankAccountsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setBankAccounts", []);
                    commit("setBankAccountsLoadStatus", 3);
                });
         
        },
        loadMoreBankAccounts({ state, commit, dispatch }) {
            commit("setBankAccountsLoadStatus", 1);
                BankAccountAPI.getBankAccounts({
                    offset:state.bankAccounts.length,
                    itemsPerPage:state.bankAccountOptions.itemsPerPage, 
                    orderBy:state.bankAccountOptions.orderBy,
                    orderDirection:state.bankAccountOptions.orderDirection,
                    showDeleted:state.bankAccountOptions.showDeleted,
                })
                .then(function(response) {
                    commit("setBankAccountLineCount", response.data.lineCount);
                    commit("setBankAccounts", _.concat(state.bankAccounts, response.data.bankAccounts));
                    dispatch("applyIndexOnBankAccounts");
                    commit("setBankAccountsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setBankAccounts", []);
                    commit("setBankAccountsLoadStatus", 3);
                });
         
        },
        searchBankAccounts({ state, commit, dispatch },data) {
            commit("setBankAccountsLoadStatus", 1);
                BankAccountAPI.searchBankAccounts({
                    showDeleted:state.bankAccountOptions.showDeleted,
                    filterableHeaders:data.filterableHeaders,
                    q:data.q
                })
                .then(function(response) {
                    commit("setOptionsItemAddedAndSearchedFalse");
                    commit("setBankAccountSearchedTrue");
                    commit("setBankAccountLineCount", response.data.lineCount);
                    commit("setBankAccounts", response.data.bankAccounts);
                    dispatch("applyIndexOnBankAccounts");
                    commit("setBankAccountsLoadStatus", 2);
                })
                .catch(function() {
                    commit("setBankAccounts", []);
                    commit("setBankAccountsLoadStatus", 3);
                });
         
        },
        setBankAccountOptions({ state, commit, dispatch },data){
            commit("setBankAccountOptions",data.options);
            dispatch("loadBankAccounts");
        },
        applyIndexOnBankAccounts({ commit, state, rootState }) {
            let localBankAccounts = state.bankAccounts;

            var i = 1;
            localBankAccounts.forEach(bankAccount => {
                // if(_.isNumber(bankAccount.desktop)){
                //     bankAccount.desktop = _.find(rootState.lookups.permissions, { 'value': bankAccount.desktop }).text;
                //     bankAccount.dictionary = _.find(rootState.lookups.permissions, { 'value': bankAccount.dictionary }).text;
                //     bankAccount.setting = _.find(rootState.lookups.permissions, { 'value': bankAccount.setting }).text;
                // }
                bankAccount.lp = i;
                i++;
            });

            commit("setBankAccounts",localBankAccounts);
        },
        addBankAccount({ commit, state, dispatch }, data) {
            commit("setBankAccountAddStatus", 1);
            commit("setBankAccountAddText", "");

            BankAccountAPI.postAddNewBankAccount(
                data.bank,
                data.iban,
                data.currency,
                data.swift,
                data.cod,
                data.document
            )
                .then(function(response) {
                    commit("setBankAccountAddStatus", 2);
                    commit("setAddedBankAccount",response.data);
                    commit("setBankAccountLineCount", state.bankAccountOptions.lineCount + 1);
                    
                    dispatch("applyIndexOnBankAccounts");
                })
                .catch(function(error) {
                    commit("setBankAccountAddStatus", 3);
                    commit("setBankAccountAddText", error.response.data.errors);
                });
        },

        loadBankAccountEdit({ commit }, data) {
            commit("setBankAccountEditLoadStatus", 1);
            commit("setBankAccountEditStatus", 0);
            commit("setBankAccountEditText", "");

            BankAccountAPI.getBankAccountEdit(data.slug)
                .then(function(response) {
                    commit("setBankAccountEdit", response.data);
                    commit("setBankAccountEditLoadStatus", 2);
                })
                .catch(function() {
                    commit("setBankAccountEdit", {});
                    commit("setBankAccountEditLoadStatus", 3);
                });
        },
        loadBankAccountEditDefault({ commit }) {
            commit("setBankAccountEditDefault");
        },
        loadBankAccountAddDefault({ commit }) {
            commit("setBankAccountAddStatus", 0);
            commit("setBankAccountAddText", "");
        },
        editBankAccount({ commit, state, dispatch }, data) {
            commit("setBankAccountEditStatus", 1);

            BankAccountAPI.putEditBankAccount(
                data.slug,
                data.bank,
                data.iban,
                data.currency,
                data.swift,
                data.cod,
                data.document
            )
                .then(function(response) {
           
                    commit("setBankAccountEditStatus", 2);
                    commit("setEditedBankAccount",response.data)
                    dispatch("applyIndexOnBankAccounts");
                })
                .catch(function(error) {
                    commit("setBankAccountEditStatus", 3);
                    commit("setBankAccountEditText", error.response.data.errors);
                });
        },
        editBankAccountReactivate({ commit, state, dispatch }, data) {
            commit("setBankAccountEditStatus", 1);
  
            BankAccountAPI.putReactivateBankAccount(data.slug)
                .then(function(response) {
                    commit("setBankAccountEditStatus", 2);
                    commit("setEditedBankAccount",response.data);
                    
                    dispatch("applyIndexOnBankAccounts");
                })
                .catch(function(error) {
                    commit("setBankAccountEditStatus", 3);
                });
        },
        deleteBankAccount({ commit, state, dispatch }, data) {
            commit("setBankAccountDeleteStatus", 1);

            BankAccountAPI.deleteBankAccount(data.slug)
                .then(function(response) {
                    commit("setBankAccountDeleteStatus", 2);
                    if(state.bankAccountOptions.showDeleted){
                        commit("setEditedBankAccount",response.data)
                    }else{
                        commit("setBankAccountLineCount", state.bankAccountOptions.lineCount - 1);
                        commit("setDeletedBankAccount",response.data) 
                    }
                    
                    dispatch("applyIndexOnBankAccounts");
                })
                .catch(function() {
                    commit("setBankAccountDeleteStatus", 3);
                });
        },
        forceDeleteBankAccount({ commit, state, dispatch }, data) {
            commit("setBankAccountDeleteStatus", 1);

            BankAccountAPI.forceDeleteBankAccount(data.slug)
                .then(function(response) {
                    commit("setBankAccountDeleteStatus", 2);
                    commit("setBankAccountLineCount", state.bankAccountOptions.lineCount - 1);
                    commit("setDeletedBankAccount",response.data)
                    dispatch("applyIndexOnBankAccounts");
                })
                .catch(function() {
                    commit("setBankAccountDeleteStatus", 3);
                });
        },
    },
    mutations: {
        setBankAccountOptions(state,options){
            state.bankAccountOptions = options;
        },
        setBankAccountsLoadStatus(state, status) {
            state.bankAccountsLoadStatus = status;
        },
        setBankAccounts(state, bankAccounts) {
            state.bankAccounts = bankAccounts;
        },
        setBankAccountLineCount(state, lineCount) {
            state.bankAccountOptions.lineCount = lineCount;
        },
        setBankAccountAddStatus(state, status) {
            state.bankAccountAddStatus = status;
        },
        setBankAccountAddText(state, text) {
            state.bankAccountAddText = text;
        },
        setBankAccountEdit(state, bankAccount) {
            state.bankAccountEdit = bankAccount;
        },
        setEditedBankAccount(state, bankAccount) {
            var lp = _.find(state.bankAccounts,{'id':bankAccount.id}).lp;
            var index = _.findIndex(state.bankAccounts,{'id':bankAccount.id});
            if(bankAccount.cod){//cod change
                state.bankAccounts.forEach(element => {
                    element.can_delete = true;
                    element.cod = false;
                });
            }
            if(bankAccount.document){//document change
                state.bankAccounts.forEach(element => {
                    element.can_delete = true;
                    element.document = false;
                });
            }
            state.bankAccounts[index] = bankAccount;
            state.bankAccounts[index].lp = lp;
        },
        setAddedBankAccount(state, bankAccount) {
            if(bankAccount.cod){//cod change
                state.bankAccounts.forEach(element => {
                    element.can_delete = true;
                    element.cod = false;
                });
            }
            if(bankAccount.document){//document change
                state.bankAccounts.forEach(element => {
                    element.can_delete = true;
                    element.document = false;
                });
            }
            state.bankAccountOptions.itemAdded = true;
            state.bankAccounts.push(bankAccount);
        },
        setBankAccountSearchedTrue(state) {
            state.bankAccountOptions.itemSearched = true;
        },
        setOptionsItemAddedAndSearchedFalse(state) {
            state.bankAccountOptions.itemAdded = false;
            state.bankAccountOptions.itemSearched = false;
        },
        setDeletedBankAccount(state, bankAccount) {
            var index = _.findIndex(state.bankAccounts,{'id':bankAccount.id});
            state.bankAccounts.splice(index,1);
        },
        setBankAccountEditDefault(state) {
            state.bankAccountEdit = { ...state.bankAccountEditDefault };
        },
        setBankAccountEditStatus(state, status) {
            state.bankAccountEditStatus = status;
        },
        setBankAccountEditText(state, text) {
            state.bankAccountEditText = text;
        },
        setBankAccountEditLoadStatus(state, status) {
            state.bankAccountEditLoadStatus = status;
        },
        setBankAccountDeleteStatus(state, status) {
            state.bankAccountDeleteStatus = status;
        },
        setBankAccountDeletedText(state, text) {
            state.bankAccountDeleteText = text;
        }
    },

    getters: {
        getBankAccountOptions(state){
            return state.bankAccountOptions;
        },
        getBankAccountsLoadStatus(state) {
            return state.bankAccountsLoadStatus;
        },
        getBankAccounts(state) {
            return state.bankAccounts;
        },
        getBankAccountAddStatus(state) {
            return function(){
                return state.bankAccountAddStatus;
            } 
        },
        getBankAccountAddText(state) {
            return state.bankAccountAddText;
        },
        getBankAccountEdit(state) {
            return state.bankAccountEdit;
        },
        getBankAccountEditStatus(state) {
          return function(){
            return state.bankAccountEditStatus;
          }
        },
        getBankAccountEditing(state) {
            return state.bankAccountEdit.id > 0;
        },
        getBankAccountEditText(state) {
            return state.bankAccountEditText;
        },
        getBankAccountEditLoadStatus(state) {
            return state.bankAccountEditLoadStatus;
            
        },
        getBankAccountDeleteStatus(state) {
            return function(){
                return state.bankAccountDeleteStatus;
            }
        },
        getBankAccountDeleteText(state) {
            return state.bankAccountDeleteText;
        }
    }
};

import IntegrationDocumentAPI from "../api/integrationDocument";

export const tpayChecks = {
    state: {
        TpayChecks: [],
        TpayCheckOptions:{
            orderBy:undefined,
            orderDirection:undefined,
            accountId:undefined,
            statusId:undefined,
            showActive:true,
            fromDate:undefined,
            toDate:undefined,
            typeId:undefined
        },
        TpayChecksLoadStatus: 0,
    },

    actions: {
        loadTpayChecks({ state, commit, dispatch,rootState },data) {
            commit("setTpayChecksLoadStatus", 1);
                IntegrationDocumentAPI.postTpayChecks({
                    accountId:state.TpayCheckOptions.accountId,
                    statusId:state.TpayCheckOptions.statusId,
                    showActive:state.TpayCheckOptions.showActive,
                    typeId:state.TpayCheckOptions.typeId,
                    file_content:data.file_content
                })
                .then(function(response) {
                    commit("setTpayCheckLineCount", response.data.lineCount);
                    commit("setTpayChecks", response.data.TpayChecks);
                    dispatch("applyIndexOnTpayChecks");
                    commit("setTpayChecksLoadStatus", 2);
                })
                .catch(function() {
                    commit("setTpayChecks", []);
                    commit("setTpayChecksLoadStatus", 3);
                });
         
        },
        setTpayCheckOptions({ state, commit, dispatch },data){
            commit("setTpayCheckOptions",data.options);
            //dispatch("loadTpayChecks");
        },
        applyIndexOnTpayChecks({ commit, state, dispatch, rootState }) {
            let localTpayChecks = state.TpayChecks;

            var i = 1;
            localTpayChecks.forEach(TpayCheck => {
                // if(_.isNumber(TpayCheck.type)){
                //     TpayCheck.type = _.find(rootState.lookups.TpayCheckTypes, { 'value': TpayCheck.type }).text;
                // }
                TpayCheck.lp = i;
                i++;
            });

            commit("setTpayChecks",localTpayChecks);
        },
        
    },
    mutations: {
        setTpayCheckOptions(state,options){
            state.TpayCheckOptions = options;
        },
        setTpayChecksLoadStatus(state, status) {
            state.TpayChecksLoadStatus = status;
        },
        setTpayChecks(state, TpayChecks) {
            state.TpayChecks = TpayChecks;
        },
    
        setTpayCheckLineCount(state, lineCount) {
            state.TpayCheckOptions.lineCount = lineCount;
        },
    },

    getters: {
        getTpayCheckOptions(state){
            return state.TpayCheckOptions;
        },
        getTpayChecksLoadStatus(state) {
            return function(){
                return state.TpayChecksLoadStatus;
            }
        },
        getTpayChecks(state) {
            return state.TpayChecks;
        },
    }
};

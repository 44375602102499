<template>
          <v-sheet flat>
          <v-snackbar
            v-model="integrationDocumentSnackbar"
            timeout="5000"
            top
          >
            {{integrationDocumentEditText}}
          </v-snackbar>
          <v-snackbar
            v-model="integrationTaskSnackbar"
            timeout="5000"
            top
          >
            {{integrationTaskResponseText}}
            
            <template v-slot:action="{ attrs }">
              <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="openTaskLog"
              >
                Szczegóły
              </v-btn>
            </template>
          </v-snackbar>
          
          <v-card flat :loading="integrationDocumentEditStatus===1 || integrationDocumentPdfLoadStatus===1 || integrationTaskRunningStatus===1">
            <v-toolbar flat color="white">
                    <v-toolbar-title>{{_.find(lookups.integrationDocumentTypes,{'value':editedItem.type}).text}}</v-toolbar-title>
                    <v-divider
                    class="mx-4"
                    inset
                    vertical
                    ></v-divider>

                    <v-toolbar-title>{{editedItem.document_number}}</v-toolbar-title>

                    <v-divider
                    v-if="editedItem.amount"
                    class="mx-4"
                    inset
                    vertical
                    ></v-divider>
                    <v-toolbar-title v-if="editedItem.amount">{{$n(editedItem.amount,'number','pl-PL')}} {{editedItem.amount_currency}}</v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-dialog v-model="dialogLog" max-width="1400px">
                    <v-card>
                        <v-card-text>
                            <integrationTaksLog :t-id="selectedTaskId"/>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="grey" text @click="dialogLog=false">Zamknij</v-btn>
                        </v-card-actions>
                    </v-card>
                    </v-dialog>

                    <v-menu
                        v-model="invoiceCorrectionMenu"
                        :close-on-content-click="false"
                        offset-y
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn @click="correctionDateToday" v-on="on" small color="red" dark>
                              Korekta
                          </v-btn>
                        </template>
                        <v-card>
                          <v-container class="px-5">
                            <v-row>
                              <v-list>
                                <v-list-item @click="totalCorrection">
                                  <v-list-item-title>Pełna (wybrana data)</v-list-item-title>
                                </v-list-item>
                                <v-list-item v-if="tableItemsSelected.length>0" @click="selectedCorrection">
                                  <v-list-item-title>Wybrane wiersze (wybrana data)</v-list-item-title>
                                </v-list-item>
                                <v-list-item v-if="unsendItems.length>0" @click="unsendCorrection">
                                  <v-list-item-title>Niewysłane pozycje (wybrana data)</v-list-item-title>
                                </v-list-item>
                                <v-list-item v-for="s in editedItem.shipments" :key="s.id" @click="shipmentCorrection(s.id)">
                                  <v-list-item-title>Wysyłka nr {{s.ext_shipment_id}} (data opłacenia)</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-row>
                            <v-row>
                               <v-menu
                                          v-model="selectedDayMenu"
                                          :close-on-content-click="false"
                                          :nudge-right="40"
                                          transition="scale-transition"
                                          offset-y
                                          min-width="150px"
                                        >
                                          <template v-slot:activator="{ on }">
                                            <v-text-field
                                              v-model="correctionDate"
                                              readonly
                                              v-on="on"
                                              label="Data wystawienia"
                                              hide-details
                                            ></v-text-field>
                                          </template>
                                          <v-date-picker no-title first-day-of-week="1" v-model="correctionDate" @input="selectedDayMenu = false"></v-date-picker>
                              </v-menu>
                           
                            </v-row>
                          </v-container>
                        </v-card>
                        
                    </v-menu>
                    
                    <v-divider class="mx-4" inset vertical ></v-divider>
                    <v-btn small color="primary" @click="showOrder = !showOrder">{{showOrder?'Ukryj zamówienie':'Pokaż zamówienie'}}</v-btn>
                    <v-divider class="mx-4" inset vertical></v-divider>

                    <v-toolbar-title>{{editedItem.buyer}}</v-toolbar-title>

                    <v-divider class="mx-4" inset vertical ></v-divider>

                    <v-tooltip bottom><template v-slot:activator="{ on }"><v-icon v-on="on" @click="sendDocument" size="32" color="tetriary">mdi-email-outline</v-icon></template>Wyślij do klienta</v-tooltip>

                    <v-divider class="mx-4" inset vertical ></v-divider>

                    <v-tooltip bottom><template v-slot:activator="{ on }"><v-icon v-on="on" @click="downloadDocument" size="32" color="tetriary">mdi-printer</v-icon></template>Pokaż PDF</v-tooltip>

                    <v-divider class="mx-4" inset vertical ></v-divider>
                    <v-tooltip v-if="editedItem.status" bottom>
                      <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            size="42"
                            :color="_.find(lookups.integrationDocumentStatus,{'value':editedItem.status}).color"
                          >
                            {{_.find(lookups.integrationDocumentStatus,{'value':editedItem.status}).icon}}
                        </v-icon>
                      </template>
                      <span>{{_.find(lookups.integrationDocumentStatus,{'value':editedItem.status}).text}}</span>
                    </v-tooltip>
                    
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col class="col-9">
                  <div v-if="showOrder" >
                 <v-data-table
                        v-for="order in editedItem.order"
                        :key="order.order_id"
                        :hide-default-footer="order && order.item_list.length<11"
                        :headers="orderHeaders"
                        :items="order.item_list"
                        class="elevation-1 mb-5"
                        dense
                    >
                      <template v-slot:top>
                      <v-toolbar flat dense color="white">
                          <v-tooltip v-if="editedItem.order_status" top>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                  v-on="on"
                                  size="25"
                                  class="mr-2"
                                  :color="_.find(lookups.integrationOrderStatus,{'value':editedItem.order_status}).color"
                                >
                                  {{_.find(lookups.integrationOrderStatus,{'value':editedItem.order_status}).icon}}
                              </v-icon>
                            </template>
                            <span>{{_.find(lookups.integrationOrderStatus,{'value':editedItem.order_status}).text}}</span>
                          </v-tooltip>
                          <v-toolbar-title>Zamówienie | {{order.order_id}}</v-toolbar-title>
                          <!-- <v-chip
                              color="tertiary"
                              text-color="white"
                              class="ml-3"
                            >
                              {{order.order_status.text}}
                              <v-icon size="20" @click="refreshOrderStatus(order)" right>
                                mdi-reload
                              </v-icon>
                          </v-chip> -->

                          <v-icon size="28" class="ml-5" color="orange darken-4" v-if="order.invoice_required">
                                mdi-file-document
                          </v-icon>

                          <v-tooltip v-if="order.order_message" right>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                  v-on="on"
                                  size="28"
                                  class="ml-5"
                                  color="blue lighten-3"
                                >
                                  mdi-message-text
                              </v-icon>
                            </template>
                            <span>{{order.order_message}}</span>
                          </v-tooltip>
                        
                          <v-spacer></v-spacer>
                          <v-toolbar-title>{{order.delivery_method_name}} ({{$n(order.delivery_cost_amount,'number','pl-PL')}} {{order.delivery_cost_currency}})</v-toolbar-title>
                      </v-toolbar>
                      </template>
                      <template v-slot:item.item_name="{ item }">       
                       {{item.item_name}}
                      </template>
                      <template v-slot:item.item_price="{ item }">       
                        {{$n(item.item_price,'number','pl-PL')}} {{item.item_price?item.item_price_currency:''}}
                      </template>
                      <template v-slot:item.item_price_net="{ item }">       
                        {{$n(item.item_price_net,'number','pl-PL')}} {{item.item_price_net?item.item_price_currency:''}}
                      </template>
                      <template v-slot:item.item_tax_rate="{ item }">       
                        {{item.item_tax_rate}}%
                      </template>
                      <template v-slot:item.item_price_sum="{ item }">       
                        {{$n(item.item_price*item.item_quantity,'number','pl-PL')}} {{item.item_price?item.item_price_currency:''}}
                      </template>
                  </v-data-table>

                  <v-data-table
                        hide-default-footer
                        :headers="shipmentheaders"
                        :items="editedItem.shipments"
                        class="elevation-1 mb-5"
                        dense
                    >
                      <template v-slot:top>
                      <v-toolbar flat dense color="white">
                          <v-toolbar-title>Wysyłki</v-toolbar-title>
                                <v-dialog v-model="dialogShipments" :max-width="dialogWidth">
                                  <shipmentEditor v-on:close="dialogShipments=false"  />
                                </v-dialog>
                      </v-toolbar>
                      </template>
                      <template v-slot:item.amount="{ item }">
                          {{item.amount?$n(item.amount + (item.carrier.delivery_cost_amount?item.carrier.delivery_cost_amount:0),'number','pl-PL'):''}} {{item.amount?item.amount_currency:''}}
                        </template>
                        <template v-slot:item.delivery_cost_amount="{ item }">
                          {{item.carrier.delivery_cost_amount?$n(item.carrier.delivery_cost_amount,'number','pl-PL'):''}} {{item.carrier.delivery_cost_amount?item.amount_currency:''}}
                        </template>
                        <template v-slot:item.status="{ item }">

                        <v-tooltip right>
                              <template v-slot:activator="{ on }">
                              <v-icon
                                v-on="on"
                                :color="_.find(lookups.integrationShipmentStatus,{'value':item.status}).color"
                              >
                                {{_.find(lookups.integrationShipmentStatus,{'value':item.status}).icon}}
                              </v-icon>
                              </template>
                              <span>{{_.find(lookups.integrationShipmentStatus,{'value':item.status}).text}}</span>
                        </v-tooltip>

                        </template>

                      <template v-slot:item.actions="{ item }">       
                        <v-btn color="primary" x-small @click="editShipmentItem(item)">Detale</v-btn>
                      </template> 
                </v-data-table>

                  </div>

                  <v-data-table
                        :headers="itemHeaders"
                        :hide-default-footer="editedItem.document.item_list && editedItem.document.item_list.length<11"
                        :items="editedItem.document.item_list"
                        class="elevation-1 mb-5"
                        item-key="item_external_id"
                        v-model="tableItemsSelected"
                        :show-select="multiEdit"
                        dense
                    >
                      <template v-slot:top>
                      <v-toolbar flat dense color="white">
                          <v-toolbar-title>Pozycje dokumentu</v-toolbar-title>
                          <!-- <v-chip
                              color="tertiary"
                              text-color="white"
                              class="ml-3"
                            >
                              {{order.order_status.text}}
                              <v-icon size="20" @click="refreshOrderStatus(order)" right>
                                mdi-reload
                              </v-icon>
                          </v-chip> -->

                          <!-- <v-icon size="28" class="ml-5" color="orange darken-4" v-if="order.invoice_required">
                                mdi-file-document
                          </v-icon> -->

                          <!-- <v-tooltip v-if="order.order_message" right>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                  v-on="on"
                                  size="28"
                                  class="ml-5"
                                  color="blue lighten-3"
                                >
                                  mdi-message-text
                              </v-icon>
                            </template>
                            <span>{{order.order_message}}</span>
                          </v-tooltip> -->
                        
                          <v-spacer></v-spacer>
                          <v-switch class="mt-4" v-model="multiEdit" label="Zaznaczanie" @change="multiEditChange"></v-switch>
                          <!-- <v-toolbar-title v-if="editedItem.carrier.shipment_weight">{{$n(editedItem.carrier.shipment_weight/1000,'number','pl-PL')}}kg</v-toolbar-title> -->
                          <!-- 
                          <v-toolbar-title>{{order.payment_type.text}} {{order.payment_provider?'('+order.payment_provider+')':''}}</v-toolbar-title>
                          <v-divider
                            class="mx-4"
                            inset
                            vertical
                          ></v-divider>
                          <v-toolbar-title>{{order.delivery_method_name}} ({{$n(order.delivery_cost_amount,'number','pl-PL')}} {{order.delivery_cost_currency}})</v-toolbar-title> -->
                      </v-toolbar>
                      </template>
                      <template v-slot:item.selection="{ item }">       
                        <v-simple-checkbox class="mt-0 mb-0 mr-0 ml-0" hide-details="auto" single-line v-model="item.selection" label=""></v-simple-checkbox>
                      </template>
                      <template v-slot:item.item_name="{ item }">       
                       {{item.item_name}}
                      </template>

                      <template v-slot:item.item_quantity="{ item }"> 
                        <v-text-field 
                        v-if="multiEdit && tableItemsSelected.includes(item)"
                        v-model="item.item_quantity" min="1"
                        type="number" dense single-line hide-details="auto"></v-text-field>
                        
                        <span v-else>{{item.item_quantity}}</span>
                      </template>

                      <template v-slot:item.item_price="{ item }"> 
                        <!-- <inline-editor
                            v-if="multiEdit && tableItemsSelected.includes(item)"
                            :item.sync="item.item_price"
                            item-name=""
                            errors=""
                            required
                            decimal
                          >     
                        </inline-editor>
                        <span v-else>{{$n(item.item_price,'number','pl-PL')}}</span> -->
                        {{$n(item.item_price,'number','pl-PL')}} {{item.item_price?item.item_price_currency:''}}
                      </template>
                   
                      <template v-slot:item.item_price_net="{ item }">       
                        {{$n(item.item_price_net,'number','pl-PL')}} {{item.item_price_net?item.item_price_currency:''}}
                      </template>

                       <template v-slot:item.item_price_sum="{ item }">       
                        {{$n(item.item_price*item.item_quantity,'number','pl-PL')}} {{item.item_price?item.item_price_currency:''}}
                      </template>

                      <template v-slot:item.item_tax_rate="{ item }">       
                        {{item.item_tax_rate}}%
                      </template>
                      <template v-slot:item.actions="{ item }">       
                        <!-- <v-btn @click="editItem(item)" color="primary" x-small class="ml-2">Edytuj</v-btn>
                        <v-btn v-if="user.group_id > 1 && user.group_id <= 4" color="primary" x-small @click="deleteItem(item)" class="ml-2">Usuń</v-btn> -->
                      </template> 
                  </v-data-table>

                 
                <v-row class="mt-0" >
                  <v-col class="col-7" v-if="editedItem.logs.length>1">
                      <v-data-table
                        
                        hide-default-footer
                        :headers="logHeaders"
                        :items="editedItem.logs"
                        class="elevation-1 mb-5"
                        dense
                      >
                      <template v-slot:top>
                        <v-toolbar flat dense color="white">
                            <v-toolbar-title>Historia statusów</v-toolbar-title>
                        </v-toolbar>
                      </template>
                      <template v-slot:item.statusIcon="{ item }">
                                <v-icon
                                  :color="_.find(lookups.integrationDocumentStatus,{'value':item.status}).color"
                                >
                                  {{_.find(lookups.integrationDocumentStatus,{'value':item.status}).icon}}
                                </v-icon>
                      </template>
                      <template v-slot:item.status="{ item }">
                          {{_.find(lookups.integrationDocumentStatus,{'value':item.status}).text}}
                      </template>
                      </v-data-table>
                  </v-col>
                  
                </v-row>
                </v-col>
                
                <v-col class="col-3">
                    <v-card class="mb-5">
                        <v-toolbar flat dense color="white">
                            <v-toolbar-title>Odbiorca</v-toolbar-title>
                            <v-tooltip v-if="editedItem.document.contact_email" right>
                              <template v-slot:activator="{ on }">
                                  <v-icon
                                    v-on="on"
                                    class="pl-2"
                                    @click="doCopy"
                                  >
                                    mdi-email-plus-outline
                                </v-icon>
                              </template>
                              <span>Skopiuj adres e-mail</span>
                            </v-tooltip>
                            <v-spacer></v-spacer>
                            <v-switch v-model="buyerEdit" class="mt-4"></v-switch>
                        </v-toolbar>
                        <v-container v-if="buyerEdit && !editedItem.Closed"  class="pt-0">
                          <v-row>
                            <v-col class="col-12 pl-3 pr-3 pt-0 pb-2">
                              <v-text-field filled dense single-line hide-details  v-model="editedItem.document.invoice_company_name" label="Firma"></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="col-5 pl-3 pr-2 pt-0 pb-2">
                              <v-text-field filled dense single-line hide-details v-model="editedItem.document.invoice_first_name" :background-color="getColorIfError('invoice_first_name')"
                              @blur="$v.editedItem.document.invoice_first_name.$touch()"  label="Imię"></v-text-field>
                            </v-col>
                            <v-col class="col-7 pl-0 pr-3 pt-0 pb-2">
                              <v-text-field filled dense single-line hide-details v-model="editedItem.document.invoice_last_name" :background-color="getColorIfError('invoice_last_name')"
                              @blur="$v.editedItem.document.invoice_last_name.$touch()" label="Nazwisko"></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="col-12 pl-3 pr-3 pt-0 pb-2">
                              <v-text-field filled dense single-line hide-details v-model="editedItem.document.invoice_street" :background-color="getColorIfError('invoice_street')" 
                                  @blur="$v.editedItem.document.invoice_street.$touch()"  label="Ulica"></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="col-3 pl-3 pr-0 pt-0 pb-2">
                              <v-text-field filled dense single-line hide-details v-model="editedItem.document.invoice_post_code" :background-color="getColorIfError('invoice_post_code')"  
                                  @blur="$v.editedItem.document.invoice_post_code.$touch()" label="Kod pocztowy"></v-text-field>
                            </v-col>
                            <v-col class="col-7 pl-2 pr-2 pt-0 pb-2">
                              <v-text-field filled dense single-line hide-details v-model="editedItem.document.invoice_city" :background-color="getColorIfError('invoice_city')"   
                                  @blur="$v.editedItem.document.invoice_city.$touch()" label="Miasto"></v-text-field>
                            </v-col>
                            <v-col class="col-2 pl-0 pr-3 pt-0 pb-2">
                              <v-text-field filled dense single-line hide-details v-model="editedItem.document.invoice_country_code" :background-color="getColorIfError('invoice_country_code')"   
                                  @blur="$v.editedItem.document.invoice_country_code.$touch()" label="Kod kraju"></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="col-12 pl-3 pr-3 pt-0 pb-2">
                              <v-text-field filled dense single-line hide-details v-model="editedItem.document.contact_email" :background-color="getColorIfError('contact_email')" 
                                  @blur="$v.editedItem.document.contact_email.$touch()"  label="Adres e-mail"></v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                        <v-container v-else  class="pt-0">
                          <v-row class="pl-5 pr-5 d-flex flex-column">
                            <h2>{{editedItem.document.invoice_company_name}}</h2>
                            <h2>{{editedItem.document.invoice_first_name}} {{editedItem.document.invoice_last_name}}</h2>
                            <h2>{{editedItem.document.invoice_street}}</h2>
                            <h2>{{editedItem.document.invoice_post_code}} {{editedItem.document.invoice_city}} {{editedItem.document.invoice_country_code}}</h2>
                          </v-row>
                        </v-container>
                      </v-card>
                      
                      <v-card v-if="$route.name=='payu' || $route.name=='tpay'" color="orange lighten-4">
                        <v-toolbar flat dense color="orange lighten-4">
                            <v-toolbar-title>Kontrola PayU</v-toolbar-title>
                        </v-toolbar>
                        <v-container class="pt-0">
                          <v-row class="pl-5 pr-5 d-flex flex-column">
                            <h3>Data: {{integrationOrderPayUNote.date}}</h3>
                            <h3>Kwota: {{$n(integrationOrderPayUNote.amount,'number','pl-PL')}} {{integrationOrderPayUNote.currency}}</h3>
                            <h3>Status: {{_.find(lookups.payUCheckStaus,{'value':integrationOrderPayUNote.status}).text}}</h3>
                            <h3>Opis: {{integrationOrderPayUNote.desc}}</h3>
                          </v-row>
                        </v-container>
                      </v-card>
                      
                      <v-card v-if="$route.name=='storn'" color="orange lighten-4">
                        <v-toolbar flat dense color="orange lighten-4">
                            <v-toolbar-title>Kontrola Storn</v-toolbar-title>
                        </v-toolbar>
                        <v-container class="pt-0">
                          <v-row class="pl-5 pr-5 d-flex flex-column">
                            <h3>Data: {{integrationOrderPayUNote.date}}</h3>
                            <h3>Kwota: {{$n(integrationOrderPayUNote.amount,'number','pl-PL')}} {{integrationOrderPayUNote.currency}}</h3>
                            <h3>Status: {{_.find(lookups.stornCheckStaus,{'value':integrationOrderPayUNote.status}).text}}</h3>
                            <h3>Opis: {{integrationOrderPayUNote.desc}}</h3>
                          </v-row>
                        </v-container>
                      </v-card>
                </v-col>
              </v-row>

            </v-card-text>
            <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="grey" text @click="close()">Zamknij</v-btn>

                          <!-- <v-btn v-if="independentItem" color="primary darken-1" text @click="handleSubmit">Dodaj</v-btn>
                          <v-btn v-else-if="!editedItem.Closed" color="primary darken-1" text @click="handleSubmit">Zapisz</v-btn> -->
             </v-card-actions>
            <confirm ref="confirm"></confirm>
          </v-card>
          </v-sheet>
</template>

<script>
  import confirm from '../components/Confirm'
  import required from 'vuelidate/lib/validators/required'
  import email from 'vuelidate/lib/validators/email'
  import integer from 'vuelidate/lib/validators/integer'
  import requiredIf from 'vuelidate/lib/validators/requiredIf'
  import { helpers } from 'vuelidate/lib/validators'
  const decimalPL = helpers.regex('decimalPL', /^[-]?\d*(\,\d+)?$/)
  import integrationTaksLog from '../components/IntegrationTaskLog'
  import inlineEditor from '../components/InlineEditor.vue'

  import i18n from "../main";

  export default {
    components:{
      confirm,
      integrationTaksLog,
      inlineEditor
    },
    data: () => ({
      integrationDocumentSnackbar:false, 
      integrationTaskSnackbar:false,
      integrationTaskResponseText:'',
      componentKey:0,
      tableItemsSelected:[],
      correctionDate:undefined,
      datePickupMenu:false,
      selectedDayMenu:false,
      itemEditing:false,
      bEdit:false,
      multiEdit:false,
      labelPrintMenu:false,
      dialogWidth:1500,
      dialogShipments:false,
      dialogLog: false,
      selectedTaskId:undefined,
      invoiceCorrectionMenu:false,
      showOrder:false,
      orderHeaders: [
        { text: 'Kod',align: 'start', value: 'item_external_id',sortable: true },
        { text: 'Towar',align: 'start', value: 'item_name',sortable: true },
        { text: 'Ilość', value: 'item_quantity',sortable: true },
        { text: 'Cena jedn.', value: 'item_price',sortable: true },
        { text: 'Cena jedn. netto', value: 'item_price_net',sortable: true },
        { text: 'Suma', value: 'item_price_sum',sortable: true },
        { text: 'Stawka VAT', value: 'item_tax_rate',sortable: true },
        { text: 'Wysłanych', value: 'item_quantity_send',sortable: true },
        { text: '', value: 'actions', sortable: false,filterable:false},
      ],
      shipmentheaders: [
        { text: '', value: 'status',sortable:true,filterable:true,filterColumn:"status",visible:true  },
        { text: 'Nr CODIDO', value: 'ext_shipment_id',sortable: true,filterable:true,filterColumn:"ext_shipment_id",visible:true },
        { text: 'Suma wysyłki', value: 'amount',sortable: true,filterable:true,filterColumn:"amount",visible:true },
        { text: 'Kwota dostawy', value: 'delivery_cost_amount',sortable: false,filterable:false,visible:true },
        { text: 'Nr nadania', value: 'shipment_id',sortable:true,filterable:true,filterColumn:"shipment_id",visible:true  },
        { text: 'Data wysłania', value: 'pickup_date',sortable:true,filterable:true,filterColumn:"pickup_date",visible:true  },
        //{ text: '', value: 'actions', sortable: false,filterable:false,visible:true},
      ],
      itemHeaders: [
        // { text: '', value: 'status',sortable:true,filterable:true,filterColumn:"status",visible:true  },
        { text: 'Kod', value: 'item_external_id',sortable: true },
        { text: 'Towar',align: 'start', value: 'item_name',sortable: true },
        { text: 'Ilość', value: 'item_quantity',sortable: true, width:70 },
        { text: 'Cena jedn.', value: 'item_price',sortable: true },
        { text: 'Cena jedn. netto', value: 'item_price_net',sortable: true },
        { text: 'Suma', value: 'item_price_sum',sortable: true },
        { text: 'Stawka VAT', value: 'item_tax_rate',sortable: true },
        { text: '', value: 'actions', sortable: false,filterable:false},
      ],
      logHeaders: [
        { text: '',align: 'start', value: 'statusIcon',sortable: false },
        { text: 'Data',align: 'start', value: 'created_at',sortable: false },
        { text: 'Status', value: 'status',sortable: false },
        { text: '', value: 'status_message',sortable:false},
      ],
    }),
    validations(){
      return this.inputValidation;
    },
    watch: {
      integrationDocumentEditStatus(val){
        if(val===2){
          this.$emit('updated');
        }else if(val===3){
          this.integrationDocumentSnackbar = true;
          //this.dialog = false;
        }
      },
       integrationShipmentEditLoadStatus(val){
        if(val===2){
          this.dialogShipments = true;
        }
      },
      integrationTaskRunningStatus(val){
        if(val===2){
          if(this.taskLogItem.status===1){
            this.integrationTaskResponseText = 'Akcja zakończona sukcesem'
          }else{
            this.integrationTaskResponseText = 'Akcja zakończona błędem'
          }
           this.integrationTaskSnackbar = true;
           this.$emit('updated');
           //this.$store.dispatch( 'loadIntegrationDocumentEdit', {slug:  this.editedItem.id}); 
           //this.$store.dispatch( 'loadIntegrationOrderEdit', {slug:  this.editedItem.integration_order_id}); 
           this.close();
            
        }
      },
      dialogLog (val) {
        if(val){

        }else{
          this.closeLog()
        }
      },
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      lookups(){
        return this.$store.getters.getLookups;
      },
      editedItem(){
        return this.$store.getters.getIntegrationDocumentEdit;
      },
      taskLogItem(){
        return this.$store.getters.getIntegrationTaskLog;
      },
      buyerEdit:{
        get () {  
          return this.bEdit;
        },
        set (value) {
          this.bEdit = value;
        }
      },
      inputValidation(){
        let validationObject = {
          editedItem:{
          document:{
            invoice_first_name:{
              required: requiredIf(function (nestedModel) {
                return !this.editedItem.document.invoice_company_name;
              })
            },
             invoice_last_name:{
              required: requiredIf(function (nestedModel) {
                return !this.editedItem.document.invoice_company_name;
              })
            },
            invoice_city:{required},
            invoice_post_code:{required},
            invoice_street:{required},
            invoice_country_code:{required},
            contact_email:{required},
          },
        }}

        // var i = 0;
        // if(!this.editedItem.carrier) return validationObject;

        // this.editedItem.carrier.service_list.forEach((service_value,service_index) => {
        //   //console.log(service_value.parameters.length);
        //    let serviceIndex = service_index;
        //    let serviceValue = service_value;

        //    let ob = {parameters:{}};
        //     _.forIn(serviceValue.parameters, function(value, key, object) {
        //       if(value.required && value.type=='float'){
        //          ob.parameters[key] = {value:{required, decimalPL}}
        //       }else if(value.required && value.type=='number'){
        //          ob.parameters[key] = {value:{required, integer}}
        //       }else if(value.required){
        //          ob.parameters[key] = {value:{required}}
        //       }

        //     });
        //     if(serviceValue.selected)validationObject.editedItem.carrier.service_list[serviceIndex] = ob;
        // });

        return validationObject;
      },
    
      tableItems(){
        return this.$store.getters.getIntegrationDocuments;
      },
      integrationDocument(){
        return this.$store.getters.getIntegrationDocument;
      },
      integrationDocumentEditStatus(){
        return this.$store.getters.getIntegrationDocumentEditStatus();
      },
      integrationDocumentPdfLoadStatus(){
        return this.$store.getters.getIntegrationDocumentPdfLoadStatus();
      },
      integrationShipmentEditLoadStatus(){
        return this.$store.getters.getIntegrationShipmentEditLoadStatus()
      },
      integrationDocumentEditText(){
        return this.$store.getters.getIntegrationDocumentEditText
      },
      integrationTaskRunningStatus(){
        return this.$store.getters.getIntegrationTaskRunningStatus();
      },
      unsendItems(){
        var $items = []
        if(!this.editedItem || !this.editedItem.order)return $items;

         this.editedItem.order.forEach(o => {
           o.item_list.forEach(item => {
            var unsend = item.item_quantity - item.item_quantity_send;
             if(unsend > 0){
               var i = item;
               i.item_quantity = unsend;
               $items.push(i);
             }
          });
         });

        return $items;
      },
      integrationOrderPayUNote(){
        return this.$store.getters.getIntegrationOrderPayUNote;
      }
    },
    mounted(){
      //this.buyerEdit = this.independentItem;
      // if(this.lookups.integrationDocuments.length>0){
      //   this.loadIntegrationDocuments();
      // }else{
      //   this.$store.dispatch( 'loadLookups' );
      //   setTimeout(() => {
      //     this.loadIntegrationDocuments();
      //   }, 1000)
      // }
    },
    methods: {
      multiEditChange(){
        if(!this.multiEdit){
          this.tableItemsSelected = [];
        }
      },
      totalCorrection(){
        this.$store.dispatch( 'runCustomIntegrationTask', {
                slug: 10008,//Zadnie anuluj zaliczkę ręcznie
                initialValues:{'fakturownia_invoice_id':this.editedItem.ext_document_id,'invoice_issue_date':this.correctionDate}
        });
      },
      selectedCorrection(){
        var selected = []
        this.tableItemsSelected.forEach(e => {
          var element = e;
          element.item_quantity = parseInt(element.item_quantity);
          selected.push(element);
        });
        this.$store.dispatch( 'runCustomIntegrationTask', {
                slug: 10009,//Korekta częściowa ręcznie
                initialValues:{'fakturownia_invoice_id':this.editedItem.ext_document_id,'invoice_issue_date':this.correctionDate,'item_list':selected}
        });
      },
      unsendCorrection(){
        this.$store.dispatch( 'runCustomIntegrationTask', {
                slug: 10009,//Korekta częściowa ręcznie
                initialValues:{'fakturownia_invoice_id':this.editedItem.ext_document_id,'invoice_issue_date':this.correctionDate,'item_list':this.unsendItems}
        });
      },
      correctionDateToday(){
        var today = new Date();
        this.correctionDate =  today.toISOString().substr(0,10)// getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      },
      shipmentCorrection(shipment_id){
        this.$store.dispatch( 'runCustomIntegrationTask', {
                slug: 10006,//Korekta częściowa
                initialValues:{'spedix_shipment_id':shipment_id}
        });
      },
      sendDocument(){
        this.$store.dispatch( 'runCustomIntegrationTask', {
                slug: 10001,//Zadnie wyślij dokument
                initialValues:{'spedix_document_id':this.editedItem.id}
        });
      },
      closeLog () {
        this.selectedTaskId = undefined;
        setTimeout(() => {
          this.$store.dispatch( 'loadIntegrationTaskLogsEmpty'); 
        }, 300)
      },
      openTaskLog(){
        this.selectedTaskId = this.taskLogItem.integration_task_id;
        this.dialogLog = true;
      },
      downloadDocument(){
        this.$store.dispatch( 'getIntegrationDocumentPdf', {slug:this.editedItem.id});
      },
      getColorIfError(parameter) {
        if(!this.$v.editedItem.document[parameter] || !this.$v.editedItem.document[parameter].$dirty) return ''
        if(!this.$v.editedItem.document[parameter].required) return 'red accent-1'
      },
      doCopy: function () {
        const container = document.querySelector('.v-dialog');
        this.$copyText(this.editedItem.document.contact_email, container);
      },
      editShipmentItem (item) {
        this.dialogWidth = 1500;
        this.itemEditing = true
        this.$store.dispatch( 'loadIntegrationShipmentEdit', {slug:  item.id});  
        //this.dialog = true
      },
      refreshOrderStatus(order){
        this.$store.dispatch( 'editIntegrationDocumentOrderStatus', {slug:this.editedItem.id,order_id:  order.order_id});
      },
      integrationIdChange(){
        this.$store.dispatch( 'editIntegrationDocumentIntegrationId', {slug:this.editedItem.id, integration_id:  this.editedItem.integration_id});
      },
      close(){
        this.$v.$reset();
        this.$emit('close');
      },
      handleSubmit () {
        this.$v.$touch()
         if (this.$v.$invalid) {
           
        } else {
            // if(this.independentItem){//new item
            //    this.$store.dispatch( 'addIntegrationDocument', {
            //     buyer:  this.editedItem.buyer,
            //     amount: this.editedItem.amount,
            //     amount_currency: this.editedItem.amount_currency,
            //     custom_order: this.editedItem.custom_order
            //   });
            // }else{
            //   this.$store.dispatch( 'editIntegrationDocument', {
            //     slug:  this.editedItem.id,
            //     order:  this.editedItem.order,
            //     carrier:  this.prepareCarrier(),
            //     pickup_list:  this.preparePickupList(),
            //     buyer:  this.editedItem.buyer,
            //     amount: this.editedItem.amount,
            //     amount_currency: this.editedItem.amount_currency,
            //     custom_order: this.editedItem.custom_order
            //   });
            // }
        }
      }
    },
  }
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color:red;
  border-width: 2px;
}
a.offerLink{
  text-decoration: none;
  color: inherit;
}
a.offerLink:hover{
  text-decoration: underline;
}

</style>

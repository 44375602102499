<template>
     <v-dialog v-model="dialog" max-width="500px" persistent>
                    
                    <v-card :loading="userEditStatus===1">
                        
                        <v-card-text class="pb-0">
                            <v-container>
                                <v-row>
                                    <v-col cols="5">
                                        <v-img src="/logus.png"></v-img>
                                    </v-col>
                                    <v-col cols="7" class="pt-6">
                                        <v-text-field 
                                            :error-messages="passwordErrors" 
                                            required 
                                            @blur="$v.password.$touch()"  
                                            v-model="password"
                                            :type="showPassword ? 'text' : 'password'"                               
                                            @keydown.enter="changePassword"
                                            label="nowe hasło">
                                            
                                            <template v-slot:append>
                                                <v-icon tabindex="-1" @click="showPassword = !showPassword">{{showPassword ? 'mdi-eye' : 'mdi-eye-off'}}</v-icon>
                                            </template>

                                            </v-text-field>

                                            <v-text-field 
                                            :error-messages="passwordConfirmErrors" 
                                            required 
                                            @blur="$v.passwordConfirm.$touch()"  
                                            v-model="passwordConfirm"
                                            :type="showPasswordConfirm ? 'text' : 'password'"                               
                                            @keydown.enter="changePassword"
                                            label="powtórz hasło">
                                            
                                            <template v-slot:append>
                                                <v-icon tabindex="-1" @click="showPasswordConfirm = !showPasswordConfirm">{{showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'}}</v-icon>
                                            </template>

                                            </v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row v-if="userEditStatus===3">
                                    <v-col class="d-flex justify-center">
                                        <v-alert dense outlined type="error" class="mb-0">
                                        Nieprawidłowe hasło
                                        </v-alert>    
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions class="pr-6 pb-3">
                            <p class="text--secondary caption ml-5">Minimalne wymagania hasła <br />Minimum 8 znaków, 1 duża litera, 1 cyfra</p>

                            <v-spacer></v-spacer>
                            <v-btn color="grey" @click="$router.go(-1)" text>Anuluj</v-btn>
                            <v-btn color="primary darken-1" text @click="changePassword">Zmień hasło</v-btn>
                        </v-card-actions>
                    </v-card>
     </v-dialog>
                    
</template>

<script>
import { LOGUS_CONFIG } from '../config'
//import myLogoPng from "../img/logo_duze.png"
import {required, sameAs, minLength } from 'vuelidate/lib/validators'
import passwordComplexity from '../plugins/passwordComplexity'


export default {
  data: () => ({
    showPassword:false,
    showPasswordConfirm:false,
    dialog:true,
    password:'',
    passwordConfirm:'',
    //myLogo:myLogoPng
  }),
  validations:{
          password:{
            required,
            minLength: minLength(8),
            passwordComplexity
          },
          passwordConfirm:{
            sameAsPassword: sameAs('password'),
          },
  },
  watch: {
     userEditStatus(val){
        if(val===2){
          this.$router.go(-1)
        }
      }
  },
  computed: {
      user(){
        return this.$store.getters.getUser;
      },
      userEditStatus(){
        return this.$store.getters.getUserEditStatus();
      },
      passwordErrors () {
        const errors = []
        if ( !this.$v.password.$dirty) return errors
        !this.$v.password.required && errors.push('Hasło jest wymagane')
        !this.$v.password.minLength && errors.push('Musi mieć minimum 8 znaków')
        !this.$v.password.passwordComplexity && errors.push('Hasło jest zbyt proste')
        return errors
      },
      passwordConfirmErrors () {
        const errors = []
        if (!this.$v.passwordConfirm.$dirty) return errors
        !this.$v.passwordConfirm.sameAsPassword && errors.push('Hasła nie są identyczne')
        return errors
      },

  },
  created() {
    
  },

  methods: {
    changePassword(){
      this.$v.$touch()
        if (!this.$v.$invalid) {
            this.$store.dispatch( 'editUserPassword', {
                slug: this.user.id,
                password: this.password,
                oneTimePassword:  false
            });
        }
    }
  }
};
</script>

<style scoped>

</style>
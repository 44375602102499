import IntegrationDocumentAPI from "../api/integrationDocument";

export const stornChecks = {
    state: {
        StornChecks: [],
        StornCheckOptions:{
            orderBy:undefined,
            orderDirection:undefined,
            accountId:undefined,
            statusId:undefined,
            showActive:true,
            fromDate:undefined,
            toDate:undefined,
            typeId:undefined,
            codido_qty:undefined,
            codido_sum:undefined,
            spedix_qty:undefined,
            spedix_sum:undefined
        },
        StornChecksLoadStatus: 0,
    },

    actions: {
        loadStornChecks({ state, commit, dispatch,rootState },data) {
            commit("setStornChecksLoadStatus", 1);
                IntegrationDocumentAPI.postStornChecks({
                    accountId:state.StornCheckOptions.accountId,
                    statusId:state.StornCheckOptions.statusId,
                    showActive:state.StornCheckOptions.showActive,
                    typeId:state.StornCheckOptions.typeId,
                    fromDate:state.StornCheckOptions.fromDate,
                    toDate:state.StornCheckOptions.toDate
                })
                .then(function(response) {
                    commit("setStornCheckLineCount", response.data.lineCount);
                    commit("setStornChecks", response.data.StornChecks);
                    commit("setStornCheckSummary",response.data);
                    dispatch("applyIndexOnStornChecks");
                    commit("setStornChecksLoadStatus", 2);
                })
                .catch(function() {
                    commit("setStornChecks", []);
                    commit("setStornChecksLoadStatus", 3);
                });
         
        },
        setStornCheckOptions({ state, commit, dispatch },data){
            commit("setStornCheckOptions",data.options);
            //dispatch("loadStornChecks");
        },
        applyIndexOnStornChecks({ commit, state, dispatch, rootState }) {
            let localStornChecks = state.StornChecks;

            var i = 1;
            localStornChecks.forEach(StornCheck => {
                StornCheck.lp = i;
                i++;
            });

            commit("setStornChecks",localStornChecks);
        },
        
    },
    mutations: {
        setStornCheckOptions(state,options){
            state.StornCheckOptions = options;
        },
        setStornChecksLoadStatus(state, status) {
            state.StornChecksLoadStatus = status;
        },
        setStornChecks(state, StornChecks) {
            state.StornChecks = StornChecks;
        },
    
        setStornCheckLineCount(state, lineCount) {
            state.StornCheckOptions.lineCount = lineCount;
        },
        setStornCheckSummary(state, data) {
            state.StornCheckOptions.codido_qty = data.codido_qty
            state.StornCheckOptions.codido_sum = data.codido_sum
            state.StornCheckOptions.spedix_qty = data.spedix_qty
            state.StornCheckOptions.spedix_sum = data.spedix_sum
        },
    },

    getters: {
        getStornCheckOptions(state){
            return state.StornCheckOptions;
        },
        getStornChecksLoadStatus(state) {
            return function(){
                return state.StornChecksLoadStatus;
            }
        },
        getStornChecks(state) {
            return state.StornChecks;
        },
    }
};

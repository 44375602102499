<template>
  <v-app id="inspire">
    <navigation v-if="$route.name !== 'logowanie' && $route.name !== 'jednorazowe' && $route.name !== 'haslo'" ></navigation>

    <v-main>
      <v-container
        class="pt-5"
        fluid
      >
      
      <v-row no-gutters>
        <router-view></router-view>
        <v-col md="auto">
          <router-view name="menu"></router-view>
        </v-col>
        
      </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import Navigation from '../components/global/Navigation.vue';
  import _isEmpty from "lodash/isEmpty"

  export default {
    components: {
      Navigation,
    },
    computed:{
      user(){
        return this.$store.getters.getUser;
      },
    },
    watch:{
      user(val){
        if(_isEmpty(val)){//wylogowanie
          this.$store.dispatch( 'logoutUser');
          // setInterval(() => {
          //     window.location.reload();
          // }, 5000);
          //window.location.reload
          this.$router.push({ path: '/logowanie' }); 
        }
      }
    },
    mounted(){
      this.checkUser();
    },
    methods:{
      checkUser(){
        setInterval(() => {
          if(!_isEmpty(this.user)){
            this.$store.dispatch('loadUser');
          }
        }, 60000);
      },
    },
  }
</script>
<template>
<v-row no-gutters>
      <v-col>
          <v-card flat>
            <v-data-table
                dense
                :key="componentKey"
                hide-default-footer
                :headers="computedHeaders"
                :items="tableItems"
                class="elevation-1"
                :options.sync="tableOptions"
                :server-items-length="options.lineCount"
                :loading="integrationShipmentsLoadStatus===1 || integrationShipmentEditLoadStatus===1"
                must-sort
                :show-select="multiEdit"
                v-model="tableItemsSelected"
            >
                <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-toolbar-title>Wysyłki</v-toolbar-title>
                    
                    <v-divider
                    class="mx-4"
                    inset
                    vertical
                    ></v-divider>
                    
                    <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-on="on"
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Wyszukaj"
                          single-line
                          hide-details
                          class="search"
                          @keydown.enter="searchIntegrationShipments"
                         
                      ></v-text-field>
                      </template>
                        <span>Wpisz min 3 znaki i naciśnij Enter</span>
                    </v-tooltip>

                    <v-spacer></v-spacer>

                    <v-switch class="mt-4" v-model="multiEdit" label="Zaznaczanie" @change="multiEditChange"></v-switch>
                    <v-divider
                      class="mx-4"
                      inset
                      vertical
                    ></v-divider>

                    <v-progress-circular v-if="autoRefresh" class="mr-2" color="primary" width="3" size="24" :value="100-Math.round(refreshCount/60*100)"></v-progress-circular>
                    <v-switch v-model="autoRefresh" class="mt-5" label="Automatyczne odświeżanie"></v-switch>
                

                    <v-dialog v-model="dialog" :max-width="dialogWidth">
                      <shipmentEditor v-on:close="dialog=false" v-on:updated="loadIntegrationShipments()" />
                    </v-dialog>

                     <!-- <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-btn @click="newItem()" v-on="on" small class="ml-3 mb-2 mt-2" fab dark color="primary">
                          <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                      </template>
                        <span>Dodaj wysyłkę nizależną</span>
                    </v-tooltip> -->
                    
                </v-toolbar>
                </template>
                
                <template v-slot:item.amount="{ item }">
                  {{item.amount?$n(item.amount + (item.carrier.delivery_cost_amount?item.carrier.delivery_cost_amount:0),'number','pl-PL'):''}} {{item.amount?item.amount_currency:''}}
                </template>
                <template v-slot:item.delivery_cost_amount="{ item }">
                  {{item.carrier.delivery_cost_amount?$n(item.carrier.delivery_cost_amount,'number','pl-PL'):''}} {{item.carrier.delivery_cost_amount?item.amount_currency:''}}
                </template>
                 <template v-slot:item.integration_id="{ item }">
                    {{item.integration_id?_.find(lookups.integrations,{'value':item.integration_id}).text:''}}
                </template>
                <template v-slot:item.status="{ item }">

                 <v-tooltip right>
                      <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        :color="_.find(lookups.integrationShipmentStatus,{'value':item.status}).color"
                      >
                        {{_.find(lookups.integrationShipmentStatus,{'value':item.status}).icon}}
                      </v-icon>
                      </template>
                      <span>{{_.find(lookups.integrationShipmentStatus,{'value':item.status}).text}}</span>
                </v-tooltip>

                </template>
                <template v-slot:item.details="{ item }">
                      <v-icon
                        v-if="item.COD"
                        size="28"
                        class="mr-2"
                        color="green darken-4"
                      >
                        mdi-cash-100
                      </v-icon>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn color="primary" x-small @click="editItem(item)">Otwórz</v-btn>
                </template>
            </v-data-table> 
            <div class="text-center pt-2">
            <v-btn @click="loadMore" v-if="!options.itemAdded && options.lineCount-tableItems.length>0 && integrationShipmentsLoadStatus===2" small color="primary"><v-icon left>mdi-download</v-icon>Załaduj więcej ({{ options.lineCount-tableItems.length }})</v-btn>
            <v-btn @click="loadAll(options.lineCount-tableItems.length)" v-if="!options.itemAdded && options.lineCount-tableItems.length>0 && integrationShipmentsLoadStatus===2" small color="error" class="ml-4"><v-icon left>mdi-download-lock</v-icon>Załaduj wszystkie ({{ options.lineCount-tableItems.length }})</v-btn>
            
            <v-btn @click="loadIntegrationShipments" v-else-if="options.itemAdded && options.lineCount-tableItems.length>0" small color="primary"><v-icon left>mdi-reload</v-icon>Odśwież tabele aby móc załadować więcej ({{ options.lineCount-tableItems.length }})</v-btn>
            <v-btn @click="loadIntegrationShipments" v-else-if="options.itemSearched" small color="primary"><v-icon left>mdi-reload</v-icon>Anuluj wyszukiwanie</v-btn>
          
          </div>
            <confirm ref="confirm"></confirm>
          </v-card>
      </v-col>
      <v-col md="auto">
        <menuIntegracjeWysylki>
          <template v-slot:actions>
            <v-btn @click="multiDelete" v-if="multiEdit" :disabled="tableItemsSelected.length<=0" large color="primary"  width="250" active-class="aa" exact>
                Usuń wybrane
            </v-btn>
            <v-btn @click="mergeItems" v-if="multiEdit" :disabled="tableItemsSelected.length<=0 || !selectedItemsCanBeMerged" large color="primary"  width="250" active-class="aa" exact>
                Połącz wybrane
            </v-btn>
          </template>
        </menuIntegracjeWysylki>
      </v-col>
    </v-row>
</template>

<script>
  import confirm from '../components/Confirm'
  import menuIntegracjeWysylki from '../components/MenuIntegracjeWysylki'
  import shipmentEditor from '../components/IntegrationShipmentEditor'

  export default {
    components:{
      confirm,
      menuIntegracjeWysylki,
      shipmentEditor
    },
    props:['aId'],
    data: () => ({
      componentKey:0,
      autoRefresh:false,
      refreshCount:0,
      multiEdit:false,
      tableOptions: {},
      tableItemsSelected:[],
      search:'',
      dialog: false,
      itemEditing:false,
      dialogWidth:1500,
      headers: [
        { text: 'Lp',align: 'start',sortable: false,value: 'lp',filterable:false,visible:true},
        { text: 'Status', value: 'status',sortable:true,filterable:true,filterColumn:"status",visible:true  },
        { text: 'Nr CODIDO', value: 'ext_shipment_id',sortable: true,filterable:true,filterColumn:"ext_shipment_id",visible:true },
        { text: 'Suma wysyłki', value: 'amount',sortable: true,filterable:true,filterColumn:"amount",visible:true },
        { text: 'Koszt dostawy', value: 'delivery_cost_amount',sortable: false,filterable:false,visible:true },
        // { text: 'Kupujący', value: 'buyer.buyer_login',sortable: false,filterable:false,filterColumn:"buyer_login",visible:true },
        // { text: 'Kupujący', value: 'buyer',sortable: false,filterable:true,filterColumn:"buyer",visible:false },
        { text: 'Zamówienie', value: 'order',sortable: false,filterable:true,filterColumn:"order",visible:false },
        // { text: 'Produkty', value: 'products',sortable: false,filterable:true,filterColumn:"products",visible:true },
        { text: 'Nadawca', value: 'integration_id',sortable:true,filterable:true,filterColumn:"integration_id",visible:true },
        { text: 'Nr nadania', value: 'shipment_id',sortable:true,filterable:true,filterColumn:"shipment_id",visible:true  },
        { text: 'Data wysłania', value: 'pickup_date',sortable:true,filterable:true,filterColumn:"pickup_date",visible:true  },
        { text: '', value: 'details',align: 'center', sortable: false,filterable:false,visible:true},
        { text: '', value: 'actions', sortable: false,filterable:false,visible:true},
      ],
    }),
    validations() {
      
    },
    beforeRouteLeave (to, from, next) {
      clearInterval(this.interval);
      next();
    },
    watch: {
      autoRefresh(val){
        if(val){
          this.refreshCount = 0;
          this.interval = setInterval(() => {
            this.refreshCount++;
            if(this.refreshCount>=60){
              this.loadIntegrationShipments();
              this.refreshCount = 0;
            }
          }, 1000);
        }else{
          clearInterval(this.interval);
        }
      },
      tableOptions: {
        handler () {
          var currentOptions = {...this.options};
          const { sortBy, sortDesc } = this.tableOptions
          //zmiana sortowania
          if (sortBy.length === 1 && sortDesc.length === 1) {
            currentOptions.orderBy = sortBy[0];
            currentOptions.orderDirection = sortDesc[0]?'desc':'asc';
            this.options = currentOptions;
          } 
        },
        deep: true,
      },
      dialog (val) {
        //this.$v.$reset()
        if(val){

        }else{
          this.close()
        }
      },

      // integrationShipmentEditStatusParent(val){
      //   if(val===2){
      //     //this.componentKey++;
      //   }
      // },
      integrationShipmentDeleteStatus(val){
        if(val===2){
          this.tableItemsSelected = [];
        }
      },
      integrationShipmentEditLoadStatus(val){
        if(val===2){
          this.dialog = true;
        }
      },
      integrationShipmentAddStatus(val){
        if(val===2){
          this.dialogWidth = 1500;
          this.itemEditing = true
          //this.dialog = false;
        }
      }
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      lookups(){
        return this.$store.getters.getLookups;
      },
      options:{
        get () {  
          return this.$store.getters.getIntegrationShipmentOptions;
        },
        set (value) {
          this.search='';
          this.$store.dispatch( 'setIntegrationShipmentOptions', {options:value} )
        }
      },
      accountId: {
        get () {
          return this.$store.getters.getIntegrationShipmentOptions.accountId;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.accountId = value;
          this.options = currentOptions;
        }
      },
      integrationShipment(){
        return this.$store.getters.getIntegrationShipment;
      },
      editedItem(){
        return this.$store.getters.getIntegrationShipmentEdit;
      },
      tableItems(){
        return this.$store.getters.getIntegrationShipments;
      },
      // roles(){
      //   return _.filter(this.$store.getters.getGroups,function(o) { return o.value!=4; });
      // },
      // allRoles(){
      //   return this.$store.getters.getGroups;
      // },
      integrationShipmentsLoadStatus(){
        return this.$store.getters.getIntegrationShipmentsLoadStatus;
      },
      integrationShipmentEditLoadStatus(){
        return this.$store.getters.getIntegrationShipmentEditLoadStatus()
      },
      // integrationShipmentEditStatus(){
      //   return this.$store.getters.getIntegrationShipmentEditStatus();
      // },
      integrationShipmentEditText(){
        return this.$store.getters.getIntegrationShipmentEditText
      },
      integrationShipmentAddStatus(){
        return this.$store.getters.getIntegrationShipmentAddStatus();
      },
      integrationShipmentAddText(){
        return this.$store.getters.getIntegrationShipmentAddText;
      },
      integrationShipmentDeleteStatus(){
        return this.$store.getters.getIntegrationShipmentDeleteStatus();
      },
      selectedItemsCanBeMerged(){
        var buyersStrings = [];
        this.tableItemsSelected.forEach(shipment => {
          buyersStrings.push(JSON.stringify(_.omit(shipment.buyer,['contact_email'])));
        });

        return buyersStrings.length>1 && //wiecej niz jestna zaznaczona
        _.uniq(buyersStrings).length==1 &&  //buyer identyczny w zaznaczonych
        _.uniq(_.map(this.tableItemsSelected,'status')).length===1 && //status identyczny w zaznaczonych
        _.uniq(_.map(this.tableItemsSelected,'status'))[0] === 1; //wszystkie nowe

      },
      computedHeaders(){
        return _.filter(this.headers,function(o) { return o.visible; })
      },
      
    },
    mounted(){
      //console.log(window.selected_device)
      if(this.lookups.integrations.length>0){
        this.loadIntegrationShipments();
      }else{
        this.$store.dispatch( 'loadLookups' );
        setTimeout(() => {
          this.loadIntegrationShipments();
        }, 1000)
      }
    },
    methods: {
      multiEditChange(){
        if(!this.multiEdit){
          this.tableItemsSelected = [];
        }
      },
      
      loadIntegrationShipments(){
        this.search='';
        this.accountId = this.aId;//pobiera z propsów

        //this.$store.dispatch( 'loadIntegrationShipments' );
      },
      loadMore(){
        this.$store.dispatch( 'loadMoreIntegrationShipments' );
      },
      loadAll(len){
        this.$refs.confirm.open('Uwaga', 'Czy na pewno chcesz pobrać resztę rekordów? ('+len+')', { color: 'warning' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'loadAllIntegrationShipments' );
            }
        })
        
      },
      errorMessageArray(val) {
        const errors = val;
        var err = [];
        if(!errors)return [];

        if(errors.email){
          errors.email.forEach(e => {
            err.push(e);
          });
        }

        if(errors.name){
          errors.name.forEach(e => {
            err.push(e);
          });
        }
      
        return err;
      },     
     
      editItem (item) {
        this.dialogWidth = 1500;
        this.itemEditing = true
        this.$store.dispatch( 'loadIntegrationShipmentEdit', {slug:  item.id});  
        //this.dialog = true
      },
      newItem(){
        //this.$v.$reset()
        this.dialogWidth = 1000;
        this.itemEditing = true;
        this.dialog = true;
      },
      searchIntegrationShipments(){
        if(this.search.length>2){
          const filterableHeaders = _.join(_.map(_.filter(this.headers,{'filterable':true}),'filterColumn'),';');
          this.$store.dispatch( 'searchIntegrationShipments',{filterableHeaders:filterableHeaders,q:this.search});
        }
      },
     
      mergeItems () {
        this.$refs.confirm.open('Usuń', 'Czy na pewno chcesz połączyć wybrane wysyłki? (koszty wysyłek też zostaną zsumowane)', { color: 'error' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'editIntegrationShipmentMerge', {slug:  _.map(this.tableItemsSelected,'id')});
               this.tableItemsSelected = [];
            }
        })
      },
      multiDelete(){
        this.$refs.confirm.open('Usuń', 'Czy na pewno chcesz usunać wysyłkę?', { color: 'error' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'forceDeleteIntegrationShipment', {slug:  _.map(this.tableItemsSelected,'id')});
            }
        })
      },
      close () {
        this.dialog = false
        //this.$v.$reset()
        setTimeout(() => {
          this.$store.dispatch( 'loadIntegrationShipmentEditDefault'); 
          this.$store.dispatch( 'loadIntegrationShipmentAddDefault');
          this.itemEditing = false
        }, 300)
      },
      handleSubmit () {
        this.$v.$touch()
        if (this.$v.$invalid) {

        } else {
         
           
    
        }
       
      },
    },
  }
</script>


<style scoped>
.search{
      max-width: 250px;
}
</style>

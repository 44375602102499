<template>
    <v-container class="d-flex flex-column pa-0 ml-3">

          <v-btn :to="{name: 'ustawienia.firma' }" large :color="$route.name === 'ustawienia.firma' || $route.name === 'ustawienia.centrala' ? selectedColor : defaultColor"  width="250" active-class="aa" exact>
            Firma  
            </v-btn>

            <v-btn :to="{name: 'ustawienia.uzytkownicy' }" large :color="$route.name === 'ustawienia.uzytkownicy' ? selectedColor : defaultColor" dark width="250">
            Użytkownicy  
            </v-btn>

            <v-btn :to="{name: 'ustawienia.stanowiska' }" large :color="$route.name === 'ustawienia.stanowiska' ? selectedColor : defaultColor" dark width="250">
            Stanowiska  
            </v-btn>

            <v-btn :to="{name: 'ustawienia.zadania' }" large :color="$route.name === 'ustawienia.zadania' ? selectedColor : defaultColor" dark width="250">
            Zadania  
            </v-btn>

            <v-btn :to="{name: 'ustawienia.ustawienia' }" large :color="$route.name === 'ustawienia.ustawienia' ? selectedColor : defaultColor" dark width="250">
            Integracje  
            </v-btn>

            <!-- <v-btn :to="{name: 'ustawienia.produkty' }" large :color="$route.name === 'ustawienia.produkty' ? selectedColor : defaultColor" dark width="250">
            Produkty  
            </v-btn> -->
    </v-container>
</template>

<script>
export default {
    data(){
        return{
            defaultColor:'secondary', 
            selectedColor:'primary'
        }
    },
    mounted(){

    },
    computed:{
     
    },
}
</script>

<style scoped>
.v-btn{
  margin-bottom: 1em;
}
.v-chip{
  margin-left: 1em;
}
.v-chip:hover{
    cursor: pointer;
}

.v-btn:not(.v-btn--text):not(.v-btn--outlined).v-btn--active:before {
    opacity: 0;
}

</style>
<template>
<v-row no-gutters>
      <v-col>
          <v-card flat>
            <v-data-table
                dense
                :key="componentKey"
                hide-default-footer
                :headers="computedHeaders"
                :items="tableItems"
                class="elevation-1"
                :items-per-page="-1"
                :options.sync="tableOptions"
                v-model="tableItemsSelected"
            >
                <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-toolbar-title>Kontrola Tpay</v-toolbar-title>
                    
                    <v-dialog v-model="dialog" :max-width="dialogWidth">
                      <orderEditor v-on:close="dialog=false" />
                    </v-dialog>

                    <v-spacer></v-spacer>


                     <!-- <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-btn @click="newItem()" v-on="on" small class="ml-3 mb-2 mt-2" fab dark color="primary">
                          <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                      </template>
                        <span>Dodaj wysyłkę nizależną</span>
                    </v-tooltip> -->
                    
                </v-toolbar>
                </template>
                 <template v-slot:item.Kwota="{ item }">
                    {{item.Kwota?$n(item.Kwota,'number','pl-PL'):''}} {{item.Kwota?item.Waluta:''}}
                 </template>
                 <template v-slot:item.Status="{ item }">
                   <v-icon color="green" v-if="item.Status===1">mdi-check-circle-outline</v-icon>
                   <v-icon color="error" v-else>mdi-circle-outline</v-icon>
                   <span class="font-weight-medium ml-2">{{_.find(lookups.payUCheckStaus,{'value':item.Status}).text}}</span>
                 <!-- <v-tooltip right>
                      <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        :color="_.find(lookups.TpayCheckStatus,{'value':item.status}).color"
                      >
                        {{_.find(lookups.TpayCheckStatus,{'value':item.status}).icon}}
                      </v-icon>
                      </template>
                      <span>{{_.find(lookups.TpayCheckStatus,{'value':item.status}).text}}</span>
                </v-tooltip> -->
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn v-if="item['Spedix Order ID']" color="primary" x-small @click="openOrder(item['Spedix Order ID'],item['Kwota'])">Zamówienie</v-btn>
                </template>
                <!--
                 <template v-slot:item.amount_net="{ item }">
                  {{item.amount_net?$n(item.amount_net,'number','pl-PL'):''}} {{item.amount_net?item.amount_currency:''}}
                </template>
                <template v-slot:item.amount_payed="{ item }">
                  {{item.amount_payed?$n(item.amount_payed,'number','pl-PL'):''}} {{item.amount_payed?item.amount_currency:''}}
                </template>
                 
                <template v-slot:item.status="{ item }">
                 <v-tooltip right>
                      <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        :color="_.find(lookups.TpayCheckStatus,{'value':item.status}).color"
                      >
                        {{_.find(lookups.TpayCheckStatus,{'value':item.status}).icon}}
                      </v-icon>
                      </template>
                      <span>{{_.find(lookups.TpayCheckStatus,{'value':item.status}).text}}</span>
                </v-tooltip>
                </template>
                
                <template v-slot:item.details="{ item }">

                </template>
                 -->
            </v-data-table> 
           
            <confirm ref="confirm"></confirm>
          </v-card>
      </v-col>
      <v-col md="auto"
      >
        <v-container class="d-flex flex-column pa-0 ml-3">
         <v-dialog v-model="dialogImport" max-width="500px">
                  <template v-slot:activator="{ on }">
                    <v-btn :disabled="tableItems.length!=0" v-on="on" large color="primary"  width="250" active-class="aa" exact>Wczytaj plik CSV</v-btn>
                  </template>
                  <import-tpay @close="closeImport"></import-tpay>
          </v-dialog>

          <v-btn @click="clearData" :disabled="tableItems.length===0" large color="primary"  width="250" active-class="aa" exact class="mt-5">
                Wyszyść dane
          </v-btn>
          <v-card width="250" class="mt-5">
              <v-card-text>
                <div>
                  <v-container class="pa-0 widget-header"><strong>Status</strong></v-container>
                  <v-row no-gutters dense><v-checkbox :disabled="statusId>0" hide-details v-model="showActive" label="Tylko błędne"></v-checkbox></v-row>
                  <v-row no-gutters dense><v-select hide-details clearable v-model="statusId" :items="lookups.payUCheckStaus" label="Wybrany status"></v-select></v-row>
                </div>
              </v-card-text>
        </v-card>
        </v-container>
        <!-- <menuIntegracjeDokumenty>
          <template v-slot:actions>
            <v-btn @click="multiDelete" v-if="multiEdit" :disabled="tableItemsSelected.length<=0" large color="primary"  width="250" active-class="aa" exact>
                Usuń wybrane
            </v-btn>
            <v-btn @click="advanceCSV" v-if="multiEdit" :disabled="tableItemsSelected.length<=0 || _.difference(_.map(tableItemsSelected,'type'),[_.find(lookups.TpayCheckTypes,{'value':1}).text]).length>0" large color="primary"  width="250" active-class="aa" exact>
                Zaliczki CSV
            </v-btn>
            <v-btn @click="stornCSV" v-if="multiEdit" :disabled="tableItemsSelected.length<=0 || _.difference(_.map(tableItemsSelected,'type'),[_.find(lookups.TpayCheckTypes,{'value':10}).text]).length>0" large color="primary"  width="250" active-class="aa" exact>
                Korekty CSV
            </v-btn>
           
          </template>
        </menuIntegracjeDokumenty> -->
      </v-col>
    </v-row>
</template>

<script>
  import confirm from '../components/Confirm'
  import ImportTpay from '../components/ImportTpay.vue'
  import orderEditor from '../components/IntegrationOrderEditor'

  export default {
    components:{
      confirm,
      ImportTpay,
      orderEditor
    },
    props:['aId'],
    data: () => ({
      componentKey:0,
      tableOptions: {},
      tableItemsSelected:[],
      search:'',
      dialog: false,
      dialogWidth:1500,
      dialogImport:false,
      headers: [
        { text: 'Lp',align: 'start',sortable: false,value: 'lp',filterable:false,visible:true},
        { text: 'Data', value: 'Data',sortable: true,filterable:true,filterColumn:"Data",visible:true },
        // { text: 'Typ operacji', value: 'Typ operacji',sortable:true,filterable:true,filterColumn:"type",visible:true  },
        { text: 'Opis', value: 'Opis',sortable: true,filterable:true,filterColumn:"Opis",visible:true },
        { text: 'Imię i nazwisko', value: 'Imię i nazwisko',sortable: true,filterable:true,filterColumn:"Imię i nazwisko",visible:true },
        //{ text: 'E-mail', value: 'E-mail',sortable: false,filterable:true,filterColumn:"E-mail",visible:true },
        { text: 'Kwota', value: 'Kwota',sortable: true,filterable:true,filterColumn:"Kwota",visible:true },
        { text: 'Status', value: 'Status',sortable:true,filterable:true,filterColumn:"Status",visible:true},
        { text: '', value: 'actions', sortable: false,filterable:false,visible:true},
      ],
    }),
    validations() {
      
    },
    beforeRouteLeave (to, from, next) {
      next();
    },
    watch: {
      tableOptions: {
        handler () {
          var currentOptions = {...this.options};
          const { sortBy, sortDesc } = this.tableOptions
          //zmiana sortowania
          if (sortBy.length === 1 && sortDesc.length === 1) {
            currentOptions.orderBy = sortBy[0];
            currentOptions.orderDirection = sortDesc[0]?'desc':'asc';
            this.options = currentOptions;
          } 
        },
        deep: true,
      },
      dialog (val) {
        //this.$v.$reset()
        if(val){

        }else{
          //this.close()
        }
      },
      TpayChecksLoadStatus(val){
        if(val==2)this.closeImport();
      },
      integrationOrderEditLoadStatus(val){
        if(val===2){
          this.dialog = true;
        }
      },
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      lookups(){
        return this.$store.getters.getLookups;
      },
      options:{
        get () {  
          return this.$store.getters.getTpayCheckOptions;
        },
        set (value) {
          this.search='';
          this.$store.dispatch( 'setTpayCheckOptions', {options:value} )
        }
      },
      accountId: {
        get () {
          return this.$store.getters.getTpayCheckOptions.accountId;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.accountId = value;
          this.options = currentOptions;
        }
      },
      statusId: {
        get () {
          return this.$store.getters.getTpayCheckOptions.statusId;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.statusId = value;
          this.options = currentOptions;
        }
      },
      showActive: {
        get () {
          return this.$store.getters.getTpayCheckOptions.showActive;
        },
        set (value) {
          var currentOptions = {...this.options};
          currentOptions.showActive = value;
          this.options = currentOptions;
        }
      },
      tableItems(){
        if(this.statusId){
          return _.filter(this.$store.getters.getTpayChecks,{'Status':this.statusId});
        }else if(this.showActive)return _.filter(this.$store.getters.getTpayChecks,(o)=>{return o.Status != 1})
        
        return this.$store.getters.getTpayChecks;
      },
      TpayChecksLoadStatus(){
        return this.$store.getters.getTpayChecksLoadStatus();
      },
      
      computedHeaders(){
        return _.filter(this.headers,function(o) { return o.visible; })
      },
      
      integrationOrderEditLoadStatus(){
        return this.$store.getters.getIntegrationOrderEditLoadStatus()
      },
    },
    mounted(){
      //console.log(window.selected_device)
      if(this.lookups.integrations.length>0){
        //this.loadTpayChecks();
      }else{
        this.$store.dispatch( 'loadLookups' );
        setTimeout(() => {
          //this.loadTpayChecks();
        }, 1000)
      }
    },
    methods: {
      loadTpayChecks(){
        this.search='';
        this.accountId = this.aId;//pobiera z propsów

        //this.$store.dispatch( 'loadTpayChecks' );
      },
      closeImport(){
        this.dialogImport = false;
      },
      clearData(){
        this.$store.commit( 'setTpayChecksLoadStatus',0);
        this.$store.commit( 'setTpayChecks',[]);
      },
      openOrder(id,amount){
        var order = _.find(this.tableItems,{'Spedix Order ID':id,'Kwota':amount})
        
        this.$store.commit( 'setIntegrationOrderPayUNote',{
          date:order['Data'],
          amount:order['Kwota'],
          currency:order['Waluta'],
          status:order['Status'],
          desc:order['Opis']
        });

        this.$store.dispatch( 'loadIntegrationOrderEdit', {slug:  id}); 
      },
      // editItem (item) {
      //   this.dialogWidth = 1500;
      //   this.itemEditing = true
      //   this.$store.dispatch( 'loadTpayCheckEdit', {slug:  item.id});  
      //   //this.dialog = true
      // },
      // newItem(){
      //   //this.$v.$reset()
      //   this.dialogWidth = 1000;
      //   this.itemEditing = true;
      //   this.dialog = true;
      // },
     
      // multiDelete(){
      //   this.$refs.confirm.open('Usuń', 'Czy na pewno chcesz usunać zamówienia?', { color: 'error' }).then((confirm) => {
      //       if(confirm){
      //          this.$store.dispatch( 'forceDeleteTpayCheck', {slug:  _.map(this.tableItemsSelected,'id')});
      //       }
      //   })
      // },
      
      handleSubmit () {
        this.$v.$touch()
        if (this.$v.$invalid) {

        } else {
         
    
        }
       
      },
    },
  }
</script>


<style scoped>
.search{
      max-width: 250px;
}
</style>

<template>
                  <v-card  :loading="sequenceAddStatus===1 || sequenceEditLoadStatus===1 || sequenceEditStatus===1">
                    <v-card-title>
                      <img class="mr-3" src="/logo_listek.png" />
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        
                          <v-row>
                            <v-col cols="12" sm="8" md="8">
                              <v-text-field v-model="editedItem.name" :error-messages="nameErrors" required @input="$v.editedItem.name.$touch()"  @blur="$v.editedItem.name.$touch()" label="Nazwa"></v-text-field>                            
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                              <v-select clearable v-model="editedItem.type" :items="lookups.sequenceType" label="Typ dokumentu"></v-select>                            
                            </v-col>  
                            <v-col cols="12" sm="6" md="6">
                              <v-text-field :hint="computedMask" persistent-hint v-model="editedItem.mask" :error-messages="maskErrors" required @input="$v.editedItem.mask.$touch()"  @blur="$v.editedItem.mask.$touch()" label="Maska"></v-text-field>
                            </v-col>                 
                            <v-col cols="12" sm="3" md="3">
                              <v-text-field v-model="editedItem.from" :error-messages="fromErrors" required @input="$v.editedItem.from.$touch()"  @blur="$v.editedItem.from.$touch()" label="Numer początowy"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3" md="3">
                              <v-text-field v-model="editedItem.to" :error-messages="toErrors" @input="$v.editedItem.to.$touch()"  @blur="$v.editedItem.to.$touch()" label="Numer końcowy"></v-text-field>
                            </v-col>
                          </v-row>

                            <v-row dense><v-col class="col-12"><strong>Maska może zawierać:</strong></v-col></v-row>
                            <v-row dense><v-col class="col-1">[N]</v-col><v-col>Kolejny numer porządkowy</v-col></v-row>
                            <v-row dense><v-col class="col-1">[M]</v-col><v-col>Bierzący miesiąc księgowy(1,2,..12)</v-col></v-row>
                            <v-row dense><v-col class="col-1">[MM]</v-col><v-col>Bierzący miesiąc księgowy(01,02,..12)</v-col></v-row>
                            <v-row dense><v-col class="col-1">[RRRR]</v-col><v-col>Bierzący rok księgowy(2020,2021..)</v-col></v-row>
                            <v-row dense><v-col class="col-1">[RR]</v-col><v-col>Bierzący rok księgowy(20,21..)</v-col></v-row>

                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="gray" text @click="close()">Anuluj</v-btn>
                      <v-btn color="primary darken-1" text @click="handleSubmit" >Zapisz</v-btn>
                    </v-card-actions>
                  </v-card>
</template>

<script>
  import required from 'vuelidate/lib/validators/required'
  import minLength from 'vuelidate/lib/validators/minLength'
  import maxLength from 'vuelidate/lib/validators/maxLength'
  import numeric from 'vuelidate/lib/validators/numeric'
  import email from 'vuelidate/lib/validators/email'

  export default {
    data: () => ({
    
    }),
    props:['itemEditing','itemWasMain'],
    validations: {
      editedItem:{
        name: {
          required,
        },
        from:{
          required,
          numeric
        },
        to:{
          numeric
        },
        mask:{
          required
        },
      }
    },
    watch: {
    
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      lookups(){
        return this.$store.getters.getLookups;
      },
      editedItem(){
        return this.$store.getters.getSequenceEdit;
      },
      sequenceEditLoadStatus(){
        return this.$store.getters.getSequenceEditLoadStatus
      },
      sequenceEditStatus(){
        return this.$store.getters.getSequenceEditStatus();
      },
      sequenceEditText(){
        return this.$store.getters.getSequenceEditText
      },
      sequenceAddStatus(){
        return this.$store.getters.getSequenceAddStatus();
      },
      sequenceAddText(){
        return this.$store.getters.getSequenceAddText;
      },
    
      formTitle () {
        return !this.itemEditing ? 'Nowy dokument' : 'Edycja dokumentu'
      },
      nameErrors () {
        const errors = []
        if (!this.$v.editedItem.name.$dirty) return errors
        !this.$v.editedItem.name.required && errors.push('Nazwa jest wymagana')
        return errors
      },
      fromErrors () {
        const errors = []
        if (!this.$v.editedItem.from.$dirty) return errors
        !this.$v.editedItem.from.required && errors.push('Numer początkowy jest wymagany')
        !this.$v.editedItem.from.numeric && errors.push('Numer początkowy musi być liczbą')
        return errors
      },
      toErrors () {
        const errors = []
        if (!this.$v.editedItem.to.$dirty) return errors
        !this.$v.editedItem.to.numeric && errors.push('Numer końcowy musi być liczbą')
        return errors
      },
      maskErrors () {
        const errors = []
        if (!this.$v.editedItem.mask.$dirty) return errors
        !this.$v.editedItem.mask.required && errors.push('Maska jest wymagana')
        return errors
      },
      computedMask(){
        var date = new Date(); 
        var mask = this.editedItem.mask;
        mask = _.replace(mask,'[RRRR]',(date.toISOString().substr(0,4)))
        mask = _.replace(mask,'[RR]',(date.toISOString().substr(2,2)))
        mask = _.replace(mask,'[MM]',(date.toISOString().substr(5,2)))
        mask = _.replace(mask,'[M]',(parseInt(date.toISOString().substr(5,2))))
        mask = _.replace(mask,'[N]',this.editedItem.from)
        return 'Przykładowy numer: ' + mask;
      }
    },
 
    methods: {
      close(){
        this.$v.$reset();
        this.$emit('close');
      },
       handleSubmit () {
        this.$v.$touch()
        if (this.$v.$invalid) {

        } else {
         
            if (this.itemEditing) {
              //edycja
              this.$store.dispatch( 'editSequence', {
                slug: this.editedItem.id,
                type:  this.editedItem.type,
                name:  this.editedItem.name,
                from:  this.editedItem.from,
                to: this.editedItem.to,
                next: this.editedItem.next,
                mask:  this.editedItem.mask,
              });
            } else {//new item
              this.$store.dispatch( 'addSequence', {
                  type:  this.editedItem.type,
                  name:  this.editedItem.name,
                  from:  this.editedItem.from,
                  to: this.editedItem.to,
                  mask:  this.editedItem.mask,
              });

            }
        }
      },
    },
  }
</script>


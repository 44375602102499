import { FLOREX_CONFIG } from '../config'
import axios from 'axios'

export default {

    
//     /*
//         GET     /api/integrations
//     */
//     getIntegrations: function(options){
//         return axios.get( FLOREX_CONFIG.API_URL + '/integrations',{
//             params:{
//                 offset:options.offset,
//                 itemsPerPage:options.itemsPerPage,
//                 orderBy:options.orderBy,
//                 orderDirection:options.orderDirection,
//                 showUnactive:options.showUnactive
//         }});
//     },

//     /*
//         GET     /api/integrations/search
//     */
//    searchIntegrations: function(options){
//     return axios.get( FLOREX_CONFIG.API_URL + '/integrations/search',{
//         params:{
//             showUnactive:options.showUnactive,
//             filterableHeaders:options.filterableHeaders,
//             q:options.q
//     }});
// },

    /*
		POST 	/api/integrations
	*/
    postAddNewIntegration: function( type){
        return axios.post( FLOREX_CONFIG.API_URL + '/integrations',
            {
                type:type
            }
        );
    },

    /*
		GET 	/api/integrations/{slug}/edit
	*/
    getIntegrationEdit: function( slug ){
		return axios.get( FLOREX_CONFIG.API_URL + '/integrations/' + slug + '/edit' );
    },
    
     /*
		GET 	/api/integrations/{slug}/test
	*/
    getConnectionTest: function( slug ){
		return axios.get( FLOREX_CONFIG.API_URL + '/integrations/' + slug + '/test' );
	},

    /*
		GET 	/api/integrations
	*/
    getIntegrations: function( options ){
		return axios.get( FLOREX_CONFIG.API_URL + '/integrations',{
                        params:{
                            orderBy:options.orderBy,
                            orderDirection:options.orderDirection,
                            accountId:options.accountId
                }});
    },

    /*
		PUT 	/api/integrations/{slug}
	*/
    putEditIntegration: function(slug, name, integrable_type,integrable){
        return axios.put( FLOREX_CONFIG.API_URL + '/integrations/'+ slug,
            {
                name: name,
                integrable_type:integrable_type,
                integrable:integrable
            }
        );
    },

    /*
		PUT 	/api/integrations/allegro
	*/
    putAllegroCode: function(id, code){
        return axios.put( FLOREX_CONFIG.API_URL + '/integrations/allegro',
            {
                id: id,
                code:code,
            }
        );
    },

    /*
		DELETE /api/integrations/{slug}/force
	*/
    forceDeleteIntegration: function( slug ){
		return axios.delete( FLOREX_CONFIG.API_URL + '/integrations/' + slug + '/force' );
    },
    
}
import { FLOREX_CONFIG } from '../config'
import axios from 'axios'

export default {

    /*
        GET     /api/bankAccounts
    */
    getBankAccounts: function(options){
        return axios.get( FLOREX_CONFIG.API_URL + '/bankAccounts',{
            params:{
                offset:options.offset,
                itemsPerPage:options.itemsPerPage,
                orderBy:options.orderBy,
                orderDirection:options.orderDirection,
                showDeleted:options.showDeleted,
        }});
    },

    /*
        GET     /api/bankAccounts/search
    */
   searchBankAccounts: function(options){
    return axios.get( FLOREX_CONFIG.API_URL + '/bankAccounts/search',{
        params:{
            showDeleted:options.showDeleted,
            filterableHeaders:options.filterableHeaders,
            q:options.q
    }});
},

    /*
		POST 	/api/bankAccounts
	*/
    postAddNewBankAccount: function( bank, iban, currency, swift, cod, document ){
        return axios.post( FLOREX_CONFIG.API_URL + '/bankAccounts',
            {
                bank: bank,
                iban: iban,
                currency: currency,
                swift: swift,
                cod: cod,
                document: document
            }
        );
    },

    /*
		GET 	/api/bankAccounts/{slug}/edit
	*/
    getBankAccountEdit: function( slug ){
		return axios.get( FLOREX_CONFIG.API_URL + '/bankAccounts/' + slug + '/edit' );
	},

    /*
		PUT 	/api/bankAccounts/{slug}
	*/
    putEditBankAccount: function(slug, bank, iban, currency, swift, cod, document){
        return axios.put( FLOREX_CONFIG.API_URL + '/bankAccounts/'+ slug,
            {
                bank: bank,
                iban: iban,
                currency: currency,
                swift: swift,
                cod: cod,
                document: document
            }
        );
    },

     /*
		GET 	/api/bankAccounts/reactivate/{slug}
	*/
    putReactivateBankAccount: function(slug){
        return axios.get( FLOREX_CONFIG.API_URL + '/bankAccounts/reactivate/' + slug);
    },

    /*
		DELETE /api/bankAccounts/{slug}
	*/
    deleteBankAccount: function( slug ){
		return axios.delete( FLOREX_CONFIG.API_URL + '/bankAccounts/' + slug );
    },
    
    /*
		DELETE /api/bankAccounts/{slug}/force
	*/
    forceDeleteBankAccount: function( slug ){
		return axios.delete( FLOREX_CONFIG.API_URL + '/bankAccounts/' + slug + '/force' );
	}
}